import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { isMobile } from "react-device-detect";
// import SignInTab from "./components/SingInTab";
// import SignUpTab from "./components/SignUpTab";
import mainImg from "./../../img/focusNext.png";
import newslider23 from "./../../img/newslider23.jpeg";
import sliderimg1 from "./../../img/sliderimg1.png";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { loginUser } from "../../redux/slices/auth/authService";
import { updateUserDetails } from "./../../redux/slices/auth/authSlice";
// import "~react-image-gallery/styles/css/image-gallery.css";
import { triggerMixpanelEvent } from "../../Scenes/common";
import ImageGallery from "react-image-gallery";
import ForgotPasswordModel from "./../../components/ForgotPasswordModel";

const images = [
  {
    original:
      "https://kylas.io/wp-content/themes/kylas/min-images/easy-use-crm.svg",
  },
  {
    original:
      "https://kylas.io/wp-content/themes/kylas/min-images/secure-tech.svg",
  },

  {
    original:
      "https://kylas.io/wp-content/themes/kylas/min-images/expert-help.svg",
  },
];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = (props) => {
  // local state

  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState("");
  const [showForgotPassModel, setShowForgotPassModel] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let location = useLocation();
  const query = useQuery();
  const resetCode = query.get("totken");
  const resetEmail = query.get("email");

  const { error, loading, user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    console.log("useruser", user);
    triggerMixpanelEvent("login_page");
    if (user?.id) {
      history.push("/jobcardsummary");
    } else if (resetEmail && resetCode) {
      handleForgotPassword();
    }
  }, []);
  useEffect(() => {
    let urlQueryString = location.search;
    let signInType = new URLSearchParams(urlQueryString).get("returnUrl");
  }, [location.search]);
  useEffect(() => {
    if (user?.id) {
      triggerMixpanelEvent("login_success");
      history.push("/jobcardsummary");
    } else {
      // history.push("/");
    }
  }, [user]);
  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     console.log("User pressed: ", event.key);
  //     if (event.key === "Enter") {
  //       event.preventDefault();
  //       handleLogin();
  //     }
  //   };
  //   document.addEventListener("keydown", keyDownHandler);
  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, []);

  const handleKeypress = (e) => {
    if (e?.keyCode === 13) {
      handleLogin();
    }
  };

  const handleNumberChange = (e) => {
    const value1 = e.target.value;
    const re = /^[0-9\b]+$/;
    if (value1 === "" || re.test(value1)) {
      setIsError("");
      setMobileNumber(value1);
    }
  };
  const handleLogin = async () => {
    if (!password && !mobileNumber) {
      setIsError("Mobile Number and Password is required.");
    } else if (!mobileNumber) {
      setIsError("Mobile Number is required.");
    } else if (!password) {
      setIsError("Password is required.");
    } else {
      const body = {
        username: mobileNumber,
        password,
      };
      triggerMixpanelEvent("login_click", {
        mobileNumber: mobileNumber,
        mobileNumber1: password,
      });
      await dispatch(loginUser(body));
    }
    // localStorage.setItem("user", JSON.stringify(body));
    // dispatch(updateUserDetails(body));
    // history.push("/dashboard");
  };
  const gotoDelivery = () => {
    props?.updateStep("delivery");
  };
  const handleForgotPassword = () => {
    setShowForgotPassModel(true);
  };
  return (
    <>
      <div className="login-page-full-container">
        <ForgotPasswordModel
          show={showForgotPassModel}
          resetEmail={resetEmail}
          resetCode={resetCode}
          onHide={() => {
            setShowForgotPassModel(false);
          }}
        />
        <div className="login-page-full-container-con">
          <div className="login-page-full-left">
            <div className="login-page-full-left-cont">
              <ImageGallery
                items={images}
                showNav={true}
                slideInterval={500}
                showThumbnails={false}
                infinite={true}
                showPlayButton={false}
                showFullscreenButton={false}
                useBrowserFullscreen={false}
                showBullets={false}
                showIndex={false}
              />
            </div>
          </div>
          <div className="login-page-full-right">
            <div className="login-page-full-right-container">
              <div className="login-page-full-right-con">
                <div className="login-page-full-right-con-top">
                  <div className="main-logo-login-page">
                    <span>RASKO</span>
                  </div>
                  <p className="login-to-your-acc">Login to Your Account</p>
                  {/* <p className="login-to-your-acc-or">----- Or -----</p>
                  <p className="create-to-your-acc">
                    <a href="/signup">New to RASKO? Create Account</a>
                  </p> */}
                </div>
                <div className="login-page-full-right-con-bottom">
                  <div className="login-page-full-form">
                    <div className="col-sm-8 login login-container-sec">
                      <div className="row mb-3">
                        <div className="col-sm-12 FCLogo text-center"></div>
                        <div className="col-sm-12 mt-15 box-login-sec">
                          <label
                            for="inputEmail3"
                            className="col-sm-2 col-form-label"
                          >
                            Mobile
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="username"
                            id="username"
                            maxLength={10}
                            onKeyDown={handleKeypress}
                            autocomplete="off"
                            onChange={handleNumberChange}
                            value={mobileNumber}
                          />
                        </div>
                        <div className="col-sm-12 mt-15 box-login-sec">
                          <label
                            for="inputPassword3"
                            className="col-sm-2 col-form-label"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            onKeyDown={handleKeypress}
                            autocomplete="off"
                            onChange={(e) => {
                              setIsError("");
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {(error || isError) && (
                        <p className="error_message">{error || isError}</p>
                      )}
                      <div className="login_keepmelogin_btn d-flex align-items-center flex-wrap">
                        <Form.Group
                          controlId="formBasicCheckbox"
                          className="mr-auto mb-0 keep-margin-login-check d-md-block"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Keep me logged in"
                            defaultChecked
                          />
                        </Form.Group>
                      </div>
                      {/* Keep me logged in */}
                      <button
                        onClick={handleLogin}
                        type="submit"
                        className=" login_btn_sec_acc"
                      >
                        SIGN IN
                      </button>
                      <p
                        onClick={handleForgotPassword}
                        className="forgot-pass-sec-login"
                      >
                        Forgot Password?
                      </p>
                      <p className="login-to-your-acc-or">----- Or -----</p>

                      <div className="dont-have-acc">
                        <a href="/signup"> Don't have an account? Sign up</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="login-signup-container">
        <div className="data-loginsignupContainer"></div>
      </div> */}
    </>
  );
};

export default Login;
