import styled from "styled-components";

export const ForgotPasswordModelStyled = styled.div`
  .for-got-pass-sec-cont {
    padding: 20px 20px;
    padding-top: 0px;
  }
  .new-option-header {
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 35px;
    }
  }
  .email-sub-header {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .main-content-enter-email {
    margin-top: 1y0px;
    p {
      width: 80%;
      font-size: 18px;
      color: rgba(32, 32, 32, 0.6);
    }
  }
  .box-login-sec {
    input {
      height: 50px;
    }
  }
  .action-sec-pass {
    text-align: center;
  }
  .remember-pass-action {
    margin-top: 20px;
    span {
      color: #f9751b;
      cursor: pointer;
    }
  }
  .enter-pass-sec-content {
    margin-top: 30px;
  }
  .remember-cont-sec,
  .reset-pass-action {
    text-align: center;
    button {
      height: 47px;
      background: steelblue;
      border: none;
      border-radius: 4px;
      color: #fff;
      width: 250px;
      /* margin-top: 15px !important; */
      margin-bottom: 5px !important;
      font-size: 20px;
      opacity: 1;
    }
    button:disabled {
      opacity: 0.2;
    }
  }
  .show-succ-content-text {
    text-align: center;
    img {
      height: 100px;
      width: 150px;
      /* height: 159px; */
      border-radius: 50px;
      height: 150px;
      object-fit: fill;
    }
  }
  .show-succ-content {
    h3 {
      font-size: 36px;
    }
    > p {
      width: 80%;
      font-size: 20px;
      line-height: 28px;
      margin-top: 30px;
    }
  }
  .show-succ-content-text {
    p span {
      color: #f9751b;
      cursor: pointer;
    }
  }
  .show-succ-content-succ {
    text-align: center;
    h3 {
      font-size: 30px;
    }
    h4 {
      margin-top: 30px;
      color: steelblue;
      margin-bottom: 30px;
    }
  }
`;
