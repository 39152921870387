import { configureStore } from "@reduxjs/toolkit";

// reducers
import appReducer from "../slices/app/appSlice";
import authReducer from "../slices/auth/authSlice";
import serviceManagementReducer from "../slices/serviceManagement/serviceManagementSlice";
import leadManagementReducer from "../slices/LeadManagement/leadManagementSlice";
import bookingManagementReducer from "../slices/BookingManagement/BookingManagementSlice";
import storeManagementReducer from "../slices/storeManagement/storeManagementSlice";
import reportsReducer from "../slices/Reports/ReportsSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    serviceManagement: serviceManagementReducer,
    leadManagement: leadManagementReducer,
    bookingManagement: bookingManagementReducer,
    storeManagement: storeManagementReducer,
    reports: reportsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
