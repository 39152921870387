import styled from "styled-components";

export const PrevListSectionStyled = styled.div`
  .enginner-prev-list-all {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .enginner-prev-list,
  .enginner-prev-list-header {
    display: flex;

    gap: 15px;
  }
  .enginner-prev-list-header {
    border-bottom: 1px solid #e2e2e2;
    padding: 6px;
    font-weight: 600;
  }
  .enginner-prev-list {
    border-bottom: 1px solid #e2e2e2;
    padding: 6px;
  }
  .enginner-prev-createdby {
    width: 20%;
  }
  .enginner-prev-date {
    width: 20%;
  }
  .enginner-prev-desc {
    width: 60%;
  }
  .part-enginner-prev-part {
    width: 40%;
  }
  .part-enginner-prev-serial {
    width: 60%;
  }
  .enginner-prev-list-allactionTaken .enginner-prev-date {
    width: 40%;
  }
  .enginner-prev-list-allactionTaken > div,
  .enginner-prev-list-allpartReplaced > div,
  .enginner-prev-list-allundefined > div {
    text-align: center;
  }
  .edit-action-content {
    // margin: 30px 0px;
  }
`;
