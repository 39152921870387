import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoresReportStyled } from "./EmailConfiguration.styled";
// import {
//   getAllSubscriptions,
//   addCouponCodeAPI,
// } from "../../../../redux/slices/dashboard/dashboardService";
import { Space, Table, Tag } from "antd";
import "antd/dist/antd.css";

const EmailConfiguration = (props: any) => {
  const { handleBookNow } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // dispatch(getAllSubscriptions());
  }, []);

  const handleSubmit = async () => {
    if (couponCode) {
      const body = {
        code: couponCode,
      };
      // setIsLoading(true);
      // // const resp = (await dispatch(addCouponCodeAPI(body))) as any;
      // setIsLoading(false);
      // console.log("respresp", resp);
      // if (resp?.payload?.success) {
      //   setErrorMessage("");
      //   history.push("/dashboard/subscriptions");
      // } else {
      //   setErrorMessage(resp?.error?.message);
      // }
    }
  };
  return (
    <div>
      <StoresReportStyled>
        <br />
        <div className="coupon_code_section">
          <h2>Email Configuration</h2>
          <div className="coupon_code_section_content">
            <div>
              <input
                className="input_coupon_code"
                value={couponCode}
                onChange={(e) => {
                  setErrorMessage("");
                  setCouponCode(e.target.value);
                }}
                placeholder="From Name"
              />
            </div>
            <div>
              <input
                className="input_coupon_code"
                value={couponCode}
                onChange={(e) => {
                  setErrorMessage("");
                  setCouponCode(e.target.value);
                }}
                placeholder="From Email Address"
              />
            </div>
            <div>
              <input
                className="input_coupon_code"
                value={couponCode}
                onChange={(e) => {
                  setErrorMessage("");
                  setCouponCode(e.target.value);
                }}
                placeholder="Replay To Email"
              />
            </div>
            <div>
              <input
                className="input_coupon_code"
                value={couponCode}
                onChange={(e) => {
                  setErrorMessage("");
                  setCouponCode(e.target.value);
                }}
                placeholder="Company Address"
              />
            </div>
            {errorMessage && <p className="error_message">{errorMessage}</p>}

            <div>
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
        <br />
        <div></div>
      </StoresReportStyled>
    </div>
  );
};

export default EmailConfiguration;
