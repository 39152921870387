import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Table } from "antd";

import TimePicker from "rc-time-picker";
import Select from "react-select";
import { getLeadDetailsById } from "../../redux/slices/LeadManagement/leadManagementService";

import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobCardApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  // getActionTakenoptList,
} from "../../redux/slices/serviceManagement/serviceManagementService";
import moment from "moment";
import PrevListSection from "../CreateJobcard/PrevListSection";

import { LeadItemDetailsStyled } from "./LeadItemDetails.styled";
const LeadItemDetails = (props: any) => {
  const { showItems } = props;
  const leadId = props?.match?.params?.leadId;
  const dispatch = useDispatch();
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
  } = useSelector((state: any) => state.serviceManagement);
  const { leadDetails } = useSelector((state: any) => state.leadManagement);

  useEffect(() => {
    if (leadId) {
      dispatch(getLeadDetailsById(leadId));
    }
  }, []);
  console.log("jobCardDetails", jobCardDetails);
  const { jobCard } = jobCardDetails;
  const itemRecivedList = leadDetails?.itemRecived?.split(",");
  const defaultIssuesList = leadDetails?.defaultIssues?.split(",");
  const customerAddRequirementsList = leadDetails?.customerAddRequirements?.split(
    ","
  );
  console.log("leadDetails", leadDetails);
  const leadDetails1 = leadDetails?.leadInfo;

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      fixed: "left",
      key: 1,
      render: (item: any, record: any, index: any) => {
        const indexP = index + 1;
        return <>{indexP}</>;
      },
    },
    {
      title: "Product Name",
      width: "170px",
      fixed: "left",
      key: 1,
      render: (_: any, record: any) => {
        const finaLN =
          record?.product?.name || record?.optionName || record?.status;
        return (
          <>
            <p className="table-option-text">{finaLN?.toLowerCase()}</p>{" "}
          </>
        );
      },
    },
    {
      title: "Brand Name",
      width: "150px",
      key: 1,
      render: (name: any, record: any) => {
        const finaLN = record?.brand?.name;
        return (
          <>
            <p className="table-option-text">{finaLN} </p>
          </>
        );
      },
    },
    {
      title: "Model",
      width: "200px",
      key: 1,
      render: (name: any, record: any) => {
        const finaLN = record?.model;
        return (
          <>
            <p className="table-option-text">{finaLN} </p>
          </>
        );
      },
    },
    {
      title: "Configuration",
      width: "250px",
      key: 1,
      render: (name: any, record: any) => {
        const finaLN = record?.configuration;
        return (
          <>
            <p className="table-option-text">{finaLN} </p>
          </>
        );
      },
    },
    {
      title: "Qty",
      width: "80px",
      key: 1,
      render: (name: any, record: any) => {
        const finaLN = record?.qty;
        return (
          <>
            <p className="table-option-text">{finaLN} </p>
          </>
        );
      },
    },
    {
      title: "Referral Link",
      width: "200px",
      key: 1,
      render: (name: any, record: any) => {
        const finaLN = record?.referralLink;
        return (
          <>
            <p className="table-option-text">{finaLN} </p>
          </>
        );
      },
    },
  ] as any;

  return (
    <>
      <LeadItemDetailsStyled>
        <div className="focusnext-boby-section">
          <div className="">
            <div className="section-container-header">
              <h3> {`Lead Item Details : ${leadDetails1?.id}`} </h3>
              <span>
                <a href="/jobcardsummary">Job Card Summary List</a>
              </span>
            </div>
          </div>
          <div className="focusnext-boby-content">
            <div className="col datatable1">
              <div className="fluid-container ProductDetails1">
                {!showItems && (
                  <div className="row job-card-details-items">
                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">Mobile No: </div>
                        <div className="col-6 col-value">
                          {leadDetails1?.customerInfo?.mobileNo}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">Customer Name: </div>
                        <div className="col-6 col-value">
                          {leadDetails1?.customerInfo?.customerName}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">
                        Alternative Mobile No:
                      </div>
                      <div className="col-6 col-value">
                        {leadDetails1?.customerInfo?.alternativeNo}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Email: </div>
                      <div className="col-6 col-value">
                        {leadDetails1?.customerInfo?.email}
                      </div>
                    </div>
                  </div> */}

                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">
                          Booking Date & Time:{" "}
                        </div>
                        <div className="col-6 col-value">
                          {}
                          {moment(jobCardDetails?.bookingDateTime).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">Payment Type: </div>
                        <div className="col-6 col-value">
                          {leadDetails1?.warrantyServiceTemp ||
                          leadDetails1?.disWarrantyService === "Yes"
                            ? "Warranty"
                            : "Chargeable"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">
                          Incomming Source:{" "}
                        </div>
                        <div className="col-6 col-value">
                          {leadDetails1?.incommingSource}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">Assigned To: </div>
                        <div className="col-6 col-value">
                          {`${leadDetails1?.assignedTo?.firstName} ${leadDetails1?.assignedTo?.lastName}`}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">Status: </div>
                        <div className="col-6 col-value">
                          {leadDetails1?.status}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className="row">
                        <div className="col-6 col-lavel">Severity: </div>
                        <div className="col-6 col-value">
                          {leadDetails1?.priority}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <div className="itmes-selected-section">
                    <div className="itmes-selected-header">
                      Action Taken List:
                    </div>
                    <div className="itmes-selected-content">
                      <PrevListSection
                        section="actionTaken"
                        items={jobCardDetails?.actionTakens}
                      />
                    </div>
                  </div>
                </div>

                <br />
                <div className="itmes-selected-header">Lead items Details</div>
                <br />
                <Table
                  columns={columns}
                  dataSource={leadDetails1?.leadItem}
                  scroll={{ y: 540 }}
                  pagination={false}
                  className="all-reports-data-table1"
                />
              </div>
            </div>
          </div>
        </div>
      </LeadItemDetailsStyled>
    </>
  );
};
export default LeadItemDetails;
