import styled from "styled-components";

export const ServiceManagementStyled = styled.div`
  .section-container-header span {
    margin-left: 20px;
    display: block;
    margin-top: 2px;
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
  }
  .section-container-header input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 250px;
    margin-left: 20px;
    border-radius: 5px;
    height: 35px;
    margin-top: -2px;
    padding-left: 10px;
  }
  .show-list-all-itemsReceivedList,
  .show-list-all-additionalRequirementList,
  .show-list-all-actiontakenList,
  .show-list-all-engineerObservationList,
  .show-list-all-customerNoteList {
    .data-show-book-service {
      display: none;
    }
    .data-actions-item-action {
      width: 200px !important;
      display: inline-block;
    }
  }
  .hide-all-extra-services-items {
    .data-show-book-service {
      display: none;
    }
    .data-actions-item-action {
      width: 200px !important;
      display: inline-block;
    }
  }
  .section-container-body-table .ant-table-pagination-right {
    justify-content: center;
  }
  .hide-book-service .data-show-book-service {
    // display: none;
  }
  .edit-sec-checkboxs {
    margin-top: 20px;
  }
  .edit-sec-checkboxs > div {
    margin-top: 10px;
    font-size: 20px;
  }
  .edit-sec-checkboxs input {
    margin-left: 17px;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
  .section-container-body-table {
    margin-top: 30px;
  }
  .section-container-body-table tbody td {
    font-size: 20px;
    padding: 10px 20px;
  }
  .section-container-body-table thead th {
    font-size: 20px;
  }
  .table-option-text {
    text-transform: capitalize;
    line-height: 28px;
  }
  .table-actions-btn span {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 9px 5px;
    margin: 5px;
    width: 45%;
    display: inline-block;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    border-radius: 10px;
  }
  .table-actions-btn span:nth-child(2) {
    color: red;
  }
  .add-scroll-option {
    max-height: 300px;
    overflow-x: scroll;
  }
  .new-option-body-header p {
    margin-bottom: 3px;
  }
  .select-all-option {
    label {
      font-size: 20px;
      cursor: pointer;
    }
    input {
      height: 20px;
      width: 25px;
      position: relative;
      top: 2px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .new-option-body-text input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 45px;
    padding-left: 10px;
    border-radius: 4px;
    width: 100%;
    font-size: 20px;
    text-transform: capitalize;
  }
  .new-option-body-actions {
    // margin-top: 50px;
    text-align: center;
  }
  .new-option-header {
    display: flex;
    justify-content: space-between;
  }
  .table-actions-btn {
    display: flex;
  }
  .new-option-body {
    border-top: 1px solid #e2e2e2;
    margin-top: 25px;
    padding-top: 20px;
  }
  .new-option-body-header p {
    font-weight: 400;
    font-size: 20px;
  }
  .items-recieved-section {
    margin-top: 30px;
    border: none;
  }
  .section-container-header2 {
    display: flex;
    // background: #d8e6f3;
    margin-bottom: 15px;
    .search-icon-sec {
      margin-left: 20px;
      margin-top: -8px;
    }
  }
`;
