import CommonDynamicComponent from "../ServiceManagement/CommonDynamicComponent/CommonDynamicComponent";
const ConfigurationList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Configuration List"
        section="configurationList"
        subTitle="Create New Configuration"
      />
    </>
  );
};
export default ConfigurationList;
