import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import Logo from "../../img/focusNext.png";
import {
  getStoreDetails,
  getPrintSettingByIdApi,
  getCustomerDetails,
} from "./../../redux/slices/auth/authService";
import { MdOutlineCurrencyRupee } from "react-icons/md";

import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobCardApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  // getActionTakenoptList,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import moment from "moment";
import PrevListSection from "./../CreateJobcard/PrevListSection";
import { isRaskoAccount } from "../../Scenes/common";
import { PrintJobCardStyled } from "./PrintJobCard.styled";
import Barcode from "react-barcode";

const PrintJobCard = (props: any) => {
  const jobId = props?.match?.params?.jobId;
  const dispatch = useDispatch();
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
  } = useSelector((state: any) => state.serviceManagement);

  const [printData, setPrintData] = useState({} as any);
  const {
    user,
    loginStoreDetails,
    selectedCustomerDetails,
    getPrintSettingsResp,
  } = useSelector((state: any) => state.auth);
  useEffect(() => {
    if (getPrintSettingsResp?.printSetting) {
      setPrintData({ ...getPrintSettingsResp?.printSetting });
      // setValue(getPrintSettingsResp?.printSetting?.termsAndConditions);
    }
  }, [getPrintSettingsResp?.printSetting]);

  // const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(
  //   {} as any
  // );
  console.log("user", user);
  console.log("printData", printData);
  useEffect(() => {
    dispatch(getPrintSettingByIdApi(1));

    if (jobId) {
      dispatch(getJobCardDetailsApi(jobId));
    }
    if (user?.storeId) {
      dispatch(getStoreDetails(user?.storeId));
    }
  }, []);
  useEffect(() => {
    console.log("jobCardDetails", jobCardDetails);
    if (jobCardDetails?.jobCard?.selectedCustomerId) {
      customerDetails(jobCardDetails?.jobCard?.selectedCustomerId);
    }
  }, [jobCardDetails]);
  const customerDetails = async (id: any) => {
    const resp = dispatch(getCustomerDetails(id)) as any;
    console.log("resp", resp);
    // setSelectedCustomerDetails(resp?.payload);
  };
  console.log("loginStoreDetails", loginStoreDetails);
  console.log("selectedCustomerDetails", selectedCustomerDetails);
  const { jobCard } = jobCardDetails;
  const itemRecivedList = jobCard?.itemRecived?.split(",");
  const defaultIssuesList = jobCard?.defaultIssues?.split(",");
  const customerAddRequirementsList =
    jobCard?.customerAddRequirements?.split(",");
  const cDetails = selectedCustomerDetails?.customerDetails;
  console.log("jobCard", jobCard);
  const imgUrls = jobCard?.imageUrls ? jobCard?.imageUrls.split(",") : [];
  console.log("imgUrls", imgUrls);
  return (
    <>
      <PrintJobCardStyled>
        <div className="print-page-box-container">
          <div className="print-page-box-sec">
            <div className="print-head">
              <span>
                {" "}
                {printData?.imageUrl && printData?.imageUrl !== "noImage" && (
                  <img src={printData?.imageUrl} />
                )}
                <span className="logo-header-left-text">
                  {printData?.storeName ||
                    loginStoreDetails?.storeName ||
                    "RASkO"}
                </span>
              </span>
              <div className="bar-code-example-con">
                <Barcode
                  width={2}
                  height={50}
                  displayValue={false}
                  // format: "CODE128",
                  value={jobId}
                />
              </div>
              {/* <img className="mainLogoImage-print" src={Logo}></img> */}
            </div>
            <div className="print-sub-head">Service Call Slip</div>
            <div className="user-details-sec">
              <div className="user-customer-sec user-customer-sec-left">
                <div className="user-customer-sec-header">Customer Details</div>
                <div className="left-items">
                  <div className="names-list">
                    <p>Name: </p>
                    {(cDetails?.mobileNo || jobCard?.mobileNo) && (
                      <p>Mobile: </p>
                    )}
                    {(cDetails?.altMobileNo || jobCard?.alternateMobileNo) && (
                      <p>Alt No: </p>
                    )}
                    {(cDetails?.email || jobCard?.customerEmail) && (
                      <p>Email:</p>
                    )}
                    {cDetails?.city && <p>Location:</p>}
                  </div>
                  <div className="names-value">
                    {(cDetails?.customerName || jobCard?.customerName) && (
                      <p>{cDetails?.customerName || jobCard?.customerName} </p>
                    )}
                    {(cDetails?.mobileNo || jobCard?.mobileNo) && (
                      <p>{cDetails?.mobileNo || jobCard?.mobileNo} </p>
                    )}
                    {(cDetails?.altMobileNo || jobCard?.alternateMobileNo) && (
                      <p>
                        {cDetails?.altMobileNo || jobCard?.alternateMobileNo}{" "}
                      </p>
                    )}
                    {(cDetails?.email || jobCard?.customerEmail) && (
                      <p>{cDetails?.email || jobCard?.customerEmail}</p>
                    )}
                    {cDetails?.city && <p>{cDetails?.city}</p>}
                  </div>
                </div>
              </div>
              <div className="user-customer-sec">
                <div className="user-customer-sec-header">Call Details</div>
                <div className="left-items">
                  <div className="names-list names-list1">
                    <p>Service ID: </p>
                    {/* <p>Ref Number:</p> */}
                    <p>{`Date & Time:`} </p>
                    <p>Incoming Source:</p>
                    <p>
                      {" "}
                      <span className="bold-text">
                        Inspection Charges:
                      </span>{" "}
                    </p>
                    <p>Estimation Amount:</p>
                    <p>Advance Amount:</p>
                    <p>Warranty Type:</p>
                    <p>Created By:</p>
                    <p>Assigned To:</p>
                  </div>
                  <div className="names-value names-value1">
                    <p className="print-job-id">{jobId} </p>
                    {/* <p>{` `} </p> */}
                    <p>
                      {moment(jobCard?.createdDate).format("DD/MM/YYYY h:mm A")}{" "}
                    </p>
                    <p>{jobCard?.incommingSource}</p>
                    <p>
                      {" "}
                      <span className="bold-text">
                        {printData?.inspectionCharges || jobCard?.minCharges}
                      </span>{" "}
                    </p>
                    <p>
                      {" "}
                      <span className="">
                        <MdOutlineCurrencyRupee />
                        {jobCard?.estimationAmount ?? 0}
                      </span>{" "}
                    </p>
                    <p>
                      {" "}
                      <span className="">
                        <MdOutlineCurrencyRupee />
                        {jobCard?.advanceAmount ?? 0}
                      </span>{" "}
                    </p>
                    <p>{jobCard?.paymentType}</p>
                    <p>{jobCard?.createdBy}</p>
                    <p>
                      {" "}
                      {`${jobCard?.assignedTo?.firstName} ${jobCard?.assignedTo?.lastName}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-sub-head">Product Details</div>
            <div className="product-details-sec">
              <div className="product-details-item-row">
                <div className="product-details-name"> Product Name:</div>
                <div className="product-details-value product-details-value1">
                  {jobCard?.product?.name}
                </div>
                <div className="product-details-name">Brand Name:</div>
                <div className="product-details-value">
                  {" "}
                  {jobCard?.brand?.name}
                </div>
              </div>
              <div className="product-details-item-row">
                <div className="product-details-name">Model:</div>
                <div className="product-details-value product-details-value1">
                  {" "}
                  {jobCard?.model}
                </div>
                <div className="product-details-name">S No:</div>
                <div className="product-details-value">
                  {" "}
                  {jobCard?.serialNo1}
                </div>
              </div>
            </div>
            <div className="extra-items">
              <div className="extra-items-list">
                <div className="extra-items-key">Items Received:</div>
                <div className="extra-items-value">
                  {itemRecivedList?.map((itemV: any, index: any) => {
                    return (
                      <>
                        {index !== 0 && (
                          <span className="checkbox-checked-span-left">
                            {" | "}
                          </span>
                        )}
                        <input
                          className="checkbox-checked"
                          type="checkbox"
                          checked={true}
                        />
                        <span className="checkbox-checked-span">{itemV}</span>
                      </>
                    );
                  })}
                  {jobCardDetails?.csRemarks?.length > 0 && (
                    <p>
                      {" "}
                      <span className="remarks-header"> Remarks:</span>
                      {jobCardDetails?.itRemarks?.map((item: any) => {
                        return <span>{item?.remarks}</span>;
                      })}
                    </p>
                  )}
                </div>
              </div>
              <div className="extra-items-list">
                <div className="extra-items-key">Problem Reported:</div>
                <div className="extra-items-value">
                  {defaultIssuesList?.map((itemV: any, index: any) => {
                    return (
                      <>
                        {index !== 0 && (
                          <span className="checkbox-checked-span-left">
                            {" | "}
                          </span>
                        )}
                        <input
                          className="checkbox-checked"
                          type="checkbox"
                          checked={true}
                        />
                        <span className="checkbox-checked-span">{itemV}</span>
                      </>
                    );
                  })}
                  {jobCardDetails?.csRemarks?.length > 0 && (
                    <p>
                      {" "}
                      <span className="remarks-header"> Remarks:</span>
                      {jobCardDetails?.diRemarks?.map((item: any) => {
                        return <span>{item?.remarks}</span>;
                      })}
                    </p>
                  )}
                </div>
              </div>
              <div className="extra-items-list">
                <div className="extra-items-key">Add On Requirements:</div>
                <div className="extra-items-value">
                  {customerAddRequirementsList?.map(
                    (itemV: any, index: any) => {
                      return (
                        <>
                          {index !== 0 && (
                            <span className="checkbox-checked-span-left">
                              {" | "}
                            </span>
                          )}
                          <input
                            className="checkbox-checked"
                            type="checkbox"
                            checked={true}
                          />
                          <span className="checkbox-checked-span">{itemV}</span>
                        </>
                      );
                    }
                  )}
                  {jobCardDetails?.csRemarks?.length > 0 && (
                    <p>
                      {" "}
                      <span className="remarks-header"> Remarks:</span>
                      {jobCardDetails?.csRemarks?.map((item: any) => {
                        return <span>{item?.remarks}</span>;
                      })}
                    </p>
                  )}
                </div>
              </div>
              {imgUrls?.length > 0 && (
                <div className="extra-items-list">
                  <div className="extra-items-key">Product Images:</div>
                  <div className="extra-items-value print-imag-lists">
                    {imgUrls?.map((imgU: any) => {
                      return <img src={imgU} />;
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="sub-sub-head">
              {`Terms & Conditions`} <span className="manditary">*</span>
            </div>
            <div className="terms-list">
              {printData?.termsAndConditions ? (
                <div
                  className="comment-text-terms-con-sec"
                  dangerouslySetInnerHTML={{
                    __html: `${printData?.termsAndConditions.replace(
                      /\n/g,
                      "<br>"
                    )}`,
                  }}
                ></div>
              ) : (
                <>
                  <ol>
                    <li>
                      Job Sheet in original must be produced by customer while
                      collecting the material.
                    </li>
                    <li>
                      The actual charges may differ from estimated charges.
                    </li>
                    <li>
                      In case the estimate is not approved by the customer, and
                      wants to take back the product without repair,{" "}
                      <span className="bold-text">
                        Rs.{jobCard?.minCharges} will be charged as inspection
                        charges for all Products
                      </span>
                      .
                    </li>
                    <li>
                      Management Is Not Responsible For Loss or Theft Of
                      Material( Cartoons, Bags Etc.)Which Are Not Mentioned In
                      Job Sheet.
                    </li>
                    <li>
                      <span className="bold-text">
                        If The Product Not Collect Within 21 Days, The Customer
                        Has To Pay Rent For The Product Rs. 25/- Per Day.
                      </span>
                    </li>
                    <li>
                      Cost Of The Part Required During The Scope Of Repair Will
                      Be Borne By The Customer.
                    </li>
                    <li>
                      There is no guarantee / warranty for any parts replaced /
                      sold or services rendered.
                    </li>
                    <li>
                      In display related concerns like dull display or graphic
                      chip related, there is a probability of product going
                      completely dead for which we will not be liable.
                    </li>
                    <li>
                      During repair process, if the product goes dead due to
                      technical issues, we are not responsible for it.
                    </li>
                    <li>
                      We will not be responsible for any loss / damage due to
                      theft / fire / riots /acts of state / acts of Gods etc.
                    </li>
                    <li>
                      After delivery of the product, the firm will not be held
                      responsible for any damages.
                    </li>
                    <li>
                      {" "}
                      <span className="bold-text">
                        All Norms Mentioned Above Refer To Workings Hours (9 AM
                        To 7.30 PM) Or Working Days ( Monday To Saturday) Only.
                      </span>
                    </li>
                    {isRaskoAccount(loginStoreDetails?.id) && (
                      <li>
                        Any disputes will be subject to KADAPA Jurisdiction
                      </li>
                    )}
                  </ol>
                  <p className="note-list-sec">
                    <span className="bold-text">
                      {" "}
                      Note:- For any service related enquiries please call to
                      below mentioned Phone Numbers Only.
                    </span>
                  </p>
                </>
              )}
            </div>
            <div className="print-sign">Customer's Signature:</div>
            <div className="print-compu-text">
              **This is computer generated print signature not required.
            </div>
            <div className="print-sign-bottom">
              {/* <div className="sign-head">FOCUS CORPORATION</div> */}
              <div className="sign-sub-head">
                {/* D.NO:42/108 | APSARA CIRCLE | SVR COMPLEX | RTC BUS STAND ROAD |
                KADAPA-516002 */}
                {printData?.address || loginStoreDetails?.address}
              </div>
              <div className="sign-sub-sub-head">
                {" "}
                {printData?.phoneNumbersInfo ? (
                  <>{printData?.phoneNumbersInfo}</>
                ) : (
                  <>
                    +91 {loginStoreDetails?.storeMobileNo} | +91{" "}
                    {loginStoreDetails?.contactNumber}
                  </>
                )}
                {isRaskoAccount(loginStoreDetails?.id) && (
                  <>
                    {" "}
                    | For Book To Repair please visit www.focusnext.in web site
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </PrintJobCardStyled>
    </>
  );
};
export default PrintJobCard;
