import moment from "moment";
import { PrevListSectionStyled } from "./PrevListSection.styled";
const PrevListSection = (props: any) => {
  const { items, section } = props;
  // console.log("itemsitems", items);
  return (
    <>
      <PrevListSectionStyled>
        {items?.length > 0 && (
          <>
            <div
              className={`enginner-prev-list-all enginner-prev-list-all${section}`}
            >
              <div className="enginner-prev-list-header">
                {section !== "actionTaken1" && section !== "partReplaced1" && (
                  <div className="enginner-prev-createdby">Created By</div>
                )}
                {section === "partReplaced" ? (
                  <>
                    <div className="part-enginner-prev-part">Date </div>
                    <div className="part-enginner-prev-part">Part Name </div>
                    <div className="part-enginner-prev-serial">Serial No</div>
                  </>
                ) : (
                  <>
                    <div className="enginner-prev-date">Date </div>
                    <div className="enginner-prev-desc">Description</div>
                  </>
                )}
              </div>

              {items?.map((eOB: any) => {
                return (
                  <>
                    {section === "partReplaced" ? (
                      <>
                        <div className="enginner-prev-list">
                          <div className="enginner-prev-createdby">
                            {eOB?.modifiedBy}
                          </div>
                          <div className="part-enginner-prev-part">
                            {moment
                              .utc(eOB?.createdDate)
                              .local()
                              .format("DD/MM/YYYY h:mm A")}

                            {/* {moment(eOB?.createdDate).format(
                              "DD/MM/YYYY h:mm A"
                            )} */}
                          </div>
                          <div className="part-enginner-prev-serial">
                            {eOB?.partName}
                          </div>
                          <div className="part-enginner-prev-serial">
                            {eOB?.serialNo}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="enginner-prev-list">
                          {section !== "actionTaken2" && (
                            <div className="enginner-prev-createdby">
                              {eOB?.modifiedBy}
                            </div>
                          )}
                          <div className="enginner-prev-date">
                            {/* {moment(eOB?.createdDate).format(
                              "DD/MM/YYYY h:mm A"
                            )} */}
                            {moment
                              .utc(eOB?.createdDate)
                              .local()
                              .format("DD/MM/YYYY h:mm A")}
                          </div>
                          <div className="enginner-prev-desc">
                            {eOB?.observation ||
                              eOB?.note ||
                              eOB?.serviceTaken ||
                              eOB?.remarks}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
      </PrevListSectionStyled>
    </>
  );
};
export default PrevListSection;
