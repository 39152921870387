import styled from "styled-components";

export const ContactUSStyled = styled.div`
  .consultnew img {
    width: 450px;
    height: 450px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 190px;
  }
  .getinTouchPage {
    padding-bottom: 200px;
  }
  .consultnew h2 {
    margin-top: 40px;
    font-weight: 700;
  }
  /* .consultnew p{
      margin-left: 80px;
  } */
  .getinTouchPage input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    color: #000;
    background-color: #fff;
  }
  .successText {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  .emailError {
    margin: -10px 0px 10px 0px !important;
    color: red;
    font-size: 15px;
    text-align: left;
  }
  .getinTouchPage input[type="checkbox"] {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .getinTouchPage input[type="submit"] {
    background-color: #d52d34;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }

  .contactformnew {
    margin-top: 30px;
    margin-right: 80px;
    padding: 20px;
    border-radius: 6px !important;
    border: 2px solid rgba(0, 0, 0, 0.03) !important;
    box-shadow: 0px 0px 21px rgb(51 51 51 / 1%) !important;
  }
  .contactformnew .error {
    border-color: red !important;
  }
  .consultnew h1 {
    font-size: 51px;
    font-weight: 600;
    margin-left: 100px;
    margin-top: 50px;
  }
  .consultnew p {
    margin-left: 100px;
  }

  @media screen and (max-width: 640px) {
    .consultnew h1 {
      margin-left: 0px;
      margin-top: 20px;
      text-align: center;
    }
    .consultnew p {
      margin-left: 0px;
      margin-right: 15px;
      text-align: center;
    }
    .consultnew h2 {
      text-align: center;
    }
    .consultnew h6 {
      text-align: center;
    }
    .contactformnew {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
`;
