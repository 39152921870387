import React, { useState, useEffect } from "react";
import CommonReportData from "./CommonReportData";
const StatusWise = () => {
  useEffect(() => {
    console.log("useEffect");
    getAllData();
  }, []);
  const getAllData = () => {};

  return (
    <>
      <CommonReportData secName="status" />
    </>
  );
};
export default StatusWise;
