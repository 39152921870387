import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const ActiontakenList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Action Taken List"
        section="actiontakenList"
        subTitle="Create Action Taken Option"
      />
    </>
  );
};
export default ActiontakenList;
