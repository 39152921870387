import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

// "/getactiontakenoptlist/{id}"

// (value = "/get itemsrecievedbyproductid tobookservice/{id}/{jobcardId}")
// alue = "/get defaultissuesbyproductid tobookservice/{id}/{jobcardId}")
// (value = "/getbrandsbyproductid tobookservice/{id}/{jobcardId}")
// "/getcustomeradditionalreq tobookservice/{id}/{jobcardId}")
// http://195.95.200.22:8080/focusnextrestservices/api/getapprovedpendingstoreslist

export const getbusinessuserbyidCall = createAsyncThunk(
  "storeManagement/getbusinessuserbyidCall",
  async (param: any) => await get(`${SERVER_IP}/getapprovedpendingstoreslist`)
);

export const blockCustomer = createAsyncThunk(
  "storeManagement/blockCustomer",
  async (param: any) => await get(`${SERVER_IP}/customernotelist`)
);

export const getcustomeraddressCall = createAsyncThunk(
  "storeManagement/getcustomeraddressCall",
  async (param: any) => await get(`${SERVER_IP}/customernotelist`)
);

export const getPendingUsersCall = createAsyncThunk(
  "storeManagement/getPendingUsersCall",
  async (param: any) => await get(`${SERVER_IP}/getapprovedpendingstoreslist`)
);

export const getRejectedUsersCall = createAsyncThunk(
  "storeManagement/getRejectedUsersCall",
  async (param: any) => await get(`${SERVER_IP}/getapprovedpendingstoreslist`)
);
// http://195.95.200.22:8080/focusnextrestservices/api/

export const getApprovedUsersCall = createAsyncThunk(
  "storeManagement/getApprovedUsersCall",
  async (param: any) => {
    console.log("getApprovedUsersCallgetApprovedUsersCall");
    return await get(`${SERVER_IP}/getapprovedstoreslist`);
  }
);

export const getPaymentDetailsCall = createAsyncThunk(
  "storeManagement/getPaymentDetailsCall",
  async (param: any) => await get(`${SERVER_IP}/customernotelist`)
);
export const customerApprovelCall = createAsyncThunk(
  "storeManagement/customerApprovelCall",
  async (param: any) => await post(`${SERVER_IP}/approveStore`, param)
);
export const customerRejectCall = createAsyncThunk(
  "storeManagement/customerRejectCall",
  async (param: any) => await post(`${SERVER_IP}/rejectStore`, param)
);
