import { DateRangePicker } from "react-date-range";

import { DateRangeSelectStyled } from "./DateRangeSelect.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Calendar } from "react-date-range";
import { addDays } from "date-fns";

// import CommonReportData from "./../Reports/CommonReportData";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  getAllBrands,
  getProductsListApi,
  deleteitemrecivedoptionCall,
  deleteadditionalrequirementsoptionCall,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import {
  getAllUsersList,
  deleteUserApi,
} from "../../redux/slices/auth/authService";

import { Chart } from "react-google-charts";

// import { getDashboards } from "./../../../redux/slices/serviceManagement/serviceManagementService";

const DateRangeSelect = (props: any) => {
  const { statusUpdate, handleFilterClick } = props;
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  const {
    brandsList,
    productsList,
    itemsReceivedList,
    defaultIssuesList,
    additionalRequirementsList,
    actionTakenList,
    engineerObservationList,
    customerNotesList,
  } = useSelector((state: any) => state.serviceManagement);

  const { error, loading, user, searchUsersList, allUsersList } = useSelector(
    (state: any) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  console.log("usersList", usersList);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      // endDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  console.log("dte range state", state);
  return (
    <>
      <DateRangeSelectStyled>
        {/* <JobCardDashboardStyled> */}
        <div className="job-card-dashboard">
          <div>
            <DateRangePicker
              onChange={(item: any) => {
                console.log("itemitem", item);
                setState([item.selection]);
              }}
              // showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
          </div>
          <div className="filter-date-row-sec">
            <button
              className="filter-btn-btn"
              onClick={() => {
                handleFilterClick(state);
              }}
            >
              Filter
            </button>
          </div>

          {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
        </div>

        <div>
          {/* <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} /> */}
        </div>
        {/* </JobCardDashboardStyled> */}
      </DateRangeSelectStyled>
    </>
  );
};
export default DateRangeSelect;
