import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const ItemsReceivedList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Items Recieved List Create"
        section="itemsReceivedList"
        subTitle="Create New Item Received Option"
      />
    </>
  );
};
export default ItemsReceivedList;
