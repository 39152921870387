import { CreateJobcardStyled } from "./../../CreateJobcard/CreateJobcard.styled";
import React, { useState, useEffect, Component, useRef } from "react";
import CustomModal from "../../../components/CustomModel";
import { useHistory } from "react-router";
import AddNewUser from "./../../CreateJobcard/AddNewUser";
import PasswordPatternLock from "./../../CreateJobcard/PasswordPatternLock";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Table, Pagination } from "antd";
// import { statusList, severityOptions } from "./../../../Scenes/constants";

import TimePicker from "rc-time-picker";
import Select from "react-select";
import { CreateLeadManagementStyled } from "./CreateLeadManagement.styled";
import "rc-time-picker/assets/index.css";
import {
  getAllCustomersList,
  deleteCustomerApi,
} from "./../../../redux/slices/auth/authService";
import {
  createJobCardApi,
  getProductsListApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  getJobCardOptionsBasedOnProductId,
} from "./../../../redux/slices/serviceManagement/serviceManagementService";
import {
  getleadstatuslistApi,
  leadactiontakenlistApi,
} from "./../../../redux/slices/LeadManagement/leadManagementService";

import icons_plus from "./../../../img/icons8-plus-50.png";
import icons_edit from "./../../../img/icons8-edit-32.png";

import { useDispatch, useSelector } from "react-redux";
import {
  createLeadApi,
  editLeadApi,
  paymentmodelistApi,
  configurationlistApi,
  createconfigurationApi,
  getcongigurationsbyproductidforleadApi,
  getLeadDetailsById,
} from "./../../../redux/slices/LeadManagement/leadManagementService";
import { getCustomerSearch } from "./../../../redux/slices/auth/authService";
import moment from "moment";
import {
  statusList,
  severityOptions,
  statusListUpdate,
} from "../../../Scenes/constants";
import PrevListSection from "./../../CreateJobcard/PrevListSection";
import arrow_down from "./../../../img/arrow-205.png";
import password_pattern from "./../../../img/password-pattern.png";
import Loader from "./../../../components/Common/Loader";
import { triggerMixpanelEvent } from "../../../Scenes/common";
const format = "h:mm a";
const currentDate = new Date();

const currentHour = currentDate.getHours();
const currentMinute = currentDate.getMinutes();

var timeNow = moment().hour(currentHour).minute(currentMinute);
// moment(new Date()).format("h:mm a");
const defaultAction = {};
const partsReplaced = {};
const partsReplaced2 = { id: 2 };

const requiredFieldsList = {
  customerName: false,
  mobileNo: false,
  // deliveryDateTemp: false,
  userType: false,
  paymentMode: false,
  // advanceAmount: false,
  incommingSource: false,
  selectedUserId: false,
};
const requiredFieldsList1 = {
  customerName: false,
  mobileNo: false,
  // deliveryDateTemp: false,
  userType: false,
  paymentMode: false,
  // advanceAmount: false,
  incommingSource: false,
};
const editRequiredFields1 = {
  toCourierName: false,
  tempToCourierDate: false,
  toDocNumber: false,
  toCompanyName: false,
};

const editRequiredFields3 = {
  receiveingCourierName: false,
  tempReceiveingToCourierDate: false,
  receiveingDocNumber: false,
};

const CreateLeadManagement = (props) => {
  const leadId = props?.match?.params?.leadId;

  const [jobData, setJobData] = useState({});
  // const [requiredData, setRequiredData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [itemsRecievedList, setItemsRecievedList] = useState([]);
  const [defaultIssues, setDefaultIssues] = useState([]);
  const [cARequirements, setCARequirements] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessPopupUser, setShowSuccessPopupUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [actionTakenList, setActionTakenList] = useState([]);
  const [engineerObservationList, setEngineerObservationList] = useState([]);
  const [addNewUserPopup, setAddNewUserPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState(requiredFieldsList);
  const [requiredFields1, setRequiredFields1] = useState(requiredFieldsList1);
  const [selectedUserEditData, setSelectedUserEditData] = useState({});
  const [tempUserPhoneNumber, setTempUserPhoneNumber] = useState("");
  const [reqInformation, setReqInformation] = useState([]);
  const [configarationList, setConfigarationList] = useState([]);
  const [paymentModeList, setPaymentModeList] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [productConfigs, setProductConfigs] = useState([]);
  const [showSuccessUserPopup, setShowSuccessUserPopup] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(
    new Date().toLocaleString()
  );

  const [editRequiredFields, setEditRequiredFields] =
    useState(editRequiredFields1);
  const [editRequiredFields2, setEditRequiredFields2] =
    useState(editRequiredFields3);
  const [showItemReceived, setShowItemReceived] = useState(true);
  const [showDefaultIssues, setShowDefaultIssues] = useState(true);
  const [showCustomerAR, setShowCustomerAR] = useState(true);

  const [showItemReceivedRemarks, setShowItemReceivedRemarks] = useState(false);
  const [showDefaultIssuesRemarks, setShowDefaultIssuesRemarks] =
    useState(false);
  const [showCustomerARRemarks, setShowCustomerARRemarks] = useState(false);
  const [showPasswordPatternLock, setShowPasswordPatternLock] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [openEngineerObservationsBox, setOpenEngineerObservationsBox] =
    useState(false);
  const [openCustomerNotesBox, setOpenCustomerNotesBox] = useState(false);
  const history = useHistory();
  const dropdownRef = useRef();

  // const [itemsRecievedListNoItems, setItemsRecievedListNoItems] = useState(
  //   false
  // );
  // const [defaultIssuesNoItems, setDefaultIssuesNoItems] = useState(false);
  // const [cARequirementsNoItems, setCARequirementsNoItems] = useState(false);

  const [updateData, setUpdateData] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [actionTakenFullList, setActionTakenFullList] = useState([]);
  const [statusFullList, setStatusFullList] = useState([]);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [userSelectedInfo, setUserSelectedInfo] = useState({});
  const [actionTakenItems, setActionTakenItems] = useState([
    { ...defaultAction },
  ]);
  const [partsReplacedItems, setPartsReplacedItems] = useState([
    { ...partsReplaced },
    // { ...partsReplaced2 },
  ]);
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
    getJobCardOptionsBasedOnProductIdResp,
  } = useSelector((state) => state.serviceManagement);
  const { leadDetails, leadStatusList, leadActionTakenList } = useSelector(
    (state) => state.leadManagement
  );

  const { searchUsersList, user, allCustomersList } = useSelector(
    (state) => state.auth
  );
  const jobId = props?.match?.params?.jobId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getleadstatuslistApi());
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
        setShowDropDown1(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    if (leadId) {
      dispatch(getLeadDetailsById(leadId));
      dispatch(leadactiontakenlistApi());
    }
  }, [leadId]);
  useEffect(() => {
    console.log("leadDetails", leadDetails);
    const lData = leadDetails?.leadInfo;
    if (lData?.id) {
      setJobData({
        ...lData,
        mobileNo: lData?.customerInfo?.mobileNo,
        customerName: lData?.customerInfo?.customerName,
      });
      let newList5 = [];
      lData?.leadItem?.map((newItemss) => {
        newList5.push({
          ...newItemss,
          brand: newItemss?.brand?.name,
          brandId: newItemss?.brand?.id,
          product: newItemss?.product?.name,
          productId: newItemss?.product?.id,
          configuration: newItemss?.configuration,
          configurationId: newItemss?.configurationId,
        });
        // getBrandsAndConfig(index, newItemss?.product?.id);
      });
      console.log("newList5newList5", newList5);
      setPartsReplacedItems(newList5);
      setUserSelectedInfo(lData?.customerInfo);
      setTimeout(() => {
        lData?.leadItem?.map((newItemss1, index1) => {
          getBrandsAndConfig1(index1, newItemss1?.product?.id, newItemss1);
        });
      }, 500);
      // dispatch(getLeadDetailsById(leadId));
    }
  }, [leadDetails]);

  // console.log("jobIdjobId", jobId);
  useEffect(() => {
    window.scrollTo(0, 0);

    getOnloadDefaultValues();
    console.log("jobIdjobIdjobId", jobId);
    if (leadId) {
      triggerMixpanelEvent("edit_job_card_page", { leadId });
      setShowItemReceived(false);
      setShowDefaultIssues(false);
      setShowCustomerAR(false);
      // dispatch(getJobCardDetailsApi(jobId));
      // var timeNow = moment().hour(currentHour).minute(currentMinute);
    } else {
      triggerMixpanelEvent("create_job_card_page");
      // const newTime = moment(timeNow).format("H:mm");
      // const newDDate = moment(timeNow).format("YYYY-MM-DD");
      // const newTime = moment(timeNow).format("h:mm a");
      // const newDDate = moment(timeNow).format("DD/MM/YYYY");
      const pdeliveryDateTime = moment(new Date()).format();
      // const pbookingDateTime = moment(new Date()).format("DD/MM/YYYY H:MM A");
      const pbookingDateTime = moment(new Date()).format();

      setTimeout(() => {
        setJobData({
          ...jobData,
          deliveryDateTime: pdeliveryDateTime,
          bookingDateTime: pbookingDateTime,
        });
      }, 100);
      // setJobData({});
    }
  }, []);
  useEffect(async () => {
    const jobCData = jobCardDetails?.jobCard;
    if (jobCData?.id) {
      setJobData({
        ...jobCData,
        warrantyServiceTemp:
          jobCData?.warrantyServiceTemp || jobCData?.disWarrantyService,
        selectedBrandId: jobCData?.brand?.id,
        brandName: jobCData?.brand?.name,
        selectedProductId: jobCData?.product?.id,
        productName: jobCData?.product?.name,
        deliveryDateTemp: jobCardDetails?.date,
        deliveryDateTempTime: jobCardDetails?.time,
        selectedUserId: jobCData?.assignedTo?.id,
        originalJobCardReceived: jobCardDetails?.originalJobCardReceived,
        receiverPersonSame: jobCardDetails?.receiverPersonSame,
        priority: jobCardDetails?.priority,

        // customerNotes: jobCardDetails?.customerNotes?.[0]?.note,
      });
      setItemsRecievedList(jobCardDetails?.itemRecivedOptions);
      setDefaultIssues(jobCardDetails?.defaultIssuesOptions);
      setCARequirements(jobCardDetails?.additionalRequirementsOptions);

      setActionTakenList(jobCardDetails?.actionTakenOptions);
      setEngineerObservationList(jobCardDetails?.engineerObservationOptions);

      const newList = [];
      jobCardDetails?.brands?.map((item) => {
        newList.push({
          ...item,
          value: item?.brandName,
          label: item?.brandName,
        });
      });
      setBrandsList(newList);

      var newList12 = requiredFields1;

      if (jobCardDetails?.receiverPersonSame === "No") {
        newList12.originalNotJobCardReason = false;
      } else {
        delete newList12.originalNotJobCardReason;
      }
      if (jobCardDetails?.originalJobCardReceived === "No") {
        newList12.recevierName = false;
        newList12.recevierMobileNo = false;
      } else {
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
      }
      // setRequiredFields(newList12);
      setRequiredFields1(newList12);
    }
  }, [jobCardDetails]);
  useEffect(() => {
    // const newList = [];
    // displayJobCardDetails?.brands?.map((item) => {
    //   newList.push({ ...item, value: item?.name, label: item?.name });
    // });
    // setBrandsList(newList);
    const newListProducts = [];

    displayJobCardDetails?.products?.map((item) => {
      newListProducts.push({ ...item, value: item?.id, label: item?.name });
    });
    setProductsList(newListProducts);
    const usersList1 = [];
    displayJobCardDetails?.users?.map((user) => {
      const name =
        user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
      usersList1.push({ id: user?.id, value: user?.id, label: name });
    });
    setUsersList(usersList1);
    // setItemsRecievedList(displayJobCardDetails?.itemRecivedOptions);
    // setDefaultIssues(displayJobCardDetails?.defaultIssuesOptions);
    // setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
  }, [displayJobCardDetails]);
  useEffect(() => {
    const newList = [];
    var selecbID = null;
    var selecbName = null;
    brandsByProductIdResp?.brands?.map((item) => {
      newList.push({ ...item, value: item?.name, label: item?.name });
      if (item?.selected === "selected") {
        selecbID = item?.id;
        selecbName = item?.name;
      }
    });
    setBrandsList(newList);
    // setItemsRecievedList(itemsRecievedByProductIdResp);

    const prevValues = { ...jobData };
    if (selecbID) {
      prevValues["selectedBrandId"] = selecbID;
      prevValues["brandName"] = selecbName;
    }
    setJobData(prevValues);
  }, [brandsByProductIdResp]);

  useEffect(() => {
    setActionTakenList(
      getJobCardOptionsBasedOnProductIdResp?.actionTakenOptions
    );
    setEngineerObservationList(
      getJobCardOptionsBasedOnProductIdResp?.engineerObservationOptions
    );
  }, [getJobCardOptionsBasedOnProductIdResp]);
  useEffect(() => {
    setItemsRecievedList(itemsRecievedByProductIdResp);
  }, [itemsRecievedByProductIdResp]);
  useEffect(() => {
    setDefaultIssues(defaultIssuesByProductIdResp);
  }, [defaultIssuesByProductIdResp]);

  useEffect(() => {
    setCARequirements(customerAdditionalReqResp);
  }, [customerAdditionalReqResp]);
  useEffect(() => {}, [jobData?.status]);
  // const [actionTakenFullList, setActionTakenFullList] = useState([] as any);
  // const [statusFullList, setStatusFullList] = useState([] as any);

  useEffect(() => {
    console.log("leadStatusList", leadStatusList);
    if (leadStatusList?.length > 0) {
      let finalList = [];
      leadStatusList.map((iiem) => {
        if (iiem?.enable) {
          const itemv = {
            id: iiem?.id,
            label: iiem?.status,
            value: iiem?.status,
          };
          finalList.push(itemv);
        }
      });
      setStatusFullList(finalList);
    }
  }, [leadStatusList]);

  useEffect(() => {
    console.log("leadActionTakenList", leadActionTakenList);
    if (leadActionTakenList?.length > 0) {
      let finalList = [];
      leadActionTakenList.map((iiem) => {
        if (iiem?.enable) {
          const itemv = {
            id: iiem?.id,
            label: iiem?.optionName,
            value: iiem?.optionName,
            optionName: iiem?.optionName,
          };
          finalList.push(itemv);
        }
      });
      setActionTakenFullList(finalList);
    }
  }, [leadActionTakenList]);

  const getOnloadDefaultValues = async () => {
    dispatch(getDisplayJobCardFormApi(null));
    dispatch(getAllCustomersList());
    // dispatch(
    //   createconfigurationApi({ name: "new name", selected: "2, 34, 4" })
    // );
    const resp1 = await dispatch(paymentmodelistApi());
    setPaymentModeList(resp1?.payload);
    const resp2 = await dispatch(configurationlistApi());
    setConfigarationList(resp2?.payload);
    // const [configarationList, setConfigarationList] = useState([]);
    // const [paymentModeList, setPaymentModeList] = useState([]);
  };
  const isDisabledBtn = () => {
    let returnF = false;
    if (jobId) {
      if (
        jobCardDetails?.jobCard?.status === "Closed" ||
        jobCardDetails?.jobCard?.status === "Closing Approval"
      ) {
        returnF = true;
      }
      if (user?.roles[0] === "ROLE_STORE_OWNER") {
        returnF = false;
      }
    }
    return returnF;
  };
  const searchOnChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "items-recieved") {
      setShowItemReceived(true);
      if (value === "") {
        setItemsRecievedList(itemsRecievedByProductIdResp);
      } else {
        const newItems = [];
        itemsRecievedByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setItemsRecievedList(newItems);
      }
    } else if (name === "default-issues") {
      setShowDefaultIssues(true);
      if (value === "") {
        setDefaultIssues(defaultIssuesByProductIdResp);
      } else {
        const newItems = [];
        defaultIssuesByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setDefaultIssues(newItems);
      }
    } else if (name === "customer-additional") {
      setShowCustomerAR(true);
      if (value === "") {
        setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
      } else {
        const newItems = [];
        displayJobCardDetails?.additionalRequirementsOptions?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setCARequirements(newItems);
      }
    }
  };
  // }, [searchValue]);

  const onChangeValue = async (e) => {
    const { name, value } = e.target;
    const prevValues = { ...jobData };
    if (
      name === "minCharges" ||
      name === "printPageCount" ||
      name === "printBlack" ||
      name === "printColor"
    ) {
      var value1 = value;
      if (value.trim() == "") {
        value1 = value.trim();
      }
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
        setJobData(prevValues);
      }
    } else {
      prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
      setJobData(prevValues);
    }

    const newList = requiredFields;
    newList[name] = false;
    await setRequiredFields(newList);

    const newList1 = editRequiredFields;
    newList1[name] = false;
    setEditRequiredFields(newList1);

    const newList22 = editRequiredFields2;
    newList22[name] = false;
    setEditRequiredFields2(newList22);

    if (name === "customerName") {
      getDropDownValues(value);
      setShowDropDown(true);
    } else if (name === "mobileNo") {
      getDropDownValues(value);
      setShowDropDown1(true);
    }
    if (name === "engineerObservationOption") {
      setOpenEngineerObservationsBox(value ? true : false);
    } else if (name === "reasonForClose") {
      setOpenCustomerNotesBox(value ? true : false);
    }
    updateRecevierPersonList(name, value);
  };
  const updateRecevierPersonList = async (name, value) => {
    if (name === "receiverPersonSame") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        const fiallIst = {
          ...requiredFields1,
          recevierName: false,
          recevierMobileNo: false,
        };
        // await setRequiredFields(fiallIst);
        await setRequiredFields1(fiallIst);
      }
    }
    if (name === "originalJobCardReceived") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.originalNotJobCardReason;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        // await setRequiredFields({
        //   ...requiredFields1,
        //   originalNotJobCardReason: false,
        // });
        await setRequiredFields1({
          ...requiredFields1,
          originalNotJobCardReason: false,
        });
      }
    }
  };
  const onChangeValueTime = (e) => {
    const newTime = moment(e?._d).format("H:mm");
    setJobData({ ...jobData, deliveryDateTempTime: newTime });
  };
  const handleDateTimeB = (date) => {
    console.log("date", date);
    setSelectedDateTime(new Date(date).toLocaleString());
    const prevValues = { ...jobData };
    prevValues["bookingDateTime"] = moment(new Date(date)).format();

    setJobData(prevValues);

    // deliveryDateTemp
    // :
    // "2023-10-08"
    // deliveryDateTempTime
    // :
    // "14:08"
  };
  const handleDateTimeD = (date) => {
    setSelectedDateTime(new Date(date).toLocaleString());
    const prevValues = { ...jobData };
    // prevValues["deliveryDateTime"] = moment(new Date(date)).format();

    setJobData(prevValues);

    // deliveryDateTemp
    // :
    // "2023-10-08"
    // deliveryDateTempTime
    // :
    // "14:08"
  };

  const disablePastDt = (current) => {
    let days_date = 1;
    if (user?.roles[0] === "ROLE_STORE_OWNER") {
      days_date = 365;
    }
    const yesterday = moment().subtract(days_date, "day");
    return current.isAfter(yesterday);
  };

  const onChangeValueSelect = (name1, e) => {
    const PId = e?.id;
    // const { name, value } = e.target;
    const pValue = e.label;
    const prevValues = { ...jobData };
    prevValues[name1] = e.label;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);
    if (name1 === "productName") {
      prevValues["selectedProductId"] = PId;
      prevValues["itemRecived"] = "";
      prevValues["defaultIssues"] = "";
      prevValues["customerAddRequirements"] = "";

      if (e.value === "") {
        prevValues["brandName"] = "";
      } else {
        const jsonBody = { PId, jobId: jobId ? jobId : 0 };
        // dispatch(getDisplayJobCardFormApi(PId));
        // dispatch(getBrandsByProductIdApi(jsonBody));
        // dispatch(getItemsRecievedByProductIdAPi(jsonBody));
        // dispatch(getDefaultIssuesByProductIdAPi(jsonBody));
        // dispatch(getCustomerAdditionalReqAPi(jsonBody));
        // dispatch(getJobCardOptionsBasedOnProductId(jsonBody));
      }
      if (
        pValue === "Desktop" ||
        pValue === "Laptop" ||
        pValue === "Mobile" ||
        pValue === "TAB" ||
        pValue === "DVR"
      ) {
        // setRequiredFields({ ...requiredFields, systemPassword: false });
        setRequiredFields1({ ...requiredFields1, systemPassword: false });
      } else if (pValue === "Printer") {
        setRequiredFields1({ ...requiredFields1, printPageCount: false });
      } else {
        // var newList1 = requiredFields;
        // delete newList1.systemPassword;
        // setRequiredFields(newList1);
        var newList12 = requiredFields1;
        delete newList12.systemPassword;
        delete newList12.printPageCount;
        setRequiredFields1(newList12);
      }
      setShowItemReceived(true);
      setShowDefaultIssues(true);
      setShowCustomerAR(true);
    } else {
      const braId = e?.brandId || e?.id;
      prevValues["selectedBrandId"] = braId;
    }

    setJobData(prevValues);
  };
  const onChangeValueSelectAssigned = (name1, e) => {
    // const { name, value } = e.target;
    const prevValues = { ...jobData };
    prevValues[name1] = e.id;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);

    setJobData(prevValues);
  };
  const dateFormatType = (date) => {
    var dtToday = new Date();
    if (date != undefined && date != "") {
      dtToday = new Date(date);
    }
    var month = "";
    var day = "";
    month = dtToday.getMonth() + 1;
    day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    if (user?.roles[0] === "ROLE_STORE_OWNER") {
      year = year - 1;
    }

    var DateNow = year + "-" + month + "-" + day;
    return DateNow;
  };
  const lowerName = jobData?.productName?.toLowerCase();
  const showExtraFields =
    lowerName === "desktop" ||
    lowerName === "laptop" ||
    lowerName === "mobile" ||
    lowerName === "tab" ||
    lowerName === "dvr"
      ? true
      : false;
  const showExtraSerialFields =
    lowerName === "laptop" ||
    lowerName === "mobile" ||
    lowerName === "tab" ||
    lowerName === "dvr"
      ? true
      : false;
  const showPrinterFields = jobData?.productName === "Printer" ? true : false;
  const noItems = [1, 2, 3, 4, 5, 66, 7, 7, 8, 8];
  // $("#items-recieved-search-box").on("keyup", function () {
  //   var query = this.value?.toLowerCase();
  //   $("#loadItemsRecived_dev > div").each(function (i, elem) {
  //     var currentV = $(this).find("input")?.val()?.toLowerCase();
  //     if (currentV.indexOf(query) != -1) {
  //       $(this).removeClass("dis-none-imp");
  //     } else {
  //       $(this).addClass("dis-none-imp");
  //     }
  //   });
  // });
  const addNewRowForAction = (item, index, value1) => {
    const prevValues = actionTakenItems;
    prevValues.push({ ...defaultAction, id: index });
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const onChangeValueActionTaken = (e, item, index) => {
    const { name, value } = e.target;

    const prevValues = actionTakenItems;
    prevValues[index].serviceTaken = value;
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const showPartsReplacedSec = () => {
    let returnF = true;
    // actionTakenItems?.map((itemV) => {
    //   if (
    //     itemV?.serviceTaken &&
    //     itemV?.serviceTaken?.toLowerCase().includes("replaced")
    //   ) {
    //     returnF = true;
    //   }
    // });
    // if (jobCardDetails?.partReplaceds?.length > 0) {
    //   returnF = true;
    // }
    return returnF;
  };
  const deleteItemForAction = (item, index) => {
    const prevValues = actionTakenItems;

    prevValues.splice(index, 1);
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const addNewRowForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;

    prevValues.push({ ...defaultAction, id: "" });
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const deleteItemForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;

    prevValues.splice(index, 1);
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
  };
  const handleValidate = () => {
    var firstEleId = "";
    var refurnFlag = false;
    const newList = requiredFields1;
    for (var key in newList) {
      const Pvalue = jobData[key];

      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        newList[key] = true;
        refurnFlag = true;
        if (firstEleId === "") {
          firstEleId = key;
        }
      }
    }
    setUpdateData(!updateData);
    setRequiredFields(newList);
    // if (
    //   jobData?.status === "Ready For Delivery" ||
    //   jobData?.status === "Out for Warranty"
    // ) {
    //   const editnewList = {
    //     toCourierName: false,
    //     tempToCourierDate: false,
    //     toDocNumber: false,
    //     toCompanyName: false,
    //   };
    //   for (var key1 in editnewList) {
    //     const Pvalue = jobData[key1];
    //     if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
    //       editnewList[key1] = true;
    //       refurnFlag = true;
    //       if (firstEleId === "") {
    //         firstEleId = key1;
    //       }
    //     }
    //   }
    //   setEditRequiredFields(editnewList);
    // }

    // if (
    //   jobData?.toCourierName &&
    //   (jobData?.status === "Repair Closed" ||
    //     jobData?.status === "Ready For Delivery")
    // ) {
    //   const editRequiredFields3_temp = {
    //     receiveingCourierName: false,
    //     tempReceiveingToCourierDate: false,
    //     receiveingDocNumber: false,
    //   };
    //   for (var key1 in editRequiredFields3_temp) {
    //     const Pvalue = jobData[key1];
    //     if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
    //       editRequiredFields3_temp[key1] = true;
    //       refurnFlag = true;
    //       if (firstEleId === "") {
    //         firstEleId = key1;
    //       }
    //     }
    //   }
    //   setEditRequiredFields2(editRequiredFields3_temp);
    // }
    if (refurnFlag && firstEleId) {
      const element = document.getElementById(firstEleId);
      if (element) {
        window.scrollTo({ top: element?.offsetTop - 100, behavior: "smooth" });
        // element.scrollIntoView({ behavior: "smooth" });
      }
    }

    return refurnFlag;
  };
  const handleCreateLead = async () => {
    const requirementInformation1 = [];
    const body1 = { ...jobData };
    body1.requirementInformation = partsReplacedItems;
    body1.requirementInformation = partsReplacedItems;
    body1.userId = jobData?.selectedUserId;
    body1.address_id = jobData?.selectedUserId;
    body1.selectedCustomerId = userSelectedInfo?.id;

    if (actionTakenItems?.length > 0 && actionTakenItems[0]?.serviceTaken) {
      body1.actionTakenItems = actionTakenItems;
    }

    console.log("body1body1", body1);
    // return false;
    if (!handleValidate() && !isLoading) {
      setIsPageLoading(true);
      if (body1?.id) {
        delete body1.leadItem;
        const resp1 = await dispatch(editLeadApi(body1));
        setIsPageLoading(false);
        if (resp1?.payload?.id) {
          setShowSuccessUserPopup(true);
        }
      } else {
        const resp1 = await dispatch(createLeadApi(body1));
        setIsPageLoading(false);
        if (resp1?.payload?.id) {
          setShowSuccessUserPopup(true);
        }
      }
    }
  };
  const hideModel = () => {
    // history.push("/login");
    setShowSuccessUserPopup(false);
  };
  const hideModelOk = () => {
    history.push("/LeadSummary");
    setShowSuccessUserPopup(false);
  };

  const onChnageValueList = (Jtype, item, e, newList = "") => {
    const newList1 = requiredFields;
    newList1[Jtype] = false;
    setRequiredFields(newList1);

    const isChecked = e.target.checked;
    const prevValues = { ...jobData };
    if (isChecked) {
      var newList2 = "";
      if (newList === "") {
        newList2 = item?.optionName;
      } else {
        newList2 = newList + "," + item?.optionName;
      }
      newList = newList2;
      // newList.push(item);
      if (
        item?.optionName === "No Items Received" ||
        item?.optionName === "No Additional Required" ||
        item?.optionName === "NO ADDITIONAL REQUIRED"
      ) {
        newList = item?.optionName;
      }
    } else {
      const newList1 = newList.split(",");
      const idx = newList1.findIndex((u) => u === item?.optionName);
      if (idx !== -1) {
        newList1.splice(idx, 1);
        newList = newList1.join(",");
      }
    }

    if (Jtype === "itemRecived") {
      prevValues.itemRecived = newList;
    } else if (Jtype === "defaultIssues") {
      prevValues.defaultIssues = newList;
    } else if (Jtype === "customerAddRequirements") {
      prevValues.customerAddRequirements = newList;
    }
    // var newList = prevValues?.itemRecived || [];

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // prevValues["itemRecived"] = value;
    setJobData(prevValues);
  };
  const checkItemByList = (list, item) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex((u) => u === item?.optionName);
      if (idx === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const isNoitemSelectedCheck = (list) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex(
        (u) =>
          u === "No Items Received" ||
          u === "No Additional Required" ||
          u === "NO ADDITIONAL REQUIRED"
      );
      if (idx === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const handlePartChange = (e, index) => {
    const { name, value } = e.target;
    const newValues = partsReplacedItems;
    if (name === "qty" || name === "advanceAmount") {
      var value1 = value;
      if (value.trim() == "") {
        value1 = value.trim();
      }
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        newValues[index][name] = value;
        setPartsReplacedItems(newValues);
      }
    } else {
      newValues[index][name] = value;
      setPartsReplacedItems(newValues);
    }
  };
  const handleAddNewUSer = () => {
    setSelectedUserEditData({});

    triggerMixpanelEvent("add_new_customer_click");
    setAddNewUserPopup(true);
  };
  const handleEditUSer = () => {
    if (userSelectedInfo?.id) {
      const found = allCustomersList.find(
        (element) => element?.id === userSelectedInfo?.id
      );
      setSelectedUserEditData(found);

      triggerMixpanelEvent("edit_customer_click");
      setAddNewUserPopup(true);
    }
  };
  const addUserSuccess = (select) => {
    triggerMixpanelEvent("add_new_customer_success", select);
    handleCloseAddNewUSer();
    dispatch(getAllCustomersList());
    setShowSuccessPopupUser(true);
    setUserSelectedInfo(select);
    const prevValues = { ...jobData };
    prevValues["customerName"] = select?.customerName;
    prevValues["mobileNo"] = select?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);
  };
  const handleCloseAddNewUSer = () => {
    setAddNewUserPopup(false);
  };
  const onChangeValuePriority = (name, value, jobId) => {
    // const { name, value, jobId } = e.target;
    // let jsonvalBody = {
    //   id: jobId,
    //   status: value,
    // };
    // let newText = `Are you sure, do you want to change status to ${value}`;
    // if (name === "priority") {
    //   newText = `Are you sure, do you want to change Priority to ${value}`;
    //   let jsonvalBody = {
    //     id: jobId,
    //     priority: value,
    //   };
    // } else {
    // }
    // setJsonBody(jsonvalBody);
    // setIsConfirmModalOpen(true);
    // setMessageText(newText);
  };
  const handleClickBack = () => {
    history.push("/jobcardsummary");
  };
  const getDropDownValues = (val) => {
    if (val) {
      dispatch(getCustomerSearch(val));
    }
  };
  // const onChangeValuesManufacturer = (e) => {
  //   setBrandValue(e.target.value);
  //   // setTimeout(() => {
  //   const regJson = {
  //     Page: 1,
  //     PageSize: 25,
  //     Brand: e?.target?.value,
  //   };
  //   dispatch(getBrandsListApi(regJson));
  //   setFieldValue("brand", "");
  //   setFieldValue("manufacture", "");
  //   setFieldValue("manufacturerCode", "");
  //   setFieldValue("brandCode", "");
  //   if (e?.target?.value === "") {
  //     setShowDropDown(false);
  //     setFieldValue("manufacturer", "");
  //     setFieldValue("manufacturerCode", "");
  //   } else {
  //     setShowDropDown(true);
  //   }
  //   // }, 300);
  // };
  const handleManuClick = () => {
    setShowDropDown(true);
  };
  const handleItemClick = async (item) => {
    setShowDropDown(false);
    setShowDropDown1(false);
    setUserSelectedInfo(item);
    const prevValues = { ...jobData };
    prevValues["customerName"] = item?.customerName;
    prevValues["mobileNo"] = item?.mobileNo;
    prevValues["selectedCustomerId"] = item?.id;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);

    if (item?.value) {
      // await setBrandValue(item?.label);
      // const regJson = {
      //   brandCode: item?.value,
      // };
      // setFieldValue("brand", item?.label);
      // setFieldValue("brandCode", item?.value);
      // setFieldValue("manufacturer", item?.brandOwner);
      // setFieldValue("manufacturerCode", item?.brandOwnerCode);
      // setFieldValue("brandOemflag", item?.brandOemflag);
      // dispatch(getManfacturerApi(regJson));
    }
  };
  const handleClosePassLock = () => {
    setShowPasswordPatternLock(false);
  };
  const handleSuccessPassLock = (passData) => {
    handleClosePassLock();
    if (passData) {
      const prevValues = { ...jobData };
      prevValues["systemPassword"] = passData;
      setJobData(prevValues);
    }
  };

  const handleKeypress = (e) => {
    if (e?.keyCode === 13) {
      if (searchUsersList?.length === 0) {
        setShowDropDown1(false);
        setSelectedUserEditData({});
        setTempUserPhoneNumber(e?.target?.value);
        handleAddNewUSer();
      } else {
        handleItemClick(searchUsersList[0]);
      }
    }
  };
  const handleChangeDropDown = async (index, key1, key2, value2, list) => {
    const value1 = list?.find(
      (item1) => (item1?.label || item1?.name) == value2
    )?.id;

    console.log("key1, key2, value1, value2", key1, key2, value1, value2, list);
    const newValues = [...partsReplacedItems];
    newValues[index][key1] = value1;
    newValues[index][key2] = value2;
    setPartsReplacedItems(newValues);
    if (key1 === "productId") {
      getBrandsAndConfig(index, value1);
    }
    // getcongigurationsbyproductidforleadApi(value1)
    // getcongigurationsbyproductidforlead
    // const prevValues = { ...jobData };
    // prevValues[name1] = e.label;
  };
  const getBrandsAndConfig = async (index, value1) => {
    const jsonBody = { PId: value1, jobId: 0 };
    const newValues = [...partsReplacedItems];
    console.log("indexindexindex 111", index);
    console.log("value1value1value1 111", value1);
    console.log("newValues 111", newValues);
    // dispatch(getDisplayJobCardFormApi(PId));
    const resp = await dispatch(getBrandsByProductIdApi(jsonBody));
    // console.log("resp", resp?.payload?.brands);

    const prevBrd = [...productBrands];
    prevBrd.push({ id: value1, list: resp?.payload?.brands });
    newValues[index]["brandsList"] = resp?.payload?.brands;
    setPartsReplacedItems(newValues);
    setProductBrands(prevBrd);
    const resp1 = await dispatch(
      getcongigurationsbyproductidforleadApi(value1)
    );
    // console.log("resp1resp1", resp1);
    const prevBrd1 = [...productConfigs];
    prevBrd1.push({ id: value1, list: resp1?.payload?.configurations });
    setProductConfigs(prevBrd1);
  };
  const getBrandsAndConfig1 = async (index, value1, listrr) => {
    const jsonBody = { PId: value1, jobId: 0 };
    const newValues = [...partsReplacedItems];
    console.log("indexindexindex 111", index);
    console.log("value1value1value1 111", value1);
    console.log("newValues 111", newValues);
    // dispatch(getDisplayJobCardFormApi(PId));
    const resp = await dispatch(getBrandsByProductIdApi(jsonBody));
    // console.log("resp", resp?.payload?.brands);

    const prevBrd = [...productBrands];
    prevBrd.push({ id: value1, list: resp?.payload?.brands });
    // newValues[index]["brandsList"] = resp?.payload?.brands;
    // setPartsReplacedItems(newValues);
    setProductBrands(prevBrd);
    const resp1 = await dispatch(
      getcongigurationsbyproductidforleadApi(value1)
    );
    // console.log("resp1resp1", resp1);
    const prevBrd1 = [...productConfigs];
    prevBrd1.push({ id: value1, list: resp1?.payload?.configurations });
    setProductConfigs(prevBrd1);
  };
  const userSName = usersList?.find(
    (item1) =>
      item1?.id === (jobData?.selectedUserId || jobData?.assignedTo?.id)
  )?.label;
  // let currencyName = this.props?.currenciesList.find(
  //   (i) => collectionData?.currency == i?.id
  // )?.currencyCode;
  // const statusList1 = [{ id: 0, value: "", label: "All" }];
  const statusList3 = [{ id: 5, value: "Closed", label: "Closed" }];
  let statusList4 = [
    { id: 5, value: "Closing Approval", label: "Closing Approval" },
    { id: 25, value: "Re Open", label: "Re Open" },
  ];
  if (jobData?.status === "Closed") {
    statusList4.push({ id: 5, value: "Closed", label: "Closed" });
  }
  // age;
  const statusList2 =
    jobData?.age > 10
      ? [...statusList, ...statusList4]
      : [...statusList, ...statusList3];
  console.log("statusList2", statusList2);
  const leadSources = [
    "Just Dail",
    "Google",
    "FaceBook",
    "INSTAGRAM",
    "Referal",
    "News Paper",
    "Step In",
    "Old Customer",
  ];
  const onChangeValueSelect_product = (name1, e, productsList11, index11) => {
    handleChangeDropDown(
      index11,
      "productId",
      "product",
      e?.label,
      productsList11
    );
  };
  // <div>Product</div>
  // <div>Brand</div>
  // <div>Model</div>
  // <div>Select Configuration</div>
  // <div>User Type</div>
  // <div>Qty</div>
  // <div>Advance Amount</div>
  // <div>Payment Mode</div>
  // <div>Referral Link</div>
  const columns = [
    {
      title: "Product Name",
      width: 150,
      key: "0",
      fixed: "left",
      render: (item1, record, index) => {
        const currentS = `${item1?.product}`;
        const sid = item1?.productId;
        console.log("item1item1", item1);
        const pValue = item1?.product?.name || item1?.product;
        console.log("pValuepValuepValue", pValue);
        return (
          <>
            <div className={"assigned-to-sec1"}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={"Select"}
                isSearchable={true}
                value={pValue}
                name="product"
                id="product"
                options={productsList}
                onChange={(e) => {
                  onChangeValueSelect_product(
                    "product",
                    e,
                    productsList,
                    index
                  );
                }}
                // required
                // value={jobData?.productName}
                placeholder={pValue ? pValue : "Select"}
                // isInvalid={requiredFields?.productName}
              />
              {/* 
              <select
                // label={sid}
                name="product"
                onChange={(e) => {
                  handleChangeDropDown(
                    index,
                    "productId",
                    "product",
                    e.target?.value,
                    productsList
                  );
                }}
                required
                value={currentS}
              >
                <option value={""} name={""}>
                  Select
                </option>
                {productsList?.map((ittmmm) => {
                  const isSlect = ittmmm?.id == sid ? true : false;

                  return (
                    <option
                      value={ittmmm?.label}
                      selected={isSlect ? true : false}
                    >
                      {ittmmm?.label}
                    </option>
                  );
                })}
              </select> */}
            </div>
          </>
        );
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Brand Name",
      // dataIndex: "createdDate",
      key: "Date",
      width: 140,

      render: (item1, record, index) => {
        const currentS = item1?.brand?.name || item1?.brand;
        const sid = item1?.brandId || item1?.brand?.id;
        const pId = item1?.productId || item1?.product?.id;
        const bList = productBrands?.find((item1) => item1?.id == pId)?.list;
        const bbList = bList || item1?.brandDtoList;
        const pValue = item1?.brand?.name || item1?.brand;

        console.log("item1item1", item1);
        console.log("bListbList", bList);
        console.log("sidsid", sid);
        console.log("currentS", currentS);
        // currentS
        console.log("productBrandsproductBrands", productBrands);
        return (
          <>
            <div className={"assigned-to-sec1"}>
              <select
                // label={sid}
                name="brand"
                onChange={(e) => {
                  handleChangeDropDown(
                    index,
                    "brandId",
                    "brand",
                    e.target?.value,
                    bbList
                  );
                }}
                required
                value={currentS}
              >
                <option value={""} name={""}>
                  Select
                </option>
                {bbList?.map((ittmmm) => {
                  const isSlect = ittmmm?.id == sid ? true : false;
                  return (
                    <option
                      value={ittmmm?.name}
                      manss={isSlect ? "yes" : "No"}
                      selected={isSlect ? true : false}
                    >
                      {ittmmm?.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <span className={`priority_Select_${item}`}>{item}</span> */}
          </>
        );
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "2",
      width: 120,
      render: (item1, record, index) => {
        return (
          <>
            <input
              onChange={(e) => {
                handlePartChange(e, index);
              }}
              name="model"
              value={item1}
              className="input-row-box"
            />
          </>
        );
      },
    },
    {
      title: "Select Configuration",
      // dataIndex: "configuration",
      key: "2",
      width: 150,
      className: `priority_Select_P`,
      render: (item1, record, index) => {
        const currentS = item1?.configuration;
        const sid = item1?.id;
        const pId = item1?.productId || item1?.product?.id;

        const bList1 = productConfigs?.find((item1) => item1?.id == pId)?.list;
        const bList = bList1 || item1?.itemConfigurationList;
        return (
          <>
            <div className={"assigned-to-sec1"}>
              <select
                // label={sid}
                name="configuration"
                onChange={(e) => {
                  handleChangeDropDown(
                    index,
                    "configurationId",
                    "configuration",
                    e.target?.value,
                    bList
                  );
                }}
                // onChange={onChangeValue}
                required
                value={currentS}
              >
                <option value={""}>Select</option>
                {bList?.map((ittmmm) => {
                  const isSlect = ittmmm?.id == sid ? true : false;

                  return (
                    <option
                      value={ittmmm?.name}
                      selected={isSlect ? true : false}
                    >
                      {ittmmm?.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <span className={`priority_Select_${item}`}>{item}</span> */}
          </>
        );
      },
    },

    {
      title: "Qty",
      dataIndex: "qty",
      key: "42",
      width: 80,
      render: (qty, record, index) => {
        return (
          <>
            <input
              onChange={(e) => {
                handlePartChange(e, index);
              }}
              name="qty"
              value={qty}
              className="input-row-box"
            />
          </>
        );
      },
    },

    {
      title: "Referral Link",
      dataIndex: "referralLink",
      key: "6",
      width: 200,
      render: (item1, record, index) => {
        const currentS = item1?.priority;
        const sid = item1?.id;
        return (
          <>
            <input
              onChange={(e) => {
                handlePartChange(e, index);
              }}
              name="referralLink"
              value={item1}
              className="input-row-box"
            />
          </>
        );
      },
    },

    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 200,
    //   dataIndex: "id",
    //   render: (item) => {
    //     return (
    //       <>
    //         {/* {item} */}
    //         <span className="table-actions-btn">
    //           <span
    //             className="view-job-card"
    //             onClick={() => {
    //               viewJobCardItem(item);
    //             }}
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-eye"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
    //               <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
    //             </svg>
    //           </span>
    //           <span
    //             onClick={() => {
    //               editJobCardItem(item);
    //             }}
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-pencil-fill"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
    //             </svg>
    //           </span>

    //           <a className="redText" href="#">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-trash-fill"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
    //             </svg>
    //           </a>

    //           <a className="grayText" href="#">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-file-arrow-down-fill"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z" />
    //             </svg>
    //           </a>
    //           <span className="print-icon-main">
    //             <a target="_blank" href={`/printJobCard/${item}`}>
    //               <img src={print_img} />
    //             </a>
    //           </span>
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 60,
      dataIndex: "id",
      render: (item, record, index) => {
        return (
          <>
            <div className="main-row-actions-items">
              <div
                className="action-click-item"
                onClick={() => {
                  addNewRowForAction1(item, index);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                </svg>
              </div>
              <div
                className="action-click-item"
                onClick={() => {
                  deleteItemForAction1(item, index);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                </svg>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  console.log("partsReplacedItems", partsReplacedItems);
  console.log("productBrands", productBrands);
  console.log("jobData", jobData);
  console.log("jobData?.bookingDateTime", jobData?.bookingDateTime);
  console.log("productBrandsproductBrands", productBrands);
  console.log("lead details 1", leadDetails);

  return (
    <CreateJobcardStyled>
      <CreateLeadManagementStyled>
        <div>
          <CustomModal
            show={showSuccessPopup}
            titleText={
              jobId
                ? "Job Card Successfully Updated."
                : "Job Card Successfully Created."
            }
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessPopup(false);
              history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessPopupUser}
            titleText={"Successfully Added Customer."}
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessPopupUser(false);
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={errorModel}
            titleText={errorText}
            deleteText="Ok"
            cancelText=""
            dynamicClassName={`error_message`}
            onDelete={() => {
              setErrorModel(false);
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessUserPopup}
            titleText={
              leadId
                ? "You have Successfully Updated Lead."
                : "You have Successfully Created Lead."
            }
            deleteText="ok"
            cancelText=""
            onCancel={() => hideModel()}
            onDelete={() => hideModelOk()}
          />

          <AddNewUser
            addUserSuccess={addUserSuccess}
            show={addNewUserPopup}
            onHide={handleCloseAddNewUSer}
            selectedEditData={selectedUserEditData}
            tempUserPhoneNumber={tempUserPhoneNumber}
          />
          <PasswordPatternLock
            handleSuccessPassLock={handleSuccessPassLock}
            show={showPasswordPatternLock}
            onHide={handleClosePassLock}
          />
          {isPageLoading && <Loader />}
          <div className="focusnext-boby-section">
            <div className="">
              {jobId && (
                <div className="add-back-arrow">
                  <span onClick={handleClickBack}>{`< Back`}</span>
                </div>
              )}
              <div className="section-container-header">
                <h3> {"Create Lead"} </h3>
                {/* <span>
                <a href="/jobcardsummary">Job Card Summary List</a>
              </span>
              <span onClick={handleAddNewUSer} className="add-new-user-sec">
                Add New Customer
              </span> */}
              </div>
            </div>
            <div className="focusnext-boby-content create-edit-lead-sec">
              <div className="col datatable1">
                <div>
                  <div>
                    <h3>Product Details</h3>
                  </div>
                  <div className="row ProductDetails1">
                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Mobile No <span className="manditary">*</span>
                        </Form.Label>
                        <div className="extra-fields-content">
                          <Form.Control
                            maxLength={10}
                            isInvalid={requiredFields?.mobileNo}
                            value={jobData?.mobileNo}
                            type="text"
                            name="mobileNo"
                            onKeyDown={handleKeypress}
                            id="mobileNo"
                            autoComplete="off"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          {userSelectedInfo?.id && (
                            <span
                              onClick={handleEditUSer}
                              className="add_edit_icons_sec"
                            >
                              <img src={icons_edit} />{" "}
                            </span>
                          )}
                          <span
                            onClick={handleAddNewUSer}
                            className="add_edit_icons_sec"
                          >
                            <img src={icons_plus} />{" "}
                          </span>
                        </div>

                        <Form.Control.Feedback type="invalid">
                          {requiredFields?.mobileNo ? <></> : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {showDropDown1 && jobData?.mobileNo && (
                        <div className="menu-items-list col-12">
                          <ul id="results" className="list-group">
                            {isLoading && (
                              <li className="list-group-item list-group-item-action">
                                Loading...
                              </li>
                            )}
                            {searchUsersList?.length === 0 && (
                              <li
                                onClick={handleItemClick}
                                className="list-group-item list-group-item-action"
                              >
                                No results found.
                              </li>
                            )}
                            {searchUsersList?.map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    handleItemClick(item);
                                  }}
                                  className="list-group-item list-group-item-action"
                                >
                                  {item?.customerName}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-3 " ref={dropdownRef}>
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Customer Name <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          isInvalid={requiredFields?.customerName}
                          value={jobData?.customerName}
                          type="text"
                          name="customerName"
                          id="customerName"
                          disabled={true}
                          onChange={onChangeValue}
                        />
                        <Form.Control.Feedback type="invalid">
                          {requiredFields?.customerName ? <></> : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {showDropDown && jobData?.customerName && (
                        <div className="menu-items-list col-12">
                          <ul id="results" className="list-group">
                            {isLoading && (
                              <li className="list-group-item list-group-item-action">
                                Loading...
                              </li>
                            )}
                            {searchUsersList?.length === 0 && (
                              <li
                                onClick={handleItemClick}
                                className="list-group-item list-group-item-action"
                              >
                                No results found.
                              </li>
                            )}
                            {searchUsersList?.map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    handleItemClick(item);
                                  }}
                                  className="list-group-item list-group-item-action"
                                >
                                  {item?.customerName}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-3 " ref={dropdownRef}>
                      <Form.Group>
                        <Form.Label className="requiredField">
                          City <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          isInvalid={requiredFields?.customerName}
                          value={userSelectedInfo?.city}
                          type="text"
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 " id="deliveryDateTemp">
                      <Form.Group>
                        <Form.Label>
                          Booking Date & Time
                          <span className="manditary">*</span>
                          {/* {selectedDateTime} */}
                        </Form.Label>
                        <Datetime
                          onChange={handleDateTimeB}
                          isInvalid={requiredFields?.bookingDateTime}
                          // value={`${jobData?.bookingDateTime}`}
                          // initialValue={`${jobData?.bookingDateTime}`}
                          dateFormat={"DD/MM/YYYY"}
                          isValidDate={disablePastDt}
                          value={moment(jobData?.bookingDateTime).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 " ref={dropdownRef}>
                      <Form.Group>
                        <Form.Label className="requiredField">
                          User Type <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          name="userType"
                          as="select"
                          value={jobData?.userType}
                          isInvalid={requiredFields?.userType}
                          onChange={onChangeValue}
                        >
                          <option value="">Select</option>
                          <option value="Student">Student</option>
                          <option value="Studio">Studio</option>
                          <option value="SOHO">SOHO</option>
                          <option value="Govt">Govt</option>
                          <option value="Office">Office</option>
                          <option value="Hospital">Hospital</option>
                          <option value="School/College">School/College</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 " ref={dropdownRef}>
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Payment Mode <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          name="paymentMode"
                          as="select"
                          value={jobData?.paymentMode}
                          isInvalid={requiredFields?.paymentMode}
                          onChange={onChangeValue}
                        >
                          {/* <option value="">Select</option>
                          <option value="Home">Home</option>
                          <option value="Home">Service</option> */}
                          <option value={""}>Select</option>
                          {paymentModeList?.map((ittmmm) => {
                            const isSlect =
                              ittmmm?.id == jobData?.sid ? true : false;

                            return (
                              <option
                                value={ittmmm?.name}
                                selected={isSlect ? true : false}
                              >
                                {ittmmm?.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-sm-3 " ref={dropdownRef}>
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Incomming Source <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          name="incommingSource"
                          as="select"
                          isInvalid={requiredFields?.incommingSource}
                          onChange={onChangeValue}
                        >
                          {/* <option value="">Select</option>
                          <option value="Home">Home</option>
                          <option value="Home">Service</option> */}
                          <option value={""}>Select</option>
                          {leadSources?.map((ittmmm) => {
                            const isSlect =
                              ittmmm == jobData?.incommingSource ? true : false;

                            return (
                              <option
                                value={ittmmm}
                                selected={isSlect ? true : false}
                              >
                                {ittmmm}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 " ref={dropdownRef}>
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Advance Amount
                          {/* <span className="manditary">*</span> */}
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          name="advanceAmount"
                          isInvalid={requiredFields?.advanceAmount}
                          value={jobData?.advanceAmount}
                          type="text"
                          onChange={onChangeValue}
                          // disabled={true}
                        />
                      </Form.Group>
                    </div>

                    {jobData?.incommingSource === "Referal" && (
                      <div className="col-sm-3 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Referal Name
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            // isInvalid={errors?.title}
                            value={jobData?.referalName}
                            type="text"
                            name="referalName"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="Requirement-Information-sec">
                <h2>Requirement Information</h2>
              </div>
              <div
                className={
                  showDropDown || showDropDown1
                    ? "is-dropdown-expanded-values"
                    : ""
                }
              >
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={[...partsReplacedItems]}
                  scroll={{ x: 1500, y: 800 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority} row_create_lead_req_information`
                  }

                  // total={50}
                />
              </div>
              {leadId && (
                <>
                  <div>
                    <div className="remarks-sec-to-sec">
                      <label htmlFor="" className="col-form-label">
                        Remarks
                        {/* <span className="manditary">*</span> */}
                      </label>

                      <textarea
                        name="remarks"
                        onChange={onChangeValue}
                        value={jobData?.remarks}
                      ></textarea>
                    </div>
                  </div>

                  <div className="lead-action-taken-items">
                    {/* <PrevListSection
                          items={jobCardDetails?.actionTakenItems}
                        /> */}

                    <div className="action-taken-all-items-sec">
                      {actionTakenItems.map((item, index) => {
                        return (
                          <div className="action-taken-items">
                            <div className="assigned-to-sec1">
                              {index === 0 && (
                                <>
                                  <label htmlFor="" className="col-form-label">
                                    Cancel/Close Reason List
                                    {/* <span className="manditary">*</span> */}
                                  </label>
                                  <PrevListSection
                                    section="actionTaken"
                                    items={
                                      leadDetails?.leadInfo?.actionTakenItems
                                    }
                                  />
                                </>
                              )}

                              <div className="action-edit-add-icons">
                                <select
                                  name="actionTaken"
                                  onChange={(e) => {
                                    onChangeValueActionTaken(e, item, index);
                                  }}
                                  required
                                >
                                  <option value="">Select</option>
                                  {actionTakenFullList?.map((jobAT) => {
                                    return (
                                      <option
                                        selected={
                                          item?.serviceTaken ===
                                          jobAT?.optionName
                                            ? true
                                            : false
                                        }
                                        value={jobAT?.optionName}
                                      >
                                        {jobAT?.optionName}
                                      </option>
                                    );
                                  })}
                                </select>
                                {index == actionTakenItems.length - 1 && (
                                  <div
                                    className="action-click-item"
                                    onClick={() => {
                                      addNewRowForAction(item, index);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-plus-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>
                                  </div>
                                )}
                                {index !== 0 && (
                                  <div
                                    className="action-click-item"
                                    onClick={() => {
                                      deleteItemForAction(item, index);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-trash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                    </svg>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              {leadId && (
                <>
                  {/* <div className="action-taken-items action-taken-items-notes">
                    <div className="assigned-to-sec1">
                      <label htmlFor="" className="col-form-label">
                        Cancel/Close Reason List
                      </label>
                      <PrevListSection items={jobCardDetails?.reasonForClose} />
                      <div className="action-edit-observations-sec">
                        <select
                          name="reasonForClose"
                          onChange={onChangeValue}
                          required
                        >
                          <option value="">Select</option>
                          {jobCardDetails?.customerNoteListOptions?.map(
                            (jobSEO) => {
                              return (
                                <option value={jobSEO?.optionName}>
                                  {jobSEO?.optionName}
                                </option>
                              );
                            }
                          )}
                        </select>
                        {(openCustomerNotesBox || true) && (
                          <textarea
                            name="reasonForClose"
                            onChange={onChangeValue}
                            value={jobData?.reasonForClose}
                          ></textarea>
                        )}
                      </div>
                    </div>
                  </div> */}
                </>
              )}
              <div className="row create-lead-action-btns">
                <div className="col-sm-3 ">
                  <label htmlFor="" className="col-form-label">
                    Priority
                    <span className="manditary">*</span>
                  </label>
                  <select name="priority" onChange={onChangeValue} required>
                    <option value="">Select</option>

                    {severityOptions.map((sName) => {
                      return (
                        <option
                          value={sName}
                          selected={sName === jobData?.priority ? true : false}
                        >
                          {sName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-3 ">
                  <label htmlFor="" className="col-form-label">
                    Assigned To
                    <span className="manditary">*</span>
                  </label>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={userSName ? userSName : "Select"}
                    isSearchable={true}
                    name="selectedUserId"
                    options={usersList}
                    onChange={(e) => {
                      onChangeValueSelectAssigned("selectedUserId", e);
                    }}
                    required
                    value={userSName}
                    placeholder={userSName ? userSName : "Select"}
                  />
                </div>
                {leadId && (
                  <div className="col-sm-3 ">
                    <label htmlFor="" className="col-form-label">
                      Select Status
                      <span className="manditary">*</span>
                    </label>
                    <select name="status" onChange={onChangeValue} required>
                      <option value="">Select</option>
                      {statusFullList.map((itemStatus) => {
                        return (
                          <option
                            value={itemStatus?.value}
                            selected={
                              itemStatus?.value === jobData?.status
                                ? true
                                : false
                            }
                          >
                            {itemStatus?.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                <div className="col-sm-3">
                  <div className="terms-sec-son-btn">
                    <button onClick={handleCreateLead}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CreateLeadManagementStyled>
    </CreateJobcardStyled>
  );
};
export default CreateLeadManagement;
