import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDetails } from "./../../redux/slices/auth/authService";
import { updateUserDetails } from "../../redux/slices/auth/authService";
import { GOOGLE_MAP_URL } from "../../config";
import Loader from "./../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { triggerMixpanelEvent } from "../../Scenes/common";

// const initVal = {
//   firstName: undefined,
//   lastName: undefined,
//   phoneNumber: undefined,
//   email: undefined,
//   companyName: undefined,
//   GSTNo: undefined,
//   officeNo: undefined,
//   officeEmail: undefined,
//   PANNo: undefined,
//   companyRegNo: undefined,
//   city: undefined,
// };
const initVal = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  contactEmail: "",
  companyName: "Focus Next ",
  GSTNo: "",
  officeNo: "",
  officeEmail: "",
  PANNo: undefined,
  companyRegNo: undefined,
  city: "",
};

const initValError = {
  storeName: undefined,
  storeEmail: undefined,
  storeMobileNo: undefined,
  address: undefined,
  contactName: undefined,
  contactNumber: undefined,
  contactEmail: undefined,
};
const initValError1 = {
  storeName: undefined,
  storeEmail: undefined,
  storeMobileNo: undefined,
  address: undefined,
  contactName: undefined,
  contactNumber: undefined,
  contactEmail: undefined,
};
const UserProfile = () => {
  const [signUpData, setSignUpData] = useState(initVal);
  const [errorData, setErrorData] = useState(initValError);
  const [isLoading, setIsLoading] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, loginStoreDetails } = useSelector(
    ({ auth }) => auth
  );
  const sDetails = loginStoreDetails;
  const [isProfileEdit, setIsProfileEdit] = useState(true);
  console.log("user", user);
  console.log("loginStoreDetails", loginStoreDetails);
  useEffect(() => {
    if (user?.storeId) {
      dispatch(getStoreDetails(user?.storeId));
    }
  }, [user?.storeId]);
  useEffect(() => {
    // const initVal = {
    //   firstName: user?.data?.firstName,
    //   lastName: user?.data?.lastName,
    //   phoneNumber: user?.data?.phoneNumber,
    //   email: user?.data?.email,
    //   companyName: user?.data?.companyName,
    //   city: user?.data?.city,
    //   GSTNo: user?.data?.GSTNo,
    //   officeNo: user?.data?.officeNo,
    //   officeEmail: user?.data?.officeEmail,
    //   PANNo: user?.data?.PANNo,
    //   companyRegNo: user?.data?.companyRegNo,
    // };
    setSignUpData({ ...loginStoreDetails });
    setErrorData(initValError);
    onLoadGoogleMap();
  }, [loginStoreDetails]);
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };
    if (name == "phoneNumber" || name == "officeNo") {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else if (name === "email" || name === "officeEmail") {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  const onLoadGoogleMap = () => {
    const script = document.createElement("script");
    script.src = GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      handleScriptLoad();
    };
  };
  const handleScriptLoad = () => {
    try {
      const google = window.google;
      if (document.getElementById("editProfileaddCreateUserAdress1")) {
        const options = {
          componentRestrictions: { country: "ind" },
        };
        const autocomplete = new google.maps.places.Autocomplete(
          document.getElementById("editProfileaddCreateUserAdress1"),
          options
        );
        autocomplete.setFields(["address_components", "formatted_address"]);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          handlePlaceSelect(place);
        });
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  const handlePlaceSelect = (addressObject) => {
    var address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let address2 = "";
    let address21 = "";
    let country = "";
    console.log("addressObject", addressObject);
    let fullAddress = addressObject?.formatted_address;
    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name}`;
          break;
        }
        case "route": {
          address2 = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          break;
        }
        case "sublocality_level_1": {
          address21 = component.long_name;
          break;
        }
      }
    }
    const AddressLine1 = address1 + " " + address2;

    const stateD = getPrevData();
    stateD["address"] = fullAddress;
    stateD["address1"] = AddressLine1;
    stateD["address2"] = address21;
    stateD["city"] = city;
    stateD["state"] = state;
    // stateD["county"] = country;
    stateD["pinCode"] = postcode;

    setSignUpData(stateD);
  };
  const getPrevData = () => {
    const initValStorePrev = {
      storeName: undefined,
      storeEmail: undefined,
      storeMobileNo: undefined,
      storeWebsite: undefined,
      storeGSTNumber: undefined,
      // productsDealingWith: undefined,
      contactName: undefined,
      personDesignation: undefined,
      contactNumber: undefined,
      email: undefined,
    };
    const prevVaaa = { ...signUpData };
    for (var key in initValStorePrev) {
      if (document.getElementById(key).value) {
        prevVaaa[key] = document.getElementById(key).value;
      }
    }

    console.log("signUpData prevVaaa", prevVaaa);
    return prevVaaa;
  };

  const saveUserDetails = async () => {
    var isValid = true;
    var errornew = { ...initValError1 };
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      const body = { _id: user?.data?._id, ...signUpData };
      setIsLoading(true);
      const resp = await dispatch(updateUserDetails(body));
      console.log("resp", resp);
      setIsLoading(false);
      if (resp?.payload?.id) {
        setShowSuccessMessage(true);
      }
    }
  };
  console.log("signUpData", signUpData);
  return (
    <>
      <div className="userProfile-wrapper">
        {isLoading && <Loader />}

        <CustomModal
          show={showSuccessMessage}
          titleText={`Profile Information Successfully Updated.`}
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessMessage(false);
            setIsProfileEdit(false);
            // history.push("/jobcardsummary");
          }}
        />
        <div className="profileinfoHeader">
          <span>Profile Information</span>

          {isProfileEdit ? (
            <span
              className="profileEdit"
              onClick={() => {
                setIsProfileEdit(false);
              }}
            >
              Cancel
            </span>
          ) : (
            <span
              className="profileEdit"
              onClick={() => {
                setIsProfileEdit(true);
              }}
            >
              Edit Profile
            </span>
          )}
        </div>
        <div className="profile-store-id">
          {" "}
          <span className="headerText">Store Id: </span>
          {user?.storeId}
        </div>
        <div className="profile-store-id1">
          {" "}
          <span className="headerText">Role: </span>
          {user?.roles[0]}
        </div>

        <div className="user-details-row">
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Store Name <span className="mand-sign-field">*</span>{" "}
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.storeName}
                disabled={!isProfileEdit}
                className={errorData?.storeName && "hasError"}
                type="text"
                name="storeName"
                id="storeName"
                onChange={handleUpdate}
              />
            </div>
          </div>
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Store Email <span className="mand-sign-field">*</span>
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.storeEmail}
                disabled={!isProfileEdit}
                className={errorData?.storeEmail && "hasError"}
                type="text"
                onChange={handleUpdate}
                name="storeEmail"
                id="storeEmail"
              />
            </div>
          </div>
        </div>
        <div className="user-details-row">
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Store Phone Number <span className="mand-sign-field">*</span>
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.storeMobileNo}
                disabled={!isProfileEdit}
                className={errorData?.storeMobileNo && "hasError"}
                type="text"
                onChange={handleUpdate}
                name="storeMobileNo"
                id="storeMobileNo"
              />
            </div>
          </div>
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">Website</span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.storeWebsite}
                disabled={!isProfileEdit}
                className={errorData?.storeWebsite && "hasError"}
                type="text"
                onChange={handleUpdate}
                name="storeWebsite"
                id="storeWebsite"
              />
            </div>
          </div>
        </div>
        {/* <div className="user-details-row">
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">GST Number</span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.storeGSTNumber}
                disabled={!isProfileEdit}
                className={errorData?.storeGSTNumber && "hasError"}
                type="text"
                name="storeGSTNumber"
                id="storeGSTNumber"
                onChange={handleUpdate}
              />
            </div>
          </div>
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">Products Dealing with</span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.productsDealingWith}
                disabled={!isProfileEdit}
                className={errorData?.productsDealingWith && "hasError"}
                type="text"
                onChange={handleUpdate}
                placeholder="Laptop, Printer, Mobile ...etc"
                name="productsDealingWith"
                id="productsDealingWith"
              />
            </div>
          </div>
        </div> */}
        <div className="address-profile-edit-section">
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Address <span className="mand-sign-field">*</span>
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.address}
                id="editProfileaddCreateUserAdress1"
                disabled={!isProfileEdit}
                className={errorData?.address && "hasError"}
                type="text"
                name="address"
                onChange={handleUpdate}
              />
            </div>
          </div>
        </div>
        <div className="user-details-row">
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Contact Name <span className="mand-sign-field">*</span>
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.contactName}
                disabled={!isProfileEdit}
                className={errorData?.contactName && "hasError"}
                type="text"
                name="contactName"
                id="contactName"
                onChange={handleUpdate}
              />
            </div>
          </div>
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Contact Number <span className="mand-sign-field">*</span>
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.contactNumber}
                disabled={!isProfileEdit}
                className={errorData?.contactNumber && "hasError"}
                type="text"
                onChange={handleUpdate}
                name="contactNumber"
                id="contactNumber"
              />
            </div>
          </div>
        </div>
        <div className="user-details-row">
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">
                Email Id <span className="mand-sign-field">*</span>
              </span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.contactEmail}
                disabled={!isProfileEdit}
                className={errorData?.contactEmail && "hasError"}
                type="text"
                name="contactEmail"
                id="contactEmail"
                onChange={handleUpdate}
              />
            </div>
          </div>
          <div>
            <div className="profileHeaderSec">
              <span className="headerText">Person Designation</span>
            </div>
            <div className="namesSection">
              <input
                value={signUpData?.personDesignation}
                disabled={!isProfileEdit}
                className={errorData?.personDesignation && "hasError"}
                type="text"
                onChange={handleUpdate}
                name="personDesignation"
                id="personDesignation"
              />
            </div>
          </div>
        </div>

        <div className="user-details-row user-details-row-save">
          {isProfileEdit && (
            <div className="profileEditSave">
              <Button onClick={saveUserDetails}>Save</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
