import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Profile_pp from "../../img/Profile_pp.png";
import UserProfile from "./UserProfile";
import { triggerMixpanelEvent } from "../../Scenes/common";
const MyProfile = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const [currentStep, setCurrentStep] = useState("profile");

  useEffect(() => {
    triggerMixpanelEvent("my_profile_page");
  }, []);

  const onClickInfo = (val) => {
    setCurrentStep(val);
  };
  const gotoPage = (url) => {
    history.push(url);
  };
  return (
    <>
      <div className="myProfile-wrapper">
        <div className="myProfile-text">
          <div className="myProfile-container">
            {/* <div className="profile-left-container">
              <div className="profile-top">
                <div className="profile-image">
                  <img
                    className="imageIcon"
                    height="50px"
                    width="50px"
                    src={Profile_pp}
                  />
                  <div className="profile-name">
                    <p className="profile-hello">Hello,</p>
                    <p className="profile-username">{`${user?.data?.firstName} ${user?.data?.lastName}`}</p>
                  </div>
                </div>
              </div>

              <div className="profile-menu">
                <div className="orderSection ordersectionpage">
                  <div className="ordersecimage">
                    <img src={orderIcon} />
                  </div>
                  <div
                    className="orderSection-row"
                    onClick={() => {
                      gotoPage("/orderHistory");
                    }}
                  >
                    <span>My Orders</span>
                    <span>
                      <svg
                        width="16"
                        height="27"
                        viewBox="0 0 16 27"
                        xmlns="http://www.w3.org/2000/svg"
                        className="HQ6J2h"
                      >
                        <path
                          d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z"
                          fill="#878787"
                          className=""
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="orderSection ">
                  <div className="ordersecimage">
                    <img src={userImage} />
                  </div>
                  <div className="orderSection-row">
                    <span>ACCOUNT SETTINGS</span>
                    <span></span>
                  </div>
                </div>
                <div className="list-menu-items">
                  <div
                    className={
                      currentStep == "profile"
                        ? "list-menu-item selected"
                        : "list-menu-item"
                    }
                    onClick={() => {
                      onClickInfo("profile");
                    }}
                  >
                    Profile Information 1
                  </div>
                  <div
                    className={
                      currentStep == "address"
                        ? "list-menu-item selected"
                        : "list-menu-item"
                    }
                    onClick={() => {
                      onClickInfo("address");
                    }}
                  >
                    Manage Addresses
                  </div>
                  <div
                    className={
                      currentStep == "pancard"
                        ? "list-menu-item selected"
                        : "list-menu-item"
                    }
                    onClick={() => {
                      onClickInfo("pancard");
                    }}
                  >
                    Business Information
                  </div>
                </div>

                <div className="orderSection logoutsectionpage">
                  <div className="ordersecimage">
                    <svg
                      width="24"
                      height="24"
                      className=""
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#2874F0"
                        strokeWidth="0.3"
                        stroke="#2874F0"
                        d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"
                      ></path>
                    </svg>
                  </div>
                  <div className="orderSection-row">
                    <span>Log out</span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="profile-right-container">
              <div className="profile-content">
                <UserProfile />
                {/* {currentStep === "profile" ? <>
                  <UserProfile />
                </>
                  : currentStep === "address" ?
                    <ManageAddresses />
                    : <PanCardInformation />
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
