import { CreateJobcardStyled } from "./CreateJobcard.styled";
import React, { useState, useEffect, Component, useRef } from "react";
import CustomModal from "../../components/CustomModel";
import ImageViewModel from "../../components/ImageViewModel";

import { useHistory } from "react-router";
import AddNewUser from "./AddNewUser";
import camera_line from "./../../img/cameera_icon.png";

import PasswordPatternLock from "./PasswordPatternLock";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import {
  getAllCustomersList,
  deleteCustomerApi,
} from "../../redux/slices/auth/authService";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobCardApi,
  getProductsListApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  getJobCardOptionsBasedOnProductId,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import { getCustomerSearch } from "./../../redux/slices/auth/authService";
import moment from "moment";
import { statusList, severityOptions } from "./../../Scenes/constants";
import PrevListSection from "./PrevListSection";
import arrow_down from "./../../img/arrow-205.png";
import password_pattern from "./../../img/password-pattern.png";
import icons_plus from "./../../img/icons8-plus-50.png";
import icons_edit from "./../../img/icons8-edit-32.png";
import ImageUploadModel from "./../PrintPageSetting/ImageUploadModel/ImageUploadModel";

import { triggerMixpanelEvent } from "../../Scenes/common";
const format = "h:mm a";
const currentDate = new Date();

const currentHour = currentDate.getHours();
const currentMinute = currentDate.getMinutes();

var timeNow = moment().hour(currentHour).minute(currentMinute);
// moment(new Date()).format("h:mm a");
const defaultAction = { id: 1, serviceTaken: "" };
const partsReplaced = { id: 1, partName: "", partSerialno: "" };

const requiredFieldsList = {
  customerName: false,
  mobileNo: false,
  productName: false,
  brandName: false,
  serialNo1: false,
  deliveryDateTemp: false,
  deliveryDateTempTime: false,
  minCharges: false,
  paymentType: false,
  incommingSource: false,

  itemRecived: false,
  defaultIssues: false,
  customerAddRequirements: false,
  selectedUserId: false,

  // systemPassword: false,
  // severity: true,
  // customerAdditionalReqRemarks: true,
};
const requiredFieldsList1 = {
  customerName: false,
  mobileNo: false,
  productName: false,
  brandName: false,
  serialNo1: false,
  deliveryDateTemp: false,
  deliveryDateTempTime: false,
  minCharges: false,
  paymentType: false,
  incommingSource: false,

  itemRecived: false,
  defaultIssues: false,
  customerAddRequirements: false,
  selectedUserId: false,
  // receiverPersonSame: false,
  // originalJobCardReceived: false,
  // systemPassword: false,
  // severity: true,
  // customerAdditionalReqRemarks: true,
};
const editRequiredFields1 = {
  toCourierName: false,
  tempToCourierDate: false,
  toDocNumber: false,
  toCompanyName: false,
};

const editRequiredFields3 = {
  receiveingCourierName: false,
  tempReceiveingToCourierDate: false,
  receiveingDocNumber: false,
};

const CreateJobcard = (props) => {
  const [jobData, setJobData] = useState({
    minCharges: "150",
    priority: "",
  });
  // const [requiredData, setRequiredData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [itemsRecievedList, setItemsRecievedList] = useState([]);
  const [defaultIssues, setDefaultIssues] = useState([]);
  const [cARequirements, setCARequirements] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessPopupUser, setShowSuccessPopupUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [actionTakenList, setActionTakenList] = useState([]);
  const [engineerObservationList, setEngineerObservationList] = useState([]);
  const [addNewUserPopup, setAddNewUserPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState(requiredFieldsList);
  const [requiredFields1, setRequiredFields1] = useState(requiredFieldsList1);
  const [selectedUserEditData, setSelectedUserEditData] = useState({});
  const [tempUserPhoneNumber, setTempUserPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(
    new Date().toLocaleString()
  );
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showImageMoodel, setShowImageMoodel] = useState(false);
  const [showImageViewMoodel, setShowImageViewMoodel] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [fimgName, setFimgName] = useState("");

  const [editRequiredFields, setEditRequiredFields] =
    useState(editRequiredFields1);
  const [editRequiredFields2, setEditRequiredFields2] =
    useState(editRequiredFields3);
  const [showItemReceived, setShowItemReceived] = useState(true);
  const [showDefaultIssues, setShowDefaultIssues] = useState(true);
  const [showCustomerAR, setShowCustomerAR] = useState(true);

  const [showItemReceivedRemarks, setShowItemReceivedRemarks] = useState(false);
  const [showDefaultIssuesRemarks, setShowDefaultIssuesRemarks] =
    useState(false);
  const [showCustomerARRemarks, setShowCustomerARRemarks] = useState(false);
  const [showPasswordPatternLock, setShowPasswordPatternLock] = useState(false);

  const [conformClosingModel, setConformClosingModel] = useState(false);
  const [conformClosingModelText, setConformClosingModelText] = useState("");

  const [errorText, setErrorText] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [openEngineerObservationsBox, setOpenEngineerObservationsBox] =
    useState(false);
  const [openCustomerNotesBox, setOpenCustomerNotesBox] = useState(false);
  const history = useHistory();
  const dropdownRef = useRef();

  // const [itemsRecievedListNoItems, setItemsRecievedListNoItems] = useState(
  //   false
  // );
  // const [defaultIssuesNoItems, setDefaultIssuesNoItems] = useState(false);
  // const [cARequirementsNoItems, setCARequirementsNoItems] = useState(false);

  const [updateData, setUpdateData] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [userSelectedInfo, setUserSelectedInfo] = useState({});
  const [imagesList, setImagesList] = useState([]);
  const [actionTakenItems, setActionTakenItems] = useState([
    { ...defaultAction },
  ]);
  const [partsReplacedItems, setPartsReplacedItems] = useState([
    { ...partsReplaced },
  ]);
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
    getJobCardOptionsBasedOnProductIdResp,
  } = useSelector((state) => state.serviceManagement);
  const { searchUsersList, user, allCustomersList } = useSelector(
    (state) => state.auth
  );
  // console.log("itemsRecievedByProductIdResp", itemsRecievedByProductIdResp);
  // console.log("defaultIssuesByProductIdResp", defaultIssuesByProductIdResp);
  // console.log("customerAdditionalReqResp", customerAdditionalReqResp);
  const jobId = props?.match?.params?.jobId;
  const dispatch = useDispatch();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
        setShowDropDown1(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // console.log("jobIdjobId", jobId);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDisplayJobCardFormApi(null));
    dispatch(getAllCustomersList());
    if (jobId) {
      triggerMixpanelEvent("edit_job_card_page", { jobId });
      setShowItemReceived(false);
      setShowDefaultIssues(false);
      setShowCustomerAR(false);
      dispatch(getJobCardDetailsApi(jobId));
      // var timeNow = moment().hour(currentHour).minute(currentMinute);
    } else {
      triggerMixpanelEvent("create_job_card_page");
      // const newTime = moment(timeNow).format("H:mm");
      // const newDDate = moment(timeNow).format("YYYY-MM-DD");
      const newTime = moment(timeNow).format("h:mm a");
      const newDDate = moment(timeNow).format("DD/MM/YYYY");

      setTimeout(() => {
        setJobData({
          ...jobData,
          deliveryDateTempTime: newTime,
          deliveryDateTemp: newDDate,
        });
      }, 100);
      // setJobData({});
    }
  }, []);
  useEffect(async () => {
    const jobCData = jobCardDetails?.jobCard;
    if (jobCData?.id) {
      const dateD = jobCardDetails?.date?.split("-");
      const dateDFull = dateD[2] + "/" + dateD[1] + "/" + dateD[0];
      if (jobCData?.closingDateTemp) {
      }
      const todayTime1 = moment(new Date()).format("DD/MM/YYYY");
      const todayTime2 = moment(new Date()).format("h:mm a");

      const todayTime3 = moment(new Date()).format("DD/MM/YYYY");
      const todayTime4 = moment(new Date()).format("h:mm a");
      var deliverytime1 = todayTime4;
      if (jobCData?.deliveryDateTempTime) {
        if (jobCData?.deliveryDateTempTime?.charAt(0) === "0") {
          deliverytime1 = jobCData?.deliveryDateTempTime.slice(1);
        } else {
          deliverytime1 = jobCData?.deliveryDateTempTime;
        }
      }

      setJobData({
        ...jobCData,
        paymentType: jobCData?.paymentType || jobCData?.disWarrantyService,
        selectedBrandId: jobCData?.brand?.id,
        brandName: jobCData?.brand?.name,
        selectedProductId: jobCData?.product?.id,
        productName: jobCData?.product?.name,
        deliveryDateTemp: jobCData?.deliveryDateTemp ?? todayTime3,
        deliveryDateTempTime: deliverytime1,
        selectedUserId: jobCData?.assignedTo?.id,
        originalJobCardReceived: jobCardDetails?.originalJobCardReceived,
        receiverPersonSame: jobCardDetails?.receiverPersonSame,
        priority: jobCardDetails?.priority,
        closingDateTemp: jobCData?.closingDateTemp ?? todayTime1,
        closingDateTempTime:
          jobCData?.closingDateTempTime?.toLowerCase() ?? todayTime2,
        // customerNotes: jobCardDetails?.customerNotes?.[0]?.note,
      });
      setItemsRecievedList(jobCardDetails?.itemRecivedOptions);
      setDefaultIssues(jobCardDetails?.defaultIssuesOptions);
      setCARequirements(jobCardDetails?.additionalRequirementsOptions);

      setActionTakenList(jobCardDetails?.actionTakenOptions);
      setEngineerObservationList(jobCardDetails?.engineerObservationOptions);
      const newObj = {
        id: jobCData?.selectedCustomerId,
        mobileNo: jobCData?.mobileNo,
        customerName: jobCData?.customerName,
      };
      setUserSelectedInfo(newObj);
      // console.log("jobCardDetails?.mobileNo", jobCData?.mobileNo);
      // console.log("newObjnewObj", newObj);
      getDropDownValues(jobCData?.mobileNo);
      if (jobCData?.imageUrls) {
        setImagesList(jobCData?.imageUrls?.split(","));
      }

      const newList = [];
      jobCardDetails?.brands?.map((item) => {
        newList.push({
          ...item,
          value: item?.brandName,
          label: item?.brandName,
        });
      });
      setBrandsList(newList);

      var newList12 = requiredFields1;

      if (jobCardDetails?.originalJobCardReceived === "No") {
        newList12.originalNotJobCardReason = false;
      } else {
        delete newList12.originalNotJobCardReason;
      }
      if (jobCardDetails?.receiverPersonSame === "No") {
        newList12.recevierName = false;
        newList12.recevierMobileNo = false;
      } else {
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
      }
      // setRequiredFields(newList12);
      setRequiredFields1(newList12);
    }
  }, [jobCardDetails]);
  useEffect(() => {
    // const newList = [];
    // displayJobCardDetails?.brands?.map((item) => {
    //   newList.push({ ...item, value: item?.name, label: item?.name });
    // });
    // setBrandsList(newList);
    const newListProducts = [];

    displayJobCardDetails?.products?.map((item) => {
      newListProducts.push({ ...item, value: item?.id, label: item?.name });
    });
    setProductsList(newListProducts);
    const usersList1 = [];
    displayJobCardDetails?.users?.map((user) => {
      const name =
        user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
      usersList1.push({ id: user?.id, value: user?.id, label: name });
    });
    setUsersList(usersList1);
    // setItemsRecievedList(displayJobCardDetails?.itemRecivedOptions);
    // setDefaultIssues(displayJobCardDetails?.defaultIssuesOptions);
    // setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
  }, [displayJobCardDetails]);

  useEffect(() => {
    const newList = [];
    var selecbID = null;
    var selecbName = null;
    brandsByProductIdResp?.brands?.map((item) => {
      newList.push({ ...item, value: item?.name, label: item?.name });
      if (item?.selected === "selected") {
        selecbID = item?.id;
        selecbName = item?.name;
      }
    });
    setBrandsList(newList);
    // setItemsRecievedList(itemsRecievedByProductIdResp);

    const prevValues = { ...jobData };
    if (selecbID) {
      prevValues["selectedBrandId"] = selecbID;
      prevValues["brandName"] = selecbName;
    }
    setJobData(prevValues);
  }, [brandsByProductIdResp]);

  useEffect(() => {
    setActionTakenList(
      getJobCardOptionsBasedOnProductIdResp?.actionTakenOptions
    );
    setEngineerObservationList(
      getJobCardOptionsBasedOnProductIdResp?.engineerObservationOptions
    );
  }, [getJobCardOptionsBasedOnProductIdResp]);
  useEffect(() => {
    setItemsRecievedList(itemsRecievedByProductIdResp);
  }, [itemsRecievedByProductIdResp]);
  useEffect(() => {
    setDefaultIssues(defaultIssuesByProductIdResp);
  }, [defaultIssuesByProductIdResp]);

  useEffect(() => {
    setCARequirements(customerAdditionalReqResp);
  }, [customerAdditionalReqResp]);
  useEffect(() => {}, [jobData?.status]);
  const isDisabledBtn = () => {
    // console.log(
    //   "jobCardDetails?.jobCard?.status",
    //   jobCardDetails?.jobCard?.status
    // );
    let returnF = false;
    if (jobId) {
      if (
        jobCardDetails?.jobCard?.status === "Closed" ||
        jobCardDetails?.jobCard?.subStatus === "Closed" ||
        jobCardDetails?.jobCard?.status === "Closing Approval" ||
        jobCardDetails?.jobCard?.subStatus === "Closing Approval"
      ) {
        returnF = true;
      }
      if (user?.roles[0] === "ROLE_STORE_OWNER") {
        returnF = false;
      }
    }
    return returnF;
  };
  // console.log("jobCardDetails", jobCardDetails);
  // // useEffect(() => {
  // console.log("itemsRecievedByProductIdResp", itemsRecievedByProductIdResp);
  // console.log("ItemsRecievedList", itemsRecievedList);
  const searchOnChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "items-recieved") {
      setShowItemReceived(true);
      if (value === "") {
        setItemsRecievedList(itemsRecievedByProductIdResp);
      } else {
        const newItems = [];
        itemsRecievedByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setItemsRecievedList(newItems);
      }
    } else if (name === "default-issues") {
      setShowDefaultIssues(true);
      if (value === "") {
        setDefaultIssues(defaultIssuesByProductIdResp);
      } else {
        const newItems = [];
        defaultIssuesByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setDefaultIssues(newItems);
      }
    } else if (name === "customer-additional") {
      setShowCustomerAR(true);
      if (value === "") {
        setCARequirements(customerAdditionalReqResp);
      } else {
        const newItems = [];
        customerAdditionalReqResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setCARequirements(newItems);
      }
    }
  };
  // }, [searchValue]);

  const onChangeValue = async (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    // console.log("namename", name);
    // console.log("valuevalue", value);
    // console.log("namename", name);
    const prevValues = { ...jobData };
    if (
      name === "minCharges" ||
      name === "printPageCount" ||
      name === "printBlack" ||
      name === "printColor"
    ) {
      var value1 = value;
      if (value.trim() == "") {
        value1 = value.trim();
      }
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
        setJobData(prevValues);
      }
    } else {
      prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
      setJobData(prevValues);
    }

    const newList = requiredFields;
    newList[name] = false;
    await setRequiredFields(newList);

    const newList1 = editRequiredFields;
    newList1[name] = false;
    setEditRequiredFields(newList1);

    const newList22 = editRequiredFields2;
    newList22[name] = false;
    setEditRequiredFields2(newList22);

    if (name === "customerName") {
      getDropDownValues(value);
      setShowDropDown(true);
    } else if (name === "mobileNo") {
      getDropDownValues(value);
      setShowDropDown1(true);
    }
    if (name === "engineerObservationOption") {
      setOpenEngineerObservationsBox(value ? true : false);
    } else if (name === "customerNotes") {
      setOpenCustomerNotesBox(value ? true : false);
    }
    updateRecevierPersonList(name, value);
  };
  const updateRecevierPersonList = async (name, value) => {
    if (name === "receiverPersonSame") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        const fiallIst = {
          ...requiredFields1,
          recevierName: false,
          recevierMobileNo: false,
        };
        // await setRequiredFields(fiallIst);
        await setRequiredFields1(fiallIst);
      }
    }
    if (name === "originalJobCardReceived") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.originalNotJobCardReason;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        // await setRequiredFields({
        //   ...requiredFields1,
        //   originalNotJobCardReason: false,
        // });
        await setRequiredFields1({
          ...requiredFields1,
          originalNotJobCardReason: false,
        });
      }
    }
  };
  const onChangeValueTime = (e) => {
    const newTime = moment(e?._d).format("H:mm");
    setJobData({ ...jobData, deliveryDateTempTime: newTime });
  };
  const handleDateTime = (date) => {
    // console.log("date", date);
    setSelectedDateTime(new Date(date).toLocaleString());
    const prevValues = { ...jobData };
    prevValues["deliveryDateTemp"] = moment(new Date(date)).format(
      "DD/MM/YYYY"
    );
    prevValues["deliveryDateTempTime"] = moment(new Date(date)).format(
      "h:mm a"
    );

    setJobData(prevValues);

    // deliveryDateTemp
    // :
    // "2023-10-08"
    // deliveryDateTempTime
    // :
    // "14:08"
  };
  const handleClosingDateTime = (date) => {
    // console.log("date", date);
    const prevValues = { ...jobData };
    prevValues["closingDateTemp"] = moment(new Date(date)).format("DD/MM/YYYY");
    prevValues["closingDateTempTime"] = moment(new Date(date)).format("h:mm a");
    console.log("prevValues", prevValues);
    setJobData(prevValues);

    // deliveryDateTemp
    // :
    // "2023-10-08"
    // deliveryDateTempTime
    // :
    // "14:08"
  };

  // console.log("SelectedDateTime(", selectedDateTime);
  const disablePastDt = (current) => {
    let days_date = 1;
    if (user?.roles[0] === "ROLE_STORE_OWNER") {
      days_date = 365;
    }
    const yesterday = moment().subtract(days_date, "day");
    return current.isAfter(yesterday);
  };

  const onChangeValueSelect = (name1, e) => {
    const PId = e?.id;
    // const { name, value } = e.target;
    const pValue = e.label;
    const prevValues = { ...jobData };
    prevValues[name1] = e.label;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);
    if (name1 === "productName") {
      prevValues["selectedProductId"] = PId;
      prevValues["itemRecived"] = "";
      prevValues["defaultIssues"] = "";
      prevValues["customerAddRequirements"] = "";

      if (e.value === "") {
        prevValues["brandName"] = "";
      } else {
        const jsonBody = { PId, jobId: jobId ? jobId : 0 };
        dispatch(getDisplayJobCardFormApi(PId));
        dispatch(getBrandsByProductIdApi(jsonBody));
        dispatch(getItemsRecievedByProductIdAPi(jsonBody));
        dispatch(getDefaultIssuesByProductIdAPi(jsonBody));
        dispatch(getCustomerAdditionalReqAPi(jsonBody));
        dispatch(getJobCardOptionsBasedOnProductId(jsonBody));
      }
      if (
        pValue === "Desktop" ||
        pValue === "Laptop" ||
        pValue === "Mobile" ||
        pValue === "TAB" ||
        pValue === "DVR"
      ) {
        // setRequiredFields({ ...requiredFields, systemPassword: false });
        setRequiredFields1({ ...requiredFields1, systemPassword: false });
      } else if (pValue === "Printer" && jobId) {
        setRequiredFields1({ ...requiredFields1, printPageCount: false });
      } else {
        // var newList1 = requiredFields;
        // delete newList1.systemPassword;
        // setRequiredFields(newList1);
        var newList12 = requiredFields1;
        delete newList12.systemPassword;
        delete newList12.printPageCount;
        setRequiredFields1(newList12);
      }
      setShowItemReceived(true);
      setShowDefaultIssues(true);
      setShowCustomerAR(true);
    } else {
      const braId = e?.brandId || e?.id;
      prevValues["selectedBrandId"] = braId;
    }

    setJobData(prevValues);
  };
  const onChangeValueSelectAssigned = (name1, e) => {
    // const { name, value } = e.target;
    const prevValues = { ...jobData };
    prevValues[name1] = e.id;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);

    setJobData(prevValues);
  };
  const dateFormatType = (date) => {
    var dtToday = new Date();
    if (date != undefined && date != "") {
      dtToday = new Date(date);
    }
    var month = "";
    var day = "";
    month = dtToday.getMonth() + 1;
    day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    if (user?.roles[0] === "ROLE_STORE_OWNER") {
      year = year - 1;
    }

    var DateNow = year + "-" + month + "-" + day;
    return DateNow;
  };
  const lowerName = jobData?.productName?.toLowerCase();
  const showExtraFields =
    lowerName === "desktop" ||
    lowerName === "laptop" ||
    lowerName === "mobile" ||
    lowerName === "tab" ||
    lowerName === "dvr"
      ? true
      : false;
  const showExtraSerialFields =
    lowerName === "laptop" ||
    lowerName === "mobile" ||
    lowerName === "tab" ||
    lowerName === "dvr"
      ? true
      : false;
  // (jobData?.status === "" ||
  // jobData?.status === "Repair Closed" ||
  // jobData?.status === "Closed" ||
  // jobData?.status === "Ready For Delivery")

  const showPrinterFields = jobData?.productName === "Printer" ? true : false;
  const noItems = [1, 2, 3, 4, 5, 66, 7, 7, 8, 8];
  // $("#items-recieved-search-box").on("keyup", function () {
  //   var query = this.value?.toLowerCase();
  //   $("#loadItemsRecived_dev > div").each(function (i, elem) {
  //     var currentV = $(this).find("input")?.val()?.toLowerCase();
  //     if (currentV.indexOf(query) != -1) {
  //       $(this).removeClass("dis-none-imp");
  //     } else {
  //       $(this).addClass("dis-none-imp");
  //     }
  //   });
  // });
  const addNewRowForAction = (item, index, value1) => {
    const prevValues = actionTakenItems;
    prevValues.push({ ...defaultAction, id: index });
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const onChangeValueActionTaken = (e, item, index) => {
    const { name, value } = e.target;

    const prevValues = actionTakenItems;
    prevValues[index].serviceTaken = value;
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const showPartsReplacedSec = () => {
    let returnF = false;
    actionTakenItems?.map((itemV) => {
      // console.log("itemV", itemV);
      if (
        itemV?.serviceTaken &&
        itemV?.serviceTaken?.toLowerCase().includes("replaced")
      ) {
        returnF = true;
      }
    });
    if (jobCardDetails?.partReplaceds?.length > 0) {
      returnF = true;
    }
    return returnF;
  };
  const deleteItemForAction = (item, index) => {
    const prevValues = actionTakenItems;

    prevValues.splice(index, 1);
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const addNewRowForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;
    prevValues.push({ ...defaultAction, id: index });
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const deleteItemForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;

    prevValues.splice(index, 1);
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
  };
  const handleValidate = () => {
    setErrorMessage("");
    var firstEleId = "";
    var refurnFlag = false;
    const newList = requiredFields1;
    for (var key in newList) {
      const Pvalue = jobData[key];

      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        newList[key] = true;
        refurnFlag = true;
        if (firstEleId === "") {
          firstEleId = key;
        }
      }
    }
    setUpdateData(!updateData);
    setRequiredFields(newList);
    if (
      // jobData?.status === "Ready For Delivery" ||
      jobData?.status === "Out for Warranty"
    ) {
      const editnewList = {
        toCourierName: false,
        tempToCourierDate: false,
        toDocNumber: false,
        toCompanyName: false,
      };
      for (var key1 in editnewList) {
        const Pvalue = jobData[key1];
        if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
          editnewList[key1] = true;
          refurnFlag = true;
          if (firstEleId === "") {
            firstEleId = key1;
          }
        }
      }
      setEditRequiredFields(editnewList);
    }

    if (
      jobData?.toCourierName &&
      (jobData?.status === "Repair Closed" ||
        jobData?.status === "Ready For Delivery" ||
        jobData?.subStatus === "Repair Closed" ||
        jobData?.subStatus === "Ready For Delivery")
    ) {
      const editRequiredFields3_temp = {
        receiveingCourierName: false,
        tempReceiveingToCourierDate: false,
        receiveingDocNumber: false,
      };
      for (var key1 in editRequiredFields3_temp) {
        const Pvalue = jobData[key1];
        if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
          editRequiredFields3_temp[key1] = true;
          refurnFlag = true;
          if (firstEleId === "") {
            firstEleId = key1;
          }
        }
      }
      setEditRequiredFields2(editRequiredFields3_temp);
    }
    if (jobId) {
      if (
        jobData?.status === "Repair Closed" ||
        jobData?.subStatus === "Repair Closed"
      ) {
        if (!jobData?.receiverPersonSame) {
          refurnFlag = true;
          setErrorMessage(
            "Please select Recevier person is same as that of customer field"
          );
          if (firstEleId === "") {
            firstEleId = "receiverPersonSame";
          }
        } else if (!jobData?.originalJobCardReceived) {
          refurnFlag = true;
          setErrorMessage("Please select Original Job Sheet Recevied field");
          if (firstEleId === "") {
            firstEleId = "originalJobCardReceived";
          }
        }
      }
      // console.log("jobData", jobData);
      // receiverPersonSame: false,
      // originalJobCardReceived: false,

      // return true;
    }
    // return false;
    // return true;
    console.log("firstEleId", firstEleId);
    console.log("refurnFlag", refurnFlag);
    if (refurnFlag && firstEleId) {
      const element = document.getElementById(firstEleId);
      if (element) {
        window.scrollTo({ top: element?.offsetTop - 100, behavior: "smooth" });
        // element.scrollIntoView({ behavior: "smooth" });
      }
    }

    return refurnFlag;
  };
  const handleSubmitBtn = async () => {
    if (!handleValidate() && !isLoading) {
      if (
        jobData?.status === "Closed" ||
        jobData?.subStatus === "Closed" ||
        jobData?.subStatus === "Closing Approval"
      ) {
        setConformClosingModel(true);
        setConformClosingModelText(
          `Are you sure, you want to send Job card status to <span>${jobData?.subStatus}</span>. Job card Id: <span>${jobData?.id}</span> , Job card Name: <span>${jobData?.customerName}</span>`
        );
      } else {
        handleSubmitBtnConform();
      }
    }
  };
  const handleSubmitBtnConform = async () => {
    // jobData?.customerAddRequirements.map()
    console.log("jobData", jobData);
    console.log("jobData?.status", jobData?.status);
    console.log("jobData?.status", jobData?.subStatus);
    // return false;
    const finalJson = {
      ...jobData,
      // disWarrantyService: "No",
      // selectedUserId: 1,
      // minCharges: 200,
      // warrantyService: 0,
      // serialNo1: "uuuuu",
      // serialNo2: "",
      // serialNo3: "",
      // systemPassword: "sys",
    };
    if (jobId) {
      triggerMixpanelEvent("edit_save_job_card_click");
      finalJson.partReplaceds = partsReplacedItems;
      const finalV = [];
      actionTakenItems?.map((itmm) => {
        if (itmm?.serviceTaken) finalV.push(itmm?.serviceTaken);
      });
      finalJson.actionTaken = finalV;

      // console.log("jobDatajobData", jobData);
      if (
        (jobData?.status === "Closed" ||
          jobData?.subStatus === "Closed" ||
          jobData?.subStatus === "Closing Approval") &&
        finalV?.length === 0
      ) {
        setErrorText("Please selct at least one Action Taken.");
        setErrorModel(true);
        return false;
      } else if (
        (jobData?.status === "Return Without Repair" ||
          jobData?.status === "Intentionally Left Out" ||
          jobData?.status === "Buy Back" ||
          jobData?.status === "Cancel" ||
          jobData?.status === "Completed") &&
        !jobData?.subStatus
      ) {
        setErrorText(
          `You have selected current status as <span>${jobData?.status}</span>, So Please selct the Closing Status also.`
        );
        setErrorModel(true);
        return false;
      }
      // console.log("actionTakenItems", actionTakenItems);
    } else {
      triggerMixpanelEvent("create_job_card_click");
    }

    finalJson.imageUrls = imagesList.join(",");
    if (userSelectedInfo?.id) {
      finalJson.selectedCustomerId = userSelectedInfo?.id;
    }
    setIsLoading(true);

    const resp = await dispatch(createJobCardApi(finalJson));
    setIsLoading(false);
    if (resp?.payload?.id) {
      if (jobId) {
        triggerMixpanelEvent("edit_job_card_success");
      } else {
        triggerMixpanelEvent("create_job_card_success");
      }
      setShowSuccessPopup(true);
      window.scrollTo(0, 0);
      setJobData({});
    } else if (resp?.error?.message) {
      if (jobId) {
        triggerMixpanelEvent("edit_job_card_error", {
          message: resp?.error?.message,
        });
      } else {
        triggerMixpanelEvent("create_job_card_error", {
          message: resp?.error?.message,
        });
      }
      setErrorText(resp?.error?.message);
      setErrorModel(true);
    }
  };
  const onChnageValueList = (Jtype, item, e, newList = "") => {
    const newList1 = requiredFields;
    newList1[Jtype] = false;
    setRequiredFields(newList1);

    const isChecked = e.target.checked;
    const prevValues = { ...jobData };
    if (isChecked) {
      var newList2 = "";
      if (newList === "") {
        newList2 = item?.optionName;
      } else {
        newList2 = newList + "," + item?.optionName;
      }
      newList = newList2;
      // newList.push(item);
      if (
        item?.optionName === "No Items Received" ||
        item?.optionName === "No Additional Required" ||
        item?.optionName === "NO ADDITIONAL REQUIRED"
      ) {
        newList = item?.optionName;
      }
    } else {
      const newList1 = newList.split(",");
      const idx = newList1.findIndex((u) => u === item?.optionName);
      if (idx !== -1) {
        newList1.splice(idx, 1);
        newList = newList1.join(",");
      }
    }

    if (Jtype === "itemRecived") {
      prevValues.itemRecived = newList;
    } else if (Jtype === "defaultIssues") {
      prevValues.defaultIssues = newList;
    } else if (Jtype === "customerAddRequirements") {
      prevValues.customerAddRequirements = newList;
    }
    // var newList = prevValues?.itemRecived || [];

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // prevValues["itemRecived"] = value;
    setJobData(prevValues);
  };
  const checkItemByList = (list, item) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex((u) => u === item?.optionName);
      if (idx === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const isNoitemSelectedCheck = (list) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex(
        (u) =>
          u === "No Items Received" ||
          u === "No Additional Required" ||
          u === "NO ADDITIONAL REQUIRED"
      );
      if (idx === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const handlePartChange = (value, key, index) => {
    const newValues = partsReplacedItems;
    newValues[index][key] = value;
    setPartsReplacedItems(newValues);
  };
  const handleAddNewUSer = () => {
    setSelectedUserEditData({});
    setTempUserPhoneNumber(jobData?.mobileNo);
    triggerMixpanelEvent("add_new_customer_click");
    setAddNewUserPopup(true);
  };
  const handleEditUSer = () => {
    if (userSelectedInfo?.id) {
      const found = allCustomersList.find(
        (element) => element?.id === userSelectedInfo?.id
      );
      // console.log("found", found);
      setSelectedUserEditData(found);

      triggerMixpanelEvent("edit_customer_click");
      setAddNewUserPopup(true);
    }
  };
  const addUserSuccess = (select) => {
    triggerMixpanelEvent("add_new_customer_success", select);
    handleCloseAddNewUSer();
    dispatch(getAllCustomersList());
    setShowSuccessPopupUser(true);
    setUserSelectedInfo(select);
    const prevValues = { ...jobData };
    prevValues["customerName"] = select?.customerName;
    prevValues["mobileNo"] = select?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);
  };
  const handleCloseAddNewUSer = () => {
    setAddNewUserPopup(false);
  };

  const handleClickBack = () => {
    history.push("/jobcardsummary");
  };
  const getDropDownValues = (val) => {
    if (val) {
      dispatch(getCustomerSearch(val));
    }
  };
  // const onChangeValuesManufacturer = (e) => {
  //   setBrandValue(e.target.value);
  //   // setTimeout(() => {
  //   const regJson = {
  //     Page: 1,
  //     PageSize: 25,
  //     Brand: e?.target?.value,
  //   };
  //   dispatch(getBrandsListApi(regJson));
  //   setFieldValue("brand", "");
  //   setFieldValue("manufacture", "");
  //   setFieldValue("manufacturerCode", "");
  //   setFieldValue("brandCode", "");
  //   if (e?.target?.value === "") {
  //     setShowDropDown(false);
  //     setFieldValue("manufacturer", "");
  //     setFieldValue("manufacturerCode", "");
  //   } else {
  //     setShowDropDown(true);
  //   }
  //   // }, 300);
  // };
  const handleManuClick = () => {
    setShowDropDown(true);
  };
  const handleItemClick = async (item) => {
    setShowDropDown(false);
    setShowDropDown1(false);
    setUserSelectedInfo(item);
    const prevValues = { ...jobData };
    prevValues["customerName"] = item?.customerName;
    prevValues["mobileNo"] = item?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);

    if (item?.value) {
      // await setBrandValue(item?.label);
      // const regJson = {
      //   brandCode: item?.value,
      // };
      // setFieldValue("brand", item?.label);
      // setFieldValue("brandCode", item?.value);
      // setFieldValue("manufacturer", item?.brandOwner);
      // setFieldValue("manufacturerCode", item?.brandOwnerCode);
      // setFieldValue("brandOemflag", item?.brandOemflag);
      // dispatch(getManfacturerApi(regJson));
    }
  };
  const handleClosePassLock = () => {
    setShowPasswordPatternLock(false);
  };
  const handleSuccessPassLock = (passData) => {
    handleClosePassLock();
    if (passData) {
      const prevValues = { ...jobData };
      prevValues["systemPassword"] = passData;
      setJobData(prevValues);
    }
  };

  const handleKeypress = (e) => {
    if (e?.keyCode === 13) {
      if (searchUsersList?.length === 0) {
        setShowDropDown1(false);
        setSelectedUserEditData({});
        setTempUserPhoneNumber(e?.target?.value);
        handleAddNewUSer();
      } else {
        handleItemClick(searchUsersList[0]);
      }
    }
  };
  const handleImageClick = () => {
    // setTempImageData(obj);
    // setUploadImageIndex(indexww);
    setShowImageMoodel(true);
  };
  const handleImageView = (imgg) => {
    setShowImageViewMoodel(true);
    setSelectedImageUrl(imgg);
  };
  const handleCloseImage = () => {
    setShowImageMoodel(false);
  };
  const handleImageUploadSuccess = (imgName) => {
    console.log("imgName", imgName);
    handleCloseImage();
    const pplistItems = [...imagesList];
    const fimgName1 = `https://rasko-images.s3.ap-south-1.amazonaws.com/${imgName}`;
    pplistItems.push(fimgName1);
    setImagesList(pplistItems);
  };
  const handleDeleteImage = (index1) => {
    const pplistItems = [...imagesList];
    pplistItems.splice(index1, 1);
    setImagesList(pplistItems);
  };

  const getDiffDays = (fromDate) => {
    const toDate = moment().format("YYYY-MM-DD");
    var a = moment(fromDate, "YYYY-MM-DD");
    var b = moment(toDate, "YYYY-MM-DD");
    const returnVal = b.diff(a, "days"); // 1
    return returnVal;
  };
  const userSName = usersList?.find(
    (item1) => item1?.id === jobData?.selectedUserId
  )?.label;
  // let currencyName = this.props?.currenciesList.find(
  //   (i: any) => collectionData?.currency == i?.id
  // )?.currencyCode;
  console.log("jobDatajobData", jobData);
  console.log("requiredFields", requiredFields);
  console.log("requiredFields1222", requiredFields1);
  // console.log("brandsList", brandsList);
  // const statusList1 = [{ id: 0, value: "", label: "All" }];
  // const statusList3 = [{ id: 5, value: "Closed", label: "Closed" }];
  const statusList3 = [];
  let statusList4 = [
    // { id: 5, value: "Closing Approval", label: "Closing Approval" },
    { id: 25, value: "Re Open", label: "Re Open" },
  ];
  if (jobData?.status === "Closed") {
    // statusList4.push({ id: 5, value: "Closed", label: "Closed" });
  }
  const statusList9 =
    jobData?.age > 10
      ? [{ id: 5, value: "Closing Approval", label: "Closing Approval" }]
      : [{ id: 5, value: "Closed", label: "Closed" }];

  // console.log()
  // age;
  const statusList2 =
    jobData?.age > 10
      ? [...statusList, ...statusList4]
      : [...statusList, ...statusList3];
  console.log("jobData?.deliveryDateTemp", jobData?.deliveryDateTemp);
  console.log("jobData?.deliveryDateTemp", jobData?.deliveryDateTempTime);
  console.log("jobData?.closingDateTemp", jobData?.closingDateTemp);
  console.log("jobData?.closingDateTemp", jobData?.closingDateTempTime);

  const isDisableSubStatus =
    jobData?.status === "Cancel" ||
    jobData?.status === "Buy Back" ||
    jobData?.status === "Intentionally Left Out" ||
    jobData?.status === "Return Without Repair" ||
    jobData?.status === "Completed with Repair"
      ? false
      : true;

  // jobData?.status === "Open" ||
  // jobData?.status === "Repair Start" ||
  // jobData?.status === "Approval Pending" ||
  // jobData?.status === "Need Tech Support" ||
  // jobData?.status === "Part Required" ||
  // jobData?.status === "Pending" ||
  // jobData?.status === "Warranty Crated" ||
  // jobData?.status === "Ready For Delivery" ||
  // jobData?.status === "Waiting for Pickup (OW)" ||
  // jobData?.status === "Approved"

  return (
    <CreateJobcardStyled>
      <div>
        <ImageUploadModel
          show={showImageMoodel}
          handleClose={handleCloseImage}
          handleImageUploadSuccess={handleImageUploadSuccess}
        />
        <ImageViewModel
          onHide={() => {
            setShowImageViewMoodel(false);
          }}
          show={showImageViewMoodel}
          url={selectedImageUrl}
        />
        <CustomModal
          show={showSuccessPopup}
          titleText={
            jobId
              ? "Job Card Successfully Updated."
              : "Job Card Successfully Created."
          }
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessPopup(false);
            history.push("/jobcardsummary");
          }}
        />
        <CustomModal
          show={showSuccessPopupUser}
          titleText={"Successfully Added Customer."}
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessPopupUser(false);
            // history.push("/jobcardsummary");
          }}
        />
        <CustomModal
          show={errorModel}
          titleText={errorText}
          deleteText="Ok"
          cancelText=""
          dynamicClassName={`error_message`}
          onDelete={() => {
            setErrorModel(false);
            // history.push("/jobcardsummary");
          }}
        />
        <CustomModal
          show={conformClosingModel}
          titleText={conformClosingModelText}
          deleteText="CONFIRM"
          cancelText="Cancel"
          dynamicClassName={`error_message`}
          onCancel={() => {
            setConformClosingModel(false);
            // history.push("/jobcardsummary");
          }}
          onDelete={() => {
            setConformClosingModel(false);
            handleSubmitBtnConform();
            // history.push("/jobcardsummary");
          }}
        />

        <AddNewUser
          addUserSuccess={addUserSuccess}
          show={addNewUserPopup}
          onHide={handleCloseAddNewUSer}
          selectedEditData={selectedUserEditData}
          tempUserPhoneNumber={tempUserPhoneNumber}
        />
        <PasswordPatternLock
          handleSuccessPassLock={handleSuccessPassLock}
          show={showPasswordPatternLock}
          onHide={handleClosePassLock}
        />
        <div className="focusnext-boby-section">
          <div className="">
            {jobId && (
              <div className="add-back-arrow">
                <span onClick={handleClickBack}>{`< Back`}</span>
              </div>
            )}
            <div className="section-container-header">
              <h3> {jobId ? `Service ID: ${jobId}` : "Create Job Card"} </h3>
              <span>
                <a href="/jobcardsummary">Job Card Summary List</a>
              </span>
              <span onClick={handleAddNewUSer} className="add-new-user-sec">
                Add New Customer
              </span>
            </div>
          </div>
          <div className="focusnext-boby-content">
            <div className="col datatable1">
              <div className="fluid-container ProductDetails1">
                <div className="row">
                  <div className="col-sm-6 left-side-content-sec">
                    <div className="row align-items-center">
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Mobile No <span className="manditary">*</span>
                          </Form.Label>
                          <div className="extra-fields-content">
                            <Form.Control
                              maxLength={10}
                              isInvalid={requiredFields?.mobileNo}
                              value={jobData?.mobileNo}
                              type="text"
                              name="mobileNo"
                              onKeyDown={handleKeypress}
                              id="mobileNo"
                              autoComplete="off"
                              onChange={onChangeValue}
                              // onChange={this.handleChangeValue}
                            />
                            {userSelectedInfo?.id && (
                              <span
                                onClick={handleEditUSer}
                                className="add_edit_icons_sec"
                              >
                                <img src={icons_edit} />{" "}
                              </span>
                            )}
                            <span
                              onClick={handleAddNewUSer}
                              className="add_edit_icons_sec"
                            >
                              <img src={icons_plus} />{" "}
                            </span>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.mobileNo ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {showDropDown1 && jobData?.mobileNo && (
                          <div className="menu-items-list col-12">
                            <ul id="results" className="list-group">
                              {isLoading && (
                                <li className="list-group-item list-group-item-action">
                                  Loading...
                                </li>
                              )}
                              {searchUsersList?.length === 0 && (
                                <li
                                  onClick={handleItemClick}
                                  className="list-group-item list-group-item-action"
                                >
                                  No results found.
                                </li>
                              )}
                              {searchUsersList?.map((item) => {
                                return (
                                  <li
                                    onClick={() => {
                                      handleItemClick(item);
                                    }}
                                    className="list-group-item list-group-item-action"
                                  >
                                    {item?.customerName}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 " ref={dropdownRef}>
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Customer Name <span className="manditary">*</span>
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.customerName}
                            value={jobData?.customerName}
                            type="text"
                            name="customerName"
                            id="customerName"
                            disabled={true}
                            onChange={onChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.customerName ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {showDropDown && jobData?.customerName && (
                          <div className="menu-items-list col-12">
                            <ul id="results" className="list-group">
                              {isLoading && (
                                <li className="list-group-item list-group-item-action">
                                  Loading...
                                </li>
                              )}
                              {searchUsersList?.length === 0 && (
                                <li
                                  onClick={handleItemClick}
                                  className="list-group-item list-group-item-action"
                                >
                                  No results found.
                                </li>
                              )}
                              {searchUsersList?.map((item) => {
                                return (
                                  <li
                                    onClick={() => {
                                      handleItemClick(item);
                                    }}
                                    className="list-group-item list-group-item-action"
                                  >
                                    {item?.customerName}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>

                      {/* <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Alternative Mobile No
                          </Form.Label>
                          <Form.Control
                            maxLength={10}
                            // isInvalid={errors?.title}
                            value={jobData?.alternativeNo}
                            type="text"
                            name="alternativeNo"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Email
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            className="user-email-sec"
                            // isInvalid={errors?.title}
                            value={jobData?.customerEmail}
                            type="text"
                            name="customerEmail"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                        </Form.Group>
                      </div> */}
                      <div className="col-sm-6 product-name-drop-down">
                        <Form.Group
                          className={
                            requiredFields?.productName
                              ? "error-fields-sec"
                              : ""
                          }
                        >
                          <label htmlFor="" className="col-form-label">
                            Product Name <span className="manditary">*</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={
                              jobData?.productName
                                ? jobData?.productName
                                : "Select"
                            }
                            isSearchable={true}
                            name="productName"
                            id="productName"
                            options={productsList}
                            onChange={(e) => {
                              onChangeValueSelect("productName", e);
                            }}
                            required
                            value={jobData?.productName}
                            placeholder={
                              jobData?.productName
                                ? jobData?.productName
                                : "Select"
                            }
                            isInvalid={requiredFields?.productName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.productName ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-sm-6 product-name-drop-down">
                        <Form.Group
                          className={
                            requiredFields?.brandName ? "error-fields-sec" : ""
                          }
                        >
                          <label htmlFor="" className="col-form-label">
                            Brand Name <span className="manditary">*</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={
                              jobData?.brandName ? jobData?.brandName : "Select"
                            }
                            placeholder={
                              jobData?.brandName ? jobData?.brandName : "Select"
                            }
                            isSearchable={true}
                            name="brandName"
                            id="brandName"
                            options={brandsList}
                            onChange={(e) => {
                              onChangeValueSelect("brandName", e);
                            }}
                            required
                            value={jobData?.brandName}
                            isInvalid={requiredFields?.brandName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.brandName ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      {/* <div className="col-sm-6 product-name-drop-down">
                        <Form.Group>
                          <label htmlFor="" className="col-form-label">
                            Brand Name <span className="manditary">*</span>
                          </label>
                          <select
                            name="brandName"
                            onChange={onChangeValue}
                            required
                            disabled={!jobData?.productName}
                            value={jobData?.brandName}
                          >
                            <option value="">Select</option>
                            {displayJobCardDetails?.brands?.map((item) => {
                              return (
                                <option value={item?.id}>{item?.name}</option>
                              );
                            })}
                          </select>
                        </Form.Group>
                      </div> */}
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Model Name
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            // isInvalid={errors?.title}
                            value={jobData?.model}
                            type="text"
                            name="model"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Serial / IMEI No. 1{" "}
                            <span className="manditary">*</span>
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.serialNo1}
                            value={jobData?.serialNo1}
                            type="text"
                            name="serialNo1"
                            id="serialNo1"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.serialNo1 ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      {showExtraSerialFields && (
                        <>
                          <div className="col-sm-6 ">
                            <Form.Group>
                              <Form.Label className="requiredField">
                                Serial / IMEI No. 2
                              </Form.Label>
                              <Form.Control
                                maxLength={200}
                                // isInvalid={errors?.title}
                                value={jobData?.serialNo2}
                                type="text"
                                name="serialNo2"
                                onChange={onChangeValue}
                                // onChange={this.handleChangeValue}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-sm-6 ">
                            <Form.Group>
                              <Form.Label className="requiredField">
                                Serial / IMEI No. 3
                              </Form.Label>
                              <Form.Control
                                maxLength={200}
                                // isInvalid={errors?.title}
                                value={jobData?.serialNo3}
                                type="text"
                                name="serialNo3"
                                onChange={onChangeValue}
                                // onChange={this.handleChangeValue}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {showExtraFields && (
                        <>
                          <div className="col-sm-6 ">
                            <Form.Group>
                              <Form.Label className="requiredField">
                                Laptop/Desktop Password/Pattern Lock{" "}
                                <span
                                  className="pattern-lock-img"
                                  onClick={() => {
                                    setShowPasswordPatternLock(true);
                                  }}
                                >
                                  <img src={password_pattern} />
                                </span>{" "}
                                <span className="manditary">*</span>
                              </Form.Label>
                              <Form.Control
                                maxLength={200}
                                isInvalid={requiredFields?.systemPassword}
                                value={jobData?.systemPassword}
                                type="text"
                                name="systemPassword"
                                id="systemPassword"
                                onChange={onChangeValue}
                                // onChange={this.handleChangeValue}
                              />
                              <Form.Control.Feedback type="invalid">
                                {requiredFields?.systemPassword ? <></> : null}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6 left-side-content-sec">
                    <div className="row align-items-center">
                      <div className="col-sm-6 " id="deliveryDateTemp">
                        <Form.Group>
                          <Form.Label>
                            Booking Date & Time
                            <span className="manditary">*</span>
                            {/* {selectedDateTime} */}
                          </Form.Label>
                          <Datetime
                            onChange={handleDateTime}
                            value={`${
                              jobData?.deliveryDateTemp
                            } ${jobData?.deliveryDateTempTime?.toLowerCase()}`}
                            dateFormat={"DD/MM/YYYY"}
                            isValidDate={disablePastDt}

                            // dateFormat={moment(new Date()).format("DD/MM/YYYY")}
                            // timeFormat={moment(new Date()).format("h:mm A")}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Inspection Charges
                            <span className="manditary">*</span>
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.minCharges}
                            value={jobData?.minCharges}
                            type="text"
                            id="minCharges"
                            name="minCharges"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.minCharges ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 "></div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-sm-6 " id="deliveryDateTemp">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Estimation Amount
                            {/* <span className="manditary">*</span> */}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.estimationAmount}
                            value={jobData?.estimationAmount}
                            type="text"
                            id="estimationAmount"
                            name="estimationAmount"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.estimationAmount ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Advance Amount
                            {/* <span className="manditary">*</span> */}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.advanceAmount}
                            value={jobData?.advanceAmount}
                            type="text"
                            id="advanceAmount"
                            name="advanceAmount"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.advanceAmount ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row align-items-center payment-time-sec-mo">
                      <div className="col-sm-12  warrenty-type-section">
                        <div
                          className={
                            requiredFields?.paymentType
                              ? "error-fields-sec-radio row radio-buttons"
                              : " row radio-buttons"
                          }
                        >
                          <label for="" className="col-form-label">
                            Payment Type <span className="manditary">*</span>
                          </label>
                          <div className="radio-button-values" id="paymentType">
                            <div className="col  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="paymentType"
                                id="WarrentyTypeWarranty"
                                value="Warranty"
                                checked={
                                  jobData?.paymentType === "Warranty"
                                    ? true
                                    : false
                                }
                                onChange={onChangeValue}
                              />
                              <label
                                className="form-check-label"
                                for="WarrentyTypeWarranty"
                              >
                                Warranty
                              </label>
                            </div>
                            <div className="col  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="paymentType"
                                id="WarrentyTypeWarranty1"
                                value="Chargeable"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.paymentType === "Chargeable"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="WarrentyTypeWarranty1"
                              >
                                Chargeable
                              </label>
                            </div>
                            <div className="col  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="paymentType"
                                id="WarrentyTypeWarranty2"
                                value="AMC"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.paymentType === "AMC" ? true : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="WarrentyTypeWarranty2"
                              >
                                AMC
                              </label>
                            </div>
                          </div>
                          <div className="col  "></div>
                          <div className="col  "></div>
                        </div>
                      </div>
                      {(jobData?.paymentType === "AMC" ||
                        jobData?.paymentType === "Warranty") && (
                        <div className="assigned-to-sec assigned-to-sec-amc">
                          <div className="assigned-to-sec-amc-left">
                            <Form.Group>
                              <Form.Label>
                                Purchase Date{" "}
                                <span className="manditary">*</span>
                              </Form.Label>
                              <div className="assigned-to-sec-amc-left-con">
                                <Form.Control
                                  // min={dateFormatType(undefined)}
                                  type="date"
                                  max={new Date().toJSON().slice(0, 10)}
                                  isInvalid={
                                    editRequiredFields?.amcTempPurchageDate
                                  }
                                  value={jobData?.amcTempPurchageDate}
                                  name="amcTempPurchageDate"
                                  id="amcTempPurchageDate"
                                  onChange={onChangeValue}
                                />
                                {jobData?.amcTempPurchageDate && (
                                  <div>
                                    ({getDiffDays(jobData?.amcTempPurchageDate)}{" "}
                                    days)
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          </div>
                          {/* <div className="assigned-to-sec-amc-right">
                            (160 days)
                          </div> */}
                        </div>
                      )}
                      <div className="col-sm-12  warrenty-type-section warrenty-type-section1">
                        <div
                          className={
                            requiredFields?.incommingSource
                              ? "error-fields-sec-radio row radio-buttons"
                              : " row radio-buttons"
                          }
                          id="incommingSource"
                        >
                          <label for="" className="col-form-label">
                            Incomming Source{" "}
                            <span className="manditary">*</span>
                          </label>
                          <div className="radio-button-values2 radio-button-values-incoming1">
                            <div className="col2  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="incommingSource"
                                id="IncommingSourceCarryIn"
                                value="carry in"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.incommingSource === "carry in"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="IncommingSourceCarryIn"
                              >
                                Carry in
                              </label>
                            </div>
                            <div className="col2  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="incommingSource"
                                id="IncommingSourceOnsite"
                                value="onsite"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.incommingSource === "onsite"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="IncommingSourceOnsite"
                              >
                                Onsite
                              </label>
                            </div>

                            <div className="col2  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="incommingSource"
                                id="IncommingSourceCourier"
                                value="Courier"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.incommingSource === "Courier"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="IncommingSourceCourier"
                              >
                                Courier
                              </label>
                            </div>

                            <div className="col2  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="incommingSource"
                                id="IncommingSourceRemote"
                                value="Remote"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.incommingSource === "Remote"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="IncommingSourceRemote"
                              >
                                Remote
                              </label>
                            </div>
                            <div className="col2  ">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="incommingSource"
                                id="IncommingSourcePickup"
                                value="Pickup"
                                onChange={onChangeValue}
                                checked={
                                  jobData?.incommingSource === "Pickup"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="IncommingSourcePickup"
                              >
                                Pickup
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {jobData?.incommingSource === "Courier" && (
                        <div className="courier-sec-content-sec">
                          <div>
                            <div className="courier-view">
                              <div className="row">
                                <div className="col-sm-6">
                                  <Form.Group>
                                    <Form.Label className="requiredField">
                                      Courier Name
                                      <span className="manditary">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      maxLength={200}
                                      // isInvalid={errors?.title}
                                      value={jobData?.incomSourceCourierName}
                                      type="text"
                                      name="incomSourceCourierName"
                                      id="incomSourceCourierName"
                                      isInvalid={
                                        editRequiredFields?.incomSourceCourierName
                                      }
                                      onChange={onChangeValue}
                                      // onChange={this.handleChangeValue}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-sm-6">
                                  <Form.Group>
                                    <Form.Label>
                                      Courier Date{" "}
                                      <span className="manditary">*</span>
                                    </Form.Label>

                                    <Form.Control
                                      // min={dateFormatType(undefined)}
                                      type="date"
                                      isInvalid={
                                        editRequiredFields?.incomTempSourceCourierDate
                                      }
                                      value={
                                        jobData?.incomTempSourceCourierDate
                                      }
                                      name="incomTempSourceCourierDate"
                                      id="incomTempSourceCourierDate"
                                      onChange={onChangeValue}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-sm-6">
                                  <Form.Group>
                                    <Form.Label className="requiredField">
                                      DOC Number{" "}
                                      <span className="manditary">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      maxLength={200}
                                      isInvalid={
                                        editRequiredFields?.incomSourceCourierDocNumber
                                      }
                                      value={
                                        jobData?.incomSourceCourierDocNumber
                                      }
                                      type="text"
                                      name="incomSourceCourierDocNumber"
                                      id="incomSourceCourierDocNumber"
                                      onChange={onChangeValue}
                                      // onChange={this.handleChangeValue}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {jobData?.productName && (
              <>
                <div className="dis-none1" id="additional-info-select">
                  <div
                    className={
                      requiredFields?.itemRecived
                        ? "items-recieved-section-error items-recieved-section"
                        : " items-recieved-section"
                    }
                    id="itemRecived"
                  >
                    <div className="section-container-header1">
                      {/* <div className="section-container-header1-left"> */}
                      <h3>
                        Items Recieved <span className="manditary">*</span>
                      </h3>
                      <div className="search-icon-sec">
                        <input
                          id="items-recieved-search-box"
                          onChange={searchOnChangeValue}
                          name="items-recieved"
                          placeholder="Search "
                        />
                        <div
                          onClick={() => {
                            setShowItemReceived(!showItemReceived);
                          }}
                          className="arrow-text-sec"
                        >
                          <span>
                            {" "}
                            <img src={arrow_down} />{" "}
                          </span>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    {showItemReceived && (
                      <>
                        <div
                          className="row g-3 align-items-center items-recieved-body-content"
                          id="loadItemsRecived_dev"
                        >
                          {itemsRecievedList?.map((item, index) => {
                            const isChecked = checkItemByList(
                              jobData?.itemRecived,
                              item
                            );
                            const isNoItemSelected = isNoitemSelectedCheck(
                              jobData?.itemRecived
                            );
                            return (
                              <div className="mb-3 col-sm-3 form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={`item_received${index}`}
                                  id={`item_received${index}`}
                                  value={item?.optionName}
                                  checked={!isChecked}
                                  disabled={
                                    item?.optionName !== "No Items Received"
                                      ? isNoItemSelected
                                      : false
                                  }
                                  onChange={(e) => {
                                    onChnageValueList(
                                      "itemRecived",
                                      item,
                                      e,
                                      jobData?.itemRecived
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for={`item_received${index}`}
                                >
                                  {item?.optionName}
                                </label>
                              </div>
                            );
                          })}
                          {!showItemReceivedRemarks && (
                            <div className="add-others-remark-sec">
                              <button
                                onClick={() => {
                                  setShowItemReceivedRemarks(true);
                                }}
                              >
                                Add Other Remarks
                              </button>{" "}
                            </div>
                          )}
                        </div>
                        <PrevListSection items={jobCardDetails?.itRemarks} />
                        {showItemReceivedRemarks ? (
                          <div className="remark-text-field">
                            Remark:
                            <br />
                            <textarea
                              type="text"
                              className="form-control remark-textarea"
                              name="itemsRecievedRemarks"
                              value={jobData?.itemsRecievedRemarks}
                              id="itemsRecievedRemarks"
                              onChange={onChangeValue}
                            ></textarea>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div
                    className={
                      requiredFields?.defaultIssues
                        ? "items-recieved-section-error items-recieved-section"
                        : " items-recieved-section"
                    }
                    id="defaultIssues"
                  >
                    <div className="section-container-header1">
                      {/* <div className="section-container-header1-left"> */}
                      <h3>
                        Default Issues <span className="manditary">*</span>
                      </h3>
                      <div className="search-icon-sec">
                        <input
                          id="default-issues-search-box"
                          onChange={searchOnChangeValue}
                          name="default-issues"
                          placeholder="Search "
                        />
                        <div
                          onClick={() => {
                            setShowDefaultIssues(!showDefaultIssues);
                          }}
                          className="arrow-text-sec"
                        >
                          <span>
                            {" "}
                            <img src={arrow_down} />{" "}
                          </span>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    {showDefaultIssues && (
                      <>
                        <div
                          className="row g-3 align-items-center items-recieved-body-content"
                          id="loaddefaultissues_dev"
                        >
                          {defaultIssues?.map((item, index) => {
                            const isChecked = checkItemByList(
                              jobData?.defaultIssues,
                              item
                            );
                            const isNoItemSelected = isNoitemSelectedCheck(
                              jobData?.defaultIssues
                            );
                            return (
                              <div className="mb-3 col-sm-3 form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={`default_issues${index}`}
                                  id={`default_issues${index}`}
                                  value={item?.optionName}
                                  checked={!isChecked}
                                  disabled={
                                    item?.optionName !== "No Items Received"
                                      ? isNoItemSelected
                                      : false
                                  }
                                  onChange={(e) => {
                                    onChnageValueList(
                                      "defaultIssues",
                                      item,
                                      e,
                                      jobData?.defaultIssues
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for={`default_issues${index}`}
                                >
                                  {item?.optionName}
                                </label>
                              </div>
                            );
                          })}
                          {!showDefaultIssuesRemarks && (
                            <div className="add-others-remark-sec">
                              <button
                                onClick={() => {
                                  setShowDefaultIssuesRemarks(true);
                                }}
                              >
                                Add Other Remarks
                              </button>{" "}
                            </div>
                          )}
                        </div>
                        <PrevListSection items={jobCardDetails?.diRemarks} />

                        {showDefaultIssuesRemarks && (
                          <div className="remark-text-field">
                            Remark:
                            <br />
                            <textarea
                              type="text"
                              className="form-control remark-textarea"
                              name="defaultIssuesRemarks"
                              id="DefaultIssuesRemarks"
                              value={jobData?.defaultIssuesRemarks}
                              onChange={onChangeValue}
                            ></textarea>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={
                      requiredFields?.customerAddRequirements
                        ? "items-recieved-section-error items-recieved-section"
                        : " items-recieved-section"
                    }
                    id="customerAddRequirements"
                  >
                    <div className="section-container-header1">
                      {/* <div className="section-container-header1-left"> */}
                      <h3>
                        Customer Additional Requirements{" "}
                        <span className="manditary">*</span>
                      </h3>
                      <div className="search-icon-sec">
                        <input
                          id="customer-additional-search-box"
                          onChange={searchOnChangeValue}
                          name="customer-additional"
                          placeholder="Search "
                        />
                        <div
                          onClick={() => {
                            setShowCustomerAR(!showCustomerAR);
                          }}
                          className="arrow-text-sec"
                        >
                          <span>
                            {" "}
                            <img src={arrow_down} />{" "}
                          </span>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    {showCustomerAR && (
                      <>
                        <div
                          className="row g-3 align-items-center items-recieved-body-content"
                          id="customerAdditionalReq_dev"
                        >
                          {cARequirements?.map((item, index) => {
                            const isChecked = checkItemByList(
                              jobData?.customerAddRequirements,
                              item
                            );
                            const isNoItemSelected = isNoitemSelectedCheck(
                              jobData?.customerAddRequirements
                            );
                            return (
                              <div className="mb-3 col-sm-3 form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={`customer_requiments${index}`}
                                  id={`customer_requiments${index}`}
                                  value={item?.optionName}
                                  checked={!isChecked}
                                  disabled={
                                    item?.optionName !==
                                      "No Additional Required" &&
                                    item?.optionName !==
                                      "NO ADDITIONAL REQUIRED"
                                      ? isNoItemSelected
                                      : false
                                  }
                                  onChange={(e) => {
                                    onChnageValueList(
                                      "customerAddRequirements",
                                      item,
                                      e,
                                      jobData?.customerAddRequirements
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for={`customer_requiments${index}`}
                                >
                                  {item?.optionName}
                                </label>
                              </div>
                            );
                          })}
                          {!showCustomerARRemarks && (
                            <div className="add-others-remark-sec">
                              <button
                                onClick={() => {
                                  setShowCustomerARRemarks(true);
                                }}
                              >
                                Add Other Remarks
                              </button>{" "}
                            </div>
                          )}
                        </div>
                        <PrevListSection items={jobCardDetails?.csRemarks} />
                        {showCustomerARRemarks && (
                          <div className="remark-text-field">
                            Remark:
                            <br />
                            <textarea
                              type="text"
                              className="form-control remark-textarea"
                              name="customerAdditionalReqRemarks"
                              value={jobData?.customerAdditionalReqRemarks}
                              id="customerAdditionalReqRemarks"
                              onChange={onChangeValue}
                            ></textarea>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {jobData?.productName && (
              <div className="edit-action-all-items-right upload-images-sec">
                <div className="action-taken-all-items-sec">
                  <div className="action-taken-items">
                    <div className="assigned-to-sec1">
                      <>
                        <label htmlFor="" className="col-form-label">
                          Upload Images
                          {/* <span className="manditary">*</span> */}
                        </label>
                      </>
                      <div className="list-images-sec-con">
                        {imagesList?.map((imgItem, index) => {
                          return (
                            <div className="image-link-sec-item">
                              <img
                                onClick={() => handleImageView(imgItem)}
                                src={imgItem}
                              />
                              <span
                                onClick={() => {
                                  handleDeleteImage(index);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </div>
                          );
                        })}
                        {imagesList?.length < 5 && (
                          <div
                            className="image-link-sec-item image-link-sec-item-new"
                            onClick={() => {
                              handleImageClick();
                            }}
                          >
                            {/* <input type="file" id="myFile" name="filename" /> */}
                            <img src={camera_line} />
                            <div>
                              <span>
                                Upload Image
                                <span className="manditary-field">*</span>
                              </span>{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {jobId && (
              <>
                {/* <div>
                  <div className="assigned-to-sec1">
                    <label htmlFor="" className="col-form-label">
                      Remarks
                    </label>

                    <textarea
                      name="customerNotes"
                      onChange={onChangeValue}
                      value={jobData?.customerNotes}
                    ></textarea>
                  </div>
                </div> */}
                {/* grid-template-columns */}
                <div className="edit-action-content">
                  <div className="edit-action-all-items-left">
                    <div className="action-taken-items">
                      <div className="assigned-to-sec1">
                        <label htmlFor="" className="col-form-label">
                          Engineer Observations
                          {/* <span className="manditary">*</span> */}
                        </label>
                        <PrevListSection
                          items={jobCardDetails?.savedEngineerObservations}
                        />
                        <div className="action-edit-observations-sec">
                          <select
                            name="engineerObservationOption"
                            onChange={onChangeValue}
                            required
                          >
                            <option value="">Select</option>
                            {engineerObservationList?.map((jobSEO) => {
                              return (
                                <option value={jobSEO?.optionName}>
                                  {jobSEO?.optionName}
                                </option>
                              );
                            })}
                          </select>
                          {(openEngineerObservationsBox || true) && (
                            <textarea
                              name="engineerObservationOption"
                              onChange={onChangeValue}
                              value={jobData?.engineerObservationOption}
                            ></textarea>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="action-taken-items action-taken-items-notes">
                      <div className="assigned-to-sec1">
                        <label htmlFor="" className="col-form-label">
                          Customer Notes
                          {/* <span className="manditary">*</span> */}
                        </label>
                        <PrevListSection
                          items={jobCardDetails?.customerNotes}
                        />
                        <div className="action-edit-observations-sec">
                          <select
                            name="customerNotes"
                            onChange={onChangeValue}
                            required
                          >
                            <option value="">Select</option>
                            {jobCardDetails?.customerNoteListOptions?.map(
                              (jobSEO) => {
                                return (
                                  <option value={jobSEO?.optionName}>
                                    {jobSEO?.optionName}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          {(openCustomerNotesBox || true) && (
                            <textarea
                              name="customerNotes"
                              onChange={onChangeValue}
                              value={jobData?.customerNotes}
                            ></textarea>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="edit-action-all-items-right">
                    <div className="action-taken-all-items-sec action-taken-all-items-sec-d">
                      {actionTakenItems.map((item, index) => {
                        return (
                          <div className="action-taken-items">
                            <div className="assigned-to-sec1">
                              {index === 0 && (
                                <>
                                  <label htmlFor="" className="col-form-label">
                                    Action Taken
                                    {/* <span className="manditary">*</span> */}
                                  </label>
                                  <PrevListSection
                                    section="actionTaken"
                                    items={jobCardDetails?.actionTakens}
                                  />
                                </>
                              )}

                              <div className="action-edit-add-icons">
                                <select
                                  name="actionTaken"
                                  onChange={(e) => {
                                    onChangeValueActionTaken(e, item, index);
                                  }}
                                  required
                                >
                                  <option value="">Select</option>
                                  {actionTakenList?.map((jobAT) => {
                                    return (
                                      <option
                                        selected={
                                          item?.serviceTaken ===
                                          jobAT?.optionName
                                            ? true
                                            : false
                                        }
                                        value={jobAT?.optionName}
                                      >
                                        {jobAT?.optionName}
                                      </option>
                                    );
                                  })}
                                </select>
                                {index == actionTakenItems.length - 1 && (
                                  <div
                                    className="action-click-item"
                                    onClick={() => {
                                      addNewRowForAction(item, index);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-plus-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>
                                  </div>
                                )}
                                {index !== 0 && (
                                  <div
                                    className="action-click-item"
                                    onClick={() => {
                                      deleteItemForAction(item, index);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-trash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                    </svg>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {showPartsReplacedSec() && (
                      <div className="action-taken-items-parts-replaced">
                        {partsReplacedItems.map((item, index) => {
                          return (
                            <div className="action-taken-items">
                              <div className="assigned-to-sec1">
                                {index === 0 && (
                                  <>
                                    <label
                                      htmlFor=""
                                      className="col-form-label"
                                    >
                                      Part Replaced
                                      <span className="manditary">*</span>
                                    </label>
                                    <PrevListSection
                                      section="partReplaced"
                                      items={jobCardDetails?.partReplaceds}
                                    />
                                  </>
                                )}
                                <div
                                  className={
                                    partsReplacedItems?.length === 1
                                      ? "action-edit-add-icons-name action-edit-add-icons-name1"
                                      : "action-edit-add-icons-name"
                                  }
                                >
                                  <div className="action-edit-add-icons-name-left">
                                    {index === 0 && (
                                      <div className="action-usernames">
                                        <div>Part Name</div>
                                        <div>Serial No</div>
                                      </div>
                                    )}
                                    <div className="action-usernames">
                                      <input
                                        onChange={(e) => {
                                          handlePartChange(
                                            e?.target?.value,
                                            "partName",
                                            index
                                          );
                                        }}
                                      />

                                      <input
                                        onChange={(e) => {
                                          handlePartChange(
                                            e?.target?.value,
                                            "partSerialno",
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {index == partsReplacedItems.length - 1 && (
                                    <div
                                      className="action-click-item"
                                      onClick={() => {
                                        addNewRowForAction1(item, index);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-plus-square-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                      </svg>
                                    </div>
                                  )}
                                  {index !== 0 && (
                                    <div
                                      className="action-click-item"
                                      onClick={() => {
                                        deleteItemForAction1(item, index);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-trash"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="radio-buttons-section-tems-sec radio-buttons-section-tems-sec1">
                  <div className=" align-items-center radio-buttons-section-tems radio-buttons-section-tems-left">
                    <div className=" warrenty-type-section">
                      <div className=" radio-buttons">
                        <label for="" className="col-form-label">
                          Recevier person is same as that of customer{" "}
                          <span className="manditary">*</span>
                        </label>
                        <div
                          className="radio-button-values"
                          id="receiverPersonSame"
                        >
                          <div className="col ">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="receiverPersonSame"
                              id="recevierPersonSameAsCustome1"
                              value="Yes"
                              checked={
                                jobData?.receiverPersonSame === "Yes"
                                  ? true
                                  : false
                              }
                              onChange={onChangeValue}
                            />
                            <label
                              className="form-check-label"
                              for="recevierPersonSameAsCustome1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="col  ">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="receiverPersonSame"
                              id="receiverPersonSame"
                              value="No"
                              checked={
                                jobData?.receiverPersonSame === "No"
                                  ? true
                                  : false
                              }
                              onChange={onChangeValue}
                            />
                            <label
                              className="form-check-label"
                              for="receiverPersonSame"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {jobData?.receiverPersonSame === "No" && (
                      <div className="extra-items-row-con">
                        <div className="row">
                          <div className="col-sm-6 ">
                            <Form.Group>
                              <Form.Label className="requiredField">
                                Receiver Name{" "}
                                <span className="manditary">*</span>
                              </Form.Label>
                              <Form.Control
                                maxLength={50}
                                isInvalid={requiredFields?.recevierName}
                                value={jobData?.recevierName}
                                type="text"
                                name="recevierName"
                                id="recevierName"
                                onChange={onChangeValue}
                                // onChange={this.handleChangeValue}
                              />
                              <Form.Control.Feedback type="invalid">
                                {requiredFields?.recevierName ? <></> : null}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-sm-6 ">
                            <Form.Group>
                              <Form.Label className="requiredField">
                                Receiver Mobile No{" "}
                                <span className="manditary">*</span>
                              </Form.Label>
                              <Form.Control
                                maxLength={10}
                                isInvalid={requiredFields?.recevierMobileNo}
                                value={jobData?.recevierMobileNo}
                                type="text"
                                name="recevierMobileNo"
                                id="recevierMobileNo"
                                onChange={onChangeValue}
                                // onChange={this.handleChangeValue}
                              />
                              <Form.Control.Feedback type="invalid">
                                {requiredFields?.recevierMobileNo ? (
                                  <></>
                                ) : null}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=" align-items-center radio-buttons-section-tems radio-buttons-section-tems-left">
                    <div
                      className="  warrenty-type-section"
                      id="originalJobCardReceived"
                    >
                      <div className=" radio-buttons">
                        <label for="" className="col-form-label">
                          Original Job Sheet Recevied
                          <span className="manditary">*</span>
                        </label>
                        <div className="radio-button-values">
                          <div className="col  ">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="originalJobCardReceived"
                              id="originalJobCardReceived1"
                              value="Yes"
                              checked={
                                jobData?.originalJobCardReceived === "Yes"
                                  ? true
                                  : false
                              }
                              onChange={onChangeValue}
                            />
                            <label
                              className="form-check-label"
                              for="originalJobCardReceived1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="col  ">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="originalJobCardReceived"
                              id="originalJobCardReceived2"
                              value="No"
                              checked={
                                jobData?.originalJobCardReceived === "No"
                                  ? true
                                  : false
                              }
                              onChange={onChangeValue}
                            />
                            <label
                              className="form-check-label"
                              for="originalJobCardReceived2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {jobData?.originalJobCardReceived === "No" && (
                      <div className="extra-items-row-con">
                        <div className="row">
                          <div className="col-sm-6 ">
                            <Form.Group>
                              <Form.Label className="requiredField">
                                Reason <span className="manditary">*</span>
                              </Form.Label>
                              <Form.Control
                                maxLength={400}
                                isInvalid={
                                  requiredFields?.originalNotJobCardReason
                                }
                                value={jobData?.originalNotJobCardReason}
                                type="text"
                                name="originalNotJobCardReason"
                                id="originalNotJobCardReason"
                                onChange={onChangeValue}
                                // onChange={this.handleChangeValue}
                              />
                              <Form.Control.Feedback type="invalid">
                                {requiredFields?.originalNotJobCardReason ? (
                                  <></>
                                ) : null}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {(jobData?.status === "Out for Warranty" ||
              // jobData?.status === "Ready For Delivery" ||
              jobData?.toCourierName) && (
              <div className="courier-view">
                <div
                  className={
                    jobData?.productName
                      ? " create-job-action mb-3"
                      : "add-border-top create-job-action mb-3"
                  }
                >
                  <div className="assigned-to-sec1">
                    <Form.Group>
                      <Form.Label className="requiredField">
                        To Courier Name<span className="manditary">*</span>
                      </Form.Label>
                      <Form.Control
                        maxLength={200}
                        // isInvalid={errors?.title}
                        value={jobData?.toCourierName}
                        type="text"
                        name="toCourierName"
                        id="toCourierName"
                        isInvalid={editRequiredFields?.toCourierName}
                        onChange={onChangeValue}
                        // onChange={this.handleChangeValue}
                      />
                    </Form.Group>
                  </div>

                  <div className="assigned-to-sec1">
                    <Form.Group>
                      <Form.Label>
                        To Courier Date <span className="manditary">*</span>
                      </Form.Label>

                      <Form.Control
                        // min={dateFormatType(undefined)}
                        type="date"
                        isInvalid={editRequiredFields?.tempToCourierDate}
                        value={jobData?.tempToCourierDate}
                        name="tempToCourierDate"
                        id="tempToCourierDate"
                        onChange={onChangeValue}
                      />
                    </Form.Group>
                  </div>
                  <div className="assigned-to-sec1">
                    <Form.Group>
                      <Form.Label className="requiredField">
                        To DOC Number <span className="manditary">*</span>
                      </Form.Label>
                      <Form.Control
                        maxLength={200}
                        isInvalid={editRequiredFields?.toDocNumber}
                        value={jobData?.toDocNumber}
                        type="text"
                        name="toDocNumber"
                        id="toDocNumber"
                        onChange={onChangeValue}
                        // onChange={this.handleChangeValue}
                      />
                    </Form.Group>
                  </div>
                  <div className="assigned-to-sec1">
                    <Form.Group>
                      <Form.Label className="requiredField">
                        To Company Name <span className="manditary">*</span>
                      </Form.Label>
                      <Form.Control
                        maxLength={200}
                        // isInvalid={errors?.title}
                        value={jobData?.toCompanyName}
                        isInvalid={editRequiredFields?.toCompanyName}
                        type="text"
                        name="toCompanyName"
                        id="toCompanyName"
                        onChange={onChangeValue}
                        // onChange={this.handleChangeValue}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            )}
            {jobData?.toCourierName &&
              (jobData?.status === "Repair Closed" ||
                jobData?.status === "Ready For Delivery" ||
                jobData?.status === "Closing Approval" ||
                jobData?.status === "Closed" ||
                jobData?.subStatus === "Closing Approval" ||
                jobData?.subStatus === "Closed") && (
                <div className="courier-view">
                  <div
                    className={
                      jobData?.productName
                        ? " create-job-action mb-3"
                        : "add-border-top create-job-action mb-3"
                    }
                  >
                    <div className="assigned-to-sec1">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Receiveing Courier Name
                          <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          // isInvalid={errors?.title}
                          value={jobData?.receiveingCourierName}
                          type="text"
                          name="receiveingCourierName"
                          id="receiveingCourierName"
                          isInvalid={editRequiredFields2?.receiveingCourierName}
                          onChange={onChangeValue}
                          // onChange={this.handleChangeValue}
                        />
                      </Form.Group>
                    </div>

                    <div className="assigned-to-sec1">
                      <Form.Group>
                        <Form.Label>
                          Receiveing Courier Date{" "}
                          <span className="manditary">*</span>
                        </Form.Label>

                        <Form.Control
                          // min={dateFormatType(undefined)}
                          type="date"
                          isInvalid={
                            editRequiredFields2?.tempReceiveingToCourierDate
                          }
                          value={jobData?.tempReceiveingToCourierDate}
                          name="tempReceiveingToCourierDate"
                          id="tempReceiveingToCourierDate"
                          onChange={onChangeValue}
                        />
                      </Form.Group>
                    </div>
                    <div className="assigned-to-sec1">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Receiveing DOC Number{" "}
                          <span className="manditary">*</span>
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          isInvalid={editRequiredFields2?.receiveingDocNumber}
                          value={jobData?.receiveingDocNumber}
                          type="text"
                          name="receiveingDocNumber"
                          id="receiveingDocNumber"
                          onChange={onChangeValue}
                          // onChange={this.handleChangeValue}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              )}
            {showPrinterFields && jobId && (
              <div className="row">
                <div className="col-sm-3 ">
                  <Form.Group>
                    <Form.Label className="requiredField printPage-Count-sec">
                      Total Page Count <span className="manditary">*</span>
                    </Form.Label>
                    <Form.Control
                      maxLength={20}
                      isInvalid={requiredFields?.printPageCount}
                      value={jobData?.printPageCount}
                      type="text"
                      name="printPageCount"
                      id="printPageCount"
                      onChange={onChangeValue}
                      // onChange={this.handleChangeValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {requiredFields?.printPageCount ? <></> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm-3 ">
                  <Form.Group>
                    <Form.Label className="requiredField">Color </Form.Label>
                    <Form.Control
                      maxLength={20}
                      isInvalid={requiredFields?.printColor}
                      value={jobData?.printColor}
                      type="text"
                      name="printColor"
                      id="printColor"
                      onChange={onChangeValue}
                      // onChange={this.handleChangeValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {requiredFields?.printColor ? <></> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm-3 ">
                  <Form.Group>
                    <Form.Label className="requiredField">Black </Form.Label>
                    <Form.Control
                      maxLength={200}
                      isInvalid={requiredFields?.printBlack}
                      value={jobData?.printBlack}
                      type="text"
                      name="printBlack"
                      id="printBlack"
                      onChange={onChangeValue}
                      // onChange={this.handleChangeValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {requiredFields?.printBlack ? <></> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            )}
            <div
              className={
                jobData?.productName
                  ? " create-job-action create-job-action1 mb-3"
                  : "add-border-top create-job-action  create-job-action1 mb-3"
              }
            >
              {jobId && (
                <div className="assigned-to-sec1">
                  <label htmlFor="" className="col-form-label">
                    Repair Status
                    <span className="manditary">*</span>
                  </label>
                  <select name="status" onChange={onChangeValue} required>
                    <option value="">Select</option>
                    {statusList2.map((itemStatus) => {
                      return (
                        <option
                          selected={
                            itemStatus?.value === jobData?.status ? true : false
                          }
                        >
                          {itemStatus?.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {!jobId && (
                <div
                  className={
                    requiredFields?.priority
                      ? "error-fields-sec assigned-to-sec1"
                      : "assigned-to-sec1"
                  }
                >
                  <label htmlFor="" className="col-form-label">
                    Priority
                    <span className="manditary">*</span>
                  </label>
                  <select name="priority" onChange={onChangeValue} required>
                    <option value="">Select</option>

                    {severityOptions.map((sName) => {
                      return (
                        <option
                          value={sName}
                          selected={sName === jobData?.priority ? true : false}
                        >
                          {sName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div
                className={
                  requiredFields?.selectedUserId
                    ? "error-fields-sec assigned-to-sec1"
                    : "assigned-to-sec1"
                }
              >
                <label htmlFor="" className="col-form-label">
                  Assigned To
                  <span className="manditary">*</span>
                </label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={userSName ? userSName : "Select"}
                  isSearchable={true}
                  name="selectedUserId"
                  options={usersList}
                  onChange={(e) => {
                    onChangeValueSelectAssigned("selectedUserId", e);
                  }}
                  required
                  value={userSName}
                  placeholder={userSName ? userSName : "Select"}
                />
              </div>
              {jobId && (
                <div className="col-sm-12 " id="deliveryDateTemp">
                  <Form.Group>
                    <Form.Label>
                      Closing Date & Time
                      {/* <span className="manditary">*</span> */}
                      {/* {selectedDateTime} */}
                    </Form.Label>
                    <Datetime
                      onChange={handleClosingDateTime}
                      value={`${
                        jobData?.closingDateTemp
                      } ${jobData?.closingDateTempTime?.toLowerCase()}`}
                      dateFormat={"DD/MM/YYYY"}
                      // isValidDate={disablePastDt}

                      // dateFormat={moment(new Date()).format("DD/MM/YYYY")}
                      // timeFormat={moment(new Date()).format("h:mm a")}
                    />
                  </Form.Group>
                </div>
              )}
              {jobId && (
                <div className="assigned-to-sec1">
                  <label htmlFor="" className="col-form-label">
                    Closing Status
                    <span className="manditary">*</span>
                  </label>
                  <select
                    name="subStatus"
                    id="subStatus_field_sec"
                    disabled={isDisableSubStatus}
                    onChange={onChangeValue}
                    required
                  >
                    <option value="">Select</option>
                    {statusList9.map((itemStatus) => {
                      return (
                        <option
                          selected={
                            itemStatus?.value === jobData?.subStatus
                              ? true
                              : false
                          }
                        >
                          {itemStatus?.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            {errorMessage && <p className="error_message">{errorMessage}</p>}
            <div className="submit-btn-sec-top create-job-action99">
              <div className="submit-button-action mt-15">
                <button
                  type="submit"
                  id="btn_submit"
                  disabled={isDisabledBtn()}
                  name="btn_submit"
                  onClick={handleSubmitBtn}
                  className="btn btn-primary job-card-submit-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateJobcardStyled>
  );
};
export default CreateJobcard;
