import styled from "styled-components";

export const AboutUsStyled = styled.div`
  .middle-about-us-section {
    width: 80%;
    margin: auto;
    margin-top: 50px;
  }
  .about-us-top-section h2 span {
    color: #2d3092;
  }
  .about-us-page-top-sec {
    display: flex;
    gap: 40px;
    > p {
      width: 60%;
    }
    > div {
      width: 40%;
    }
    img {
      max-width: 100%;
      border-radius: 20px;
    }
  }
  .contact-us-sec > p {
    font-weight: bold;
    font-size: 26px;
    margin-top: 30px;
  }
  .sec-row-item-row {
    display: flex;
    gap: 25px;
    margin-top: 20px;
    p {
      margin-bottom: 3px;
      font-weight: bold;
    }
  }
  .about-us-top-section p {
    margin-top: 50px;
    line-height: 35px;
    font-size: 18px;
  }
  .about-us-bottom-section h2 {
    color: #2d3092;
    margin-bottom: 20px;
  }
  .about-us-bottom-section {
  }
  .about-us-item h4 {
    font-size: 26px;
    margin-top: 30px;
  }
  .about-us-item ul li {
    line-height: 35px;
    font-size: 18px;
  }
  .about-us-item {
  }
  @media (max-width: 800px) {
    .about-us-page-top-sec {
      display: block;
    }
    .about-us-top-section > p,
    .about-us-page-top-sec > p {
      width: 100%;
    }
    .about-us-page-top-sec > div {
      width: 100%;
    }
    .about-us-bottom-section {
      margin-top: 30px;
    }
    .about-us-top-section p {
      margin-top: 20px;
    }
    .middle-about-us-section {
      width: 90%;
      margin-top: 30px;
    }
  }
`;
