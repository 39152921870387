import styled from "styled-components";

export const CommonReportStyled = styled.div`
  .main-all-sec-report {
    display: flex;
    > div {
      width: 50%;
    }
  }
`;
