import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const AdditionalRequirementList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Additional Requirements Option"
        section="additionalRequirementList"
        subTitle="Create New Additional Requirements Option"
      />
    </>
  );
};
export default AdditionalRequirementList;
