import { CustomersListStyled } from "./CustomersList.styled";
import { Table } from "antd";
import { useEffect, useState } from "react";
import AddNewOption from "./AddNewOption";
import type { ColumnsType } from "antd/es/table";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersList,
  deleteCustomerApi,
} from "../../redux/slices/auth/authService";
import { updateAddNewUserResponse } from "../../redux/slices/auth/authSlice";
import AddNewUser from "./../CreateJobcard/AddNewUser";
import { triggerMixpanelEvent } from "../../Scenes/common";

const CustomersList = (props: any) => {
  const [searchValue, setSearchValue] = useState("");
  // const [dataSource, setDataSource] = useState(dataSource1);
  const [addNewPopup, setAddNewPopup] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userAddSuccess, setUserAddSuccess] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState({});
  const [dataItemsAll, setDataItemsAll] = useState([] as any);
  const [dataItems, setDataItems] = useState([] as any);
  const [selectedEditData, setSelectedEditData] = useState({} as any);
  const dispatch = useDispatch();
  const [addNewUserPopup, setAddNewUserPopup] = useState(false);

  const { brandsList } = useSelector((state: any) => state.serviceManagement);
  const { allCustomersList, addNewUserResponse } = useSelector(
    (state: any) => state.auth
  );
  //   const [editorState, setEditorState] = useState(EditorState.createEmpty());
  console.log("brandsLis t", brandsList);
  console.log("allCustomersList", allCustomersList);
  useEffect(() => {
    if (searchValue) {
      const newItems = [] as any;
      dataItemsAll.map((item: any) => {
        if (
          (item?.customerName &&
            item?.customerName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase())) ||
          (item?.mobileNo &&
            item?.mobileNo?.toLowerCase().includes(searchValue?.toLowerCase()))
        ) {
          newItems.push(item);
        }
      });
      setDataItems(newItems);
      console.log("searchValue", searchValue);
    } else {
      setDataItems(dataItemsAll);
    }
  }, [searchValue]);

  // useEffect(() => {
  //   console.log("addNewUserResponse", addNewUserResponse);
  //   if (addNewUserResponse?.id) {
  //     setAddNewPopup(false);
  //     setUserAddSuccess(true);
  //     dispatch(updateAddNewUserResponse({}));
  //     dispatch(getAllCustomersList());
  //   }
  // }, [addNewUserResponse]);

  useEffect(() => {
    //brandsList
    triggerMixpanelEvent(`customers_list_page`);
    getCustomersList();
  }, []);

  useEffect(() => {
    //brandsList
    setDataItems(allCustomersList);
    setDataItemsAll(allCustomersList);
  }, [allCustomersList]);
  const getCustomersList = () => {
    dispatch(getAllCustomersList());
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "30px",
      key: 1,
      render: (item: any, record: any, index: any) => {
        const indexP = index + 1;
        return <>{indexP}</>;
      },
    },
    {
      title: "Customer Name",
      width: "15%",
      key: 1,
      render: (itemD: any) => {
        const uName = `${itemD?.customerName}`;

        return (
          <>
            <span className="table-option-text">{uName?.toLowerCase()}</span>
          </>
        );
      },
    },

    {
      title: "Mobile No",
      width: "110px",
      dataIndex: "mobileNo",
      className: "long-text-word",
      key: 1,
      render: (Options: any) => {
        return (
          <>
            <span className="table-option-text">{Options}</span>
          </>
        );
      },
    },
    {
      title: "Alt Mobile No",
      width: "120px",
      dataIndex: "altMobileNo",
      className: "long-text-word",
      key: 1,
      render: (Options: any) => {
        return (
          <>
            <span className="table-option-text">{Options}</span>
          </>
        );
      },
    },

    {
      title: "Email",
      width: "200px",
      dataIndex: "email",
      className: "long-text-word",
      key: 1,
      render: (username: any) => {
        return (
          <>
            <span className="table-option-text">{username?.toLowerCase()}</span>
          </>
        );
      },
    },
    {
      title: "Address",
      width: "100px",
      dataIndex: "city",
      className: "long-text-word",
      key: 1,
      render: (Options: any) => {
        return (
          <>
            <span className="table-option-text">{Options}</span>
          </>
        );
      },
    },
    // {
    //   title: "Created By",
    //   width: "10%",
    //   dataIndex: "createdBy",
    //   key: 1,
    //   render: (Options: any) => {
    //     return (
    //       <>
    //         <span className="table-option-text">{Options}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "id",
      width: "100px",
      fixed: "right",
      key: 2,
      render: (Ditem: any) => {
        return (
          <>
            <div className="table-actions-btn">
              <span
                onClick={() => {
                  const found = dataItemsAll.find(
                    (element: any) => element?.id === Ditem
                  );
                  console.log("found", found);
                  setSelectedEditData(found);
                  handleAddNewUSer();
                }}
              >
                Edit
              </span>
              <span
                onClick={() => {
                  const found = dataItemsAll.find(
                    (element: any) => element?.id === Ditem
                  );
                  handleDelete(Ditem);
                  setSelectedEditData(found);
                }}
              >
                Delete
              </span>
            </div>
          </>
        );
      },
    },
  ];
  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    setSearchValue(value);
  };
  const cancelPopup = () => {
    setIsConfirmModalOpen(false);
    setIsDeleteModalOpen(false);
    setAddNewPopup(false);
    setUserAddSuccess(false);
  };

  const handleRemove = async () => {
    setIsConfirmModalOpen(false);
    if (selectedEditData?.id) {
      const resp = (await dispatch(
        deleteCustomerApi(selectedEditData?.id)
      )) as any;
      console.log("resp", resp);
      if (resp?.payload?.id) {
        setIsDeleteModalOpen(true);
        getCustomersList();
      }
    }
  };
  const handleDelete = (Ditem: any) => {
    console.log("DitemDitem", Ditem);
    setIsConfirmModalOpen(true);
  };
  const handleAddNewUSer = () => {
    triggerMixpanelEvent(`add_new_customer_click`);
    setAddNewUserPopup(true);
  };
  const handleCloseAddNewUSer = () => {
    setSelectedEditData({});
    setAddNewUserPopup(false);
  };
  const addUserSuccess = (select: any) => {
    handleCloseAddNewUSer();
    triggerMixpanelEvent(`add_new_customer_success`);
    getCustomersList();
    // setShowSuccessPopupUser(true);
    // setUserSelectedInfo(select);
    // const prevValues = { ...jobData };
    // prevValues["customerName"] = select?.customerName;
    // prevValues["mobileNo"] = select?.mobileNo;
    // setJobData(prevValues);
    // const newList = requiredFields;
    // newList["customerName"] = false;
    // newList["mobileNo"] = false;
    // setRequiredFields(newList);
  };
  return (
    <>
      <CustomersListStyled>
        <div>
          <AddNewUser
            addUserSuccess={addUserSuccess}
            show={addNewUserPopup}
            selectedEditData={selectedEditData}
            onHide={handleCloseAddNewUSer}
          />

          <ConfirmationModal
            className="club-confirmation"
            show={isConfirmModalOpen}
            onHide={cancelPopup}
            description="Are you sure, do you want to delete?"
            onConfirmClick={handleRemove}
            confirmLabel="DELETE"
            cancelLabel="CANCEL"
          ></ConfirmationModal>
          <ConfirmationModal
            className="club-confirmation"
            show={isDeleteModalOpen}
            onHide={cancelPopup}
            description="Item Deleted Successfully"
            onConfirmClick={cancelPopup}
            confirmLabel="Okay"
          ></ConfirmationModal>

          <ConfirmationModal
            className="club-confirmation"
            show={userAddSuccess}
            onHide={cancelPopup}
            description="User Added/Updated Successfully."
            onConfirmClick={cancelPopup}
            confirmLabel="Okay"
          ></ConfirmationModal>
          {/* 
          <AddNewOption
            className="club-confirmation"
            selectedData={selectedEditData}
            show={addNewPopup}
            onHide={cancelPopup}
            description="Are you sure, do you want to delete?"
            onConfirmClick={handleRemove}
            {...props}
          /> */}
          <div className="section-container-header">
            <h3>{props?.title}</h3>
            <input
              name="optionSearch"
              value={searchValue}
              onChange={onChangeValue}
              placeholder="Search"
            ></input>
            <span
              onClick={() => {
                handleAddNewUSer();
              }}
            >
              {`Add New Customer`}
            </span>
          </div>
          <div className="section-container-body-table">
            <Table
              columns={columns as any}
              dataSource={dataItems}
              //   pagination={{ pageSize: 5 }}
              scroll={{ y: 540 }}
            />
            {/* <Table
              columns={columns}
              dataSource={dataItems}
              scroll={{ x: 1500, y: 500 }}
            /> */}
          </div>
        </div>
      </CustomersListStyled>
    </>
  );
};
export default CustomersList;
