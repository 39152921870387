import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const ProductList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Products List"
        section="productList"
        subTitle="Add Product"
      />
    </>
  );
};
export default ProductList;
