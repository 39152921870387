import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import CheckJobcardStatusList from "./../Home/CheckJobcardStatusList";
import { CheckJobcardStatusStyled } from "./CheckJobcardStatus.styled";
import about_us from "../../img/about-us.png";
import location_img from "../../img/icons8-location-26.png";
import phone_img from "../../img/icons8-phone-26.png";
import email_img from "../../img/icons8-email-30.png";
import {
  getJobCardDetailsApi,
  getJobCardDetailsWithMobileNoApi,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import GuestJobCardDetails from "./../../pages/JobCardDetails/GuestJobCardDetails";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckJobcardStatus = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [jobId, setJobId] = useState("");
  const [mobileNUmber, setMobileNUmber] = useState("");
  const { guestJobCardDetails } = useSelector(
    (state: any) => state.serviceManagement
  );

  const [showJobCardDetails, setShowJobCardDetails] = useState(false);
  const [jobIdError, setJobIdError] = useState(false);
  const [mobileNUmberError, setMobileNUmberError] = useState(false);
  const query = useQuery();
  const jcardno = query.get("jcardno");
  const mno = query.get("mno");

  // const { allProducts } = useSelector(
  //   (MainROute: any) => MainROute?.productCart
  // );
  useEffect(() => {
    triggerMixpanelEvent("CheckJobcardStatus");
    getJobCardDetailsCall();
  }, []);
  const onChangeValue = (e: any) => {
    const { value, name } = e.target;
    const value1 = value;
    if (name === "jobId") {
      setJobId(value);
      setJobIdError(false);
    } else {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setMobileNUmber(value);
        setMobileNUmberError(false);
      }
    }
  };
  const handleCheckJobcardStatus = () => {
    let returnF = true;
    if (!mobileNUmber || mobileNUmber?.length < 10) {
      setMobileNUmberError(true);
      returnF = false;
    }
    if (!jobId) {
      setJobIdError(true);
      returnF = false;
    }
    if (returnF && jobId && mobileNUmber) {
      getJobCardDetailsCall();
    }
  };
  const getJobCardDetailsCall = () => {
    setShowJobCardDetails(true);
    const body = { jcardno, mno };
    dispatch(getJobCardDetailsWithMobileNoApi(body));
  };
  return (
    <CheckJobcardStatusStyled>
      <div className="middle-section">
        <div>
          {/* <div className="job-header">
            <h2>your Job Card Status</h2>
          </div> */}
          <div className="job-header-body">
            {/*<div>  <div>
                <div className="row">
                  <div className="col-sm-4 fields-sec-input-box">
                    <Form.Group>
                      <Form.Label className="requiredField">
                        Job Card Id <span className="manditary">*</span>
                      </Form.Label>
                      <Form.Control
                        maxLength={20}
                        isInvalid={jobIdError}
                        value={jobId}
                        type="text"
                        name="jobId"
                        id="jobId"
                        //   disabled={isReadOnly}
                        onChange={onChangeValue}
                      />
                      <Form.Control.Feedback type="invalid">
                        {jobIdError ? (
                          <>Please Enter Valid Job Card ID</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-sm-4 fields-sec-input-box">
                    <Form.Group>
                      <Form.Label className="requiredField">
                        Mobile Number <span className="manditary">*</span>
                      </Form.Label>
                      <Form.Control
                        maxLength={10}
                        isInvalid={mobileNUmberError}
                        value={mobileNUmber}
                        type="text"
                        name="mobileNUmber"
                        id="mobileNUmber"
                        //   disabled={isReadOnly}
                        onChange={onChangeValue}
                      />
                      <Form.Control.Feedback type="invalid">
                        {mobileNUmberError ? (
                          <>Please Enter Valid Mobile Number</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-sm-4 submit-btn-sec">
                    <button onClick={handleCheckJobcardStatus}>Submit</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div>
          {guestJobCardDetails?.id ? (
            <>
              {showJobCardDetails && (
                <GuestJobCardDetails pageFrom={"checkStatus"} />
              )}
            </>
          ) : (
            <>No Records Found.</>
          )}
        </div>
      </div>
    </CheckJobcardStatusStyled>
  );
};
export default CheckJobcardStatus;
