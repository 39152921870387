import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const EngineerObservationList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Engineer Observation List"
        section="engineerObservationList"
        subTitle="Create Engineer Observation Option"
      />
    </>
  );
};
export default EngineerObservationList;
