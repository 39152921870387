import styled from "styled-components";

export const DashboardStyled = styled.div`
  .box-all-rows {
    display: block;
    display: flow-root;
  }
  .box-all-rows .box-row {
    border: 1px solid #000;
    margin-left: 1.5%;
    // padding: 10px;
    width: 18%;
    float: left;
    height: 110px;
    margin-top: 10px;
    cursor: pointer;
    // border-left: 0px;
    padding: 0 5px;
    text-align: center;
    border-radius: 10px;
  }
  .profile-warning-message-text {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fae0e4;
    border-radius: 4px;
    margin-bottom: 30px;
    p {
      font-size: 24px;
      padding-top: 7px;
    }
    button {
      background: #000;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 5px 20px;
      font-size: 22px;
      cursor: pointer;
    }
  }
  .view-all-btn {
    text-align: center;
    margin: 30px 0;
  }
  .box-all-rows .box-row:hover {
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);
  }
  .box-row p:nth-child(1) {
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #1c69da;
  }
  .box-row p:nth-child(2) {
    padding: 10px;
    margin: 0;
    font-size: 34px;
    font-weight: 600;
    line-height: 32px;
  }
  .box-all-rows .red-color p {
    color: #f00816;
  }
  .box-all-rows .green-color p {
    color: #008000;
  }
  .box-all-rows .black-color p {
    color: #000;
  }
  .box-all-rows .yellow-color p {
    color: #ffc300;
  }
  .box-all-rows .orange-color p {
    color: #f89a05;
  }
`;
