import { UsersListStyled } from "./UsersList.styled";
import { Table } from "antd";
import { useEffect, useState } from "react";
import AddNewOption from "./AddNewOption";
import ChangePassword from "./ChangePassword";
import type { ColumnsType } from "antd/es/table";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersList,
  deleteUserApi,
} from "../../redux/slices/auth/authService";
import { updateAddNewUserResponse } from "./../../redux/slices/auth/authSlice";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const UsersList = (props: any) => {
  const [searchValue, setSearchValue] = useState("");
  // const [dataSource, setDataSource] = useState(dataSource1);
  const [addNewPopup, setAddNewPopup] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userAddSuccess, setUserAddSuccess] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [tempDeleteData, setTempDeleteData] = useState({});
  const [dataItemsAll, setDataItemsAll] = useState([] as any);
  const [dataItems, setDataItems] = useState([] as any);
  const [selectedEditData, setSelectedEditData] = useState({} as any);
  const dispatch = useDispatch();
  const { brandsList } = useSelector((state: any) => state.serviceManagement);
  const { allUsersList, addNewUserResponse } = useSelector(
    (state: any) => state.auth
  );
  //   const [editorState, setEditorState] = useState(EditorState.createEmpty());
  console.log("brandsLis t", brandsList);
  console.log("dataItemsAll", dataItemsAll);
  useEffect(() => {
    if (searchValue) {
      const newItems = [] as any;
      dataItemsAll.map((item: any) => {
        if (
          item?.firstName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          item?.lastName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          item?.mobileNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          item?.username?.toLowerCase().includes(searchValue?.toLowerCase())
        ) {
          newItems.push(item);
        }
      });
      setDataItems(newItems);
      console.log("searchValue", searchValue);
    } else {
      setDataItems(dataItemsAll);
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("addNewUserResponse", addNewUserResponse);
    if (addNewUserResponse?.id) {
      triggerMixpanelEvent(`add_new_user_success`, addNewUserResponse);
      setAddNewPopup(false);
      setUserAddSuccess(true);
      dispatch(updateAddNewUserResponse({}));
      dispatch(getAllUsersList());
    }
  }, [addNewUserResponse]);

  useEffect(() => {
    triggerMixpanelEvent(`users_list_pag  e`);
    //brandsList
    dispatch(getAllUsersList());
  }, []);
  const addUserSuccess = (id: any) => {
    dispatch(getAllUsersList());
    setUserAddSuccess(true);
  };
  const addPasswordSuccess = (id: any) => {
    // dispatch(getAllUsersList());
    setUserAddSuccess(true);
    cancelPopup();
  };

  const handleChangePassword = (id: any) => {
    // dispatch(getAllUsersList());
    // setUserAddSuccess(true);
  };
  useEffect(() => {
    //brandsList
    setDataItems(allUsersList);
    setDataItemsAll(allUsersList);
  }, [allUsersList]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "30px",
      key: 1,
      render: (item: any, record: any, index: any) => {
        const indexP = index + 1;
        return <>{indexP}</>;
      },
    },
    {
      title: "Name",
      width: "150px",
      key: 1,
      render: (itemD: any) => {
        const uName = `${itemD?.firstName} ${itemD?.lastName}`;

        return (
          <>
            <span className="table-option-text">{uName?.toLowerCase()}</span>
          </>
        );
      },
    },
    {
      title: "Email",
      width: "180px",
      dataIndex: "username",
      className: "long-text-word",
      key: 1,
      render: (username: any) => {
        return (
          <>
            <span className="table-option-text">{username?.toLowerCase()}</span>
          </>
        );
      },
    },
    {
      title: "Mobile No",
      width: "90px",
      dataIndex: "mobileNo",
      className: "long-text-word",
      key: 1,
      render: (Options: any) => {
        return (
          <>
            <span className="table-option-text">{Options}</span>
          </>
        );
      },
    },
    {
      title: "Role",
      width: "100px",
      dataIndex: "userType",
      className: "long-text-word",
      key: 1,
      render: (Options: any) => {
        return (
          <>
            <span className="table-option-text">{Options}</span>
          </>
        );
      },
    },
    // {
    //   title: "Created By",
    //   width: "10%",
    //   dataIndex: "createdBy",
    //   key: 1,
    //   render: (Options: any) => {
    //     return (
    //       <>
    //         <span className="table-option-text">{Options}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      // dataIndex: "id",
      width: "120px",
      fixed: "right",
      key: 2,
      render: (Ditem: any) => {
        console.log("Ditem", Ditem);
        return (
          <>
            <div className="table-actions-btn">
              <span
                onClick={() => {
                  setAddNewPopup(true);
                  const found = dataItemsAll.find(
                    (element: any) => element?.id === Ditem?.id
                  );
                  console.log("found", found);
                  setSelectedEditData(found);
                }}
              >
                Edit
              </span>
              {Ditem?.userType !== "STORE_OWNER" && (
                <span
                  onClick={() => {
                    const found = dataItemsAll.find(
                      (element: any) => element?.id === Ditem?.id
                    );
                    handleDelete(Ditem?.id);
                    setSelectedEditData(found);
                  }}
                >
                  Delete
                </span>
              )}
              {Ditem?.userType !== "STORE_OWNER" && (
                <span
                  onClick={() => {
                    const found = dataItemsAll.find(
                      (element: any) => element?.id === Ditem?.id
                    );
                    handleChangePassword(Ditem?.id);
                    setShowChangePassword(true);
                    setSelectedEditData(found);
                  }}
                >
                  C.P.
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];
  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    setSearchValue(value);
  };
  const cancelPopup = () => {
    setIsConfirmModalOpen(false);
    setIsDeleteModalOpen(false);
    setAddNewPopup(false);
    setUserAddSuccess(false);
    setShowChangePassword(false);
  };

  const handleRemove = async () => {
    setIsConfirmModalOpen(false);
    if (selectedEditData?.id) {
      const resp = (await dispatch(deleteUserApi(selectedEditData?.id))) as any;
      console.log("resp", resp);
      if (resp?.payload?.id) {
        setIsDeleteModalOpen(true);
        dispatch(getAllUsersList());
      }
    }
  };
  const handleDelete = (Ditem: any) => {
    console.log("DitemDitem", Ditem);
    setIsConfirmModalOpen(true);
  };
  console.log("showChangePassword", showChangePassword);
  return (
    <>
      <UsersListStyled>
        <div>
          <ConfirmationModal
            className="club-confirmation"
            show={isConfirmModalOpen}
            onHide={cancelPopup}
            description="Are you sure, do you want to delete?"
            onConfirmClick={handleRemove}
            confirmLabel="DELETE"
            cancelLabel="CANCEL"
          ></ConfirmationModal>
          <ConfirmationModal
            className="club-confirmation"
            show={isDeleteModalOpen}
            onHide={cancelPopup}
            description="Item Deleted Successfully"
            onConfirmClick={cancelPopup}
            confirmLabel="Okay"
          ></ConfirmationModal>

          <ConfirmationModal
            className="club-confirmation"
            show={userAddSuccess}
            onHide={cancelPopup}
            description="User Added/Updated Successfully."
            onConfirmClick={cancelPopup}
            confirmLabel="Okay"
          ></ConfirmationModal>

          <AddNewOption
            className="club-confirmation"
            selectedData={selectedEditData}
            show={addNewPopup}
            onHide={cancelPopup}
            description="Are you sure, do you want to delete?"
            onConfirmClick={handleRemove}
            {...props}
            addUserSuccess={addUserSuccess}
          />
          <ChangePassword
            selectedData={selectedEditData}
            show={showChangePassword}
            onHide={cancelPopup}
            addPasswordSuccess={addPasswordSuccess}
          />
          <div className="section-container-header">
            <h3>{props?.title}</h3>
            <input
              name="optionSearch"
              value={searchValue}
              onChange={onChangeValue}
              placeholder="Search"
            ></input>
            <span
              onClick={() => {
                setSelectedEditData({});
                triggerMixpanelEvent(`add_new_user_click`);
                setAddNewPopup(true);
              }}
            >
              {`Add New User`}
            </span>
          </div>
          <div className="section-container-body-table section-container-body-table1">
            <Table
              columns={columns as any}
              dataSource={dataItems}
              //   pagination={{ pageSize: 5 }}
              scroll={{ y: 540 }}
            />
            {/* <Table
              columns={columns}
              dataSource={dataItems}
              scroll={{ x: 1500, y: 500 }}
            /> */}
          </div>
        </div>
      </UsersListStyled>
    </>
  );
};
export default UsersList;
