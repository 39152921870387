import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";
import CloseButton from "react-bootstrap/CloseButton";

const ImageViewModel = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`modalBodyDefault alert-model-popup-sec image-view-model-cls`}
      >
        <div className="new-option-header">
          <h4>{``}</h4>
          <CloseButton onClick={props?.onHide} />
        </div>

        <div className="image-view-model-cls-contect">
          <img src={props?.url} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageViewModel;
