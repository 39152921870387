import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, del } from "../../../library/Requests/helpers";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  // async (body:any) => {
  //   return await post(`${SERVER_IP}/auth/login`, body);
  // }
  async (body: any) => await post(`${SERVER_IP}/auth/signin`, body)
);

export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (body: any) => await post(`${SERVER_IP}/addStore`, body)
);

export const addNewUser = createAsyncThunk(
  "auth/addNewUser",
  async (body: any) => await post(`${SERVER_IP}/addCustomer `, body)
);
export const addNewUserData = createAsyncThunk(
  "auth/addNewUserData",
  async (body: any) => await post(`${SERVER_IP}/addUser `, body)
);

export const updateCustomerData = createAsyncThunk(
  "auth/updateCustomerData",
  async (body: any) => await put(`${SERVER_IP}/updateCustomer `, body)
);

export const updateUserDetails = createAsyncThunk(
  "auth/updateUserDetails",
  async (body: any) => await post(`${SERVER_IP}/updateProfile`, body)
);
export const getAllUsersList = createAsyncThunk(
  "auth/getAllUsersList",
  async () => await get(`${SERVER_IP}/users`)
);
export const getAllCustomersList = createAsyncThunk(
  "auth/getAllCustomersList",
  async () => await get(`${SERVER_IP}/customerList`)
);

export const getCustomerDetails = createAsyncThunk(
  "auth/getCustomerDetails",
  async (id: any) => await get(`${SERVER_IP}/getCustomer/${id}`)
);

export const getStoreDetails = createAsyncThunk(
  "auth/getStoreDetails",
  async (id: any) => await get(`${SERVER_IP}/getProfile/${id}`)
);
export const getCustomerSearch = createAsyncThunk(
  "auth/getCustomerSearch",
  async (search: any) => await get(`${SERVER_IP}/customerSearch/${search}`)
);
export const deleteUserApi = createAsyncThunk(
  "auth/deleteUserApi",
  async (id: any) => await get(`${SERVER_IP}/deleteuser/${id}`)
);
export const deleteCustomerApi = createAsyncThunk(
  "auth/deleteCustomerApi",
  async (id: any) => await del(`${SERVER_IP}/deletecustomer/${id}`)
);

export const updateUserPasswordApi = createAsyncThunk(
  "auth/updateUserPasswordApi",
  async (body: any) => await put(`${SERVER_IP}/updatePasswordFromAdmin`, body)
);
export const updatePasswordApi = createAsyncThunk(
  "auth/updatePasswordApi",
  async (body: any) => await put(`${SERVER_IP}/updatePassword`, body)
);
export const createPrintSettingApi = createAsyncThunk(
  "auth/createPrintSettingApi",
  async (body: any) => await post(`${SERVER_IP}/createPrintSetting `, body)
);
export const updatePrintSettingApi = createAsyncThunk(
  "auth/updatePrintSettingApi",
  async (body: any) => await put(`${SERVER_IP}/updatePrintSetting `, body)
);

export const getPrintSettingByIdApi = createAsyncThunk(
  "auth/getPrintSettingByIdApi",
  async (id?: any) => await get(`${SERVER_IP}/getPrintSettingByStoreId `)
);
export const forgotPasswordSendToken = createAsyncThunk(
  "auth/forgotPasswordSendToken",
  async (body: any) => await post(`${SERVER_IP}/user/resetPassword `, body)
);
export const forgotPasswordValidateToken = createAsyncThunk(
  "auth/forgotPasswordValidateToken",
  async (body: any) => await post(`${SERVER_IP}/user/savePassword `, body)
);

