import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createLeadApi,
  getFilteredjLeadsummaryApi,
  paymentmodelistApi,
  configurationlistApi,
  editconfigurationApi,
  getleadstatuslistApi,
  leadactiontakenlistApi,
  getLeadDetailsById,
  getfilteredleadsummaryApi,
} from "./leadManagementService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  allLeads: any;
  allLeadsData: any;
  paymentModesList: any;
  configurationList: any;
  leadStatusList: any;
  leadActionTakenList: any;
  editconfigurationDetails: any;
  leadDetails: any;
}
const initialState: leadManagementState = {
  loading: false,
  error: null,
  allLeads: [],
  allLeadsData: {},
  paymentModesList: [],
  configurationList: [],
  editconfigurationDetails: {},
  leadStatusList: [],
  leadActionTakenList: [],
  leadDetails: {},
};

export const leadManagementSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getFilteredjLeadsummaryApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getFilteredjLeadsummaryApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allLeads = action.payload;
      }
    );
    builder.addCase(
      getFilteredjLeadsummaryApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allLeads = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //getfilteredleadsummaryApi
    builder.addCase(getfilteredleadsummaryApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getfilteredleadsummaryApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.allLeadsData = action.payload;
      }
    );
    builder.addCase(
      getfilteredleadsummaryApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //getDashboards
    builder.addCase(paymentmodelistApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      paymentmodelistApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.paymentModesList = action.payload;
      }
    );
    builder.addCase(
      paymentmodelistApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //configurationList
    builder.addCase(configurationlistApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      configurationlistApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.configurationList = action.payload;
      }
    );
    builder.addCase(
      configurationlistApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //editconfigurationApi
    builder.addCase(editconfigurationApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      editconfigurationApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.editconfigurationDetails = action.payload;
      }
    );
    builder.addCase(
      editconfigurationApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getleadstatuslistApi
    builder.addCase(getleadstatuslistApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getleadstatuslistApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.leadStatusList = action.payload;
      }
    );
    builder.addCase(
      getleadstatuslistApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //leadactiontakenlistApi
    builder.addCase(leadactiontakenlistApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      leadactiontakenlistApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.leadActionTakenList = action.payload;
      }
    );
    builder.addCase(
      leadactiontakenlistApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getLeadDetailsById

    builder.addCase(getLeadDetailsById.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getLeadDetailsById.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.leadDetails = action.payload;
      }
    );
    builder.addCase(
      getLeadDetailsById.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = leadManagementSlice.actions;

export default leadManagementSlice.reducer;
