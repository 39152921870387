import React, { useEffect, useState } from "react";
import { TermsOfServiceStyled } from "./TermsOfService.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const TermsOfService = () => {
  useEffect(() => {}, []);

  return (
    <>
      <TermsOfServiceStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div className="header-text-p">
              <h3>Terms and Conditions</h3>
              <p>
                Rasko understands the importance of protecting the privacy of
                its customers. Our Privacy Policy has been updated to give you a
                better understanding of how we treat your data and also to call
                your attention to new features. Please review this Privacy
                Policy and the manner in which the information shall be
                utilized. The personal information so collected is used to
                market, study, analyse, process, and improve the services
                offered by us and our subsidiaries/associates and also to
                contact you with updates to the web site and for other marketing
                and internal business analytical / review purposes.
              </p>
            </div>
            <div>
              <p className="bold-p">
                For more information on this Privacy Policy, please read below.
              </p>
              <p className="normal-p">
                The Rasko web server uses an extended log file format which
                captures: date and time of visit, referring address (location
                from which a visitor comes to Rasko), type of Internet browser,
                and visitor's IP address (Each computer that connects to the
                Internet is assigned a unique number, an IP address, for
                identification purposes). The log file does not capture a
                visitor's email address.
              </p>
              <p className="bold-p">Does Rasko ask for personal information?</p>
              <p className="normal-p">
                No, Rasko does not mandatorily ask for your personal
                information. There are pages by which a user can contact Rasko
                and buy products but sharing personal information is entirely at
                the discretion of the user and thus at the sole risk of the
                user/customer. We need your information to register your details
                with personal information for login account purposes only as
                Rasko does not ask for or store any financial information of any
                party and sharing of personal information during the
                registration process is to contact you for updates, new products
                range and offerings which are a choice of the customer. However,
                by providing personal information to us when raising request to
                create or update your account and profile, you are expressly and
                voluntarily accepting the terms and conditions of Rasko user
                agreement and freely accepting and agreeing to our processing of
                your personal information in ways set out by this Privacy Policy
                or as amended from time to time.
              </p>
              <p className="bold-p">How does Rasko use personal information?</p>
              <p className="normal-p">
                The information collected through this site helps us identify
                the type of web site content our customers value most. We use
                this information to market and improve the web site and our
                services. We also use this information to send you
                emailers/notifications/SMS for various purposes including but
                not limited to information on new products, best sellers, new
                offers, order processing etc. and to contact you by other means
                for marketing and other purposes. Our policy is to not give,
                sell, or otherwise distribute the information collected through
                this site to third parties outside of Rasko and its
                subsidiaries/associates (unless required by law).
              </p>
              <p className="bold-p">
                How is information protection made at the merchant’s website?
              </p>
              <p className="normal-p">
                By availing of the goods and services from our website, you
                understand that there is some risk involve during transmitting
                to another vendor/bank websites for making necessary payment,
                and all risk attached thereon to the transaction is understood
                and acknowledged by you. Further, each third party vis-à-vis
                banks, vendor websites, have their own privacy statements that
                can be viewed by clicking on the corresponding links within each
                of the respective websites. Online merchants, payment gateways,
                and others who participate in our services are encouraged to
                participate in industry privacy initiatives and to take a
                responsible attitude towards consumer privacy. However, since we
                do not have direct control over the policies or practices of
                participating merchants and other third parties, we are not
                responsible for the privacy practices or contents of those sites
                and breach of any 'sensitive' information causing any loss,
                damage to you (direct or indirect) is solely at your risk and
                Rasko shall not be a party to such fraudulent practices of any
                third party vendor. We recommend and encourage that you always
                review the privacy policies of merchants and other third parties
                before you provide any personal information or complete any
                transaction with any third parties.
              </p>
              <p className="bold-p">
                How long the collected information is retained?
              </p>
              <p className="normal-p">
                Rasko keeps your information for as long as your account is
                active or as needed. However, we may keep certain information
                even after you close your account if it is necessary to comply
                with our legal obligations, meet regulatory requirements,
                resolve disputes, prevent fraud and abuse, or enforce this
                agreement.
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </TermsOfServiceStyled>
    </>
  );
};

export default TermsOfService;
