import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const BrandsList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Brands List"
        section="brandsList"
        subTitle="Add Brand"
      />
    </>
  );
};
export default BrandsList;
