import styled from "styled-components";

export const JobcardSummaryStyled = styled.div`
  .jobcard-content {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    tr th {
      line-height: 17px;
      padding: 5px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      background: #26bb6a;
      background: #7190a9;
      text-align: center;
    }
    tr td {
      font-size: 16px;
      line-height: 18px;
      padding: 5px;
    }
  }
  .last-days-sec {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      border: 1px solid #e2e2e2;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
      background: rgb(217 233 251);
      font-size: 14px;
    }
    > div.active {
      background: #000;
      color: #fff;
    }
  }
  .line-reports-status-view-sec {
    width: 100%;
  }
  .ant-table-tbody tr:nth-child(odd) , .ant-table-tbody tr:nth-child(odd): hover, .ant-table-tbody tr:nth-child(odd) td, .ant-table-tbody tr:nth-child(odd):hover {
    background: #c9f4f4;
  }
  .search-row-sec {
    display: flex;
  }
  .job-summary-pagination {
    margin-top: 10px;
    text-align: center;
  }
  .quick-search-section-list {
    margin-bottom: 10px;
    .accordion-button {
      padding: 9px 20px;
    }
  }
  .quick-search-section-list .form-group .form-label {
    margin-bottom: 1px;
  }
  .section-container-header-new {
    height: 35px;
    padding: 2px;
    font-size: 20px;
    background: #e2e2e2;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px 20px;
  }
  .middle-line-space-sec {
    width: 20px;
    display: inline-block;
    opacity: 0.5;
  }
  .section-container-header-new-search {
    cursor: pointer;
  }
  .section-container-header-new-search img {
    height: 20px;
    margin-left: 20px;
  }
  .profile-warning-message-text {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fae0e4;
    border-radius: 4px;
    margin-bottom: 30px;
    p {
      font-size: 24px;
      padding-top: 7px;
    }
    button {
      background: #000;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 5px 20px;
      font-size: 22px;
      cursor: pointer;
    }
  }
  .profile-warning-message-text {
  }
  .hover-menu-item-sec {
    font-size: 43px;
    cursor: pointer;
  }
  .search-row-sec .search-row-select {
    width: 40%;
    margin-top: -37px;
  }
  .search-row-sec .search-row-search {
    width: 58%;
  }
  .drop-down-single-item-sec {
    font-size: 20px;
  }
  .filter-action-sec {
    margin: 10px 0;
    padding: 0;
  }
  .filter-actions-btn {
    padding-left: 25px;
  }
  .search-row-select fieldset {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .search-row-search input {
    height: 40px;
    width: 100%;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    border-right: 1px solid rgba(0, 0, 0, 0.23);
    padding-left: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .filter-actions-btn,
  .top-sec-filter {
    display: flex;
  }
  .download-icon {
    svg {
      height: 35px;
      width: 35px;
    }
    span {
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .jobcard-content tr td {
    text-transform: capitalize;
    text-align: center;
    word-wrap: break-word;
  }
  .clear-filters-sec {
    font-size: 17px;
    margin-left: 30px;
    border: 1px solid #e2e2e2;
    padding: 5px 10px;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
  }
  .quick-search-section-list .date-range-selector > div,
  .quick-search-section-list .form-group .col-form-label,
  .quick-search-section-list .form-group .form-label {
    font-size: 18px;
    color: rgb(11, 102, 178);
  }
  .date-select-range-sec {
    // padding: 0;
    margin-top: 8px !important;
  }
  .date-select-range-sec button {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    font-size: 20px;
    background: #fff;
    width: 100%;
    margin-top: 8px;
  }
  .view-job-card {
    cursor: pointer;
  }
  .select__menu {
    z-index: 999999;
  }
  .select__menu *,
  .select__single-value {
    text-transform: capitalize;
  }
  .select-dropdown-filters {
    // top: 15px;
  }
  .table-actions-btn svg {
    height: 25px;
    width: 25px;
    margin: 8px;
  }
  .page-sec-header {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .assigned-to-sec1 select {
    border-radius: 50px;
    padding: 0px 5px;
  }
  .row_selected_priority_High .priority_Select_P select {
    background: #f82b60 !important;
    padding: 5px 7px;
    border-radius: 50px;
    font-size: 14px;
    width: 100%;
    display: block;
  }
  .row_selected_priority_Medium .priority_Select_P select {
    background: #fcb401 !important;
    padding: 5px 7px;
    border-radius: 50px;
    font-size: 14px;
    width: 100%;
    display: block;
  }

  .row_selected_priority_Low .priority_Select_P select {
    background: #19c0ff !important;
    padding: 5px 7px;
    border-radius: 50px;
    font-size: 14px;
    width: 100%;
    display: block;
  }
  .priority_Select_High:has(td) {
    border: solid 3px blue;
  }
  .range-date-fields {
    input {
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      height: 40px;
      padding-left: 10px;
    }
    .react-datepicker-wrapper,
    input {
      width: 100%;
    }
    > div {
      padding-right: 0px;
    }
    .col-form-label {
      font-size: 18px;
      color: rgb(11, 102, 178);
    }
  }
  .section-container-header-new-new {
    list-style-type: none;
    justify-content: center;
    height: 50px;
    display: flex;
    padding-top: 10px;
    ul {
      // justify-content: center;
      display: flex;
      height: 40px;
      list-style-type: none;
    }
    li {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 5px;
      cursor: pointer;
    }
    .selected-tab {
      color: #4682b4;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-weight: 600;
      background: #4682b4;
      color: #fff;
      border: 1px solid #4682b4;
    }
  }
  .menu-items-list {
    padding: 0;
    margin-top: -1rem;
    position: absolute;
    z-index: 5346565465;
    width: 89%;
    ul {
      max-height: 250px;
      overflow: scroll;
    }
    li {
      cursor: pointer;
      background: #4682b4;
      color: #fff;
      border-color: #fff;
    }
  }
  @media (max-width: 700px) {
    .section-container-header-new-new li {
      width: max-content;
      clear: both;
      display: inline-block;
      // overflow: hidden;
      white-space: nowrap;
    }
    .section-container-header-new-new ul {
      overflow: scroll;
      overflow-y: scroll;
      padding-left: 0px;
    }
    .quick-search-section-list .accordion-body > .row {
      // display: grid;
      // grid-auto-columns: 1fr 1fr;
      gap: 4%;
      > div {
        width: 46% !important;
        padding: 0px;
      }
    }
  }
`;
