import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const CustomerNoteList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Customer Note List"
        section="customerNoteList"
        subTitle="Create Customer Note Option"
      />
    </>
  );
};
export default CustomerNoteList;
