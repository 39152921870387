import React, { useState } from "react";
// import "./styles.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import "./datepickerstyle.css";
export default function DatePicker(props) {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    const stateDate = moment(picker.startDate._d).format("YYYY-MM-DD");
    const enddDate = moment(picker.endDate._d).format("YYYY-MM-DD");
    props?.handleDateChange(stateDate, enddDate);
  };
  return (
    <div className="date-range-selector">
      <div>Select Date Range</div>
      <DateRangePicker
        // startDate={new Date()}
        // endDate={new Date()}
        ranges={range}
        alwaysShowCalendars={true}
        onEvent={handleEvent}
      >
        <button>
          {moment(fromDate).format("DD/MM/YYYY")} to{" "}
          {moment(toDate).format("DD/MM/YYYY")}
        </button>
      </DateRangePicker>
    </div>
  );
}
