import React, { useState, useEffect } from "react";
import CommonReportData from "./CommonReportData";
const ProductorWise = () => {
  useEffect(() => {
    console.log("useEffect");
    getAllData();
  }, []);
  const getAllData = () => {
    // const options = {
    //   method: "GET",
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers": "*",
    //     accept: "text/plain",
    //     authorization:
    //       "Basic dmlub2QuYmJmYmM4Lm1wLXNlcnZpY2UtYWNjb3VudDpreXk2RHZGOTdMb05QOERTOG0wUDQwSTU2bjhQUk12VA==",
    //   },
    // };
    // fetch(
    //   "https://data.mixpanel.com/api/2.0/export?project_id=3085489&from_date=2023-07-15&to_date=2023-09-15&event=%5B%22create_job_card_success%22%5D&where=properties%5B%22storeId%22%5D%20in%20%5B%22SEP00001%22%5D%09",
    //   options
    // )
    //   .then((response) => response.json())
    //   .then((response) => console.log(response))
    //   .catch((err) => console.error(err));
    // fetch('https://data.mixpanel.com/api/2.0/export?project_id=3085489&from_date=2023-07-15&to_date=2023-09-15', options)
    // .then(response => response.json())
    // .then(response => console.log(response))
    // .catch(err => console.error(err));
  };

  return (
    <>
      <CommonReportData secName="product" />
    </>
  );
};
export default ProductorWise;
