import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
// import "./styles.css";

const UserRejectPopup = (props) => {
  const [selectedTExt, setSelectedTExt] = useState("");

  const handleValuesSelect = (value) => {
    setSelectedTExt(value);
    console.log(value);
  };
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="registertoBidText">
        <div>
          <p className="registertoBidHead">
            {"Mention the reason for Rejection"}
          </p>
        </div>
        <div>
          <Form.Control
            as="textarea"
            className="textArea rejectTextarea"
            rows="6"
            onChange={(e) => {
              handleValuesSelect(e.target.value);
            }}
            placeholder="write here..."
          />
        </div>
        <div></div>

        <div className="dividerLineBid"></div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onCancel} className="modalCandelBtn">
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onDelete(selectedTExt);
          }}
          className="modalDeleteBtn"
        >
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserRejectPopup;
