import CommonDynamicComponent from "./CommonDynamicComponent/CommonDynamicComponent";
const DefaultIssuesOptionList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Default Issues Options"
        section="defaultIssuesOptionList"
        subTitle="Create New Issue Option"
      />
    </>
  );
};
export default DefaultIssuesOptionList;
