import React, { useState, useEffect } from "react";
import { Modal, Button, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "./ForgotPasswordModel.styled.tsx";
import CloseButton from "react-bootstrap/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import email_sent from "../../img/sent-image122.png";
import tick_icon from "../../img/tick_icon.png";

import { ForgotPasswordModelStyled } from "./ForgotPasswordModel.styled.tsx";
import {
  forgotPasswordSendToken,
  forgotPasswordValidateToken,
} from "../../redux/slices/auth/authService";

const ForgotPasswordModel = (props) => {
  const { resetEmail, resetCode } = props;
  console.log("props", props);
  const [storeEmail, SetStoreEmail] = useState("");
  const [storeEmailError, SetStoreEmailError] = useState(false);
  const history = useHistory();
  const [showSection, setShowSection] = useState(""); //emailSent
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [passError, setPassError] = useState(null);

  // const [showSuccessUserPopup, setShowSuccessUserPopup] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.show) {
      if (resetEmail && resetCode) {
        setShowSection("enterPassword");
      } else {
        setShowSection("");
      }
    }
  }, [props?.show, resetEmail, resetCode]);
  const handleChangeValue = (e) => {
    setPassError("");
    // SetStoreEmailError();
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    if (name == "email") {
      SetStoreEmail(value1);
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        SetStoreEmailError(false);
      } else {
        SetStoreEmailError(true);
      }
    } else if (name == "password") {
      setPassword(value1);
    } else if (name == "conformPassword") {
      setConformPassword(value1);
    }
  };
  const handleResetPassword = async () => {
    if (!isLoading) {
      const body = {
        email: storeEmail,
      };
      setIsLoading(true);
      const resp = await dispatch(forgotPasswordSendToken(body));
      setIsLoading(false);
      console.log("respresp", resp);
      setShowSection("emailSent");
    }
  };
  const handleResertPassValidate = async () => {
    if (isPassValidateFn()) {
      if (!isLoading) {
        const body = {
          email: "tanavinod5+1@gmail.com",
          token: resetCode,
          newPassword: password,
        };
        setIsLoading(true);
        const resp = await dispatch(forgotPasswordValidateToken(body));
        setIsLoading(false);
        console.log("respresp", resp);
        setShowSection("passwordSuccess");
      }
    }
  };
  const isPassValidateFn = () => {
    var isValid = true;
    if (password !== conformPassword) {
      isValid = false;
      setPassError(
        "Password and Confirm Password fields value must be matched."
      );
    } else if (password?.length < 6) {
      isValid = false;
      setPassError("Password requires 6 characters minimum.");
    }
    return isValid;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter "
      className="forgot-pass-model-sec"
      centered
    >
      <Modal.Body className={`modalBodyDefault alert-model-popup-sec `}>
        <ForgotPasswordModelStyled>
          <div className="for-got-pass-sec-cont">
            <div className="new-option-header">
              {showSection === "" || showSection === "enterPassword" ? (
                <h4>{`Reset your password `}</h4>
              ) : (
                <h4></h4>
              )}
              {showSection !== "enterPassword" && (
                <CloseButton onClick={props?.onHide} />
              )}
            </div>
            {showSection === "" ? (
              <div className="main-content-enter-email">
                <p className="email-sub-header">
                  Enter your registered email below to receive password reset
                  link.
                </p>

                <div className="remember-cont-sec">
                  <div className="col-sm-12qqqq mt-15 box-login-sec">
                    <Form.Group className="signup-md-left11" size="lg">
                      <Form.Label>
                        Business Email{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email Address"
                        id="storeEmail"
                        isInvalid={storeEmailError}
                        value={storeEmail}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {storeEmailError ? (
                          <>{`Please enter valid Email!`}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="action-sec-pass">
                    <div>
                      <button
                        disabled={storeEmailError || isLoading}
                        onClick={handleResetPassword}
                      >
                        {isLoading ? "Loading" : "Reset Password"}
                      </button>
                    </div>
                    <div className="remember-pass-action">
                      Remember Password? <span>Log In</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : showSection === "enterPassword" ? (
              <>
                <div className="enter-pass-sec-content">
                  <div className="password-fields-sec">
                    <Row>
                      <Form.Group
                        className="signup-md222"
                        size="lg"
                        controlId="password"
                      >
                        <Form.Label>
                          Password<span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          isInvalid={passwordError}
                          placeholder="Enter New Password"
                          value={password}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {passwordError ? (
                            <>Please Enter Valid Password</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="signup-md222"
                        size="lg"
                        controlId="password"
                      >
                        <Form.Label>
                          Confirm Password
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm New Password"
                          name="conformPassword"
                          value={conformPassword}
                          onChange={(e) => handleChangeValue(e)}
                        />
                      </Form.Group>
                    </Row>
                  </div>
                  {passError && <p className="errorMessage">{passError}</p>}

                  <div className="reset-pass-action">
                    <button onClick={handleResertPassValidate}>
                      {isLoading ? "Loading" : "Reset Password"}
                    </button>
                  </div>
                </div>
              </>
            ) : showSection === "passwordSuccess" ? (
              <>
                <div className="show-succ-content show-succ-content-succ">
                  <h3>Reset Password Success!</h3>

                  <h4>Please login to your Account.</h4>

                  <div className="show-succ-content-text">
                    <img src={tick_icon} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="show-succ-content">
                  <h3>Email has been sent!</h3>
                  <p>
                    Please check your inbox and click on the Reset Link button.
                  </p>

                  <div className="show-succ-content-text">
                    <img src={email_sent} />
                    <p>
                      Didn’t receive the link? <span>Resend</span>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </ForgotPasswordModelStyled>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModel;
