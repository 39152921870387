import { ServiceManagementStyled } from "./../ServiceManagement.styled";
import Button from "./../../../components/Button/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
import { useEffect, useState } from "react";
import {
  getProductDetails,
  getAllBrands,
  createProduct,
  getProductsListApi,
  getBrandDetails,
  createBrand,
  updateProduct,
  updateBrand,
  getItemReceivedList,
  createItemReceivedOption,
  getDefaultIssuesList,
  createDefaultIssuesOption,
  getAdditionalRequirementsList,
  createAdditionalRequirementsOption,
  getActionTakenList,
  createActionTakenOption,
  getEngineerObservationList,
  createEngineerObservationOption,
  getCustomerNotesList,
  createCustomerNotesOption,
  getActionDetailsDetails,
  getEngineerobservationDetails,
  getEdititemReceivedDetails,
  getDefaultIssuesOptionDetails,
  getAdditionalRequirementsOptionDetails,
} from "./../../../redux/slices/serviceManagement/serviceManagementService";
import {
  createpaymentmodeApi,
  paymentmodelistApi,
  createconfigurationApi,
  configurationlistApi,
  updateconfigurationAPi,
  editconfigurationApi,
  getaddOrUpdateLeadStatusAPi,
  geteditleadstatusApi,
  getleadstatuslistApi,
  addLeadActionTakenOptionAPi,
  updateLeadActionTakenOptionApi,
  leadactiontakenlistApi,
} from "./../../../redux/slices/LeadManagement/leadManagementService";
import { useDispatch, useSelector } from "react-redux";
import { isRaskoAccount } from "../../../Scenes/common";

const AddNewOption = (props: any) => {
  const { selectedData } = props;
  console.log("propsprops", props);
  const secName = props?.section;
  const id = selectedData?.id;
  const dispatch = useDispatch();

  console.log("selectedData", selectedData);
  const [isEdit, setIsEdit] = useState(false);
  const [updateContent, setUpdateContent] = useState(false);
  const [selectAllOption, setSelectAllOption] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [itemDetails, setItemDetails] = useState({});
  const [itemList, setItemList] = useState([]);
  const [itemListAll, setItemListAll] = useState([]);
  const [selectedItems, setSelectedItems] = useState([] as any);
  const [checkBoxItems, setCheckBoxItems] = useState({} as any);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    productDetails,
    brandsList,
    brandDetails,
    productsList,
    actionTakenDetails,
    engineerObservationDetails,
    defaultIssuesOptionDetails,
    edititemReceivedDetails,
    additionalRequirementsOptionDetails,
  } = useSelector((state: any) => state.serviceManagement);
  const { editconfigurationDetails } = useSelector(
    (state: any) => state.leadManagement
  );

  useEffect(() => {
    console.log("selectedData", selectedData);
    if (selectedData?.id) {
      setSelectedItems([]);
      setIsEdit(true);
      setInputValue(
        selectedData?.name || selectedData?.optionName || selectedData?.status
      );
      if (secName === "brandsList") {
        dispatch(getBrandDetails(id));
      } else if (secName === "productList") {
        dispatch(getProductDetails(id));
      } else if (secName === "itemsReceivedList") {
        dispatch(getEdititemReceivedDetails(id));
      } else if (secName === "defaultIssuesOptionList") {
        dispatch(getDefaultIssuesOptionDetails(id));
      } else if (secName === "additionalRequirementList") {
        dispatch(getAdditionalRequirementsOptionDetails(id));
      } else if (secName === "actiontakenList") {
        dispatch(getActionDetailsDetails(id));
      } else if (secName === "engineerObservationList") {
        dispatch(getEngineerobservationDetails(id));
      } else if (secName === "configurationList") {
        dispatch(editconfigurationApi(id));
      }
    } else {
      setIsEdit(false);
    }
  }, [selectedData]);
  useEffect(() => {
    if (props?.show && !selectedData?.id) {
      setErrorMessage("");
      setInputValue("");
      setSelectedItems([]);
      if (secName === "brandsList") {
        // dispatch(getBrandDetails());
      } else if (secName === "productList") {
        dispatch(getAllBrands());
      } else {
        dispatch(getProductsListApi());
      }
    }
  }, [props?.show]);

  useEffect(() => {
    console.log("brandDetails", brandDetails);
    if (secName === "brandsList") {
      // setItemDetails(brandsList);
      setCheckBoxItems({ ...brandDetails?.brand });
    } else if (secName === "productList") {
      setItemDetails(productDetails);
      setItemList(productDetails?.brands);
      setItemListAll(productDetails?.brands);
      setCheckBoxItems({ ...productDetails?.product });
      console.log("productDetails?.brands", productDetails?.brands);
      updateSelectedItemsList(productDetails?.brands);
    } else if (secName === "itemsReceivedList") {
      updateSelectedItemsList(edititemReceivedDetails?.products);
    } else if (secName === "defaultIssuesOptionList") {
      updateSelectedItemsList(defaultIssuesOptionDetails?.products);
    } else if (secName === "additionalRequirementList") {
      updateSelectedItemsList(additionalRequirementsOptionDetails?.products);
    } else if (secName === "actiontakenList") {
      updateSelectedItemsList(actionTakenDetails?.products);
    } else if (secName === "engineerObservationList") {
      updateSelectedItemsList(engineerObservationDetails?.products);
    } else if (secName === "configurationList") {
      console.log(
        "editconfigurationDetails?.itemConfiguration?.products",
        editconfigurationDetails?.itemConfiguration?.products
      );
      updateSelectedItemsList(
        editconfigurationDetails?.itemConfiguration?.products
      );
    }
  }, [
    productDetails,
    brandDetails,
    actionTakenDetails,
    engineerObservationDetails,
    defaultIssuesOptionDetails,
    edititemReceivedDetails,
    additionalRequirementsOptionDetails,
    editconfigurationDetails,
  ]);
  useEffect(() => {
    if (secName === "brandsList") {
      // setItemDetails(brandsList);
    } else if (secName === "productList") {
      setItemList(brandsList);
      setItemListAll(brandsList);
    } else {
      setItemList(productsList);
      setItemListAll(productsList);
    }
  }, [brandsList, productsList]);

  const updateSelectedItemsList = (sitems: any) => {
    const selctedItemsV = sitems?.filter(
      (iitm: any) => iitm?.selected === "checked"
    );
    console.log("selctedItemsV", selctedItemsV);
    const prevSelec = [] as any;
    selctedItemsV?.map((vvvm: any) => {
      prevSelec.push(vvvm?.id);
    });
    if (prevSelec?.length > 0) {
      setSelectedItems(prevSelec);
    }
  };
  const searchOnChangeValue = (e: any) => {
    const { name, value } = e.target;
    console.log("value", value);
    if (value === "") {
      setItemList(itemListAll);
    } else {
      console.log("itemListAll", itemListAll);
      const newItems = [] as any;
      itemListAll?.map((item: any) => {
        const OVal = item?.name || item?.optionName;
        if (OVal && OVal?.toLowerCase().includes(value?.toLowerCase())) {
          newItems.push(item);
        }
      });
      console.log("newItems", newItems);
      setItemList(newItems);
    }
  };
  const handleCheckboxChange = (e: any) => {
    const { checked, name } = e.target;
    const newObj = { ...checkBoxItems, name: checked };
    newObj[name] = checked;
    setCheckBoxItems(newObj);
  };
  const onChnageValueList = (item: any, e: any) => {
    const isChecked = e.target.checked;
    const prevItems = selectedItems;
    if (isChecked) {
      prevItems.push(item?.id);
      setSelectedItems(prevItems);
    } else {
      const index = prevItems.indexOf(item?.id);
      if (index > -1) {
        prevItems.splice(index, 1);
        setSelectedItems(prevItems);
      }
    }
    setUpdateContent(!updateContent);
  };
  console.log("selectedItems", selectedItems);
  const handleSelectAllOption = () => {
    console.log("selectAllOption", selectAllOption);
    setSelectAllOption(!selectAllOption);
    if (!selectAllOption) {
      // all selected options
      let prevItems = [] as any;
      itemList?.map((item: any, index: any) => {
        prevItems.push(item?.id);
      });
      setSelectedItems(prevItems);
    } else {
      setSelectedItems([]);
    }
  };
  const closeCall = () => {
    props?.onHide();
  };
  console.log("isEdit", isEdit);
  const handleSubmitFn = async () => {
    if (inputValue) {
      console.log("inputValue", inputValue);
      const shoeService = checkBoxItems?.showInBookService ? "on" : "off";
      const shoeLead = checkBoxItems?.showInLead ? "on" : "off";

      if (secName === "productList") {
        const jsonR = {
          name: inputValue,
          tempShowInBookService: "on",
        };
        const editJson = {
          id: id,
          name: inputValue,
          tempShowInBookService: shoeService,
          tempShowInLead: shoeLead,
          tempDelete: "off",
          selectedBrands: selectedItems.join(","),
        };
        if (isEdit) {
          await dispatch(updateProduct(editJson));
          dispatch(getProductsListApi());
          closeCall();
        } else {
          const newResp = (await dispatch(createProduct(jsonR))) as any;
          console.log("newResp", newResp);
          if (newResp?.error?.message) {
            setErrorMessage(newResp?.error?.message);
          } else if (newResp?.payload?.id) {
            editJson.id = newResp?.payload?.id;
            await dispatch(updateProduct(editJson));
            dispatch(getProductsListApi());
            closeCall();
          }
        }
      } else if (secName === "brandsList") {
        const jsonV = {
          id: id,
          name: inputValue,
          tempShowInBookService: shoeService,
          tempShowInLead: shoeLead,
        };
        // if(isEdit){

        //   const newResp = (await dispatch(updateBrand(jsonV))) as any;
        //   console.log("newResp", newResp);
        //   if (newResp?.error?.message) {
        //     setErrorMessage(newResp?.error?.message);
        //   } else {
        //     dispatch(getAllBrands());
        //     closeCall();
        //   }
        // }else{
        const newResp = isEdit
          ? ((await dispatch(updateBrand(jsonV))) as any)
          : ((await dispatch(createBrand(jsonV))) as any);
        console.log("newResp", newResp);
        if (newResp?.error?.message) {
          setErrorMessage(newResp?.error?.message);
        } else {
          dispatch(getAllBrands());
          closeCall();
          // }
        }
      } else if (secName === "itemsReceivedList") {
        const jsonV = {
          id: id,
          optionName: inputValue,
          selectedProduts: selectedItems.join(","),
        };
        const newResp = (await dispatch(
          createItemReceivedOption(jsonV)
        )) as any;
        console.log("newResp", newResp);
        if (newResp?.error?.message) {
          setErrorMessage(newResp?.error?.message);
        } else {
          dispatch(getItemReceivedList({}));
          closeCall();
        }
      } else if (secName === "defaultIssuesOptionList") {
        const jsonV = {
          id: id,
          optionName: inputValue,
          selectedProduts: selectedItems.join(","),
        };
        if (isEdit) {
          await dispatch(createDefaultIssuesOption(jsonV));
          dispatch(getDefaultIssuesList({}));
          closeCall();
        } else {
          const newResp = (await dispatch(
            createDefaultIssuesOption(jsonV)
          )) as any;
          console.log("newResp", newResp);
          if (newResp?.error?.message) {
            setErrorMessage(newResp?.error?.message);
          } else if (newResp?.payload?.id) {
            jsonV.id = newResp?.payload?.id;
            await dispatch(createDefaultIssuesOption(jsonV));
            dispatch(getDefaultIssuesList({}));
            closeCall();
          }
        }

        // const newResp = (await dispatch(
        //   createDefaultIssuesOption(jsonV)
        // )) as any;
        // console.log("newResp", newResp);
        // if (newResp?.error?.message) {
        //   setErrorMessage(newResp?.error?.message);
        // } else {
        //   dispatch(getDefaultIssuesList({}));
        //   closeCall();
        // }
      } else if (secName === "additionalRequirementList") {
        additionalRequirementListCall();
      } else if (secName === "actiontakenList") {
        actiontakenListCall();
      } else if (secName === "engineerObservationList") {
        engineerObservationListCall();
      } else if (secName === "customerNoteList") {
        customerNoteListCall();
      } else if (secName === "paymentModeList") {
        createpaymentmodeApiCall();
      } else if (secName === "configurationList") {
        configurationmodeApiCall();
      } else if (secName === "leadStatusList") {
        leadStatusApiCall();
      } else if (secName === "leadActiontakenList") {
        leadActionTakenApiCall();
      }
    }
  };

  const additionalRequirementListCall = async () => {
    const jsonV = {
      id: id,
      optionName: inputValue,
      selectedProduts: selectedItems.join(","),
    };
    if (isEdit) {
      await dispatch(createAdditionalRequirementsOption(jsonV));
      dispatch(getAdditionalRequirementsList({}));
      closeCall();
    } else {
      const newResp = (await dispatch(
        createAdditionalRequirementsOption(jsonV)
      )) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(createAdditionalRequirementsOption(jsonV));
        dispatch(getAdditionalRequirementsList({}));
        closeCall();
      }
    }
    // const newResp = (await dispatch(
    //   createAdditionalRequirementsOption(jsonV)
    // )) as any;
    // console.log("newResp", newResp);
    // if (newResp?.error?.message) {
    //   setErrorMessage(newResp?.error?.message);
    // } else {
    //   dispatch(getAdditionalRequirementsList({}));
    //   closeCall();
    // }
  };
  const createpaymentmodeApiCall = async () => {
    const jsonV = {
      id: id,
      name: inputValue,
      selected: selectedItems.join(","),
    };
    if (isEdit) {
      await dispatch(createpaymentmodeApi(jsonV));
      dispatch(paymentmodelistApi());
      closeCall();
    } else {
      const newResp = (await dispatch(createpaymentmodeApi(jsonV))) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(createpaymentmodeApi(jsonV));
        dispatch(paymentmodelistApi());
        closeCall();
      }
    }
  };

  const configurationmodeApiCall = async () => {
    const jsonV = {
      id: id,
      name: inputValue,
      selected: selectedItems.join(","),
      selectedProducts: selectedItems.join(","),
    };
    if (isEdit) {
      await dispatch(updateconfigurationAPi(jsonV));
      dispatch(configurationlistApi());
      closeCall();
    } else {
      const newResp = (await dispatch(createconfigurationApi(jsonV))) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(updateconfigurationAPi(jsonV));
        dispatch(configurationlistApi());
        closeCall();
      }
    }
  };

  const leadStatusApiCall = async () => {
    const jsonV = {
      id: id,
      status: inputValue,
    };
    if (isEdit) {
      await dispatch(geteditleadstatusApi(jsonV));
      dispatch(getleadstatuslistApi());
      closeCall();
    } else {
      const newResp = (await dispatch(
        getaddOrUpdateLeadStatusAPi(jsonV)
      )) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(updateconfigurationAPi(jsonV));
        dispatch(getleadstatuslistApi());
        closeCall();
      }
    }
  };

  const leadActionTakenApiCall = async () => {
    const jsonV = {
      id: id,
      optionName: inputValue,
    };
    if (isEdit) {
      await dispatch(updateLeadActionTakenOptionApi(jsonV));
      dispatch(leadactiontakenlistApi());
      closeCall();
    } else {
      const newResp = (await dispatch(
        addLeadActionTakenOptionAPi(jsonV)
      )) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(updateLeadActionTakenOptionApi(jsonV));
        dispatch(leadactiontakenlistApi());
        closeCall();
      }
    }
  };
  const actiontakenListCall = async () => {
    const jsonV = {
      id: id,
      optionName: inputValue,
      selectedProduts: selectedItems.join(","),
    };
    if (isEdit) {
      await dispatch(createActionTakenOption(jsonV));
      dispatch(getActionTakenList({}));
      closeCall();
    } else {
      const newResp = (await dispatch(createActionTakenOption(jsonV))) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(createActionTakenOption(jsonV));
        dispatch(getActionTakenList({}));
        closeCall();
      }
    }
  };
  const engineerObservationListCall = async () => {
    const jsonV = {
      id: id,
      optionName: inputValue,
      selectedProduts: selectedItems.join(","),
    };
    if (isEdit) {
      await dispatch(createEngineerObservationOption(jsonV));
      dispatch(getEngineerObservationList({}));
      closeCall();
    } else {
      const newResp = (await dispatch(
        createEngineerObservationOption(jsonV)
      )) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(createEngineerObservationOption(jsonV));
        dispatch(getEngineerObservationList({}));
        closeCall();
      }
    }
  };

  const customerNoteListCall = async () => {
    const jsonV = {
      id: id,
      optionName: inputValue,
      selectedProduts: selectedItems.join(","),
    };
    if (isEdit) {
      await dispatch(createCustomerNotesOption(jsonV));
      dispatch(getCustomerNotesList({}));
      closeCall();
    } else {
      const newResp = (await dispatch(createCustomerNotesOption(jsonV))) as any;
      console.log("newResp", newResp);
      if (newResp?.error?.message) {
        setErrorMessage(newResp?.error?.message);
      } else if (newResp?.payload?.id) {
        jsonV.id = newResp?.payload?.id;
        await dispatch(createCustomerNotesOption(jsonV));
        dispatch(getCustomerNotesList({}));
        closeCall();
      }
    }
  };

  console.log("itemdetails", itemDetails);
  console.log("selectedItems", selectedItems);
  return (
    <>
      <Modal {...props} className="conformation-model-box1" size="lg" centered>
        <div className="conformation-model">
          <ServiceManagementStyled>
            <Modal.Body>
              <div>
                <div className="new-option-header">
                  <h4>{props?.subTitle}</h4>
                  <CloseButton onClick={props?.onHide} />
                </div>
                <div className="new-option-body">
                  <div className="new-option-body-header">
                    <p>Option</p>
                    <div className="new-option-body-text">
                      <input
                        value={inputValue}
                        onChange={(e) => {
                          setErrorMessage("");
                          setInputValue(e.target.value);
                        }}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  {isRaskoAccount() &&
                    (secName === "brandsList" || secName === "productList") && (
                      <div className="edit-sec-checkboxs">
                        <div>
                          <span> Show In Book Service </span>{" "}
                          <span>
                            <input
                              type="checkbox"
                              name="showInBookService"
                              onChange={handleCheckboxChange}
                              checked={checkBoxItems?.showInBookService}
                            />{" "}
                          </span>
                        </div>
                        <div>
                          <span> Show In Lead </span>{" "}
                          <span>
                            <input
                              type="checkbox"
                              name="showInLead"
                              onChange={handleCheckboxChange}
                              checked={checkBoxItems?.showInLead}
                            />{" "}
                          </span>
                        </div>
                      </div>
                    )}
                  {props?.section !== "customerNoteList" &&
                    props?.section !== "brandsList" &&
                    props?.section !== "leadStatusList" &&
                    props?.section !== "leadActiontakenList" && (
                      <div className="items-recieved-section">
                        <div className="section-container-header2">
                          <h5>
                            {secName === "productList" ? "Brands" : "Products"}{" "}
                            List
                          </h5>
                          <div className="search-icon-sec">
                            <input
                              id="items-recieved-search-box"
                              placeholder="Search "
                              onChange={searchOnChangeValue}
                            />
                          </div>
                          <div className="select-all-option">
                            <label
                              htmlFor="select-all-option-btn"
                              onClick={() => {
                                handleSelectAllOption();
                              }}
                            >
                              <input
                                type="checkbox"
                                id="select-all-option-btn-sec"
                                name="select-all-option-btn"
                                checked={selectAllOption}
                                // onChange={(e: any) => {
                                //   setSelectAllOption(e.target.checked);
                                // }}
                              />
                              Select All Options
                            </label>
                          </div>
                        </div>
                        <div className="add-scroll-option">
                          <div
                            className="row g-3 align-items-center items-recieved-body-content"
                            id="loadItemsRecived_dev"
                          >
                            {itemList?.map((item: any, index: any) => {
                              const isSlecP = selectedItems?.includes(item?.id);
                              // const isSelectP =  item?.selected === "checked" ? true : false;
                              // console.log("isSlecP", isSlecP);
                              // console.log("item", item);
                              return (
                                <>
                                  {item?.enable && (
                                    <div className="mb-3 col-sm-3 form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name={`item_received${index}`}
                                        id={`item_received${index}`}
                                        value={item?.id}
                                        checked={isSlecP}
                                        onChange={(e) => {
                                          onChnageValueList(item, e);
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`item_received${index}`}
                                      >
                                        {item?.name}
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {errorMessage && (
                <div>
                  <div className="error_message">{errorMessage}</div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="new-option-body-actions">
                <Button onClick={handleSubmitFn}>Submit</Button>
              </div>
            </Modal.Footer>
          </ServiceManagementStyled>
        </div>
      </Modal>
    </>
  );
};
export default AddNewOption;
