import styled from "styled-components";

export const LeadItemDetailsStyled = styled.div`
  .col-lavel,
  .col-value {
    // font-family: "Oswald,sans-serif";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: rgb(23, 26, 35);
  }
  .col-value {
    font-weight: 400;
  }
  .job-card-details-items > .mb-2 {
    margin-bottom: 15px !important;
  }
  .itmes-selected-header {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #1983e6;
  }
  .itmes-selected-section {
    margin-top: 20px;
  }
  .itmes-selected-content span {
    display: inline-block;
    padding: 10px 16px;
    margin: 10px !important;
    border-radius: 8px;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid #ced4da;
  }
  .parts-replaced-item {
    display: flex;
    margin-top: 8px;
  }
  .parts-replaced-item > div {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgb(23, 26, 35);
  }

  .parts-replaced-item-header > div {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: rgb(23, 26, 35);
  }
  .extra-items-info {
    margin-top: 20px;
  }
  .parts-replaced-item .key-parts {
    width: 150px;
  }
  .all-reports-data-table1 th {
    font-size: 20px;
  }

  .all-reports-data-table1 tr td {
    font-size: 20px;
    padding: 5px 10px !important;
    text-transform: capitalize;
  }
  .all-reports-data-table1 p {
    line-height: 20px;
    margin-bottom: 0px;
  }
`;
