import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllDashboardReportsApi,
  getLineReportsByDate,
  getStoreWiseJobCardReportAPi,
  getAllStoresReportAPi,
} from "./ReportsService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  reportsData: any;
  lineJobCardReports: any;
  todayReports: any;
  allStoresReport: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  reportsData: {},
  todayReports:{},
  lineJobCardReports: [],
  allStoresReport: [],
};

export const leadManagementSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllDashboardReportsApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllDashboardReportsApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.reportsData = action.payload;
      }
    );
    builder.addCase(
      getAllDashboardReportsApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.reportsData = {};
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getLineReportsByDate
    builder.addCase(getLineReportsByDate.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getLineReportsByDate.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.lineJobCardReports = action.payload;
      }
    );
    builder.addCase(
      getLineReportsByDate.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.lineJobCardReports = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    
    //getDashboards
    builder.addCase(getStoreWiseJobCardReportAPi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getStoreWiseJobCardReportAPi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.todayReports = action.payload?.report[0];
      }
    );
    builder.addCase(
      getStoreWiseJobCardReportAPi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.todayReports = {};
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getStoreWiseJobCardReportAPi
    //getAllStoresReportAPi
    builder.addCase(getAllStoresReportAPi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllStoresReportAPi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allStoresReport = action.payload;
      }
    );
    builder.addCase(
      getAllStoresReportAPi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allStoresReport = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    
  },
});

export const { setLoading, updateErrorMessage } = leadManagementSlice.actions;

export default leadManagementSlice.reducer;
