import styled from "styled-components";

export const JobCardsApprovalStyled = styled.div`
  width: 100%;
  margin: auto;

  .jobcard-content {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    tr th {
      line-height: 17px;
      padding: 5px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      background: #26bb6a;
      background: #f64f5a;
      text-align: center;
    }
    tr td {
      font-size: 16px;
      line-height: 18px;
      padding: 5px;
    }
  }
  .ant-table-tbody tr:nth-child(odd) , .ant-table-tbody tr:nth-child(odd): hover, .ant-table-tbody tr:nth-child(odd) td, .ant-table-tbody tr:nth-child(odd):hover {
    background: #c9f4f4;
  }
  .search-row-sec {
    display: flex;
  }
  .job-summary-pagination {
    margin-top: 10px;
    text-align: center;
  }
  .quick-search-section-list {
    margin-bottom: 10px;
    .accordion-button {
      padding: 9px 20px;
    }
  }
  .quick-search-section-list .form-group .form-label {
    margin-bottom: 1px;
  }
  .section-container-header-new {
    height: 35px;
    padding: 2px;
    font-size: 20px;
    background: #e2e2e2;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px 20px;
  }
  .middle-line-space-sec {
    width: 20px;
    display: inline-block;
    opacity: 0.5;
  }
  .section-container-header-new-search {
    cursor: pointer;
  }
  .section-container-header-new-search img {
    height: 20px;
    margin-left: 20px;
  }

  .profile-warning-message-text {
  }
  .hover-menu-item-sec {
    font-size: 43px;
    cursor: pointer;
  }
  .search-row-sec .search-row-select {
    width: 40%;
    margin-top: -37px;
  }
  .search-row-sec .search-row-search {
    width: 58%;
  }
  .drop-down-single-item-sec {
    font-size: 20px;
  }
  .filter-action-sec {
    margin: 10px 0;
    padding: 0;
  }
  .filter-actions-btn {
    padding-left: 25px;
  }
  .search-row-select fieldset {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .search-row-search input {
    height: 40px;
    width: 100%;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    border-right: 1px solid rgba(0, 0, 0, 0.23);
    padding-left: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .filter-actions-btn,
  .top-sec-filter {
    display: flex;
  }
  .download-icon {
    svg {
      height: 35px;
      width: 35px;
    }
    span {
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .jobcard-content tr td {
    text-transform: capitalize;
    text-align: center;
    word-wrap: break-word;
  }
  .clear-filters-sec {
    font-size: 17px;
    margin-left: 30px;
    border: 1px solid #e2e2e2;
    padding: 5px 10px;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
  }

  .view-job-card {
    cursor: pointer;
  }
  .select__menu {
    z-index: 999999;
  }
  .select__menu *,
  .select__single-value {
    text-transform: capitalize;
  }
  .select-dropdown-filters {
    // top: 15px;
  }
  .table-actions-btn svg {
    height: 25px;
    width: 25px;
    margin: 8px;
  }
  .page-sec-header {
    padding-left: 20px;
    margin-bottom: 20px;
  }
`;
