import React, { useState, useEffect } from "react";
import CommonReportData from "./CommonReportData";
const ActionTakenWise = () => {
  useEffect(() => {
    console.log("useEffect");
    getAllData();
  }, []);
  const getAllData = () => {};

  return (
    <>
      <CommonReportData secName="actiontaken" />
    </>
  );
};
export default ActionTakenWise;
