import styled from "styled-components";

export const SignUpStyled = styled.div`
  background: #d2e7f8;

  .signup-container {
    width: 50%;
    width: 600px;
    margin: auto;
    // margin-bottom: 400px;
    padding: 35px;
  }
  .store-sign-up {
    font-size: 30px;
    text-align: center;
    h2 {
      font-weight: 800;
      font-size: 2.575rem;
      line-height: 2.25rem;
      text-align: center;
      color: #1089ed;
    }
    h4 {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
    }
    p {
      text-align: center;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0rem;
      a {
        color: #fa7824;
      }
    }
  }
  .store-sign-up-img {
    text-align: center;
  }
  .create-account-action-con {
    text-align: center;
  }
  .create-account-btn-sec,
  .create-account-btn-sec:active,
  .create-account-btn-sec:focus {
    width: 300px;
    margin: auto;
    background: steelblue;
    border: none;
    box-shadow: none;
    height: 50px;
    color: #fff;
    border-radius: 4px;
    margin: auto;
    font-size: 20px;
  }
  .SignupContainer .form-control:focus {
    box-shadow: none;
  }
  .signup-sec-full-con {
    display: flex;
    // height: 100vh;
  }
  .create-new-form {
    border-radius: 7px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 30px;
    padding: 50px;

    margin-top: 20px;
    background: #fff;
    padding-top: 20px;
    border: 1px solid #ccc;
  }
  .SignupContainer-signup {
    // .signup-md,
    // .signup-md-left {
    //   width: 47%;
    //   float: left;
    // }
    // .signup-md-left {
    //   margin-left: 6%;
    // }
    .invalid-feedback {
      margin: 0;
    }
    .form-label {
      margin-bottom: 2px;
    }
    input: focus {
      box-shadow: none;
    }
  }
  .SignupContainer-signup > div {
    width: 100%;
    margin-left: 0;
  }
  .password-fields-sec > div > div {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 700px) {
    .signup-container {
      width: 100%;
      margin: auto;
      padding: 35px 20px;
    }
    .create-new-form {
      padding: 20px;
    }
  }
`;
