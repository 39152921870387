import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getAllBrands = createAsyncThunk(
  "leadManagement/getAllBrands",
  // async (body:any) => {
  //   return await post(`${SERVER_IP}/auth/login`, body);
  // }
  async () => await get(`${SERVER_IP}/brandlist`)
);
export const getDashboards = createAsyncThunk(
  "leadManagement/getDashboards",
  async () => await get(`${SERVER_IP}/dashboard`)
);
export const paymentmodelistApi = createAsyncThunk(
  "leadManagement/paymentmodelistApi",
  async () => await get(`${SERVER_IP}/paymentmodelist`)
);
export const configurationlistApi = createAsyncThunk(
  "leadManagement/configurationlistApi",
  async () => await get(`${SERVER_IP}/configurationlist`)
);
export const createconfigurationApi = createAsyncThunk(
  "leadManagement/createconfigurationApi",
  async (body: any) => await post(`${SERVER_IP}/createconfiguration`, body)
);
export const updateconfigurationAPi = createAsyncThunk(
  "leadManagement/updateconfigurationAPi",
  async (body: any) => await put(`${SERVER_IP}/updateconfiguration`, body)
);
export const editconfigurationApi = createAsyncThunk(
  "leadManagement/editconfigurationApi",
  async (id: any) => await get(`${SERVER_IP}/editconfiguration/${id}`)
);
export const getallleadsApi = createAsyncThunk(
  "leadManagement/getallleadsApi",
  async () => await get(`${SERVER_IP}/getallleads`)
);
export const createpaymentmodeApi = createAsyncThunk(
  "leadManagement/createpaymentmodeApi",
  async (body: any) => await post(`${SERVER_IP}/createpaymentmode`, body)
);

export const createLeadApi = createAsyncThunk(
  "leadManagement/createLeadApi",
  async (body: any) => await post(`${SERVER_IP}/createlead`, body)
);
export const editLeadApi = createAsyncThunk(
  "leadManagement/editLeadApi",
  async (body: any) => await put(`${SERVER_IP}/updatelead`, body)
);

export const getFilteredjLeadsummaryApi = createAsyncThunk(
  "serviceManagement/getFilteredjLeadsummaryApi",
  async (param: any) => await get(`${SERVER_IP}/getallleads?${param}`)
);
export const getcongigurationsbyproductidforleadApi = createAsyncThunk(
  "leadManagement/getcongigurationsbyproductidforleadApi",
  async (id: any) =>
    await get(`${SERVER_IP}/getcongigurationsbyproductidforlead/${id}`)
);
export const getleadsummaryApi = createAsyncThunk(
  "leadManagement/getleadsummaryApi",
  async () => await get(`${SERVER_IP}/leadsummary`)
);
export const getfilteredleadsummaryApi = createAsyncThunk(
  "leadManagement/getfilteredleadsummaryApi",
  async (param?: any) => await get(`${SERVER_IP}/filteredleadsummary?${param}`)
);

export const getleadstatuslistApi = createAsyncThunk(
  "leadManagement/getleadstatuslistApi",
  async () => await get(`${SERVER_IP}/leadstatuslist`)
);

export const getaddOrUpdateLeadStatusAPi = createAsyncThunk(
  "leadManagement/getaddOrUpdateLeadStatusAPi",
  async (body: any) => await post(`${SERVER_IP}/addOrUpdateLeadStatus`, body)
);

export const geteditleadstatusApi = createAsyncThunk(
  "leadManagement/geteditleadstatusApi",
  async (body: any) => await post(`${SERVER_IP}/addOrUpdateLeadStatus`, body)
);

export const leadactiontakenlistApi = createAsyncThunk(
  "leadManagement/leadactiontakenlistApi",
  async () => await get(`${SERVER_IP}/leadactiontakenlist`)
);

export const addLeadActionTakenOptionAPi = createAsyncThunk(
  "leadManagement/addLeadActionTakenOptionAPi",
  async (body: any) => await post(`${SERVER_IP}/addLeadActionTakenOption`, body)
);

export const updateLeadActionTakenOptionApi = createAsyncThunk(
  "leadManagement/updateLeadActionTakenOptionApi",
  async (body: any) =>
    await put(`${SERVER_IP}/updateLeadActionTakenOption/${body?.id}`, body)
);

export const getLeadDetailsById = createAsyncThunk(
  "leadManagement/getLeadDetailsById",
  async (leadId?: any) => await get(`${SERVER_IP}/getleadbyid/${leadId}`)
);
