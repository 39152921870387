import styled from "styled-components";

export const SideMenuStyled = styled.div`
  .dashboard-container {
    color: red;
  }
  .side-bar-container {
    margin-bottom: 200px;
  }
  .side-bar-container .accordion-button:focus {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  .side-bar-container,
  .side-bar-container .accordion-item,
  .side-bar-container .accordion-item h2 button {
    // background: #37474f;
    // color: #fff;
  }
  .job-card-sec-approval-count {
    color: #f64f5a;
    margin-left: 5px;
  }
  .side-bar-container button.accordion-button {
    // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    font-size: 19px;
    padding: 15px 1.25rem;
    color: #fff;
    background: #006261;
    // background: #efefef;
  }
  .side-bar-container button.accordion-button::after {
    width: 18px;
    height: 15px;
  }
  .accordion-body-items ul {
    list-style: none;
    padding-left: 0rem;
    margin: 0;
    li {
      background: #78bebe;
    }
  }
  .accordion-body-items {
    // background: #e7f1ff;
    padding: 0;
    // margin-top: 8px;
  }
  .accordion-body-items ul li a {
    color: #000;
    font-size: 17px;
    padding: 8px 0px;
    display: block;
    padding-left: 4rem;
    // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  .side-bar-container .accordion-button[aria-expanded="true"] {
    background: #40a9ff;
    color: #fff;
    font-size: 22px;
  }
  .accordion-body-items ul li a:hover,
  .accordion-body-items ul li a.selectedItem {
    text-decoration: none;
    text-decoration: none;
    background: #4682b4;

    background: #fff;
    background: #e7f1ff;
    color: #000;
    border-radius: 4px;
  }
  .side-bar-container button img {
    height: 20px;
    margin-right: 15px;
  }
  .side-bar-container .focusNextHome button.accordion-button::after,
  .side-bar-container .focusNextUsersList button.accordion-button::after,
  .side-bar-container .focusNextChangePassword button.accordion-button::after {
    display: none;
  }
  .accordion-item-disabled-view {
    opacity: 0.7;
    button.accordion-button {
      color: gray;
    }
  }
  @media (max-width: 700px) {
    .side-bar-container {
      margin-bottom: 50px;
    }
  }
`;
