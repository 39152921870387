import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getApprovedUsersCall } from "./storeManagementService";

export interface storeManagementState {
  loading: boolean;
  error: any;
}
const initialState: storeManagementState = {
  loading: false,
  error: null,
};

export const storeManagementSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: storeManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: storeManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: storeManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    //
    builder.addCase(
      getApprovedUsersCall.fulfilled,
      (state: storeManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        // state.getJobCardOptionsBasedOnProductIdResp = action.payload;
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = storeManagementSlice.actions;

export default storeManagementSlice.reducer;
