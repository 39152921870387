import styled from "styled-components";

export const UsersListStyled = styled.div`
  .section-container-header span {
    margin-left: 20px;
    display: block;
    margin-top: 2px;
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
  }
  .section-container-header input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 250px;
    margin-left: 20px;
    border-radius: 5px;
    height: 35px;
    margin-top: -2px;
    padding-left: 10px;
  }
  .SignupContainer-signup-add-new-user .form-group label {
    font-size: 18px;
    color: rgb(11, 102, 178);
  }
  .section-container-body-table1 tr th {
    text-align: center;
  }
  .section-container-body-table1 tr td {
    text-align: center;
  }
  .long-text-word {
    word-wrap: break-word;
  }
  .section-container-body-table {
    margin-top: 30px;
  }
  .section-container-body-table tbody td {
    font-size: 18px;
    padding: 10px 10px;
  }
  .section-container-body-table thead th {
    font-size: 20px;
  }
  .new-option-body {
    margin-top: 10px;
    padding-top: 20px;
  }
  .table-option-text {
    text-transform: capitalize;
    line-height: 28px;
  }
  .create-account-action-con {
    text-align: right;
  }
  .create-account-btn-sec {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    height: 45px;
    width: 200px;
    font-size: 20px;
  }
  .table-actions-btn span {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 9px 5px;
    margin: 5px;
    width: 45%;
    // display: inline-block;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    border-radius: 10px;
  }
  .table-actions-btn span:nth-child(2) {
    color: red;
  }
  .new-option-body-header p {
    margin-bottom: 3px;
  }
  .new-option-body-text input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 45px;
    padding-left: 10px;
    border-radius: 4px;
    width: 370px;
    font-size: 20px;
    text-transform: capitalize;
  }
  .new-option-body-actions {
    // margin-top: 50px;
    text-align: center;
  }
  .new-option-header {
    display: flex;
    justify-content: space-between;
  }
  .new-option-body {
    border-top: 1px solid #e2e2e2;
    // margin-top: 25px;
    // padding-top: 20px;
  }
  .new-option-body-header p {
    font-weight: 400;
    font-size: 20px;
  }
  .items-recieved-section {
    margin-top: 30px;
    border: none;
  }
  .section-container-header2 {
    display: flex;
    // background: #d8e6f3;
    margin-bottom: 15px;
    .search-icon-sec {
      margin-left: 20px;
      margin-top: -8px;
    }
  }
`;
