// import { LeadSummaryDashboardStyled } from "./LeadSummaryDashboard.styled";

// const LeadSummaryDashboard = () => {
//   return (
//     <div>
//       <div>LeadSummaryDashboard LeadSummaryDashboard</div>
//     </div>
//   );
// };
// export default LeadSummaryDashboard;

import { LeadSummaryDashboardStyled } from "./LeadSummaryDashboard.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Marquee from "react-fast-marquee";
import {
  getleadstatuslistApi,
  leadactiontakenlistApi,
} from "./../../../redux/slices/LeadManagement/leadManagementService";

// import { getDashboards } from "./../../redux/slices/serviceManagement/serviceManagementService";

const LeadSummaryDashboard = (props: any) => {
  const { statusUpdate, currectActiveTab } = props;
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  const { error, loading, user, loginStoreDetails } = useSelector(
    (state: any) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("user", user);
    if (user?.id) {
      //   dispatch(getDashboards(false));
    } else {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    if (currectActiveTab === "Your Dashboard") {
      //   dispatch(getDashboards(true));
    } else {
      //   dispatch(getDashboards(false));
    }
  }, [currectActiveTab]);
  console.log("dashboardData", dashboardData);
  const allCalls = [
    "Total Calls",
    "Open Calls",
    "Closed Calls",
    "Medium Calls",
    "Low Calls",
    "High Calls",
    "Quote Given Calls",
    "Approved Calls",
    "CNA Calls",
    "Cancel Calls",
    "Waiting App Calls", //For Cust
    "Pending Calls",
    "UP Coming Calls",
    "On Going Calls",
    "Follow-Up Calls",
  ];
  return (
    <>
      <LeadSummaryDashboardStyled>
        <div className="job-card-dashboard">
          <div>
            <div className="lead-summary-dashboard-calls">
              {allCalls.map((item: any) => {
                return (
                  <div className="lead-summary-dashboard-call-item">
                    <p>{item}</p>
                    <p>{999}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </LeadSummaryDashboardStyled>
    </>
  );
};
export default LeadSummaryDashboard;
