import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import mainImg from "./../../img/focusNext.png";
import { useDispatch, useSelector } from "react-redux";
// import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import { loginUser } from "../../redux/slices/auth/authService";
import { DashboardStyled } from "./Dashboard.styled";
import Button from "./../../components/Button/Button";
import { getDashboards } from "./../../redux/slices/serviceManagement/serviceManagementService";
import { getStoreDetails } from "./../../redux/slices/auth/authService";
import CustomModal from "../../components/CustomModel";
import { checkRolePermission } from "./../../Scenes/common";
// import SideMenu from "../../components/SideMenu/Sidebar";
const Dashboard = (props: any) => {
  // local state
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [showProfileModel, setShowProfileModel] = useState(false);

  const [password, setPassword] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  let location = useLocation();
  const { error, loading, user, loginStoreDetails } = useSelector(
    (state: any) => state.auth
  );
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  console.log("dashboardData", dashboardData);
  useEffect(() => {
    console.log("user", user);
    if (user?.id) {
      dispatch(getDashboards(false));
      dispatch(getStoreDetails(user?.storeId));
    } else {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    if (!loginStoreDetails?.address && loginStoreDetails?.id) {
      setShowWarningMessage(true);
      const localText = localStorage.getItem("showfillProfileModelText");
      if (localText !== "done") {
        setShowProfileModel(true);
        localStorage.setItem("showfillProfileModelText", "done");
      }
    }
    console.log("loginStoreDetails", loginStoreDetails);
  }, [loginStoreDetails?.id]);

  useEffect(() => {
    let urlQueryString = location.search;
    let signInType = new URLSearchParams(urlQueryString).get("returnUrl");
  }, [location.search]);
  const handleLogin = () => {
    console.log("mobileNumber", mobileNumber);
    console.log("password", password);
    const body = {
      username: mobileNumber,
      password,
    };
    dispatch(loginUser(body));
  };
  const gotoDelivery = () => {
    console.log("vino");
    props?.updateStep("delivery");
  };
  const gotoProfilePage = () => {
    history.push(`/myProfile`);
  };
  const gotoSummaryPage = (status: any, countN: any) => {
    console.log("countN", countN);
    if (countN > 0) {
      history.push(`/jobcardsummary?status=${status}`);
    }
  };
  return (
    <DashboardStyled>
      {/* <SideMenu /> */}
      <div className="dashboard-container">
        <CustomModal
          show={showProfileModel}
          titleText={`Please complete the profile setup, profile details will display in print page.`}
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowProfileModel(false);
            gotoProfilePage();
          }}
        />

        <div className="col-sm-12">
          {showWarningMessage && (
            <div className="profile-warning-message-text">
              <p>
                Please complete the profile setup, profile details will display
                in print page.
              </p>
              <button onClick={gotoProfilePage}>Complete Profle</button>
            </div>
          )}

          <div className="section-container">
            <div className="section-container-header">
              <h3>Service Management</h3>
              {checkRolePermission("createjobcard") && (
                <span>
                  <a href="/createjobcard">Create Job Card</a>
                </span>
              )}
            </div>
            <div className="section-container-body">
              <div className="box-all-rows">
                <div className="box-row black-color">
                  <p>Total</p>
                  <p>{dashboardData?.noOfCalls}</p>
                </div>
                <div
                  className="box-row green-color"
                  onClick={() => {
                    gotoSummaryPage("Closed", dashboardData?.closedCalls);
                  }}
                >
                  <p>Closed</p>
                  <p>{dashboardData?.closedCalls}</p>
                </div>
                <div className="box-row red-color">
                  <p>High</p>
                  <p>{dashboardData?.highPriorityCalls} </p>
                </div>
                <div className="box-row orange-color">
                  <p>Miduam</p>
                  <p>{dashboardData?.mediumPriorityCalls} </p>
                </div>
                <div className="box-row black-color">
                  <p>Low</p>
                  <p>{dashboardData?.lowPriorityCalls} </p>
                </div>
              </div>
            </div>

            <div className="section-container-body">
              <div className="box-all-rows">
                {/* <div className="box-row">
                  <p>Total</p>
                  <p>{dashboardData?.noOfCalls}</p>
                </div>
                <div className="box-row">
                  <p>Urgent</p>
                  <p>000</p>
                </div>
                <div className="box-row">
                  <p>Miduam</p>
                  <p>000</p>
                </div>
                <div className="box-row">
                  <p>Low</p>
                  <p>000</p>
                </div> */}
                {/* <div className="box-row">
                  <p>Created</p>
                  <p>000</p>
                </div> */}
                <div
                  className="box-row red-color"
                  onClick={() => {
                    gotoSummaryPage("Open", dashboardData?.openCalls);
                  }}
                >
                  <p>Create</p>
                  <p>{dashboardData?.openCalls}</p>
                </div>
                <div
                  className="box-row green-color"
                  onClick={() => {
                    gotoSummaryPage("Repair Start", dashboardData?.repairStart);
                  }}
                >
                  <p>Repair Start</p>
                  <p> {dashboardData?.repairStart} </p>
                </div>
                <div
                  className="box-row"
                  onClick={() => {
                    gotoSummaryPage(
                      "Repair Closed",
                      dashboardData?.repairClosed
                    );
                  }}
                >
                  <p>Repair Closed </p>
                  <p> {dashboardData?.repairClosed} </p>
                </div>
                {/* <div
                  className="box-row"
                  onClick={() => {
                    gotoSummaryPage("Closed");
                  }}
                >
                  <p>Closed</p>
                  <p>{dashboardData?.closedCalls}</p>
                </div> */}
                <div
                  className="box-row"
                  onClick={() => {
                    gotoSummaryPage(
                      "Internal Billing",
                      dashboardData?.internalBilling
                    );
                  }}
                >
                  <p>Internal Billing</p>
                  <p> {dashboardData?.internalBilling} </p>
                </div>
                <div
                  className="box-row"
                  onClick={() => {
                    gotoSummaryPage(
                      "Ready for Delivery",
                      dashboardData?.readyForDeliveryCalls
                    );
                  }}
                >
                  <p>Ready for Delivery</p>
                  <p>{dashboardData?.readyForDeliveryCalls}</p>
                </div>
                {showAll && (
                  <>
                    <div
                      className="box-row red-color"
                      onClick={() => {
                        gotoSummaryPage(
                          "Approval Pending",
                          dashboardData?.approvalPendingCalls
                        );
                      }}
                    >
                      <p>Approval Pending</p>
                      <p>{dashboardData?.approvalPendingCalls}</p>
                    </div>
                    <div
                      className="box-row green-color"
                      onClick={() => {
                        gotoSummaryPage(
                          "Approved",
                          dashboardData?.approvedCalls
                        );
                      }}
                    >
                      <p>Approved</p>
                      <p>{dashboardData?.approvedCalls}</p>
                    </div>
                    <div
                      className="box-row yellow-color"
                      onClick={() => {
                        gotoSummaryPage(
                          "Need Tech Support",
                          dashboardData?.needTechSupportCalls
                        );
                      }}
                    >
                      <p>Need Tech Support</p>
                      <p>{dashboardData?.needTechSupportCalls}</p>
                    </div>
                    <div
                      className="box-row"
                      onClick={() => {
                        gotoSummaryPage(
                          "Part Required",
                          dashboardData?.partRequiredCalls
                        );
                      }}
                    >
                      <p>Parts Required</p>
                      <p>{dashboardData?.partRequiredCalls}</p>
                    </div>
                    <div
                      className="box-row"
                      onClick={() => {
                        gotoSummaryPage("Pending", dashboardData?.pendingCalls);
                      }}
                    >
                      <p>Pending</p>
                      <p>{dashboardData?.pendingCalls}</p>
                    </div>
                    <div
                      className="box-row"
                      onClick={() => {
                        gotoSummaryPage(
                          "Warranty Crated",
                          dashboardData?.warrantyCrated
                        );
                      }}
                    >
                      <p> Warranty Created</p>
                      <p> {dashboardData?.warrantyCrated} </p>
                    </div>
                    <div
                      className="box-row"
                      onClick={() => {
                        gotoSummaryPage(
                          "Waiting for Pickup (OW)",
                          dashboardData?.waitingforPickup
                        );
                      }}
                    >
                      <p>Waiting for Pickup (OW)</p>
                      <p> {dashboardData?.waitingforPickup} </p>
                    </div>
                    <div
                      className="box-row red-color"
                      onClick={() => {
                        gotoSummaryPage(
                          "Out for Warranty",
                          dashboardData?.openWarrantyCalls
                        );
                      }}
                    >
                      <p>Out for Warranty</p>
                      <p>{dashboardData?.openWarrantyCalls}</p>
                    </div>

                    <div
                      className="box-row green-color"
                      onClick={() => {
                        gotoSummaryPage(
                          "Buy Back",
                          dashboardData?.buyBackCalls
                        );
                      }}
                    >
                      <p>Buy Back</p>
                      <p>{dashboardData?.buyBackCalls}</p>
                    </div>
                    <div
                      className="box-row yellow-color"
                      onClick={() => {
                        gotoSummaryPage("Cancel", dashboardData?.cancelCalls);
                      }}
                    >
                      <p>Cancel</p>
                      <p>{dashboardData?.cancelCalls}</p>
                    </div>
                    <div
                      className="box-row"
                      onClick={() => {
                        gotoSummaryPage(
                          "Intentionally Left Out",
                          dashboardData?.intentionallyLeftOutCalls
                        );
                      }}
                    >
                      <p>Intentionally Left Out</p>
                      <p>{dashboardData?.intentionallyLeftOutCalls}</p>
                    </div>
                    <div
                      className="box-row"
                      onClick={() => {
                        gotoSummaryPage(
                          "Return Without Repair",
                          dashboardData?.returnWithoutRepair
                        );
                      }}
                    >
                      <p>Return Without Repair</p>
                      <p>{dashboardData?.returnWithoutRepair}</p>
                    </div>
                    <div className="box-row">
                      <p>Online Repair</p>
                      <p></p>
                    </div>
                    <div className="box-row">
                      <p>Online Closed</p>
                      <p></p>
                    </div>
                    <div className="box-row">
                      <p>Coming Soon</p>
                      <p></p>
                    </div>
                  </>
                )}
              </div>
              <div className="view-all-btn">
                <Button
                  onClick={() => {
                    setShowAll(!showAll);
                  }}
                  text={showAll ? "View Less" : "View All"}
                ></Button>
              </div>
            </div>
          </div>

          <div className="section-container">
            <div className="section-container-header">
              <h3>Lead Management</h3>
              <span>
                <a href="/displayCreateLeadForm">Create Lead</a>
              </span>
            </div>
            <div className="section-container-body">
              <div className="">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="thead-inverse">
                      <tr>
                        <th>Total Calls</th>
                        <th>Open Calls</th>
                        <th>Closed Calls</th>
                        <th>Quote Given Calls</th>
                        <th>Approved Calls</th>
                        <th>CNA Calls</th>
                        <th>Cancel Calls</th>
                        <th>Waiting For Cust Approval Calls</th>
                        <th>Pending Calls</th>
                        <th>UP Coming Calls</th>
                        <th>On Going Calls</th>
                        <th>Follow-Up Calls</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                        <td>
                          <span className="greenText">100</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="section-container">
            <div className="section-container-header">
              <h3>Calls Management</h3>
              <span>
                <a href="/displayCreateLeadForm">Create Lead</a>
              </span>
            </div>
            <div className="section-container-body">
              <div className="">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="thead-inverse">
                      <tr>
                        <th>Desktop Calls</th>
                        <th>Laptop Calls</th>
                        <th>Printer Calls</th>
                        <th>CCTV Calls</th>
                        <th>Biometric Calls</th>
                        <th>Acces Calls</th>
                        <th>Other Calls</th>
                        <th>Software Calls</th>
                        <th>Comming Soon</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>
                        <td>
                          <span className="blackText">100</span>
                        </td>

                        {/* <td>	<span className="blackText">${leadDesktopCalls}</span></td>
									<td>	<span className="blackText">${leadLaptopCalls}</span></td>
									<td>	<span className="blackText">${leadPrinterCalls}</span></td>
									<td>	<span className="blackText">${leadCCTVCalls}</span></td>
									<td>	<span className="blackText">${leadBiometricCalls}</span></td>
									<td>	<span className="blackText">${leadAccesCalls}</span></td>
									<td>	<span className="blackText">${leadOtherCalls}</span></td>
									<td>	<span className="blackText">${leadSoftwareCalls}</span></td>
									<td>	<span className="blackText">${commingSoonCalls}</span></td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="section-container">
            <div className="section-container-header">
              <h3>Booking Management Dashbaord</h3>
              <span>
                {/* <!-- <a href="/displayJobCardForm">Create Lead</a> --> */}
              </span>
            </div>
            <div className="section-container-body">
              <div className="">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="thead-inverse">
                      <tr>
                        <th>Item Name</th>
                        <th>Brand Name</th>
                        <th>Model</th>
                        <th>Quantity</th>
                        <th>Advance Amount</th>
                        <th>Payment Mode</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="bm-item">01234</span>
                        </td>
                        <td>
                          <span className="bm-item">01234</span>
                        </td>
                        <td>
                          <span className="bm-item">01234</span>
                        </td>
                        <td>
                          <span className="bm-item">01234</span>
                        </td>
                        <td>
                          <span className="bm-item">01234</span>
                        </td>
                        <td>
                          <span className="bm-item">01234</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="section-container">
            <div className="section-container-header">
              <h3>AMC Management Dashbaord</h3>
              <span>
                {/* <!-- <a href="/displayJobCardForm">Create Lead</a> --> */}
              </span>
            </div>
            <div className="section-container-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-inverse">
                    <tr>
                      <th>Item Name</th>
                      <th>Brand Name</th>
                      <th>Model</th>
                      <th>Quantity</th>
                      <th>Advance Amount</th>
                      <th>Payment Mode</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="bm-item">01234</span>
                      </td>
                      <td>
                        <span className="bm-item">01234</span>
                      </td>
                      <td>
                        <span className="bm-item">01234</span>
                      </td>
                      <td>
                        <span className="bm-item">01234</span>
                      </td>
                      <td>
                        <span className="bm-item">01234</span>
                      </td>
                      <td>
                        <span className="bm-item">01234</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="section-container">
            <div className="section-container-header">
              <h3>Dashboard For Engineers</h3>
              <span>
                {/* <!-- <a href="/displayJobCardForm">Create Lead</a> --> */}
              </span>
            </div>
            <div className="section-container-body">
              <div className="table-responsive user-dashboard-table">
                <table className="table table-striped">
                  <thead className="thead-inverse">
                    <tr>
                      <th className="user-dashboard-name-th">Name</th>
                      <th>Closed</th>
                      <th>Open</th>
                      <th>Out for Warranty</th>
                      <th>Ready For Delivery</th>
                      <th>Approval Pending</th>
                      <th>Approved</th>
                      <th>Need Tech Support</th>
                      <th>Part Required</th>
                      <th>Pending</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {dashboardData?.userDashbord?.map((item: any) => {
                      return (
                        <tr>
                          <td className="user-dashboard-name">
                            {item?.userName.toLowerCase()}
                          </td>
                          <td className="">{item?.closed}</td>
                          <td className="">{item?.open}</td>
                          <td className="">{item?.openHyd}</td>
                          <td className="">{item?.readyForDelivery}</td>
                          <td className="">{item?.approvalPending}</td>
                          <td className="">{item?.approved}</td>
                          <td className="">{item?.needTechSupport}</td>
                          <td className="">{item?.partRequired}</td>
                          <td className="">{item?.pending}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="section-container">
            <div className="section-container-header">
              <h3>Dashboard For Incoming Source</h3>
              <span>
                {/* <!-- <a href="/displayJobCardForm">Create Lead</a> --> */}
              </span>
            </div>
            <div className="section-container-body">
              <div className="table-responsive user-dashboard-table">
                <table className="table table-striped">
                  <thead className="thead-inverse">
                    <tr>
                      <th className="user-dashboard-name-th">Name</th>
                      <th>Carry in</th>
                      <th>Onsite</th>
                      <th>Pickup</th>
                      <th>Remote</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.userDashbord?.map((item: any) => {
                      return (
                        <tr>
                          <td className="user-dashboard-name">
                            {item?.userName.toLowerCase()}
                          </td>
                          <td className="">{item?.carryIn}</td>
                          <td className="">{item?.onsite}</td>
                          <td className="">{item?.pickup}</td>
                          <td className="">{item?.remote}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Accordion Item #1
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <strong>This is the first item's accordion body.</strong> It is
              shown by default, until the collapse plugin adds the appropriate
              classes that we use to style each element. These classes control
              the overall appearance, as well as the showing and hiding via CSS
              transitions. You can modify any of this with custom CSS or
              overriding our default variables. It's also worth noting that just
              about any HTML can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Accordion Item #2
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is
              hidden by default, until the collapse plugin adds the appropriate
              classes that we use to style each element. These classes control
              the overall appearance, as well as the showing and hiding via CSS
              transitions. You can modify any of this with custom CSS or
              overriding our default variables. It's also worth noting that just
              about any HTML can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Accordion Item #3
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <strong>This is the third item's accordion body.</strong> It is
              hidden by default, until the collapse plugin adds the appropriate
              classes that we use to style each element. These classes control
              the overall appearance, as well as the showing and hiding via CSS
              transitions. You can modify any of this with custom CSS or
              overriding our default variables. It's also worth noting that just
              about any HTML can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </div>
      </div> */}
    </DashboardStyled>
  );
};

export default Dashboard;
