import { CreateJobcardStyled } from "./../../CreateJobcard/CreateJobcard.styled";
import React, { useState, useEffect, Component, useRef } from "react";
import CustomModal from "../../../components/CustomModel";
import { useHistory } from "react-router";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";

import AddNewUser from "./../../CreateJobcard/AddNewUser";
import PasswordPatternLock from "./../../CreateJobcard/PasswordPatternLock";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import { CreateBookingServiceStyled } from "./CreateBookingService.styled";
import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobCardApi,
  getProductsListApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  getJobCardOptionsBasedOnProductId,
} from "./../../../redux/slices/serviceManagement/serviceManagementService";

import {
  getAllProducts,
  getBrandsByPId,
  createBookServiceApi,
  bookservicesummaryApi,
  getBooingDetails,
  editBookServiceApi,
  rejectBookServiceApi,
  approveBookServiceApi,
} from "./../../../redux/slices/BookingManagement/BookingManagementService";
import { getCustomerSearch } from "./../../../redux/slices/auth/authService";
import moment from "moment";
import { statusList, severityOptions } from "../../../Scenes/constants";
import PrevListSection from "./../../CreateJobcard/PrevListSection";
import arrow_down from "./../../../img/arrow-205.png";
import password_pattern from "./../../../img/password-pattern.png";

import { triggerMixpanelEvent } from "../../../Scenes/common";
const format = "h:mm a";
const currentDate = new Date();

const currentHour = currentDate.getHours();
const currentMinute = currentDate.getMinutes();

var timeNow = moment().hour(currentHour).minute(currentMinute);
// moment(new Date()).format("h:mm a");
const defaultAction = { id: 1, serviceTaken: "" };
const partsReplaced = { id: 1, partName: "", partSerialno: "" };

const requiredFieldsList = {
  customerName: false,
  mobileNo: false,
  productName: false,
  brandName: false,
  // serialNo1: false,
  // deliveryDateTemp: false,
  // deliveryDateTempTime: false,
  // minCharges: false,
  // warrantyServiceTemp: false,
  // incommingSource: false,

  // itemRecived: false,
  // defaultIssues: false,
  // customerAddRequirements: false,
  // selectedUserId: false,
  email: false,
  doorNo: false,
  areaOrWard: false,
  town: false,
  pinCode: false,
  // systemPassword: false,
  // severity: true,
  // customerAdditionalReqRemarks: true,
};
const requiredFieldsList1 = {
  customerName: false,
  mobileNo: false,
  productName: false,
  brandName: false,
  // serialNo1: false,
  // deliveryDateTemp: false,
  // deliveryDateTempTime: false,
  // minCharges: false,
  // warrantyServiceTemp: false,
  // incommingSource: false,

  // itemRecived: false,
  // defaultIssues: false,
  // customerAddRequirements: false,
  // selectedUserId: false,
  email: false,
  doorNo: false,
  areaOrWard: false,
  town: false,
  pinCode: false,
  // systemPassword: false,
  // severity: true,
  // customerAdditionalReqRemarks: true,
};
const editRequiredFields1 = {
  toCourierName: false,
  tempToCourierDate: false,
  toDocNumber: false,
  toCompanyName: false,
};

const editRequiredFields3 = {
  receiveingCourierName: false,
  tempReceiveingToCourierDate: false,
  receiveingDocNumber: false,
};

const CreateBookingService = (props) => {
  const [jobData, setJobData] = useState({
    minCharges: "150",
    priority: "",
  });
  const bookingId = props?.match?.params?.bookingId;
  // const [requiredData, setRequiredData] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [itemsRecievedList, setItemsRecievedList] = useState([]);
  const [defaultIssues, setDefaultIssues] = useState([]);
  const [cARequirements, setCARequirements] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessPopupUser, setShowSuccessPopupUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [actionTakenList, setActionTakenList] = useState([]);
  const [engineerObservationList, setEngineerObservationList] = useState([]);
  const [addNewUserPopup, setAddNewUserPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState(requiredFieldsList);
  const [requiredFields1, setRequiredFields1] = useState(requiredFieldsList1);
  const [messageBookingModel, setMessageBookingModel] = useState(false);
  const [warningMessageApprove, setWarningMessageApprove] = useState(false);
  const [warningMessageReject, setWarningMessageReject] = useState(false);
  const [messageText, setMessageText] = useState("");

  // const [editRequiredFields, setEditRequiredFields] = useState(
  //   editRequiredFields1
  // );
  // const [editRequiredFields2, setEditRequiredFields2] = useState(
  //   editRequiredFields3
  // );
  const [showItemReceived, setShowItemReceived] = useState(true);
  const [showDefaultIssues, setShowDefaultIssues] = useState(true);
  const [showCustomerAR, setShowCustomerAR] = useState(true);

  const [showItemReceivedRemarks, setShowItemReceivedRemarks] = useState(false);
  const [showDefaultIssuesRemarks, setShowDefaultIssuesRemarks] =
    useState(false);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showPasswordPatternLock, setShowPasswordPatternLock] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [openEngineerObservationsBox, setOpenEngineerObservationsBox] =
    useState(false);
  const [openCustomerNotesBox, setOpenCustomerNotesBox] = useState(false);
  const history = useHistory();
  const dropdownRef = useRef();

  // const [itemsRecievedListNoItems, setItemsRecievedListNoItems] = useState(
  //   false
  // );
  // const [defaultIssuesNoItems, setDefaultIssuesNoItems] = useState(false);
  // const [cARequirementsNoItems, setCARequirementsNoItems] = useState(false);

  const [updateData, setUpdateData] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [userSelectedInfo, setUserSelectedInfo] = useState({});
  const [actionTakenItems, setActionTakenItems] = useState([
    { ...defaultAction },
  ]);
  const [partsReplacedItems, setPartsReplacedItems] = useState([
    { ...partsReplaced },
  ]);
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
    getJobCardOptionsBasedOnProductIdResp,
  } = useSelector((state) => state.serviceManagement);

  const { allProductsList, allBrandsList, bookingDetails } = useSelector(
    (state) => state.bookingManagement
  );
  console.log("allBrandsList, bookingDetails", bookingDetails);
  const { searchUsersList, user } = useSelector((state) => state.auth);
  // console.log("itemsRecievedByProductIdResp", itemsRecievedByProductIdResp);
  // console.log("defaultIssuesByProductIdResp", defaultIssuesByProductIdResp);
  // console.log("customerAdditionalReqResp", customerAdditionalReqResp);
  const jobId = props?.match?.params?.jobId;
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowDropDown(false);
  //       setShowDropDown1(false);
  //     }
  //   };
  //   document.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);

  // console.log("jobIdjobId", jobId);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllProducts(null));
    // dispatch(bookservicesummaryApi("pageNo=1"));
    const isReadOnly1 =
      props?.match?.path === "/bookingdetails/:bookingId" ? true : false;
    setIsReadOnly(isReadOnly1);

    if (bookingId) {
      triggerMixpanelEvent("edit_booking_card_page", { bookingId });
      dispatch(getBooingDetails(bookingId));
      // setShowItemReceived(false);
      // setShowDefaultIssues(false);
      // setShowCustomerAR(false);
      // dispatch(getJobCardDetailsApi(jobId));
      // var timeNow = moment().hour(currentHour).minute(currentMinute);
    }
    // else {
    //   triggerMixpanelEvent("create_job_card_page");
    //   const newTime = moment(timeNow).format("H:mm");
    //   const newDDate = moment(timeNow).format("YYYY-MM-DD");

    //   // setTimeout(() => {
    //   //   setJobData({
    //   //     ...jobData,
    //   //     deliveryDateTempTime: newTime,
    //   //     deliveryDateTemp: newDDate,
    //   //   });
    //   // }, 1000);
    //   // setJobData({});
    // }
  }, []);
  useEffect(() => {
    if (bookingDetails?.id) {
      const prevData = jobData;

      prevData.brandName = bookingDetails?.brand?.name;
      prevData.productName = bookingDetails?.product?.name;
      prevData.selectedBrandId = bookingDetails?.brand?.id;
      prevData.selectedProductId = bookingDetails?.product?.id;

      prevData.customerName = bookingDetails?.customerInfo?.customerName;
      prevData.town = bookingDetails?.customerInfo?.town;
      prevData.areaOrWard = bookingDetails?.customerInfo?.areaOrWard;
      prevData.doorNo = bookingDetails?.customerInfo?.doorNo;
      prevData.email = bookingDetails?.customerInfo?.email;
      prevData.mobileNo = bookingDetails?.customerInfo?.mobileNo;
      prevData.pinCode = bookingDetails?.customerInfo?.pinCode;

      prevData.model = bookingDetails?.customerName;
      prevData.priority = bookingDetails?.customerName;
      prevData.serialNo1 = bookingDetails?.customerName;
      prevData.minCharges = bookingDetails?.customerName;
      // prevData.id = bookingDetails?.id;
      setAgreeCheckBox(true);
      setJobData(prevData);
      // history.push("/");
    }
  }, [bookingDetails]);

  // useEffect(async () => {
  //   const jobCData = jobCardDetails?.jobCard;
  //   if (jobCData?.id) {
  //     setJobData({
  //       ...jobCData,
  //       warrantyServiceTemp:
  //         jobCData?.warrantyServiceTemp || jobCData?.disWarrantyService,
  //       selectedBrandId: jobCData?.brand?.id,
  //       brandName: jobCData?.brand?.name,
  //       selectedProductId: jobCData?.product?.id,
  //       productName: jobCData?.product?.name,
  //       deliveryDateTemp: jobCardDetails?.date,
  //       deliveryDateTempTime: jobCardDetails?.time,
  //       selectedUserId: jobCData?.assignedTo?.id,
  //       originalJobCardReceived: jobCardDetails?.originalJobCardReceived,
  //       receiverPersonSame: jobCardDetails?.receiverPersonSame,
  //       priority: jobCardDetails?.priority,

  //       // customerNotes: jobCardDetails?.customerNotes?.[0]?.note,
  //     });
  //     setItemsRecievedList(jobCardDetails?.itemRecivedOptions);
  //     setDefaultIssues(jobCardDetails?.defaultIssuesOptions);
  //     setCARequirements(jobCardDetails?.additionalRequirementsOptions);

  //     setActionTakenList(jobCardDetails?.actionTakenOptions);
  //     setEngineerObservationList(jobCardDetails?.engineerObservationOptions);

  //     const newList = [];
  //     jobCardDetails?.brands?.map((item) => {
  //       newList.push({
  //         ...item,
  //         value: item?.brandName,
  //         label: item?.brandName,
  //       });
  //     });
  //     setBrandsList(newList);

  //     var newList12 = requiredFields1;

  //     if (jobCardDetails?.receiverPersonSame === "No") {
  //       newList12.originalNotJobCardReason = false;
  //     } else {
  //       delete newList12.originalNotJobCardReason;
  //     }
  //     if (jobCardDetails?.originalJobCardReceived === "No") {
  //       newList12.recevierName = false;
  //       newList12.recevierMobileNo = false;
  //     } else {
  //       delete newList12.recevierName;
  //       delete newList12.recevierMobileNo;
  //     }
  //     // setRequiredFields(newList12);
  //     setRequiredFields1(newList12);
  //   }
  // }, [jobCardDetails]);
  useEffect(() => {
    // const newList = [];
    // displayJobCardDetails?.brands?.map((item) => {
    //   newList.push({ ...item, value: item?.name, label: item?.name });
    // });
    // setBrandsList(newList);
    const newListProducts = [];

    displayJobCardDetails?.products?.map((item) => {
      newListProducts.push({ ...item, value: item?.id, label: item?.name });
    });
    setProductsList(newListProducts);
    const usersList1 = [];
    displayJobCardDetails?.users?.map((user) => {
      const name =
        user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
      usersList1.push({ id: user?.id, value: user?.id, label: name });
    });
    setUsersList(usersList1);
    // setItemsRecievedList(displayJobCardDetails?.itemRecivedOptions);
    // setDefaultIssues(displayJobCardDetails?.defaultIssuesOptions);
    // setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
  }, [displayJobCardDetails]);

  useEffect(() => {
    // const newList = [];
    // displayJobCardDetails?.brands?.map((item) => {
    //   newList.push({ ...item, value: item?.name, label: item?.name });
    // });
    // setBrandsList(newList);
    const newListProducts = [];

    allProductsList?.map((item) => {
      newListProducts.push({ ...item, value: item?.id, label: item?.name });
    });
    // newListProducts.push({ value: 23, label: "hot nam " });
    setProductsList(newListProducts);
    // const usersList1 = [];
    // displayJobCardDetails?.users?.map((user) => {
    //   const name =
    //     user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
    //   usersList1.push({ id: user?.id, value: user?.id, label: name });
    // });
    // setUsersList(usersList1);
    // setItemsRecievedList(displayJobCardDetails?.itemRecivedOptions);
    // setDefaultIssues(displayJobCardDetails?.defaultIssuesOptions);
    // setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
  }, [allProductsList]);

  useEffect(() => {
    const newList = [];
    allBrandsList?.map((item) => {
      newList.push({ ...item, value: item?.name, label: item?.name });
    });
    // newList.push({ value: 233, label: "hot nam " });
    setBrandsList(newList);
  }, [allBrandsList]);

  useEffect(() => {
    const newList = [];
    var selecbID = null;
    var selecbName = null;
    brandsByProductIdResp?.brands?.map((item) => {
      newList.push({ ...item, value: item?.name, label: item?.name });
      if (item?.selected === "selected") {
        selecbID = item?.id;
        selecbName = item?.name;
      }
    });
    setBrandsList(newList);
    // setItemsRecievedList(itemsRecievedByProductIdResp);

    // const prevValues = { ...jobData };
    // if (selecbID) {
    //   prevValues["selectedBrandId"] = selecbID;
    //   prevValues["brandName"] = selecbName;
    // }
    // setJobData(prevValues);
  }, [brandsByProductIdResp]);

  // useEffect(() => {
  //   setActionTakenList(
  //     getJobCardOptionsBasedOnProductIdResp?.actionTakenOptions
  //   );
  //   setEngineerObservationList(
  //     getJobCardOptionsBasedOnProductIdResp?.engineerObservationOptions
  //   );
  // }, [getJobCardOptionsBasedOnProductIdResp]);
  // useEffect(() => {
  //   setItemsRecievedList(itemsRecievedByProductIdResp);
  // }, [itemsRecievedByProductIdResp]);
  // useEffect(() => {
  //   setDefaultIssues(defaultIssuesByProductIdResp);
  // }, [defaultIssuesByProductIdResp]);

  // useEffect(() => {
  //   setCARequirements(customerAdditionalReqResp);
  // }, [customerAdditionalReqResp]);
  useEffect(() => {}, [jobData?.status]);
  const isDisabledBtn = () => {
    let returnF = false;
    if (jobId) {
      if (jobCardDetails?.jobCard?.status === "Closed") {
        returnF = true;
      }
      if (user?.roles[0] === "ROLE_STORE_OWNER") {
        returnF = false;
      }
    }
    return returnF;
  };

  // useEffect(() => {
  console.log("itemsRecievedByProductIdResp", itemsRecievedByProductIdResp);
  console.log("ItemsRecievedList", itemsRecievedList);
  const searchOnChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "items-recieved") {
      setShowItemReceived(true);
      if (value === "") {
        setItemsRecievedList(itemsRecievedByProductIdResp);
      } else {
        const newItems = [];
        itemsRecievedByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setItemsRecievedList(newItems);
      }
    } else if (name === "default-issues") {
      setShowDefaultIssues(true);
      if (value === "") {
        setDefaultIssues(defaultIssuesByProductIdResp);
      } else {
        const newItems = [];
        defaultIssuesByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setDefaultIssues(newItems);
      }
    } else if (name === "customer-additional") {
      setShowCustomerAR(true);
      if (value === "") {
        setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
      } else {
        const newItems = [];
        displayJobCardDetails?.additionalRequirementsOptions?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setCARequirements(newItems);
      }
    }
  };
  // }, [searchValue]);

  const onChangeValue = async (e) => {
    const { name, value } = e.target;
    const prevValues = { ...jobData };
    if (
      name === "minCharges" ||
      name === "printPageCount" ||
      name === "printBlack" ||
      name === "printColor"
    ) {
      var value1 = value;
      if (value.trim() == "") {
        value1 = value.trim();
      }
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
        setJobData(prevValues);
      }
    } else {
      prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
      setJobData(prevValues);
    }

    const newList = requiredFields;
    newList[name] = false;
    await setRequiredFields(newList);

    // const newList1 = editRequiredFields;
    // newList1[name] = false;
    // setEditRequiredFields(newList1);

    // const newList22 = editRequiredFields2;
    // newList22[name] = false;
    // setEditRequiredFields2(newList22);

    // if (name === "customerName") {
    //   getDropDownValues(value);
    //   setShowDropDown(true);
    // } else if (name === "mobileNo") {
    //   getDropDownValues(value);
    //   setShowDropDown1(true);
    // }
    // if (name === "engineerObservationOption") {
    //   setOpenEngineerObservationsBox(value ? true : false);
    // } else if (name === "customerNotes") {
    //   setOpenCustomerNotesBox(value ? true : false);
    // }
    // updateRecevierPersonList(name, value);
  };
  const updateRecevierPersonList = async (name, value) => {
    if (name === "receiverPersonSame") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        const fiallIst = {
          ...requiredFields1,
          recevierName: false,
          recevierMobileNo: false,
        };
        // await setRequiredFields(fiallIst);
        await setRequiredFields1(fiallIst);
      }
    }
    if (name === "originalJobCardReceived") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.originalNotJobCardReason;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        // await setRequiredFields({
        //   ...requiredFields1,
        //   originalNotJobCardReason: false,
        // });
        await setRequiredFields1({
          ...requiredFields1,
          originalNotJobCardReason: false,
        });
      }
    }
  };
  const onChangeValueTime = (e) => {
    const newTime = moment(e?._d).format("H:mm");
    setJobData({ ...jobData, deliveryDateTempTime: newTime });
  };
  const onChangeValueSelect = (name1, e) => {
    const PId = e?.id;
    // const { name, value } = e.target;
    const pValue = e.label;
    const prevValues = { ...jobData };
    prevValues[name1] = e.label;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);
    if (name1 === "productName") {
      prevValues["selectedProductId"] = PId;
      // prevValues["itemRecived"] = "";
      // prevValues["defaultIssues"] = "";
      // prevValues["customerAddRequirements"] = "";

      if (e.value === "") {
        prevValues["brandName"] = "";
      } else {
        const jsonBody = { PId, jobId: jobId ? jobId : 0 };
        dispatch(getBrandsByPId(PId));
        // dispatch(getBrandsByProductIdApi(jsonBody));

        // dispatch(getItemsRecievedByProductIdAPi(jsonBody));
        // dispatch(getDefaultIssuesByProductIdAPi(jsonBody));
        // dispatch(getCustomerAdditionalReqAPi(jsonBody));
        // dispatch(getJobCardOptionsBasedOnProductId(jsonBody));
      }
      // if (
      //   pValue === "Desktop" ||
      //   pValue === "Laptop" ||
      //   pValue === "Mobile" ||
      //   pValue === "TAB" ||
      //   pValue === "DVR"
      // ) {
      //   // setRequiredFields({ ...requiredFields, systemPassword: false });
      //   setRequiredFields1({ ...requiredFields1, systemPassword: false });
      // } else if (pValue === "Printer") {
      //   setRequiredFields1({ ...requiredFields1, printPageCount: false });
      // } else {
      //   // var newList1 = requiredFields;
      //   // delete newList1.systemPassword;
      //   // setRequiredFields(newList1);
      //   // var newList12 = requiredFields1;
      //   // delete newList12.systemPassword;
      //   // delete newList12.printPageCount;
      //   // setRequiredFields1(newList12);
      // }
      // setShowItemReceived(true);
      // setShowDefaultIssues(true);
      // setShowCustomerAR(true);
    } else {
      const braId = e?.brandId || e?.id;
      prevValues["selectedBrandId"] = braId;
    }

    setJobData(prevValues);
  };
  const onChangeValueSelectAssigned = (name1, e) => {
    // const { name, value } = e.target;
    const prevValues = { ...jobData };
    prevValues[name1] = e.id;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);

    setJobData(prevValues);
  };
  const dateFormatType = (date) => {
    var dtToday = new Date();
    if (date != undefined && date != "") {
      dtToday = new Date(date);
    }
    var month = "";
    var day = "";
    month = dtToday.getMonth() + 1;
    day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    if (user?.roles[0] === "ROLE_STORE_OWNER") {
      year = year - 1;
    }

    var DateNow = year + "-" + month + "-" + day;
    return DateNow;
  };
  const showExtraFields =
    jobData?.productName === "Desktop" ||
    jobData?.productName === "Laptop" ||
    jobData?.productName === "Mobile" ||
    jobData?.productName === "TAB" ||
    jobData?.productName === "DVR"
      ? true
      : false;
  const showPrinterFields = jobData?.productName === "Printer" ? true : false;
  const noItems = [1, 2, 3, 4, 5, 66, 7, 7, 8, 8];
  // $("#items-recieved-search-box").on("keyup", function () {
  //   var query = this.value?.toLowerCase();
  //   $("#loadItemsRecived_dev > div").each(function (i, elem) {
  //     var currentV = $(this).find("input")?.val()?.toLowerCase();
  //     if (currentV.indexOf(query) != -1) {
  //       $(this).removeClass("dis-none-imp");
  //     } else {
  //       $(this).addClass("dis-none-imp");
  //     }
  //   });
  // });
  const addNewRowForAction = (item, index, value1) => {
    const prevValues = actionTakenItems;
    prevValues.push({ ...defaultAction, id: index });
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const onChangeValueActionTaken = (e, item, index) => {
    const { name, value } = e.target;

    const prevValues = actionTakenItems;
    prevValues[index].serviceTaken = value;
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const showPartsReplacedSec = () => {
    let returnF = false;
    actionTakenItems?.map((itemV) => {
      console.log("itemV", itemV);
      if (
        itemV?.serviceTaken &&
        itemV?.serviceTaken?.toLowerCase().includes("replaced")
      ) {
        returnF = true;
      }
    });
    return returnF;
  };
  const deleteItemForAction = (item, index) => {
    const prevValues = actionTakenItems;

    prevValues.splice(index, 1);
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const addNewRowForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;
    prevValues.push({ ...defaultAction, id: index });
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const deleteItemForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;

    prevValues.splice(index, 1);
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
  };
  const handleValidate = () => {
    var firstEleId = "";
    var refurnFlag = false;
    const newList = requiredFields1;
    for (var key in newList) {
      const Pvalue = jobData[key];

      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        newList[key] = true;
        refurnFlag = true;
        if (firstEleId === "") {
          firstEleId = key;
        }
      }
    }
    setUpdateData(!updateData);
    setRequiredFields(newList);

    // if (
    //   jobData?.status === "Ready For Delivery" ||
    //   jobData?.status === "Out for Warranty"
    // ) {
    //   const editnewList = {
    //     toCourierName: false,
    //     tempToCourierDate: false,
    //     toDocNumber: false,
    //     toCompanyName: false,
    //   };
    //   for (var key1 in editnewList) {
    //     const Pvalue = jobData[key1];
    //     if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
    //       editnewList[key1] = true;
    //       refurnFlag = true;
    //       if (firstEleId === "") {
    //         firstEleId = key1;
    //       }
    //     }
    //   }
    //   setEditRequiredFields(editnewList);
    // }

    // if (
    //   jobData?.toCourierName &&
    //   (jobData?.status === "Repair Closed" ||
    //     jobData?.status === "Ready For Delivery")
    // ) {
    //   const editRequiredFields3_temp = {
    //     receiveingCourierName: false,
    //     tempReceiveingToCourierDate: false,
    //     receiveingDocNumber: false,
    //   };
    //   for (var key1 in editRequiredFields3_temp) {
    //     const Pvalue = jobData[key1];
    //     if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
    //       editRequiredFields3_temp[key1] = true;
    //       refurnFlag = true;
    //       if (firstEleId === "") {
    //         firstEleId = key1;
    //       }
    //     }
    //   }
    //   setEditRequiredFields2(editRequiredFields3_temp);
    // }
    console.log("firstEleId", firstEleId);
    console.log("refurnFlag", refurnFlag);
    if (refurnFlag && firstEleId) {
      const element = document.getElementById(firstEleId);
      if (element) {
        window.scrollTo({ top: element?.offsetTop - 100, behavior: "smooth" });
        // element.scrollIntoView({ behavior: "smooth" });
      }
    }

    return refurnFlag;
  };
  const handleSubmitBtn = async () => {
    // "minCharges": "150",
    // "priority": "",
    // "productName": "Desktop",
    // "selectedProductId": 2,
    // "brandName": "Asus",
    // "selectedBrandId": 2,
    // "model": "Model naam",
    // "serialNo1": "Ser334",
    // "customerName": "Vinod",
    // "mobileNo": "9164236553",
    // "email": "Tanavinod5@gmail.com",
    // "address1": "4th main",
    // "address2": "5th cross",
    // "city": "BAngalore",
    // "zipCode": "560056"

    // "selectedProductId":1,
    // "selectedBrandId":1,
    // "customerInfo":{
    //     "customerName":"Eppu",
    //     "mobileNo":"7899653621",
    //     "email":"praveenkumar1210@gmail.com",
    //     "doorNo":"2/282",
    //     "areaOrWard":"gas",
    //     "town":"Indluru",
    //     "pinCode":571634
    // }
    if (!handleValidate() && !isLoading && agreeCheckBox) {
      // jobData?.customerAddRequirements.map()
      const jobcard11 = { ...jobData };
      try {
        delete jobcard11.selectedProductId;
        delete jobcard11.selectedBrandId;
        delete jobcard11.model;
        delete jobcard11.serialNo1;
      } catch (e) {
        console.log("eeeee", e);
      }

      const finalJson = {
        selectedBrandId: jobData?.selectedBrandId || 1,
        selectedProductId: jobData?.selectedProductId || 1,
        serialNo1: jobData?.serialNo1,
        model: jobData?.model,
        id: bookingDetails?.id,
        customerInfo: { ...jobData },
        // disWarrantyService: "No",
        // selectedUserId: 1,
        // minCharges: 200,
        // warrantyService: 0,
        // serialNo1: "uuuuu",
        // serialNo2: "",
        // serialNo3: "",
        // systemPassword: "sys",
      };

      if (userSelectedInfo?.id) {
        finalJson.selectedCustomerId = userSelectedInfo?.id;
      }
      setIsLoading(true);
      // if (bookingId) {
      //   const resp = await dispatch(editBookServiceApi(finalJson));
      //   setIsLoading(false);
      // } else {
      const resp = bookingId
        ? await dispatch(editBookServiceApi(finalJson))
        : await dispatch(createBookServiceApi(finalJson));
      setIsLoading(false);
      // }
      // const resp1 = await dispatch(createJobCardApi(finalJson));
      // const resp2 = await dispatch(createJobCardApi(finalJson));
      // const resp3 = await dispatch(createJobCardApi(finalJson));
      // const resp4 = await dispatch(createJobCardApi(finalJson));
      // const resp5 = await dispatch(createJobCardApi(finalJson));
      // const resp6 = await dispatch(createJobCardApi(finalJson));
      // const resp7 = await dispatch(createJobCardApi(finalJson));
      // const resp8 = await dispatch(createJobCardApi(finalJson));
      // const resp9 = await dispatch(createJobCardApi(finalJson));
      // const resp24 = await dispatch(createJobCardApi(finalJson));
      // const resp34 = await dispatch(createJobCardApi(finalJson));
      // const resp45 = await dispatch(createJobCardApi(finalJson));
      // const resp556 = await dispatch(createJobCardApi(finalJson));
      // const resp63 = await dispatch(createJobCardApi(finalJson));
      // const resp27 = await dispatch(createJobCardApi(finalJson));
      // const resp856 = await dispatch(createJobCardApi(finalJson));
      if (resp?.payload?.id) {
        if (jobId) {
          triggerMixpanelEvent("edit_job_card_success");
        } else {
          triggerMixpanelEvent("create_job_card_success");
        }
        setShowSuccessPopup(true);
        window.scrollTo(0, 0);
        setJobData({});
      } else if (resp?.error?.message) {
        setErrorText(resp?.error?.message);
        setErrorModel(true);
      }
    } else if (!agreeCheckBox) {
    }
  };
  const onChnageValueList = (Jtype, item, e, newList = "") => {
    const newList1 = requiredFields;
    newList1[Jtype] = false;
    setRequiredFields(newList1);

    const isChecked = e.target.checked;
    const prevValues = { ...jobData };
    if (isChecked) {
      var newList2 = "";
      if (newList === "") {
        newList2 = item?.optionName;
      } else {
        newList2 = newList + "," + item?.optionName;
      }
      newList = newList2;
      // newList.push(item);
      if (
        item?.optionName === "No Items Received" ||
        item?.optionName === "No Additional Required" ||
        item?.optionName === "NO ADDITIONAL REQUIRED"
      ) {
        newList = item?.optionName;
      }
    } else {
      const newList1 = newList.split(",");
      const idx = newList1.findIndex((u) => u === item?.optionName);
      if (idx !== -1) {
        newList1.splice(idx, 1);
        newList = newList1.join(",");
      }
    }

    if (Jtype === "itemRecived") {
      prevValues.itemRecived = newList;
    } else if (Jtype === "defaultIssues") {
      prevValues.defaultIssues = newList;
    } else if (Jtype === "customerAddRequirements") {
      prevValues.customerAddRequirements = newList;
    }
    // var newList = prevValues?.itemRecived || [];

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // prevValues["itemRecived"] = value;
    setJobData(prevValues);
  };
  const checkItemByList = (list, item) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex((u) => u === item?.optionName);
      if (idx === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const isNoitemSelectedCheck = (list) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex(
        (u) =>
          u === "No Items Received" ||
          u === "No Additional Required" ||
          u === "NO ADDITIONAL REQUIRED"
      );
      if (idx === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const handlePartChange = (value, key, index) => {
    const newValues = partsReplacedItems;
    newValues[index][key] = value;
    setPartsReplacedItems(newValues);
  };
  const handleAddNewUSer = () => {
    triggerMixpanelEvent("add_new_customer_click");
    setAddNewUserPopup(true);
  };
  const addUserSuccess = (select) => {
    triggerMixpanelEvent("add_new_customer_success", select);
    handleCloseAddNewUSer();
    setShowSuccessPopupUser(true);
    setUserSelectedInfo(select);
    const prevValues = { ...jobData };
    prevValues["customerName"] = select?.customerName;
    prevValues["mobileNo"] = select?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);
  };
  const handleCloseAddNewUSer = () => {
    setAddNewUserPopup(false);
  };

  const handleClickBack = () => {
    history.push("/jobcardsummary");
  };
  const getDropDownValues = (val) => {
    if (val) {
      dispatch(getCustomerSearch(val));
    }
  };
  // const onChangeValuesManufacturer = (e) => {
  //   setBrandValue(e.target.value);
  //   // setTimeout(() => {
  //   const regJson = {
  //     Page: 1,
  //     PageSize: 25,
  //     Brand: e?.target?.value,
  //   };
  //   dispatch(getBrandsListApi(regJson));
  //   setFieldValue("brand", "");
  //   setFieldValue("manufacture", "");
  //   setFieldValue("manufacturerCode", "");
  //   setFieldValue("brandCode", "");
  //   if (e?.target?.value === "") {
  //     setShowDropDown(false);
  //     setFieldValue("manufacturer", "");
  //     setFieldValue("manufacturerCode", "");
  //   } else {
  //     setShowDropDown(true);
  //   }
  //   // }, 300);
  // };
  const handleManuClick = () => {
    setShowDropDown(true);
  };
  const handleItemClick = async (item) => {
    setShowDropDown(false);
    setShowDropDown1(false);
    setUserSelectedInfo(item);
    const prevValues = { ...jobData };
    prevValues["customerName"] = item?.customerName;
    prevValues["mobileNo"] = item?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);

    if (item?.value) {
      // await setBrandValue(item?.label);
      // const regJson = {
      //   brandCode: item?.value,
      // };
      // setFieldValue("brand", item?.label);
      // setFieldValue("brandCode", item?.value);
      // setFieldValue("manufacturer", item?.brandOwner);
      // setFieldValue("manufacturerCode", item?.brandOwnerCode);
      // setFieldValue("brandOemflag", item?.brandOemflag);
      // dispatch(getManfacturerApi(regJson));
    }
  };
  const handleClosePassLock = () => {
    setShowPasswordPatternLock(false);
  };
  const handleSuccessPassLock = (passData) => {
    handleClosePassLock();
    if (passData) {
      const prevValues = { ...jobData };
      prevValues["systemPassword"] = passData;
      setJobData(prevValues);
    }
  };
  const userSName = usersList?.find(
    (item1) => item1?.id === jobData?.selectedUserId
  )?.label;
  const handleEdit = () => {
    setIsReadOnly(false);
  };

  const handleApprove = async () => {
    setWarningMessageApprove(true);
  };
  const handleApprove1 = async () => {
    const body = {
      id: bookingId,
    };
    setWarningMessageApprove(false);
    await dispatch(approveBookServiceApi(body));
    setMessageText(
      "Successfully Approved the Booking service and Created the Job card."
    );
    setMessageBookingModel(true);
  };
  const handleReject = async () => {
    setWarningMessageReject(true);
  };
  const handleReject1 = async () => {
    const body = {
      id: bookingId,
    };
    await dispatch(rejectBookServiceApi(body));
    setMessageText("Successfully Rejected the Booking Service.");

    setMessageBookingModel(true);
  };
  // let currencyName = this.props?.currenciesList.find(
  //   (i: any) => collectionData?.currency == i?.id
  // )?.currencyCode;
  console.log("jobDatajobData", jobData);
  console.log("requiredFields", requiredFields);
  console.log("requiredFields1222", requiredFields1);
  console.log("brandsList", brandsList);
  return (
    <CreateJobcardStyled>
      <CreateBookingServiceStyled>
        <div>
          <CustomModal
            show={showSuccessPopup}
            titleText={
              bookingId
                ? "Booking Service Successfully Updated."
                : "Thank you for created the Booking Service, we will get in touch with you soon."
            }
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessPopup(false);
              history.push("/BookingServiceSummary");
              window.location.reload();
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessPopupUser}
            titleText={"Successfully Added Customer."}
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessPopupUser(false);
              // history.push("/jobcardsummary");
            }}
          />

          <CustomModal
            show={messageBookingModel}
            titleText={messageText}
            deleteText="OkAY"
            cancelText=""
            onDelete={() => {
              setMessageBookingModel(false);
              history.push("/BookingServiceSummary");
            }}
          />

          <ConfirmationModal
            className="club-confirmation"
            show={warningMessageApprove}
            onHide={() => {
              setWarningMessageApprove(false);
            }}
            description="Are you sure, do you want to Approve Booking Service and Create Job Card?"
            onConfirmClick={handleApprove1}
            confirmLabel="DELETE"
            cancelLabel="CANCEL"
          ></ConfirmationModal>

          <ConfirmationModal
            className="club-confirmation"
            show={warningMessageReject}
            onHide={() => {
              setWarningMessageReject(false);
            }}
            description="Are you sure, do you want to Reject?"
            onConfirmClick={handleReject1}
            confirmLabel="DELETE"
            cancelLabel="CANCEL"
          ></ConfirmationModal>
          <CustomModal
            show={errorModel}
            titleText={errorText}
            deleteText="Ok"
            cancelText=""
            dynamicClassName={`error_message`}
            onDelete={() => {
              setErrorModel(false);
              // history.push("/jobcardsummary");
            }}
          />

          <AddNewUser
            addUserSuccess={addUserSuccess}
            show={addNewUserPopup}
            onHide={handleCloseAddNewUSer}
          />
          <PasswordPatternLock
            handleSuccessPassLock={handleSuccessPassLock}
            show={showPasswordPatternLock}
            onHide={handleClosePassLock}
          />
          <div
            className={
              user?.id
                ? "focusnext-boby-section "
                : "focusnext-boby-section-guest focusnext-boby-section"
            }
          >
            <div className="">
              {jobId && (
                <div className="add-back-arrow">
                  <span onClick={handleClickBack}>{`< Back`}</span>
                </div>
              )}
              <div className="section-container-header">
                <h3>
                  {" "}
                  {"Book to Repair "}
                  {bookingId && <> Details </>}
                </h3>
                {/* <span>
                <a href="/jobcardsummary">Job Card Summary List</a>
              </span>
              <span onClick={handleAddNewUSer} className="add-new-user-sec">
                Add New Customer
              </span> */}
              </div>
            </div>
            <div className="focusnext-boby-content">
              <div className="col datatable1">
                <div>
                  <div>
                    <h3>Product Details</h3>
                  </div>
                  <div className="row ProductDetails1">
                    <div className="col-sm-3 product-name-drop-down">
                      <Form.Group
                        className={
                          requiredFields?.productName ? "error-fields-sec" : ""
                        }
                      >
                        <label htmlFor="" className="col-form-label">
                          Product Name <span className="manditary">*</span>
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={
                            jobData?.productName
                              ? jobData?.productName
                              : "Select"
                          }
                          isSearchable={true}
                          name="productName"
                          id="productName"
                          options={productsList}
                          onChange={(e) => {
                            onChangeValueSelect("productName", e);
                          }}
                          required
                          value={jobData?.productName}
                          placeholder={
                            jobData?.productName
                              ? jobData?.productName
                              : "Select"
                          }
                          isInvalid={requiredFields?.productName}
                          isDisabled={isReadOnly}
                        />
                        <Form.Control.Feedback type="invalid">
                          {requiredFields?.productName ? <></> : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-sm-3 product-name-drop-down">
                      <Form.Group
                        className={
                          requiredFields?.brandName ? "error-fields-sec" : ""
                        }
                      >
                        <label htmlFor="" className="col-form-label">
                          Brand Name <span className="manditary">*</span>
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={
                            jobData?.brandName ? jobData?.brandName : "Select"
                          }
                          placeholder={
                            jobData?.brandName ? jobData?.brandName : "Select"
                          }
                          isSearchable={true}
                          name="brandName"
                          id="brandName"
                          options={brandsList}
                          onChange={(e) => {
                            onChangeValueSelect("brandName", e);
                          }}
                          required
                          value={jobData?.brandName}
                          isInvalid={requiredFields?.brandName}
                          isDisabled={isReadOnly}
                        />
                        <Form.Control.Feedback type="invalid">
                          {requiredFields?.brandName ? <></> : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Model Name
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          // isInvalid={errors?.title}
                          value={jobData?.model}
                          type="text"
                          name="model"
                          disabled={isReadOnly}
                          onChange={onChangeValue}
                          // onChange={this.handleChangeValue}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Serial / IMEI No. 1{" "}
                          {/* <span className="manditary">*</span> */}
                        </Form.Label>
                        <Form.Control
                          maxLength={200}
                          isInvalid={requiredFields?.serialNo1}
                          value={jobData?.serialNo1}
                          type="text"
                          name="serialNo1"
                          id="serialNo1"
                          disabled={isReadOnly}
                          onChange={onChangeValue}
                          // onChange={this.handleChangeValue}
                        />
                        <Form.Control.Feedback type="invalid">
                          {requiredFields?.serialNo1 ? <></> : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <h3>Customer Details</h3>
                    </div>
                    <div className="fluid-container ProductDetails1">
                      <div className="row">
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Customer Name <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.customerName}
                              value={jobData?.customerName}
                              type="text"
                              name="customerName"
                              id="customerName"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.customerName ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Mobile Number <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.mobileNo}
                              value={jobData?.mobileNo}
                              type="text"
                              name="mobileNo"
                              id="mobileNo"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.mobileNo ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Email Address <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.email}
                              value={jobData?.email}
                              type="text"
                              name="email"
                              id="email"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.email ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              D No / Street / Land Mark{" "}
                              <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.doorNo}
                              value={jobData?.doorNo}
                              type="text"
                              name="doorNo"
                              id="doorNo"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.doorNo ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Area / Ward <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.areaOrWard}
                              value={jobData?.areaOrWard}
                              type="text"
                              name="areaOrWard"
                              id="areaOrWard"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.areaOrWard ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Town/City/Villege{" "}
                              <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.town}
                              value={jobData?.town}
                              type="text"
                              name="town"
                              id="town"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.town ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-sm-4 " ref={dropdownRef}>
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Pin code <span className="manditary">*</span>
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              isInvalid={requiredFields?.pinCode}
                              value={jobData?.pinCode}
                              type="text"
                              name="pinCode"
                              id="pinCode"
                              disabled={isReadOnly}
                              onChange={onChangeValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {requiredFields?.pinCode ? <></> : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!bookingId && (
                <div className="terms-sec-son">
                  <p className="text-signup-aggr-sec">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setAgreeCheckBox(e.target.checked);
                      }}
                    />{" "}
                    By clicking you agree to the{" "}
                    <a target="_blank" href="/termsandconditions">
                      Terms &amp; Conditions
                    </a>{" "}
                    When your business operates online, you’re connected to a
                    world wide web of potential customers, connections, and
                    networks. But you’re also exposing your business to several
                    risks and threats.
                  </p>
                </div>
              )}
              {!isReadOnly && (
                <div className="terms-sec-son-btn">
                  <button onClick={handleSubmitBtn}>
                    {bookingId ? "Save Details" : "Submit Your Request"}{" "}
                  </button>
                </div>
              )}
              {isReadOnly && (
                <div>
                  <div className="terms-sec-son-btn1">
                    <button className="edit_btn" onClick={handleEdit}>
                      Edit
                    </button>
                    <button className="approve_btn" onClick={handleApprove}>
                      Approve
                    </button>
                    <button className="reject_btn" onClick={handleReject}>
                      Reject
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CreateBookingServiceStyled>
    </CreateJobcardStyled>
  );
};
export default CreateBookingService;
