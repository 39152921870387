import styled from "styled-components";

export const StoresReportStyled = styled.div`
  .signup-container {
    width: 60%;
    margin: auto;
    margin-bottom: 400px;
  }
  .Usertable-info td,
  .Usertable-info th {
    padding: 0px 8px;
  }
  .Usertable-info-table {
    th,
    td {
      text-align: center;
      // font-size: 18px;
    }
  }
  .coupon_code_section {
    width: 400px;
    .coupon_code_section_content {
      > div {
        margin-bottom: 10px;
      }
      text-align: center;
      margin-top: 40px;
    }
    .error_message {
      margin-bottom: 0px;
      text-align: left;
      margin-top: 10px;
    }
    .input_coupon_code {
      width: 100%;
      height: 40px;
      border: 1px solid #e2e2e2;
      padding-left: 10px;
    }
    button {
      background-color: #7c4dff;
      color: white;
      border: none;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 10px;
      width: 80%;
      margin-top: 30px;
    }
  }
`;
