export const statusList = [
  { id: 1, value: "Approval Pending", label: "Approval Pending" },
  { id: 2, value: "Approved", label: "Approved" },
  { id: 3, value: "Buy Back", label: "Buy Back" },
  { id: 4, value: "Cancel", label: "Cancel" },
  { id: 4, value: "Completed with Repair", label: "Completed with Repair" },
  {
    id: 13,
    value: "Intentionally Left Out",
    label: "Intentionally Left Out",
  },
  { id: 16, value: "Internal Billing", label: "Internal Billing" },

  // { id: 5, value: "Closed", label: "Closed" },
  { id: 6, value: "Need Tech Support", label: "Need Tech Support" },
  { id: 7, value: "Open", label: "Open" },
  { id: 8, value: "Out for Warranty", label: "Out for Warrenty" },
  // { id: 9, value: "Approval Pending", label: "Approval Pending" },
  { id: 10, value: "Part Required", label: "Part Required" },
  { id: 11, value: "Pending", label: "Pending" },
  { id: 12, value: "Ready For Delivery", label: "Ready For Delivery" },

  { id: 14, value: "Return Without Repair", label: "Return Without Repair" },
  { id: 15, value: "Repair Start", label: "Repair Start" },
  { id: 16, value: "Repair Closed", label: "Repair Closed" },
  { id: 16, value: "Warranty Crated", label: "Warranty Crated" },
  {
    id: 16,
    value: "Waiting for Pickup (OW)",
    label: "Waiting for Pickup (OW)",
  },
  // { id: 16, value: "Coming Soon", label: "Coming Soon" },
];

export const selctfieldsList = [
  { id: 1, value: "customerName", label: "Customer Name" },
  { id: 2, value: "mobileNo", label: "Mobile No" },
  { id: 3, value: "jobNo", label: "Service Id" },
  { id: 4, value: "refNo", label: "Ref No" },
  { id: 5, value: "serialNo1", label: "Seril No1" },
];

export const statusListUpdate = [
  { id: 15, value: "Repair Start", label: "Repair Start" },
  {
    id: 13,
    value: "Intentionally Left Out",
    label: "Intentionally Left Out",
  },
  { id: 16, value: "Warranty Crated", label: "Warranty Crated" },
  {
    id: 16,
    value: "Waiting for Pickup (OW)",
    label: "Waiting for Pickup (OW)",
  },
  { id: 6, value: "Need Tech Support", label: "Need Tech Support" },
  { id: 16, value: "Internal Billing", label: "Internal Billing" },
];
export const severityOptions = ["High", "Medium", "Low"];
