import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getAllDashboardReportsApi = createAsyncThunk(
  "reports/getAllDashboardReportsApi",
  async (body: any) => await post(`${SERVER_IP}/dashboardReports`, body)
);

export const sendMeaageAPI = createAsyncThunk(
  "reports/sendMeaageAPI",
  async (body: any) => await post(`${SERVER_IP}/dashboardReports`, body)
);

export const getLineReportsByDate = createAsyncThunk(
  "reports/getLineReportsByDate",
  async (param: any) =>
    await post(`${SERVER_IP}/getJobcardCardCountbyDate`, param)
);
export const getStoreWiseJobCardReportAPi = createAsyncThunk(
  "reports/getStoreWiseJobCardReportAPi",
  async (param: any) =>
      await get(`${SERVER_IP}/getStoreWiseJobCardReport?startDate=${param?.startDate}&endDate=${param?.endDate}`)
);

export const getAllStoresReportAPi = createAsyncThunk(
  "reports/getAllStoresReportAPi",
  async () =>
    await get(`${SERVER_IP}/getAllStoresReport`)
);
