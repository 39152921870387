import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getAllProducts = createAsyncThunk(
  "bookingManagement/getProductsForBookService",
  async () => await get(`${SERVER_IP}/getProductsForBookService`)
);
export const getBrandsByPId = createAsyncThunk(
  "bookingManagement/getBrandsByPId",
  async (id?: any) =>
    await get(`${SERVER_IP}/getBrandsByProductIdForBookService/${id}`)
);
export const createBookServiceApi = createAsyncThunk(
  "bookingManagement/createBookServiceApi",
  async (body: any) => await post(`${SERVER_IP}/createBookService`, body)
);
export const bookservicesummaryApi = createAsyncThunk(
  "bookingManagement/bookservicesummaryApi",
  async (body: any) => await get(`${SERVER_IP}/bookservicesummary?${body}`)
);
export const getBooingDetails = createAsyncThunk(
  "bookingManagement/getBooingDetails",
  async (body: any) => await get(`${SERVER_IP}/getBookService/${body}`)
);
export const editBookServiceApi = createAsyncThunk(
  "bookingManagement/editBookServiceApi",
  async (body: any) => await put(`${SERVER_IP}/updateBookService`, body)
);

export const rejectBookServiceApi = createAsyncThunk(
  "bookingManagement/rejectBookServiceApi",
  async (body: any) => await put(`${SERVER_IP}/rejectBookService`, body)
);

export const approveBookServiceApi = createAsyncThunk(
  "bookingManagement/approveBookServiceApi",
  async (body: any) => await put(`${SERVER_IP}/approveBookService`, body)
);
