import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { TestingStyled } from "./Testing.styled";

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Axios from "axios";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
const Testing = (props: any) => {
  console.log("propsprops", props);

  const history = useHistory();
  const [isError, setIsError] = useState("");
  const dispatch = useDispatch();
  const [color, setColor] = useState("rgb(255, 255, 255)");
  const [miles, setMiles] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isLoding, setIsLoding] = useState(false);

  useEffect(() => {
    if (props?.show) {
      setMiles("");
      setZipCode("");
      setIsError("");
    }
  }, [props?.show]);
  const handleSubmitFn = () => {
    props?.handleOK();
  };
  const handleChange = (event: any) => {
    setIsError("");
    const { name, value } = event.target;
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (name === "zipcode") {
        setZipCode(value);
      } else {
        setMiles(value);
      }
    }
  };
  const handleSave = () => {
    getZipCodesList();
    if (!miles || !zipCode) {
      setIsError("Please Enter Mobile Number Code & Message");
    } else if (!miles) {
      setIsError("Please Enter Message");
    } else if (!zipCode) {
      setIsError("Please Enter Mobile Number Code");
    } else if (miles && zipCode) {
      getZipCodesList();
    }
  };
  const getZipCodesList = async () => {
    // var zipCode = zipcode;
    if (zipCode && miles) {
      alert(zipCode);
    }
    const url = `https://graph.facebook.com/v17.0//messages`;
    const body = {
      messaging_product: "whatsapp",
      to: "9164236553",
      type: "template",
      template: { name: "hello_world333", language: { code: "en_US" } },
    };
    // curl -i -X POST \
    // https://graph.facebook.com/v17.0//messages \
    // -H 'Authorization: Bearer EAAMa5FI023MBO8fHFYrwclLlIekWvztZCRTQrDjx91JiOmcuCeCLcHj6ZCwcy9DzFn9yd2XHkcmbVFCszdhfHTeKGCeZCxgn5QZCszvEkz7AgcLGJnBejV2MXpSw4IZAb8HzNLr69uKItlSOvW4EILJsG3XoAHd5dCVpBbd8CZAnpkeQfAFjwDxz6qZAfvrlxqXZClfbWOndMMI0gJXZC' \
    // -H 'Content-Type: application/json' \
    // -d '{ "messaging_product": "whatsapp", "to": "919164236553", "type": "template", "template": { "name": "hello_world", "language": { "code": "en_US" } } }'
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer EAAMa5FI023MBO8fHFYrwclLlIekWvztZCRTQrDjx91JiOmcuCeCLcHj6ZCwcy9DzFn9yd2XHkcmbVFCszdhfHTeKGCeZCxgn5QZCszvEkz7AgcLGJnBejV2MXpSw4IZAb8HzNLr69uKItlSOvW4EILJsG3XoAHd5dCVpBbd8CZAnpkeQfAFjwDxz6qZAfvrlxqXZClfbWOndMMI0gJXZC",
    };
    const response = await Axios.post(url, body, { headers });
    console.log("responseresponse", response);
    //   return response.data;

    // await axios
    //   .get(url)
    //   .then((res: any) => {
    //     if (res?.data?.zip_codes) {
    //       setIsLoding(false);
    //       props?.handleOK(res?.data?.zip_codes, zipCode);
    //     } else {
    //       setIsLoding(false);
    //       setIsError("Please enter valid data!");
    //     }
    //   })
    //   .catch((rror: any) => {
    //     setIsLoding(false);
    //     setIsError("Please enter valid ZIP Code");
    //   });
  };
  const handleCancel = () => {
    setZipCode("");
    setMiles("");
  };
  // console.log("signUpData final", JSON.stringify(signUpData));
  return (
    <>
      <TestingStyled>
        <div className="content-sec-closest-to-me">
          <div>
            <div className="content-sec-closest-con">
              <h5>Send Messages to Customers</h5>
              <p>Send Messages to Customers. </p>
            </div>
            <div className="content-sec-closest-con-sec">
              <div>
                <p>Mobile Number</p>
                <input
                  name="zipcode"
                  maxLength={5}
                  value={zipCode}
                  onChange={handleChange}
                  placeholder="Enter your ZIP Code"
                />
              </div>
              <div>
                <p>Message</p>
                <input
                  onChange={handleChange}
                  name="miles"
                  value={miles}
                  maxLength={4}
                  placeholder="Enter your Miles"
                />
              </div>
            </div>
          </div>
          {isError && <p className="error-color">{isError}</p>}
          <div>
            <div className="actions-btns-sec">
              <button onClick={handleCancel} className="cancel-btn-sec">
                CANCEL
              </button>
              <button onClick={handleSave} className="cancel-btn-sec-save">
                Submit
              </button>
            </div>
          </div>
        </div>
      </TestingStyled>
    </>
  );
};
export default Testing;
