import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import icons8_data_anal from "../../img/icons8-data-analysis-66.png";
import focusnext_main_img from "../../img/focusnext-main-img.png";
import icons_circled_play from "../../img/icons8-circled-play.gif";
import sec1 from "../../img/sec1.png";
import sec2 from "../../img/sec2.png";
import sec3 from "../../img/sec3.png";
import sec4 from "../../img/sec4.png";
import sec5 from "../../img/sec5.png";
import mergedImages from "../../img/MergedImages.png";
import dashboard_image from "../../img/dashboard-image.png";

import user_branches_banner from "../../img/user_branches_banner.png";
import icons8_combo_chart_48 from "../../img/icons8-combo-chart-48.png";
import icons8_customer_support_48 from "../../img/icons8-customer-support-48.png";
import { ServicesStyled } from "./Services.styled";

const Services = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
  }, []);

  const gotoSignupPage = (url) => {
    history.push(`/${url}`);
  };
  return (
    <>
      <ServicesStyled>
        <div className="focusnext-services-page-container">
          <div className="focusnext-services-wrapper">
            <div className="services-sec1">
              <div className="services-sec1-content">
                <h2>Services</h2>
                <h3>Benefit from our years of experience.</h3>
                <p>
                  Find out what our team of experts can help you with throughout
                  your journey...
                </p>
              </div>
            </div>

            <div className="services-sec2">
              <div className="services-sec2-content ">
                <div className="services-sec2-left services-sec2-left-own">
                  <img src={dashboard_image} />
                </div>
                <div className="services-sec2-right">
                  <h2>IMPLEMENTATION</h2>
                  <h3>We make the software yours.</h3>
                  <p>
                    Rasko offers per sonalized services for the integration of
                    any of our products. Our implementation team guarantees
                    installation services, as well as migrating your site's
                    existing applicable data into the program. These services
                    can be stand-alone, but we find that they work best
                    together.
                  </p>
                </div>
              </div>
            </div>

            <div className="services-sec2">
              <div className="services-sec2-content">
                <div className="services-sec2-left">
                  <img src={sec2} />
                </div>
                <div className="services-sec2-right">
                  <h2>SUPPORT SERVICES</h2>
                  <h3>Personalized support for your every need.</h3>
                  <p>
                    We offer personalized support for all our products. One of
                    our values is being supportive, and we reflect it through
                    our services. We have an international support desk managed
                    by a team of skilled IT professionals with over 10+ years of
                    experience.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="services-sec2">
              <div className="services-sec2-content">
                <div className="services-sec2-left">
                  <img src={sec3} />
                </div>
                <div className="services-sec2-right">
                  <h2>DATA MANAGEMENT</h2>
                  <h3>Flexible hosting options to suit your needs.</h3>
                  <p>
                    The IMS Suite is a SaaS solution, which means it's entirely
                    cloud-based. Because of this, you don't need to worry about
                    installing, updating, or maintaining the software. SaaS
                    solutions are hassle-free and cost-effective. We offer both
                    flexible hosting and excellent security options for our
                    customers.
                  </p>
                </div>
              </div>
            </div> */}
            <div className="services-sec2">
              <div className="services-sec2-content">
                <div className="services-sec2-left services-sec2-left-own">
                  <img src={mergedImages} />
                </div>
                <div className="services-sec2-right">
                  <h2>TRAINING</h2>
                  <h3>Master our tools and methodologies.</h3>
                  <p>
                    We provide a variety of in-depth training programs to help
                    you make the most out of the IMS Suite and all the relevant
                    methodologies around it.
                  </p>
                </div>
              </div>
            </div>
            <div className="services-sec2 services-sec2-demo">
              <div className="services-sec2-demo-content">
                <div className="services-sec2-demo-con-hea">
                  <h3>DEMO</h3>
                  <h2>Beyond our solutions.</h2>
                </div>
                <div className="services-sec2-content">
                  <div className="services-sec2-left">
                    <img src={sec5} />
                  </div>
                  <div className="services-sec2-right">
                    <h3>We offer services for every step of your journey.</h3>
                    <p>
                      Get in touch today to learn more about what we can do for
                      you
                    </p>
                    <a href="/contactUS">
                      {" "}
                      <button>Request a demo</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-color-shadow1" id="pricingSection">
              <div className="sign-up-top-sec">
                <h3>Sign up now for 365 days free trail.</h3>
                <p>
                  Sign up now as a Store owner and register unlimited user to
                  access at one subscription and create unlimited job cards.
                </p>
                <button
                  onClick={() => {
                    gotoSignupPage("signup");
                  }}
                >
                  Start Now
                </button>
                <div>
                  <p>
                    Already Registered? <a href="/login">Log in</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="services-sec2 services-sec2-demo2">
              <div className="services-sec2-content">
                <div className="services-sec2-content-demo">
                  <h3>Get in touch with us</h3>
                  <div className="get-touch-actions">
                    <a href="/contactUS">
                      <button>Request a demo</button>
                    </a>

                    <a href="/contactUS">
                      <button>Contact us</button>
                    </a>
                    <a href="/contactUS">
                      {" "}
                      <button>Newsletter</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="empty-height"></div> */}
          </div>
        </div>
      </ServicesStyled>
    </>
  );
};

export default Services;
