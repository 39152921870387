import styled from "styled-components";

export const ServicesStyled = styled.div`
  .services-sec1-content {
    text-align: center;
    padding: 50px;
    width: 55%;
    margin: auto;
    margin-bottom: 50px;
    h2 {
      text-transform: uppercase;
      font-family: "Neue Plak SemiBold", sans-serif;
      letter-spacing: 0.06em;
      color: #979797;
      font-size: 18px;
    }
    h3 {
      line-height: 1em;
      margin-top: 0.2em;
      margin-bottom: 0.6em;
      font-family: "Neue Plak Bold", sans-serif;
      font-size: calc(35px + 40 * ((100vw - 375px) / 1545));
      font-weight: 600;
    }
    p {
      font-family: "Neue Plak SemiBold", sans-serif;
      line-height: 1.6em;
      color: #979797;
      font-size: 20px;
    }
  }
  .services-sec2-content {
    background-color: #dddfff;
    display: flex;
    gap: 50px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    border-radius: 12px;
    padding-top: calc(10px + 6vw);
    padding-bottom: calc(10px + 6vw);
    // margin-right: auto;
    padding-left: calc(10px + 6vw);
    padding-right: calc(10px + 6vw);
    .services-sec2-left {
      width: 60%;
      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        // height: auto;
      }
    }
    .services-sec2-right {
      width: 40%;
      padding-top: 60px;
    }
    h2 {
      color: #6c6c6c;
      margin-bottom: 0.5em;
      font-family: "Neue Plak SemiBold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      font-size: 18px;
    }
    h3 {
      line-height: 1em;
      margin-top: 0.5em;
      margin-bottom: 0.6em;
      font-family: "Neue Plak Bold", sans-serif;
      font-size: 55px;
      font-weight: 600;
    }
    p {
      font-family: "Neue Plak SemiBold", sans-serif;
      line-height: 1.6em;
      color: #979797;
      font-size: 20px;
    }
    button {
      background-color: #fff;
      color: #021415;
      height: 3.3em;
      border-radius: 3em;
      width: 200px;
      border: none;
      transition: opacity 0.2s cubic-bezier(0.55, 0.085, 0, 0.99),
        background-color 0.2s cubic-bezier(0.55, 0.085, 0, 0.99),
        color 0.2s cubic-bezier(0.55, 0.085, 0, 0.99);
      font-size: calc(14px + 3 * ((100vw - 375px) / 1545));
      font-weight: 400;
      font-family: "Neue Plak SemiBold", sans-serif;
      margin-top: 10px;
    }
    button:hover {
      color: #fff;
      background-color: #4682b4;
    }
  }
  .services-sec2-demo {
    margin: auto;
    width: 95%;
    margin-top: 30px;
  }
  .services-sec2-demo-content {
    padding: calc(10px + 4vw) calc(10px + 6vw) calc(50px + 6vw);
    background-color: #021415;
    border-radius: 12px;
    .services-sec2-content {
      background-color: #021415;
      margin-top: 0px;
      padding: 0px;
      padding-top: 50px;

      border-radius: 12px;
      h3 {
        color: #fff;
        font-size: 40px;
      }
    }
  }
  .services-sec2-demo-con-hea {
    text-align: center;
    h3 {
      font-size: calc(15px + 3 * ((100vw - 375px) / 1545));
      text-transform: uppercase;
      font-family: "Neue Plak SemiBold", sans-serif;
      letter-spacing: 0.06em;
      color: #979797;
    }
    h2 {
      font-size: calc(35px + 33 * ((100vw - 375px) / 1545));
      // max-width: 14em;
      text-align: center;
      line-height: 1.2em;
      color: #fff;
    }
  }
  .services-sec2-demo2 {
    background-color: #1e4248;
    .services-sec2-content {
      background-color: #1e4248;
      border-radius: 0px;

      width: 100%;
      display: block;
      h3 {
        color: #fff;
        text-align: center;
      }
    }
    .get-touch-actions {
      display: flex;
      justify-content: center;
      gap: 50px;
    }
  }
  .services-sec2-content .services-sec2-left-own img {
    object-fit: fill;
    height: 500px;
  }
  .empty-height {
    height: 30px;
    background-color: #fff;
  }

  .bg-color-shadow1 {
    background-color: #f8f8f8;
    margin-top: 50px;
    background-color: #dddfff;
  }

  .services-sec2-demo2,
  .bg-color-shadow1 {
    width: 95%;
    margin: auto;
    margin-top: 30px;
    border-radius: 12px;
    padding-top: calc(10px + 2vw);
    padding-bottom: calc(10px + 2vw);
    padding-left: calc(10px + 6vw);
    padding-right: calc(10px + 6vw);
  }
  .services-sec2-demo2 {
    margin-bottom: 50px;
    padding-top: 0px;
  }
  .bg-color-shadow {
    background-color: #fff;
  }
  .sign-up-top-sec {
    padding: 70px 0px;
    text-align: center;
    h3 {
      font-size: 46px;
      line-height: 47px;
      color: #0b2238;
      margin: 10px 0 15px;
      font-weight: 600;
      -webkit-letter-spacing: -1px;
      -moz-letter-spacing: -1px;
      -ms-letter-spacing: -1px;
      letter-spacing: -1px;
    }
    button {
      margin-top: 20px;
      background-color: #4682b4;
      text-transform: uppercase;
      font-family: "Work Sans", sans-serif;
      color: #fff;
      font-size: 16px;
      border-radius: 25px;
      line-height: 50px;
      font-weight: 600;
      display: inline-block;
      letter-spacing: 0.5px;
      padding: 0 30px;
      box-shadow: 0 3px 10px rgba(71, 44, 173, 0.2);
      border: none;
    }
    p {
      margin-top: 12px;
      // font-size: 20px;
      a {
        color: #f9751b;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 700px) {
    .services-sec1-content {
      padding: 10px;
      width: 90%;
      margin-bottom: 20px;
      h2 {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .services-sec2-content {
      display: block;
      padding: 20px;
      > div {
        width: 100% !important;
        text-align: center;
      }
    }
    .services-sec2-content .services-sec2-left-own img,
    .services-sec2-content .services-sec2-left img {
      object-fit: contain;
      height: auto;
    }
    .services-sec2-right {
      padding-top: 40px !important;
    }
    .services-sec2-content h3 {
      font-size: 40px;
    }
    .get-touch-actions {
      display: block !important;
      button {
        margin-bottom: 20px;
      }
    }
    .sign-up-top-sec {
      padding: 10px 0px;
      h3 {
        line-height: 54px;
      }
    }
  }
`;
