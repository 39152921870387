import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "./../../assets/SearchIcon.svg";
import "./styles.css";

const SearchComponent = (props) => {
  const [searchText, setSearchText] = useState("");
  const placeText = props?.placeHolderText ?? "search collections";
  return (
    <div className="searchBox">
      <Form.Control
        type="text"
        onKeyDown={(e) => e.keyCode === 13 && props.searchClickCall(searchText)}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        placeholder={placeText}
        className="searchBoxTextField"
      />
      <span
        className="iconBox"
        onClick={() => {
          props.searchClickCall(searchText);
        }}
      >
        <SearchIcon className="searchBoxIcon" />
      </span>
    </div>
  );
};

export default SearchComponent;
