import styled from "styled-components";

export const CheckJobcardStatusStyled = styled.div`
  .middle-section {
    width: 70%;
    margin: auto;
    margin-top: -0px;

    margin-bottom: 60px;
    min-height: 500px;
  }
  .job-header {
    margin-bottom: 30px;
  }
  .job-header-body {
    margin-bottom: 30px;
  }
  .fields-sec-input-box {
    input {
      height: 50px;
      color: #000;
      font-size: 18px;
    }
    label {
      font-size: 18px;
      margin-bottom: 3px;
    }
  }
  .submit-btn-sec {
    button {
      background: #4682b4;
      width: 170px;
      height: 50px;
      border: none;
      border-radius: 4px;
      color: #fff;
      margin-top: 28px;
      font-size: 24px;
    }
  }
`;
