import styled from "styled-components";

export const StoresReportStyled = styled.div`
  .signup-container {
    width: 60%;
    margin: auto;
    margin-bottom: 400px;
  }
  .Usertable-info td,
  .Usertable-info th {
    padding: 0px 8px;
  }
  .store-created-date {
    width: 100px;
  }
  .Usertable-info-table {
    th,
    td {
      text-align: center;
      // font-size: 18px;
    }
  }
`;
