import { PasswordPatternLockStyled } from "./PasswordPatternLock.styled";
import Button from "../../components/Button/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
import { useEffect, useState } from "react";
// import PatternLock from "react-pattern-lock";

const PasswordPatternLock = (props: any) => {
  const { selectedData, handleSuccessPassLock } = props;
  // console.log("selectedData", selectedData);
  const [path, setPath] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (selectedData?.id) {
      setInputValue(selectedData?.name);
    }
  }, [selectedData]);
  const handleSubmitFn = () => {
    console.log("inputValue", inputValue);
  };
  const reset = () => {
    // Renable the pattern
    setDisabled(false);
    // Clear the pattern.
    setPath([]);
  };
  const handleSuccessPassLock1 = () => {
    handleSuccessPassLock(path.join(""));
  };
  return (
    <>
      <Modal
        {...props}
        className="conformation-add-user-model-box1"
        size="lg"
        centered
      >
        <div className="conformation-model">
          <PasswordPatternLockStyled>
            <Modal.Body>
              <div className="add-user-sec-box">
                <div className="new-option-header">
                  <h4>{`Select Password Pattern Lock`}</h4>
                  <CloseButton onClick={props?.onHide} />
                </div>
                <div className="password-pattern-body">
                  {/* <PatternLock
                    path={path}
                    width={300}
                    size={3}
                    disabled={disabled}
                    onChange={(path: any) => setPath(path)}
                    onFinish={() => {
                      setDisabled(true);
                    }}
                    style={{
                      margin: "0 auto",
                    }}
                  /> */}

                  {/* A button that is used to reset the pattern */}
                </div>

                <div className="password-pattern-actions">
                  <button className="reset-btn" onClick={reset}>
                    Reset
                  </button>
                  <button
                    className="pass-ok-btn"
                    onClick={handleSuccessPassLock1}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal.Body>
          </PasswordPatternLockStyled>
        </div>
      </Modal>
    </>
  );
};
export default PasswordPatternLock;
