import styled from "styled-components";

export const PasswordPatternLockStyled = styled.div`
  .password-pattern-body {
    width: 350px;
    background: #4682b4;
    margin: auto;
    border-radius: 10px;
  }
  .new-option-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .password-pattern-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    button {
      width: 150px;
      height: 45px;
      font-size: 20px;
    }
    .reset-btn {
      background: #fff;
      border: 2px solid #000;
      border-radius: 4px;
    }
    .pass-ok-btn {
      background: #000;
      color: #fff;
      border: 2px solid #000;
      border-radius: 4px;
    }
  }
  .react-pattern-lock__pattern-wrapper.disabled
    .react-pattern-lock__point-inner,
  .react-pattern-lock__pattern-wrapper.disabled .react-pattern-lock__connector {
    background: #000;
  }
`;
