import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "react-select";

import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobCardApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  // getActionTakenoptList,
} from "../../redux/slices/serviceManagement/serviceManagementService";
import moment from "moment";
import PrevListSection from "../CreateJobcard/PrevListSection";

import { JobcardDetailsStyled } from "./JobCardDetails.styled";
const GuestJobCardDetails = (props: any) => {
  const jobId = props?.match?.params?.jobId;
  const { pageFrom } = props;
  const dispatch = useDispatch();
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
    guestJobCardDetails,
  } = useSelector((state: any) => state.serviceManagement);

  useEffect(() => {
    if (jobId) {
      dispatch(getJobCardDetailsApi(jobId));
    }
  }, []);
  console.log("jobCardDetails", jobCardDetails);
  // const { jobCard } = jobCardDetails;
  const jobCard = guestJobCardDetails;
  const itemRecivedList = jobCard?.itemRecived?.split(",");
  const defaultIssuesList = jobCard?.defaultIssues?.split(",");
  const customerAddRequirementsList =
    jobCard?.customerAddRequirements?.split(",");
  // guestJobCardDetails;
  console.log("guestJobCardDetails", guestJobCardDetails);
  return (
    <>
      <JobcardDetailsStyled>
        <div className="focusnext-boby-section">
          <div className="">
            {!pageFrom ? (
              <div className="section-container-header">
                <h3> {`Job card Details : ${jobCard?.id}`} </h3>
                <span>
                  <a href="/jobcardsummary">Job Card Summary List</a>
                </span>
              </div>
            ) : (
              <div className="section-container-header">
                <h3> {`Job card Details :`} </h3>
              </div>
            )}
          </div>
          <div className="focusnext-boby-content">
            <div className="col datatable1">
              <div className="fluid-container ProductDetails1">
                <div className="row job-card-details-items">
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Mobile No: </div>
                      <div className="col-6 col-value">{jobCard?.mobileNo}</div>
                    </div>
                  </div>

                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Customer Name: </div>
                      <div className="col-6 col-value">
                        {jobCard?.customerName}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel"> Job Card No: </div>
                      <div className="col-6 col-value">{jobCard?.id}</div>
                    </div>
                  </div>
                  <div className="col-6 mb-2 job-status-fields">
                    <div className="row">
                      <div className="col-6 col-lavel"> Job Card Status: </div>
                      <div className="col-6 col-value">{jobCard?.status}</div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">
                        Alternative Mobile No:
                      </div>
                      <div className="col-6 col-value">
                        {jobCard?.alternativeNo}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Email: </div>
                      <div className="col-6 col-value">
                        {jobCard?.customerEmail}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Product Name: </div>
                      <div className="col-6 col-value">
                        {jobCard?.product?.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Brand Name: </div>
                      <div className="col-6 col-value">
                        {jobCard?.brand?.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Model Name: </div>
                      <div className="col-6 col-value">{jobCard?.model}</div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Seril No 1: </div>
                      <div className="col-6 col-value">
                        {jobCard?.serialNo1}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Seril No 2: </div>
                      <div className="col-6 col-value">
                        {jobCard?.serialNo2}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Seril No 3: </div>
                      <div className="col-6 col-value">
                        {jobCard?.serialNo3}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">
                        Laptop/Desktop Password:
                      </div>
                      <div className="col-6 col-value">
                        {jobCard?.systemPassword}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Booking Date: </div>
                      <div className="col-6 col-value">
                        {jobCardDetails?.date}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Booking Time: </div>
                      <div className="col-6 col-value">
                        {jobCardDetails?.time}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Inspection Charges:</div>
                      <div className="col-6 col-value">
                        ₹ {jobCard?.minCharges}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Payment Type: </div>
                      <div className="col-6 col-value">
                        {jobCard?.warrantyServiceTemp ||
                        jobCard?.disWarrantyService === "Yes"
                          ? "Warranty"
                          : "Chargeable"}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Incomming Source: </div>
                      <div className="col-6 col-value">
                        {jobCard?.incommingSource}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Assigned To: </div>
                      <div className="col-6 col-value">
                        {`${jobCard?.assignedTo?.firstName} ${jobCard?.assignedTo?.lastName}`}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Status: </div>
                      <div className="col-6 col-value">{jobCard?.status}</div>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="row">
                      <div className="col-6 col-lavel">Severity: </div>
                      <div className="col-6 col-value">
                        {jobCardDetails?.severity}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="itmes-selected-section">
                    <div className="itmes-selected-header">Items Recieved:</div>
                    <div className="itmes-selected-content">
                      {itemRecivedList?.map((itemV: any) => {
                        return <span>{itemV}</span>;
                      })}
                    </div>
                  </div>
                  <div className="itmes-selected-section">
                    <div className="itmes-selected-header">Default Issues:</div>
                    <div className="itmes-selected-content">
                      {defaultIssuesList?.map((itemV: any) => {
                        return <span>{itemV}</span>;
                      })}
                    </div>
                    <div>
                      <span className="col-lavel">Remark:</span>{" "}
                      <span>XXX</span>
                    </div>
                  </div>
                  <div className="itmes-selected-section">
                    <div className="itmes-selected-header">
                      Customer Additional Requirements:
                    </div>
                    <div className="itmes-selected-content">
                      {customerAddRequirementsList?.map((itemV: any) => {
                        return <span>{itemV}</span>;
                      })}
                    </div>
                    <div>
                      <span className="col-lavel">Remark:</span>{" "}
                      <PrevListSection items={jobCardDetails?.csRemarks} />
                    </div>
                  </div>
                  <div className="itmes-selected-section">
                    <div className="itmes-selected-header">
                      Action Taken List:
                    </div>
                    <div className="itmes-selected-content">
                      <PrevListSection
                        section="actionTaken"
                        items={jobCardDetails?.actionTakens}
                      />
                    </div>
                  </div>
                  <div className="itmes-selected-section">
                    <div className="itmes-selected-header">
                      Parts Replaced List:
                    </div>
                    <div className="itmes-selected-content parts-all-items-sec">
                      <PrevListSection
                        section="partReplaced"
                        items={jobCardDetails?.partReplaceds}
                      />
                      {/* <div className="parts-replaced-item parts-replaced-item-header">
                        <div className="key-parts">Part Name - </div>
                        <div className="key-value">Serial No</div>
                      </div>
                      {itemRecivedList?.map((itemV: any) => {
                        return (
                          <div className="parts-replaced-item">
                            <div className="key-parts">Keyboard </div>
                            <div className="key-value">Ser No 12345</div>
                          </div>
                        );
                      })} */}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="extra-items-info">
                    <span className="col-lavel">Engineer Observations:</span>{" "}
                    <PrevListSection
                      items={jobCardDetails?.savedEngineerObservations}
                    />
                  </div>
                  <div className="extra-items-info">
                    <span className="col-lavel">Customer Notes:</span>{" "}
                    <PrevListSection items={jobCardDetails?.customerNotes} />
                  </div>
                  <div className="extra-items-info">
                    <span className="col-lavel">
                      Recevier person is same as that of customer:
                    </span>{" "}
                    <span>{jobCardDetails?.receiverPersonSame}</span>
                  </div>
                  <div className="extra-items-info">
                    <span className="col-lavel">
                      Original Job Sheet Recevied:
                    </span>{" "}
                    <span>{jobCardDetails?.originalJobCardReceived}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </JobcardDetailsStyled>
    </>
  );
};
export default GuestJobCardDetails;
