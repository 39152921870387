// import { ProductsDashboardStyled } from "./ProductsDashboard.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Table } from "antd";
import "./styles.css";
import Marquee from "react-fast-marquee";
import {
  statusList,
  severityOptions,
  statusListUpdate,
} from "./../../Scenes/constants";
import moment from "moment";

import { JobCardDashboardStyled } from "./../JobcardSummary/JobCardDashboard.styled";
import {
  getAllBrands,
  getProductsListApi,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import {
  getAllUsersList,
  deleteUserApi,
} from "../../redux/slices/auth/authService";
import { getAllDashboardReportsApi } from "../../redux/slices/Reports/ReportsService";
import { Chart } from "react-google-charts";
import DateRangeSelect from "./../../components/DateRangeSelect/DateRangeSelect";

// import { getDashboards } from "./../../../redux/slices/serviceManagement/serviceManagementService";

const CommonReportData = (props: any) => {
  const { statusUpdate, secName } = props;
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  const { brandsList, productsList } = useSelector(
    (state: any) => state.serviceManagement
  );

  const { error, loading, user, searchUsersList, allUsersList } = useSelector(
    (state: any) => state.auth
  );
  const { reportsData } = useSelector((state: any) => state.reports);
  console.log("reportsData", reportsData);
  const history = useHistory();
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [fullDataList, setFullDataList] = useState([] as any);
  const [finalDataList, setFinalDataList] = useState([] as any);
  const [stateDatev, setStateDatev] = useState("");
  const [endDatev, setEndDatev] = useState("");
  const buttonRef = useRef(null as any);

  useEffect(() => {
    console.log("user", user);
    if (user?.id) {
      dispatch(getProductsListApi());
      dispatch(getAllUsersList());
      getAllDashboardsData();
      //   dispatch(getDashboards(false));
    } else {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    if (allUsersList) {
      const usersList1 = [] as any;
      usersList1.push({ id: 0, value: "", label: "All" });

      allUsersList?.map((user: any) => {
        const name =
          user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
        usersList1.push({ id: user?.id, value: user?.id, label: name });
      });
      setUsersList(usersList1);
    }
  }, [allUsersList]);
  useEffect(() => {
    if (reportsData) {
      getDashboardData(secName);
    }
  }, [reportsData]);

  const getAllDashboardsData = () => {
    const body = {
      groupby: [
        "Product",
        "Brand",
        "Status",
        "Enginner/User",
        "actionTaken",
        "customerNote",
        "PaymentType",
        "incomingSource",
      ],
      fromDate: moment().subtract(30, "days").format("DD-MM-YYYY"),
      toDate: moment().format("DD-MM-YYYY"),
    };
    dispatch(getAllDashboardReportsApi(body));
    setStateDatev(body?.fromDate);
    setEndDatev(body?.toDate);
  };
  const handleFilterClick = (datesV?: any) => {
    console.log("datesV", datesV);
    const fromDate1 = moment(datesV[0]?.startDate).format("DD-MM-YYYY");
    const toDate1 = moment(datesV[0]?.endDate).format("DD-MM-YYYY");
    const body = {
      groupby: [secName],
      fromDate: fromDate1,
      toDate: toDate1,
    };
    setStateDatev(fromDate1);
    setEndDatev(toDate1);
    dispatch(getAllDashboardReportsApi(body));
    buttonRef.current.click();
  };
  console.log("productsList", productsList);

  // const options = {
  //   chart: {
  //     title: "Product wise Dashboard",
  //     subtitle: "How many job cards are creted till now ",
  //   },
  //   // width: "20px",
  // };
  // const options1 = {
  //   title: "Total Status List",
  // };
  // const options2 = {
  //   title: "Today activites List",
  // };
  // const getAllProductsList = () => {
  //   let data = [];
  //   const itemsc = [
  //     320,
  //     150,
  //     103,
  //     286,
  //     230,
  //     820,
  //     130,
  //     510,
  //     10,
  //     30,
  //     40,
  //     140,
  //     210,
  //   ];
  //   data.push(["Products List", "Job cards"]);
  //   productsList.slice(0, 10)?.map((itt: any, index: any) => {
  //     if (itt?.name) {
  //       const tempItem = [itt?.name?.substr(0, 18), itemsc[index]];
  //       data.push(tempItem);
  //     }
  //   });
  //   data.push(["Others", 120]);
  //   return data;
  // };
  // const getAllStatusList = () => {
  //   let data = [];
  //   const itemsc = [
  //     320,
  //     150,
  //     103,
  //     286,
  //     230,
  //     820,
  //     130,
  //     510,
  //     10,
  //     30,
  //     40,
  //     140,
  //     210,
  //   ];
  //   data.push(["Task", "Hours per Day"]);
  //   statusList.slice(0, 30)?.map((itt: any, index: any) => {
  //     if (itt?.value) {
  //       const tempItem = [itt?.value?.substr(0, 18), itemsc[index]];
  //       data.push(tempItem);
  //     }
  //   });
  //   data.push(["Others", 90]);
  //   return data;
  // };
  // const getAllStatusList1 = () => {
  //   let data = [];
  //   const itemsc = [6, 5, 3, 6, 1, 1, 3, 4, 3, 7];
  //   data.push(["Task", "Hours per Day"]);
  //   statusList.slice(0, 8)?.map((itt: any, index: any) => {
  //     if (itt?.value) {
  //       const tempItem = [itt?.value?.substr(0, 18), itemsc[index]];
  //       data.push(tempItem);
  //     }
  //   });
  //   data.push(["Closed", 15]);
  //   data.push(["Others", 5]);
  //   return data;
  // };

  const getDashboardData = (typeName: any) => {
    console.log("typeName", typeName);
    console.log("reportsData 222", reportsData[typeName]);

    let allData = reportsData[typeName];
    let finalData = [] as any;
    let returnData = [] as any;
    returnData.push([`${secName} List`, "Job cards"]);

    if (allData && allData?.length > 0) {
      allData.map((item: any) => {
        finalData.push({ ...item });
      });

      finalData?.sort((a: any, b: any) => {
        return b.count - a.count;
      });
      let finalData1 = [] as any;
      let othersCount = 0;
      setFullDataList(finalData);
      finalData.map((iitm: any, index: any) => {
        if (index <= 9) {
          finalData1.push(iitm);
        } else {
          othersCount += iitm?.count;
        }
      });
      finalData1.push({ name: "Others", count: othersCount });
      finalData1.map((iitm: any) => {
        const tempItem = [iitm?.name?.substr(0, 18), iitm.count];
        returnData.push(tempItem);
      });
      setFinalDataList(returnData);
      // return returnData;
      console.log("finalData", finalData);
    }
    // const;
  };

  // const data3 = getAllProductsList();
  // const data_status = getAllStatusList();
  // const data_today = getAllStatusList1();
  const productsData = finalDataList;
  // getDashboardData("product");
  console.log("usersList", usersList);
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      key: 1,
      render: (item: any, record: any, index: any) => {
        const indexP = index + 1;
        return <>{indexP}</>;
      },
    },
    {
      title: "Name",
      width: "60%",
      key: 1,
      render: (_: any, record: any) => {
        const finaLN = record?.name || record?.optionName || record?.status;
        return (
          <>
            <span className="table-option-text">{finaLN?.toLowerCase()}</span>{" "}
          </>
        );
      },
    },
    {
      title: "Count",
      width: "30%",
      dataIndex: "count",
      key: 1,
      render: (name: any) => {
        return (
          <>
            <span className="table-option-text">{name} </span>
          </>
        );
      },
    },
  ];
  return (
    <>
      {/* <ProductsDashboardStyled> */}
      {/* <JobCardDashboardStyled> */}

      <div className="job-card-dashboard job-card-dashboard-all-reports">
        {(secName === "product" ||
          secName === "brand" ||
          secName === "status" ||
          secName === "user") && (
          <>
            <h2 className="section-container-header-new">
              {secName} wise Dashboard
            </h2>
            <div className="quick-search-section-list">
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne1">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                      ref={buttonRef}
                    >
                      Select Custom Date wise
                    </button>
                  </h2>
                  <div
                    id="collapseOne1"
                    className="accordion-collapse  collapse" //open //collapse
                    aria-labelledby="headingOne1"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      <div className="line-reports-status-view-sec">
                        <DateRangeSelect
                          handleFilterClick={handleFilterClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            {/* <br /> */}
            <h3>
              Showing Reports from {stateDatev} to {endDatev}
            </h3>
            <br />
            {/* <br /> */}
            {/* <div className="main-all-sec-report">
              <div>
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="300px"
                  data={productsData}
                />
              </div>
              <div>
                <Chart
                  chartType="PieChart"
                  data={productsData}
                  width={"100%"}
                  height={"300px"}
                />
              </div>
            </div> */}
            <h2 className="section-container-header-new">
              All {secName}s list
            </h2>
            <br />
            <Table
              columns={columns}
              dataSource={fullDataList}
              scroll={{ y: 540 }}
              pagination={false}
              className="all-reports-data-table"
            />
          </>
        )}

        {/* <h2 className="section-container-header-new">Status wise Dashboard</h2>
        <Chart
          chartType="PieChart"
          data={data_status}
          options={options1}
          width={"100%"}
          height={"600px"}
        /> */}
        {(secName === "user" || secName === "actiontaken") && (
          <>
            <h2 className="section-container-header-new">
              Today All Employes Status
            </h2>
            <div className="col-sm-3 product-name-drop-down">
              <Form.Group>
                <label htmlFor="" className="col-form-label">
                  Assigned To <span className="manditary">*</span>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue="Select"
                  isSearchable={true}
                  options={usersList}
                  onChange={(e) => {
                    // setFromDashboard(false);
                    // handleChangeAssigne(e);
                  }}
                />
              </Form.Group>
            </div>
            <Chart
              chartType="PieChart"
              data={productsData}
              // options={options2}
              width={"100%"}
              height={"400px"}
            />
          </>
        )}
      </div>
      {/* </JobCardDashboardStyled> */}
      {/* </ProductsDashboardStyled> */}
    </>
  );
};
export default CommonReportData;
