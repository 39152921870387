import styled from "styled-components";

export const AddNewUserStyled = styled.div`
  .new-option-header {
    display: flex;
    justify-content: space-between;
    // margin-top: 20px;
    button {
      margin-top: -15px;
    }
    h4 {
      color: #007bff;
    }
  }
  .signup-md-full {
    width: 100%;
    float: left;
  }
  .row-item-sec-user {
    width: 100%;
    display: flex;
  }

  .add-new-submit-sec {
    width: 100%;
    display: flex;
    justify-content: end;
    button {
      width: 250px;
      margin-top: 30px;
      background: #000;
      color: #fff;
      border: none;
      height: 45px;
      font-size: 22px;
    }
  }
  .add-new-user-changes1 {
    margin-top: 10px;
    .form-group {
      margin-bottom: 7px;
    }
    .form-label {
      margin-bottom: 1px;
    }
  }
  .add-new-user-changes1 .form-group label {
    font-size: 18px;
    color: rgb(11, 102, 178);
  }
`;
