import styled from "styled-components";

export const CreateBookingServiceStyled = styled.div`
  .terms-sec-son {
    width: 80%;
    margin: auto;
  }
  .focusnext-boby-section-guest {
    margin-top: 50px;
    width: 75%;
    margin: auto;
    margin-top: 20px;
  }
  .focusnext-boby-content22 {
    margin-top: 20px;
  }
  .terms-sec-son-btn {
    text-align: center;
    button {
      height: 45px;
      background: #4682b4;
      border: 4px;
      color: #fff;
      padding: 0px 40px;
      border-radius: 4px;
      font-size: 20px;
    }
  }
  .terms-sec-son-btn1 {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
    button {
      height: 45px;
      background: #000;
      border: 4px;
      color: #fff;
      width: 200px;
      padding: 0px 40px;
      border-radius: 4px;
      font-size: 20px;
    }
    .edit_btn {
      background: #fff;
      color: #000;
      border: 1px solid #000;
    }
    .approve_btn {
      background: #209020;
      color: #fff;
      border: 1px solid #209020;
    }
    .reject_btn {
      background: #ff1d1d;
      color: #fff;
      border: 1px solid #ff1d1d;
    }
  }
`;
