import CommonDynamicComponent from "./../ServiceManagement/CommonDynamicComponent/CommonDynamicComponent";
const PaymentModeList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Payment Mode List"
        section="paymentModeList"
        subTitle="Create New Payment Mode"
      />
    </>
  );
};
export default PaymentModeList;
