import { ProductsDashboardStyled } from "./ProductsDashboard.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import Select from "react-select";
import CommonReportData from "./../Reports/CommonReportData";

import Marquee from "react-fast-marquee";
import {
  statusList,
  severityOptions,
  statusListUpdate,
} from "./../../Scenes/constants";

import { JobCardDashboardStyled } from "./../JobcardSummary/JobCardDashboard.styled";
import {
  getAllBrands,
  getProductsListApi,
  getItemReceivedList,
  getDefaultIssuesList,
  getAdditionalRequirementsList,
  getActionTakenList,
  getEngineerObservationList,
  getCustomerNotesList,
  deleteproductCall,
  deletebrandCall,
  deletedefaultissuesoptionCall,
  deleteengineerobservationCall,
  deletecustomernoteCall,
  deleteitemrecivedoptionCall,
  deleteadditionalrequirementsoptionCall,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import {
  getAllUsersList,
  deleteUserApi,
} from "../../redux/slices/auth/authService";

import { Chart } from "react-google-charts";

// import { getDashboards } from "./../../../redux/slices/serviceManagement/serviceManagementService";

const ProductsDashboard = (props: any) => {
  const { statusUpdate } = props;
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  const {
    brandsList,
    productsList,
    itemsReceivedList,
    defaultIssuesList,
    additionalRequirementsList,
    actionTakenList,
    engineerObservationList,
    customerNotesList,
  } = useSelector((state: any) => state.serviceManagement);

  const { error, loading, user, searchUsersList, allUsersList } = useSelector(
    (state: any) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    console.log("user", user);
    if (user?.id) {
      dispatch(getProductsListApi());
      dispatch(getAllUsersList());
      //   dispatch(getDashboards(false));
    } else {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    if (allUsersList) {
      const usersList1 = [] as any;
      usersList1.push({ id: 0, value: "", label: "All" });

      allUsersList?.map((user: any) => {
        const name =
          user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
        usersList1.push({ id: user?.id, value: user?.id, label: name });
      });
      setUsersList(usersList1);
    }
  }, [allUsersList]);
  console.log("productsList", productsList);
  const data = [
    ["Year", "Sales"],
    ["2014", 1000],
    ["2015", 1170],
    ["2016", 660],
    ["2017", 1030],
  ];

  const options = {
    chart: {
      title: "Product wise Dashboard",
      subtitle: "How many job cards are creted till now ",
    },
    // width: "20px",
  };
  const options1 = {
    title: "Total Status List",
  };
  const options2 = {
    title: "Today activites List",
  };
  const getAllProductsList = () => {
    let data = [];
    const itemsc = [
      320, 150, 103, 286, 230, 820, 130, 510, 10, 30, 40, 140, 210,
    ];
    data.push(["Products List", "Job cards"]);
    productsList.slice(0, 10)?.map((itt: any, index: any) => {
      if (itt?.name) {
        const tempItem = [itt?.name?.substr(0, 18), itemsc[index]];
        data.push(tempItem);
      }
    });
    data.push(["Others", 120]);
    return data;
  };
  const getAllStatusList = () => {
    let data = [];
    const itemsc = [
      320, 150, 103, 286, 230, 820, 130, 510, 10, 30, 40, 140, 210,
    ];
    data.push(["Task", "Hours per Day"]);
    statusList.slice(0, 30)?.map((itt: any, index: any) => {
      if (itt?.value) {
        const tempItem = [itt?.value?.substr(0, 18), itemsc[index]];
        data.push(tempItem);
      }
    });
    data.push(["Others", 90]);
    return data;
  };
  const getAllStatusList1 = () => {
    let data = [];
    const itemsc = [6, 5, 3, 6, 1, 1, 3, 4, 3, 7];
    data.push(["Task", "Hours per Day"]);
    statusList.slice(0, 8)?.map((itt: any, index: any) => {
      if (itt?.value) {
        const tempItem = [itt?.value?.substr(0, 18), itemsc[index]];
        data.push(tempItem);
      }
    });
    data.push(["Closed", 15]);
    data.push(["Others", 5]);
    return data;
  };

  const data4 = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];
  const data3 = getAllProductsList();
  const data_status = getAllStatusList();
  const data_today = getAllStatusList1();

  console.log("usersList", usersList);
  const options22 = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };
  const data22 = [
    ["Date", "Created", "Closed"],
    ["01/12/2023", 8, 2],
    ["02/12/2023", 15, 6],
    ["03/12/2023", 10, 3],
    ["04/12/2023", 0, 3],
    ["05/12/2023", 3, 3],
    ["06/12/2023", 7, 2],
    ["07/12/2023", 1, 13],
    ["08/12/2023", 5, 2],
    ["09/12/2023", 12, 4],
    ["10/12/2023", 0, 5],
    ["11/12/2023", 10, 4],
    ["12/12/2023", 13, 3],
    ["13/12/2023", 7, 12],
    ["14/12/2023", 3, 9],
    ["15/12/2023", 2, 10],
    ["16/12/2023", 4, 6],
  ];

  const data33 = [
    [
      { type: "date", label: "Day" },
      "Average temperature",
      "Average hours of daylight",
    ],
    [new Date(2014, 0), -0.5, 5.7],
    [new Date(2014, 1), 0.4, 8.7],
    [new Date(2014, 2), 0.5, 12],
    [new Date(2014, 3), 2.9, 15.3],
    [new Date(2014, 4), 6.3, 18.6],
    [new Date(2014, 5), 9, 20.9],
    [new Date(2014, 6), 10.6, 19.8],
    [new Date(2014, 7), 10.3, 16.6],
    [new Date(2014, 8), 7.4, 13.3],
    [new Date(2014, 9), 4.4, 9.9],
    [new Date(2014, 10), 1.1, 6.6],
    [new Date(2014, 11), -0.2, 4.5],
  ];

  const options33 = {
    chart: {
      title: "Average Temperatures and Daylight in Iceland Throughout the Year",
    },
    width: 900,
    height: 500,
    series: {
      // Gives each series an axis name that matches the Y-axis below.
      0: { axis: "Temps" },
      1: { axis: "Daylight" },
    },
    axes: {
      // Adds labels to each axis; they don't have to match the axis names.
      y: {
        Temps: { label: "Temps (Celsius)" },
        Daylight: { label: "Daylight" },
      },
    },
  };

  return (
    <>
      <ProductsDashboardStyled>
        {/* <JobCardDashboardStyled> */}
        <div className="job-card-dashboard">
          {/* <Chart
            chartType="Line" //
            width="2000px"
            height="400px"
            data={data33}
            options={options33}
          /> */}
          <Chart
            chartType="Line" //
            width="800px"
            height="400px"
            data={data22}
            options={options22}
          />
          <CommonReportData secName="product" />
          <CommonReportData secName="brand" />
          <CommonReportData secName="user" />

          <div></div>
        </div>
        {/* </JobCardDashboardStyled> */}
      </ProductsDashboardStyled>
    </>
  );
};
export default ProductsDashboard;
