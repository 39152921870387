import { ServiceManagementStyled } from "../ServiceManagement.styled";
import { Table } from "antd";
import { useEffect, useState } from "react";
import AddNewOption from "./AddNewOption";
import type { ColumnsType } from "antd/es/table";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBrands,
  getProductsListApi,
  getItemReceivedList,
  getDefaultIssuesList,
  getAdditionalRequirementsList,
  getActionTakenList,
  getEngineerObservationList,
  getCustomerNotesList,
  deleteproductCall,
  deletebrandCall,
  deletedefaultissuesoptionCall,
  deleteengineerobservationCall,
  deletecustomernoteCall,
  deleteitemrecivedoptionCall,
  deleteadditionalrequirementsoptionCall,
} from "./../../../redux/slices/serviceManagement/serviceManagementService";
import {
  paymentmodelistApi,
  configurationlistApi,
  getleadstatuslistApi,
  getaddOrUpdateLeadStatusAPi,
  geteditleadstatusApi,
  leadactiontakenlistApi,
} from "./../../../redux/slices/LeadManagement/leadManagementService";
import { triggerMixpanelEvent, isRaskoAccount } from "../../../Scenes/common";
const CommonDynamicComponent = (props: any) => {
  const secName = props?.section;
  const [searchValue, setSearchValue] = useState("");
  const [addNewPopup, setAddNewPopup] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState({});
  const [dataItemsAll, setDataItemsAll] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState({} as any);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState("" as any);
  const dispatch = useDispatch();
  const {
    brandsList,
    productsList,
    itemsReceivedList,
    defaultIssuesList,
    additionalRequirementsList,
    actionTakenList,
    engineerObservationList,
    customerNotesList,
  } = useSelector((state: any) => state.serviceManagement);
  const {
    paymentModesList,
    configurationList,
    leadStatusList,
    leadActionTakenList,
  } = useSelector((state: any) => state.leadManagement);

  const isProductBrand =
    secName === "brandsList" || secName === "productList" ? true : false;
  //   const [editorState, setEditorState] = useState(EditorState.createEmpty());
  console.log("brandsList", brandsList);
  useEffect(() => {
    if (searchValue) {
      const newItems = [] as any;
      dataItemsAll.map((item: any) => {
        const OVal = item?.name || item?.optionName;
        if (OVal && OVal?.toLowerCase().includes(searchValue?.toLowerCase())) {
          newItems.push(item);
        }
        // if (item?.name && item?.name?.toLowerCase().includes(searchValue?.toLowerCase())) {
        //   newItems.push(item);
        // }
      });
      setDataItems(newItems);
      console.log("searchValue", searchValue);
    } else {
      setDataItems(dataItemsAll);
    }
  }, [searchValue]);
  useEffect(() => {
    //brandsList
    dispatch(getProductsListApi());
    if (secName === "brandsList") {
      dispatch(getAllBrands());
    } else if (secName === "productList") {
      // dispatch(getAllBrands());
    } else if (secName === "itemsReceivedList") {
      dispatch(getItemReceivedList({}));
    } else if (secName === "defaultIssuesOptionList") {
      dispatch(getDefaultIssuesList({}));
    } else if (secName === "additionalRequirementList") {
      dispatch(getAdditionalRequirementsList({}));
    } else if (secName === "actiontakenList") {
      dispatch(getActionTakenList({}));
    } else if (secName === "engineerObservationList") {
      dispatch(getEngineerObservationList({}));
    } else if (secName === "customerNoteList") {
      dispatch(getCustomerNotesList({}));
    } else if (secName === "paymentModeList") {
      dispatch(paymentmodelistApi());
    } else if (secName === "configurationList") {
      dispatch(configurationlistApi());
    } else if (secName === "leadStatusList") {
      dispatch(getleadstatuslistApi());
    } else if (secName === "leadActiontakenList") {
      dispatch(leadactiontakenlistApi());
    }

    triggerMixpanelEvent(`sm_${secName}_page`);
  }, []);
  useEffect(() => {
    if (secName === "brandsList") {
      setDataItems(brandsList);
      setDataItemsAll(brandsList);
    } else if (secName === "productList") {
      setDataItems(productsList);
      setDataItemsAll(productsList);
    } else if (secName === "itemsReceivedList") {
      setDataItems(itemsReceivedList);
      setDataItemsAll(itemsReceivedList);
    } else if (secName === "defaultIssuesOptionList") {
      setDataItems(defaultIssuesList);
      setDataItemsAll(defaultIssuesList);
    } else if (secName === "additionalRequirementList") {
      setDataItems(additionalRequirementsList);
      setDataItemsAll(additionalRequirementsList);
    } else if (secName === "actiontakenList") {
      setDataItems(actionTakenList);
      setDataItemsAll(actionTakenList);
    } else if (secName === "engineerObservationList") {
      setDataItems(engineerObservationList);
      setDataItemsAll(engineerObservationList);
    } else if (secName === "customerNoteList") {
      setDataItems(customerNotesList);
      setDataItemsAll(customerNotesList);
    } else if (secName === "paymentModeList") {
      setDataItems(paymentModesList);
      setDataItemsAll(paymentModesList);
    } else if (secName === "configurationList") {
      setDataItems(configurationList);
      setDataItemsAll(configurationList);
    } else if (secName === "leadStatusList") {
      setDataItems(leadStatusList);
      setDataItemsAll(leadStatusList);
    } else if (secName === "leadActiontakenList") {
      setDataItems(leadActionTakenList);
      setDataItemsAll(leadActionTakenList);
    }
  }, [
    brandsList,
    productsList,
    itemsReceivedList,
    defaultIssuesList,
    additionalRequirementsList,
    actionTakenList,
    engineerObservationList,
    customerNotesList,
    paymentModesList,
    configurationList,
    leadStatusList,
    leadActionTakenList,
  ]);
  // console.log("paymentModesList", paymentModesList);
  // console.log("configurationList", configurationList);
  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    setSearchValue(value);
  };
  const cancelPopup = () => {
    setIsConfirmModalOpen(false);
    setIsDeleteModalOpen(false);
    setAddNewPopup(false);
  };
  const handleRemove = async () => {
    setIsConfirmModalOpen(false);
    // /api/deleteengineerobservation/{id}
    // /api/deletedefaultissuesoption/{id}
    // /api/deletecustomernote/{id}
    // /api/deletecustomer/{customerId}
    // /api/deleteadditionalrequirementsoption/{id}
    // /api/deleteitemrecivedoption/{id}
    if (selectedDeleteItem) {
      if (secName === "brandsList") {
        await dispatch(deletebrandCall(selectedDeleteItem));
        dispatch(getAllBrands());
      } else if (secName === "productList") {
        await dispatch(deleteproductCall(selectedDeleteItem));
        dispatch(getProductsListApi());
      } else if (secName === "itemsReceivedList") {
        await dispatch(deleteitemrecivedoptionCall(selectedDeleteItem));
        dispatch(getItemReceivedList({}));
      } else if (secName === "defaultIssuesOptionList") {
        await dispatch(deletedefaultissuesoptionCall(selectedDeleteItem));
        dispatch(getDefaultIssuesList({}));
      } else if (secName === "additionalRequirementList") {
        await dispatch(
          deleteadditionalrequirementsoptionCall(selectedDeleteItem)
        );
        dispatch(getAdditionalRequirementsList({}));
      } else if (secName === "actiontakenList") {
        await dispatch(deleteproductCall(selectedDeleteItem));
        dispatch(getActionTakenList({}));
      } else if (secName === "engineerObservationList") {
        await dispatch(deleteengineerobservationCall(selectedDeleteItem));
        dispatch(getEngineerObservationList({}));
      } else if (secName === "customerNoteList") {
        await dispatch(deletecustomernoteCall(selectedDeleteItem));
        dispatch(getCustomerNotesList({}));
      } else if (secName === "paymentModeList") {
        await dispatch(deleteproductCall(selectedDeleteItem));
        dispatch(paymentmodelistApi());
      } else if (secName === "configurationList") {
        await dispatch(deleteproductCall(selectedDeleteItem));
        dispatch(configurationlistApi());
      }

      setIsDeleteModalOpen(true);
      setSelectedDeleteItem("");
      dispatch(getProductsListApi());
    }
  };
  const handleDelete = (Ditem: any) => {
    console.log("DitemDitem", Ditem);
    setSelectedDeleteItem(Ditem);
    setIsConfirmModalOpen(true);
  };
  // console.log("data items", dataItems);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "10%",
      key: 1,
      render: (item: any, record: any, index: any) => {
        const indexP = index + 1;
        return <>{indexP}</>;
      },
    },
    {
      title: "Name",
      width: isProductBrand ? "40%" : "60%",
      key: 1,
      render: (_: any, record: any) => {
        const finaLN = record?.name || record?.optionName || record?.status;
        return (
          <>
            <span className="table-option-text">{finaLN?.toLowerCase()}</span>{" "}
          </>
        );
      },
    },
    {
      title: "Show In Book Service",
      width: "10%",
      dataIndex: "showInBookService",
      className: "data-show-book-service",
      key: 1,
      render: (name: any) => {
        return (
          <>
            <span className="table-option-text">
              {isProductBrand && <input type="checkbox" checked={name} />}
            </span>
          </>
        );
      },
    },
    {
      title: "Show In Lead",
      width: "10%",
      dataIndex: "showInLead",
      className: "data-show-book-service",
      key: 1,
      render: (name: any) => {
        return (
          <>
            <span className="table-option-text">
              {isProductBrand && <input type="checkbox" checked={name} />}
            </span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: isProductBrand ? "15%" : "25%",
      className: "data-actions-item-action",
      key: 2,
      render: (Ditem: any) => {
        return (
          <>
            <div className="table-actions-btn">
              <span
                onClick={() => {
                  setAddNewPopup(true);
                  const found = dataItemsAll.find(
                    (element: any) => element?.id === Ditem
                  );
                  console.log("found", found);
                  setSelectedEditData(found);
                }}
              >
                Edit
              </span>
              <span
                onClick={() => {
                  const found = dataItemsAll.find(
                    (element: any) => element?.id === Ditem
                  );
                  // setSelectedEditData(found);
                  handleDelete(Ditem);
                }}
              >
                Delete
              </span>
            </div>
          </>
        );
      },
    },
  ];
  // const dataItems1 = dataItems.filter((itemn: any) => itemn?.enable);
  const dataItems1 = dataItems;
  console.log("dataItems", dataItems);
  console.log("dataItems1", dataItems1);
  return (
    <>
      <ServiceManagementStyled>
        <div
          className={
            isRaskoAccount()
              ? `show-list-all-${secName}`
              : `show-list-all-${secName} hide-all-extra-services-items`
          }
        >
          <ConfirmationModal
            className="club-confirmation"
            show={isConfirmModalOpen}
            onHide={cancelPopup}
            description="Are you sure, do you want to delete?"
            onConfirmClick={handleRemove}
            confirmLabel="DELETE"
            cancelLabel="CANCEL"
          ></ConfirmationModal>
          <ConfirmationModal
            className="club-confirmation"
            show={isDeleteModalOpen}
            onHide={cancelPopup}
            description="Item Deleted Successfully"
            onConfirmClick={cancelPopup}
            confirmLabel="Okay"
          ></ConfirmationModal>

          <AddNewOption
            className="club-confirmation"
            selectedData={selectedEditData}
            show={addNewPopup}
            onHide={cancelPopup}
            description="Are you sure, do you want to delete?"
            onConfirmClick={handleRemove}
            {...props}
          />
          <div className="section-container-header">
            <h3>{props?.title}</h3>
            <input
              name="optionSearch"
              value={searchValue}
              onChange={onChangeValue}
              placeholder="Search"
            ></input>
            <span
              onClick={async () => {
                await setSelectedEditData({});
                setAddNewPopup(true);
              }}
            >
              {props?.subTitle}
            </span>
          </div>
          <div
            className={
              isProductBrand
                ? "section-container-body-table"
                : "section-container-body-table hide-book-service"
            }
          >
            {dataItems?.length < 10 ? (
              <Table
                columns={columns}
                dataSource={dataItems1}
                scroll={{ y: 540 }}
                pagination={false}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={dataItems1}
                scroll={{ y: 540 }}
              />
            )}
          </div>
        </div>
      </ServiceManagementStyled>
    </>
  );
};
export default CommonDynamicComponent;
