import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// import configureStore from "redux/store/configureStore";
// import { loadState, saveState } from "redux/store/localStorage";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// // TODO: move this out of here
// axios.defaults.headers.common["Content-Type"] = "application/json";
// // const persistedState = loadState();

// // const store = configureStore(persistedState);

// axios.interceptors.request.use(
//   (config: any) => {
//     if (config.url.includes("amazonaws.com")) return config;

//     const token = localStorage.getItem("accessToken") ?? "";
//     const idtoken = localStorage.getItem("idToken") ?? "";

//     config.headers["Authorization"] = token;
//     config.headers["idtoken"] = idtoken;

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axios.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     if (err?.response?.status === 401) {
//       console.log("vinnod, vinod 401");
//       // store.dispatch({ type: "CLEAR_STORE" }).then(() => {
//       // localStorage.clear();
//       // window.location.href = "/login";
//       //   window.location.href = `/?return=${window.location.pathname}`;
//       // });
//       return Promise.reject(err);
//     } else {
//       return Promise.reject(err);
//     }
//   }
// );

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
