import CommonDynamicComponent from "../ServiceManagement/CommonDynamicComponent/CommonDynamicComponent";
const LeadStatusList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Lead Status List"
        section="leadStatusList"
        subTitle="Create New Lead Status"
      />
    </>
  );
};
export default LeadStatusList;
