import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllProducts,
  getBrandsByPId,
  bookservicesummaryApi,
  getBooingDetails,
} from "./BookingManagementService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  allProductsList: any;
  allBrandsList: any;
  allBookingsList: any;
  bookingDetails: any;
}
const initialState: leadManagementState = {
  loading: false,
  error: null,
  allProductsList: [],
  allBrandsList: [],
  allBookingsList: {},
  bookingDetails: {},
};

export const bookingManagementSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    //configurationList
    builder.addCase(getAllProducts.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllProducts.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.allProductsList = action.payload;
      }
    );
    builder.addCase(
      getAllProducts.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(
      getBrandsByPId.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allBrandsList = action.payload?.brands ?? [];
      }
    );
    //bookservicesummaryApi
    builder.addCase(bookservicesummaryApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      bookservicesummaryApi.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.allBookingsList = action.payload;
      }
    );
    builder.addCase(
      bookservicesummaryApi.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //bookservicesummaryApi
    builder.addCase(getBooingDetails.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getBooingDetails.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.bookingDetails = action.payload?.BookService;
      }
    );
    builder.addCase(
      getBooingDetails.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const {
  setLoading,
  updateErrorMessage,
} = bookingManagementSlice.actions;

export default bookingManagementSlice.reducer;
