import { UsersListStyled } from "./UsersList.styled";
import Button from "../../components/Button/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
import { useEffect, useState } from "react";
import AddNewUser from "./AddNewUser";

const AddNewOption = (props: any) => {
  const { selectedData, addUserSuccess, onHide } = props;
  console.log("selectedData", selectedData);
  const [inputValue, setInputValue] = useState("");
  const noItems = [1, 2, 3, 4, 5, 66, 7, 7, 8, 8];
  useEffect(() => {
    if (selectedData?.id) {
      setInputValue(selectedData?.name);
    }
  }, [selectedData]);
  const handleSubmitFn = () => {
    console.log("inputValue", inputValue);
  };
  return (
    <>
      <Modal
        {...props}
        className="conformation-add-user-model-box1"
        size="lg"
        centered
      >
        <div className="conformation-model">
          <UsersListStyled>
            <Modal.Body>
              <div className="add-user-sec-box">
                <div className="new-option-header">
                  <h4>{`Add New User`}</h4>
                  <CloseButton onClick={props?.onHide} />
                </div>
                <div className="new-option-body">
                  <AddNewUser
                    addUser={true}
                    onHide={onHide}
                    selectedData={selectedData}
                    addUserSuccess={addUserSuccess}
                  />
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="new-option-body-actions">
                <Button onClick={handleSubmitFn}>Add New User</Button>
              </div>
            </Modal.Footer> */}
          </UsersListStyled>
        </div>
      </Modal>
    </>
  );
};
export default AddNewOption;
