import { useEffect, useRef } from "react";
import $ from "jquery";
import car_1 from "./img/carousel-1.jpg";
import car_2 from "./img/carousel-2.jpg";
import about from "./img/about.jpg";
import feature from "./img/feature.jpg";
import testimonial_1 from "./img/testimonial-1.jpg";
import testimonial_2 from "./img/testimonial-2.jpg";
import testimonial_3 from "./img/testimonial-3.jpg";
import testimonial_4 from "./img/testimonial-4.jpg";
import "./css/bootstrap.min.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";
import "./lib/animate/animate.min.css";
import "./css/index.css";
import WOW from "./lib/wow/wow";
import "animate.css";
import { useCountUp } from "react-countup";

const NewHome = () => {
  const carouselRef = useRef();
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
    // const $caro = $(carouselRef.current!);
    // $caro.owlCarousel({
    //   loop: true,
    //   margin: 10,
    //   nav: true,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     600: {
    //       items: 3
    //     },
    //     1000: {
    //       items: 5
    //     }
    //   }
    // });

    // Cleanup to destroy the carousel on unmount
    // return () => {
    //   $carousel.owlCarousel('destroy');
    // };
  }, []);

  useCountUp({
    ref: "counter",
    end: 14812,
    delay: 0.5,
    duration: 2,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: "counter2",
    end: 6241,
    delay: 1,
    duration: 2,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: "counter3",
    end: 65,
    delay: 1,
    duration: 2,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });

  var spinner = function () {
    setTimeout(function () {
      if ($("#spinner").length > 0) {
        $("#spinner").removeClass("show");
      }
    }, 1);
  };
  spinner();

  const $dropdown = $(".dropdown");
  const $dropdownToggle = $(".dropdown-toggle");
  const $dropdownMenu = $(".dropdown-menu");
  const showClass = "show";

  $(window).on("load resize", function () {
    if (this.matchMedia("(min-width: 992px)").matches) {
      $dropdown.on(
        "mouseenter",
        () => {
          const $this = $(this);
          $this.addClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "true");
          $this.find($dropdownMenu).addClass(showClass);
        },
        () => {
          const $this = $(this);
          $this.removeClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "false");
          $this.find($dropdownMenu).removeClass(showClass);
        }
      );
    } else {
      $dropdown.off("mouseenter mouseleave");
    }
  });

  $(window).on("scroll", () => {
    if (window.scrollY > 100 || document.documentElement.scrollTop > 100) {
      $(".back-to-top").fadeIn("slow");
    } else {
      $(".back-to-top").fadeOut("slow");
    }
  });
  $(".back-to-top").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, 1500, "easeInOutExpo");
    return false;
  });

  // $(".testimonial-carousel").owlCarousel({
  //     autoplay: true,
  //     smartSpeed: 1500,
  //     dots: true,
  //     loop: true,
  //     center: true,
  //     responsive: {
  //         0:{
  //             items:1
  //         },
  //         576:{
  //             items:1
  //         },
  //         768:{
  //             items:2
  //         },
  //         992:{
  //             items:3
  //         }
  //     }
  // });

  return (
    <div className="new-home-sec">
      <div
        id="spinner"
        className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div className="spinner"></div>
      </div>
      <div className="container-fluid position-relative p-0">
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={car_1} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3 maxwidth900">
                  <h5 className="text-white text-uppercase1 mb-3 animated slideInDown">
                    Thinking Globally | Acting Locally
                  </h5>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    New Age of SaaS ServeTech
                  </h1>
                  <p className="mb-md-4">
                    Providing a digital, Decision support system, So as to
                    address the pressing needs of the ServeTech industry, and
                    better manage and deliver quality Service outcomes.
                  </p>
                  <a
                    href="/signup"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Start free Trail
                  </a>
                  <a
                    href="/ContactUS"
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src={car_2} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3 maxwidth900">
                  <h5 className="text-white text-uppercase1 mb-3 animated slideInDown">
                    Thinking Globally | Acting Locally
                  </h5>
                  <h1 className="display-1 text-white  animated zoomIn">
                    New Age of SaaS ServeTech
                  </h1>
                  <p className="mb-md-4">
                    Providing a digital, Decision support system, So as to
                    address the pressing needs of the ServeTech industry, and
                    better manage and deliver quality Service outcomes.
                  </p>
                  <a
                    href="/signup"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Start free Trail
                  </a>
                  <a
                    href=""
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="modal fade" id="searchModal" tabIndex={-1}>
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content setbclr">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group setmaxWidth600">
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid facts py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4 setHeight150">
                <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2 ChooseDivchild">
                  <i className="fa fa-users text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Total Job Cards</h5>
                  <h1 className="text-white mb-0" id="counter">
                    {" "}
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
              <div className="bg-light shadow d-flex align-items-center justify-content-center p-4 setHeight150">
                <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2 ChooseDivchild">
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">Happy Customers</h5>
                  <h1 className="mb-0" id="counter2"></h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
              <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4 setHeight150">
                <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2 ChooseDivchild">
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Happy Clients</h5>
                  <h1 className="text-white mb-0" id="counter3"></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
                <h1 className="mb-0">Rasko Technologies Private Limited</h1>
              </div>
              <p className="mb-4">
                is a SaaS-based ServeTech IT solution provider that takes a
                data-driven approach to improve the standard of care and
                confront the Service | administrative and financial aspects of
                the ServeTech ecosystem. We are seeking to revolutionize
                ServeTech by providing secure and cutting-edge solutions that
                enable both ServeTech providers and Client to deliver better
                Service outcomes.
              </p>
              <p className="mb-4">
                Rasko’s vision is to be the platform that brings together all
                eco-system partners to deliver consumer happiness through great
                service after-sales
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Best CRM
                    Tool
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    Professional Staff
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>24/7
                    Support
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Fair Prices
                  </h5>
                </div>
              </div>
              <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div className="bg-primary d-flex align-items-center justify-content-center rounded ChooseDivchild">
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+91 888 666 3182</h4>
                </div>
              </div>
              <a
                href="/signup"
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                Start free Trail
              </a>
            </div>
            <div className="col-lg-5 setMinHeight500">
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn object-fit-cover"
                  data-wow-delay="0.9s"
                  src={about}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto setmaxWidth600">
            <h5 className="fw-bold text-primary text-uppercase">
              Why Choose Us
            </h5>
            <h1 className="mb-0">
              We Are Here to Grow Your Business Exponentially
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3 ChooseDivchild">
                    <i className="fa fa-cubes text-white"></i>
                  </div>
                  <h4>Best In Industry</h4>
                  <p className="mb-0">
                    Rasko is recognized as the best in its industry, setting the
                    benchmark for excellence and innovation. With a commitment
                    to superior quality and performance.
                  </p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3 ChooseDivchild">
                    <i className="fa fa-award text-white"></i>
                  </div>
                  <h4>Best CRM Tool</h4>
                  <p className="mb-0">
                    Rasko is the premier choice for businesses seeking an
                    exceptional Customer Relationship Management solution.
                    Renowned for its superior functionality and user-centric
                    design.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4  wow zoomIn setminhight350"
              data-wow-delay="0.9s"
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn object-fit-cover"
                  data-wow-delay="0.1s"
                  src={feature}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3 ChooseDivchild">
                    <i className="fa fa-users-cog text-white"></i>
                  </div>
                  <h4>Professional Staff</h4>
                  <p className="mb-0">
                    Our professional staff is the cornerstone of our success,
                    embodying the highest standards of expertise, dedication,
                    and professionalism.
                  </p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3 ChooseDivchild">
                    <i className="fa fa-phone-alt text-white"></i>
                  </div>
                  <h4>24/7 Support</h4>
                  <p className="mb-0">
                    Rasko we are committed to providing unparalleled support
                    with our round-the-clock, 24/7 service. Our dedicated
                    support team is available at all times to ensure that your
                    needs are met promptly and effectively, no matter the hour.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto setmaxWidth600">
            <h5 className="fw-bold text-primary text-uppercase">
              Our Services
            </h5>
            <h1 className="mb-0">
              Custom Solutions for Your ServeTech Business
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon bg-primary">
                  <i className="fa fa-shield-alt text-white"></i>
                </div>
                <h4 className="mb-3">Service Management</h4>
                <p className="m-0">
                  Our Service Management system is designed to ensure that all
                  aspects of our service delivery are executed with precision,
                  efficiency, and excellence.
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon bg-primary">
                  <i className="fa fa-chart-pie text-white"></i>
                </div>
                <h4 className="mb-3">Booking Management</h4>
                <p className="m-0">
                  Our Booking Management system is designed to streamline and
                  optimize the reservation process, ensuring a seamless
                  experience for both clients and staff.
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon bg-primary">
                  <i className="fa fa-code text-white"></i>
                </div>
                <h4 className="mb-3">Lead Management</h4>
                <p className="m-0">
                  Our Lead Management system is designed to streamline and
                  optimize the process of acquiring, nurturing, and converting
                  leads into loyal customers.
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon bg-primary">
                  <i className="fab fa-android text-white"></i>
                </div>
                <h4 className="mb-3">AMC Management</h4>
                <p className="m-0">
                  Our AMC (Annual Maintenance Contract) Management system is
                  designed to simplify and optimize the administration of
                  maintenance agreements. This robust solution ensures seamless
                  handling of contracts, enhances service delivery.
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon bg-primary">
                  <i className="fa fa-search text-white"></i>
                </div>
                <h4 className="mb-3">Reports</h4>
                <p className="m-0">
                  Our Reporting system provides powerful tools for generating,
                  analyzing, and interpreting comprehensive reports. Designed to
                  actionable intelligence to enhance operational efficiency,
                  track performance, and drive strategic initiatives.
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                <h3 className="text-white mb-3">Call Us For Demo</h3>
                <p className="text-white mb-3">
                  Experience the power and potential of Rasko firsthand by
                  scheduling a personalized demo with our expert team
                </p>
                <h3 className="text-white mb-0">+91 888 666 3182</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto setmaxWidth600">
            <h5 className="fw-bold text-primary text-uppercase">
              Pricing Plans
            </h5>
            <h1 className="mb-0">
              We offer services for every step of your journey.
            </h1>
          </div>
          <div className="row g-0">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div className="bg-light rounded">
                <div className="border-bottom py-4 px-5 mb-4">
                  <h4 className="text-primary mb-1">Basic Plan</h4>
                  <small className="text-uppercase">
                    For Small Size Business
                  </small>
                </div>
                <div className="p-5 pt-0">
                  <h1 className="display-5 mb-3">
                    <small className="align-top costSmall"></small>6 Months Free
                    {/* <small className="align-bottom setMonth">/ Month</small> */}
                  </h1>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Service Management</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Booking Management</span>
                    <i className="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Lead Management</span>
                    <i className="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>AMC Management</span>
                    <i className="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Reports</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>

                  <a href="/signup" className="btn btn-primary py-2 px-4 mt-4">
                    Start free Trail
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="bg-white rounded shadow position-relative setZindex">
                <div className="border-bottom py-4 px-5 mb-4">
                  <h4 className="text-primary mb-1">Standard Plan</h4>
                  <small className="text-uppercase">
                    For Medium Size Business
                  </small>
                </div>
                <div className="p-5 pt-0">
                  <h1 className="display-5 mb-3">
                    <small className="align-top costSmall"></small>365 Days Free
                    {/* <small className="align-bottom setMonth">/ Month</small> */}
                  </h1>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Service Management</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Booking Management</span>
                    <i className="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Lead Management</span>
                    <i className="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>AMC Management</span>
                    <i className="fa fa-times text-danger pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Reports</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <a href="/signup" className="btn btn-primary py-2 px-4 mt-4">
                    Start free Trail
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="bg-light rounded">
                <div className="border-bottom py-4 px-5 mb-4">
                  <h4 className="text-primary mb-1">Advanced Plan</h4>
                  <small className="text-uppercase">
                    For Large Size Business
                  </small>
                </div>
                <div className="p-5 pt-0">
                  <h1 className="display-5 mb-3">
                    <small className="align-top costSmall">RS </small>1000.00
                    <small className="align-bottom setMonth">/ Year</small>
                  </h1>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Service Management</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Booking Management</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Lead Management</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>AMC Management</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Reports</span>
                    <i className="fa fa-check text-primary pt-1"></i>
                  </div>
                  <a href="/signup" className="btn btn-primary py-2 px-4 mt-4">
                    Start free Trail
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-5 wow fadeInUp"
        id="RequestAQuote"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Start free Trail
                </h5>
                <h1 className="mb-0">
                  Need A Free Demo? Please Feel Free to Contact Us
                </h1>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-4">
                    <i className="fa fa-reply text-primary me-3"></i>Reply
                    within 24 hours
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-4">
                    <i className="fa fa-phone-alt text-primary me-3"></i>24 hrs
                    telephone support
                  </h5>
                </div>
              </div>
              <p className="mb-4">
                Discover the benefits of Rasko with a no-obligation, free demo
                tailored just for you. Our team is eager to show you how our
                solution can meet your needs and enhance your operations.
              </p>
              <div
                className="d-flex align-items-center mt-2 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="bg-primary d-flex align-items-center justify-content-center rounded ChooseDivchild">
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+91 888 666 3182</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <form>
                  <div className="row g-3">
                    <div className="col-xl-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0 setHeight55"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control bg-light border-0 setHeight55"
                        placeholder="Your Email"
                      />
                    </div>
                    <div className="col-12">
                      <select className="form-select bg-light border-0 setHeight55">
                        <option selected>Select A Service</option>
                        <option value="1">Service 1</option>
                        <option value="2">Service 2</option>
                        <option value="3">Service 3</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control bg-light border-0"
                        rows={3}
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-dark w-100 py-3" type="submit">
                        Start free Trail
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-4 mx-auto setmaxWidth600">
            <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
            <h1 className="mb-0">
              What Our Clients Say About Our Digital Services
            </h1>
          </div>
          <div
            className="owl-carousel  owl-theme testimonial-carousel wow fadeInUp"
            data-wow-delay="0.6s"
            //  ref={carouselRef}
          >
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded ChooseDivchild"
                  src={testimonial_1}
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Client Name</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded ChooseDivchild"
                  src={testimonial_2}
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Client Name</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded ChooseDivchild"
                  src={testimonial_3}
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Client Name</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded ChooseDivchild"
                  src={testimonial_4}
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Client Name</h4>
                  <small className="text-uppercase">Profession</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
                stet amet eirmod eos labore diam
              </div>
            </div>
          </div>
         
        </div>
      </div> */}
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </div>
  );
};

export default NewHome;
