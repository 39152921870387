import styled from "styled-components";

export const StyledTodayStatusReport = styled.div`
  .dashboard {
    margin: 40px;
  }
  .dashboard-top,
  .dashboard-middle,
  .dashboard-bottom {
    p {
      margin-bottom: 10px;
    }
  }
  .dashboard-top {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 130px;
  }
  .status-header-report,
  #accordionExample1 {
    margin-bottom: 20px;
  }
  .dashboard-overall {
    border-right: 2px solid rgba(32, 32, 32, 0.13);
    margin: 10px 0px;
    padding-top: 18px;
  }
  .listing-dp {
    width: 135px;
  }
  .dashboard-overall h5 {
    font-weight: 400;
    font-size: 31px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.25px;
  }
  .dashboard-overall p {
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.15px;
  }
  .dashboard-new {
    text-align: center;
    padding-top: 10px;
  }
  // .dashboard-new img{
  //   position: absolute;
  //   width: 18px;
  // height: 17px;
  // margin-top: 15px;
  // margin-left: -8px;
  // }
  .list-view-pp-view {
    display: flex;
    justify-content: center;
  }
  .dashboard-list-new {
    width: 42px;
    height: 42px;
    background: #4dbbff;
    border-radius: 25px;
    // margin-left: 55px;
  }
  .dashboard-new h5 {
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    margin-top: 10px;
  }
  .dashboard-new p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
  }
  .dashboard-list-ur {
    width: 42px;
    height: 42px;
    background: #fcce63;
    border-radius: 25px;
    // margin-left: 55px;
  }
  .dashboard-under-review {
    text-align: center;
    padding-top: 10px;
  }
  // .dashboard-under-review img{
  //   position: absolute;
  //   width: 18px;
  // height: 17px;
  // margin-top: 15px;
  // margin-left: -8px;
  // }
  .dashboard-under-review h5 {
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    margin-top: 10px;
  }
  .dashboard-under-review p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
  }
  .dashboard-list-app {
    width: 42px;
    height: 42px;
    background: #82d086;
    border-radius: 25px;
    // margin-left: 55px;
  }
  .dashboard-approved {
    text-align: center;
    padding-top: 10px;
  }
  .dashboard-approved img,
  .dashboard-under-review img,
  .dashboard-new img {
    position: absolute;
    width: 23px;
    height: 20px;
    margin-top: 12px;
    margin-left: -11px;
  }
  .dashboard-approved h5 {
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    margin-top: 10px;
  }
  .dashboard-approved p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
  }
  .dashboard-middle {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 130px;
    margin-top: 10px;
  }
  .dashboard-bottom {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 130px;
    margin-top: 10px;
  }
  .dashboard-list-ur-order {
    width: 42px;
    height: 42px;
    background: #fc9292;
    border-radius: 25px;
    // margin-left: 55px;
  }
  .dashboard-listing-report {
    width: 351px;
    height: 357px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .dashboard-graph {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 400px;
    margin-top: 30px;
  }
  .dashboard-right {
    margin-left: 20px;
  }
  .dashboard-listing-report p {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.15px;
    margin: 20px 13px 13px 13px;
    display: initial;
  }
  .listing-dp {
    margin-top: 10px;
    display: inline-block;
  }
  .css-1y1kqf3-control {
    height: 36px;
    margin-left: 13px;
  }
  .css-1fdsijx-ValueContainer {
    display: none;
  }
  @media (max-width: 700px) {
    .dashboard-top,
    .dashboard-middle,
    .dashboard-bottom {
      height: 180px;
    }
  }
`;
