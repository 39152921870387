import styled from "styled-components";

export const LeadSummaryDashboardStyled = styled.div`
  .lead-summary-dashboard-calls {
    margin-top: 0px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .lead-summary-dashboard-call-item {
    text-align: center;
    height: 150px;
    // padding: 5px;
    cursor: pointer;
    border: 1px solid #e2e2e2;
  }
  .lead-summary-dashboard-call-item p:nth-child(1) {
    height: 90px;
    line-height: 18px;
    padding: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    border-bottom: 1px solid #e2e2e2;
  }
  .lead-summary-dashboard-call-item p:nth-child(2) {
    font-size: 24px;
    padding: 5px;
    margin-top: -10px;
  }
  .job-card-dashboard-alerts {
    background: #35ffc3;
    text-align: center;
    padding-bottom: 1px;
    // padding-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    display: flex;
    padding: 5px;
    gap: 30px;

    h3 {
      color: #ed1e24;
      text-decoration: underline;
      margin-bottom: 7px;
      font-size: 20px;
      width: 10%;
    }
    p {
      color: #204289;
    }
  }
  .alerts-text-row {
    width: 90%;
  }

  .alerts-text-row p {
    margin-left: 100px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .box-all-rows {
    margin-top: 0px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // gap: 3px;
  }
  .box-column {
    // width: 200px;
  }
  .dash_item-sec {
    height: 6px;
    width: 6px;
    background: #000;
    display: inline-block;
    border-radius: 50px;
    top: -20px;
    position: static;
    margin-top: 10px;
  }
  .dash_item_text {
    display: inline-block;
    top: 2px;
    position: relative;
  }
  .box-row {
    background: red;
    color: #fff;
    text-align: center;
    // border-radius: 4px;
    height: 100px;
    padding: 5px;
    cursor: pointer;
    p {
      margin: 0px;
    }
  }
  .box-row p:nth-child(1) {
    height: 65px;
    line-height: 18px;
  }
  .box-row p:nth-child(2) {
    font-size: 24px;
    margin-top: -10px;
  }
  .box-column .box-row:nth-child(2) {
    margin-top: 8px;
  }
  .box-all-rows .box-column:nth-child(1) .box-row {
    // background: #ed1e24;
  }
  .box-all-rows .box-column:nth-child(2) .box-row {
    // background: #fe7e00;
  }

  .box-all-rows .box-column:nth-child(3) .box-row {
    // background: #48c207;
  }
  .box-all-rows .box-column:nth-child(4) .box-row {
    // background: #0faae3;
  }
  .box-all-rows .box-column:nth-child(5) .box-row {
    // background: #fff001;
    // color: #000;
  }
  .box-all-rows .box-column:nth-child(6) .box-row {
    // background: #ed1e24;
  }
  .box-all-rows .box-column:nth-child(7) .box-row {
    // background: #fe7e00;
  }
  .box-all-rows .box-column:nth-child(8) .box-row {
    // background: #48c207;
  }
  .box-all-rows .box-column:nth-child(9) .box-row {
    // background: #0faae3;
  }
  .box-all-rows .box-column:nth-child(10) .box-row {
    // background: #fff001;
    // color: #000;
  }
  .box-all-rows .box-column:nth-child(11) .box-row {
    // background: #fff001;
    // color: #000;
  }
  .box-all-rows .box-column:nth-child(12) .box-row {
    // background: #fff001;
    // color: #000;
  }
  .box-row1 {
    background: #cadff4;
    color: #000;
  }
  .box-row3 {
    background: #accaec;
    color: #000;
  }
  .box-row5 {
    background: #669dde;
    color: #000;
  }
  .box-row7 {
    background: #db6e6e;
  }
  .box-row9 {
    background: #ff0014cf;
  }
  .box-row11 {
    background: #ff0000;
  }
  .box-row13 {
    background: #57d0a3;
    color: #000;
  }
  .box-row15 {
    background: #33ea8f;
    color: #000;
  }
  .box-row17 {
    background: #00ffbc;
    color: #000;
  }
  .box-row19 {
    background: #d1cae9;
    color: #000;
  }
  .box-row21 {
    background: #a591e9;
    color: #000;
  }
  .box-row23 {
    background: #7e5bf6;
    color: #000;
  }

  .box-row2 {
    background: #e8dae7;
    color: #000;
  }
  .box-row4 {
    background: #e0bed9;
    color: #000;
  }
  .box-row6 {
    background: #cd8ebd;
    color: #000;
  }
  .box-row8 {
    background: #d6bebc;
  }
  .box-row10 {
    background: #d7aeaa;
  }
  .box-row12 {
    background: #f6a199;
  }
  .box-row14 {
    background: #62a6d9;
  }
  .box-row16 {
    background: #0092ff;
  }
  .box-row18 {
    background: #0062ff;
  }
  .box-row20 {
    background: #d7b27e;
  }
  .box-row22 {
    background: #f4cb93;
  }
  .box-row24 {
    background: #ffbf65;
  }

  .box-row.long_text {
    font-size: 14px;
  }
  .box-all-rows {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
  }
  .box-all-rows > div {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    // gap: 5px;
  }
`;
