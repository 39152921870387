import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { BookingServiceSummaryStyled } from "./BookingServiceSummary.styled";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import Form from 'react-bootstrap/Form';

import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
// import JobCardDashboard from "./JobCardDashboard";
import Button from "./../../../components/Button/Button";
import moment from "moment";
import DatePicker from "./../../LeadManagement/LeadSummary/DatePicker";
import {
  getJobcardSummaryApi,
  getFilteredjobcardsummaryApi,
  getJobCardDetailsApi,
  updateJobcardItemApi,
} from "./../../../redux/slices/serviceManagement/serviceManagementService";
import { getLeadDetailsById } from "./../../../redux/slices/LeadManagement/leadManagementService";
import LeadItemDetails from "../../LeadItemDetails/LeadItemDetails";
import {
  getallleadsApi,
  getFilteredjLeadsummaryApi,
  getleadsummaryApi,
  getfilteredleadsummaryApi,
} from "./../../../redux/slices/LeadManagement/leadManagementService";

import { bookservicesummaryApi } from "./../../../redux/slices/BookingManagement/BookingManagementService";

// import LeadSummaryDashboard from "./LeadSummaryDashboard";
import JobCardDetails from "./../../../pages/JobCardDetails/JobCardDetails";
import ConfirmationModal from "./../../../components/Common/ConfirmationModal";

// import { statusList, selctfieldsList } from "./../../Scenes/constants";
import print_img from "./../../assets/icons8-print-35.png";
import arrow_icon from "./../../img/arrow-205.png";
import arrow_icon_down from "./../../img/arrow-206.png";
import arrow_icon1 from "./../../img/down-arrow-6402.png";
import Loader from "./../../../components/Common/Loader";
import {
  statusList,
  severityOptions,
  statusListUpdate,
} from "./../../../Scenes/constants";

import {
  triggerMixpanelEvent,
  checkRolePermission,
} from "../../../Scenes/common";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const fieldsData1 = {};

const BookingServiceSummary: React.FC = (props: any) => {
  const query = useQuery();

  const statusVal = query.get("status");

  const [fieldsData, setFieldsData] = useState(fieldsData1 as any);
  const [age, setAge] = useState("");
  const [status, setStatus] = useState(statusVal ?? "");
  const [assigne, setAssigne] = useState("");
  const [selectedField, setSeselectedField] = useState("");
  const [selectedValue, setSeselectedValue] = useState("");
  const [priorityValue, setPriorityValue] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDashboard, setFromDashboard] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [jsonBody, setJsonBody] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  console.log("selectedItem", selectedItem);
  const [usersList, setUsersList] = useState([]);
  const [showClosedLeads, setShowClosedLeads] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { jobCardSummaryDetails } = useSelector(
    (state: any) => state.serviceManagement
  );

  const { allBookingsList } = useSelector(
    (state: any) => state.bookingManagement
  );

  const { allLeads, allLeadsData } = useSelector(
    (state: any) => state.leadManagement
  );

  useEffect(() => {
    triggerMixpanelEvent("job_summary_page");
    dispatch(getleadsummaryApi());
    dispatch(bookservicesummaryApi("pageNo=1"));
    // getAllLeadsCall();
    // if (!statusVal) {
    // dispatch(getfilteredleadsummaryApi());
    // }
    // if (!loginStoreDetails?.address && loginStoreDetails?.id) {
    //   setShowWarningMessage(true);
    // }
  }, []);
  // useEffect(() => {
  //   if (statusVal) {
  //     setStatus(statusVal);
  //   }
  // }, [statusVal]);
  useEffect(() => {
    handleSubmit(currentPage);
  }, [status, assigne, currentPage, priorityValue]);
  useEffect(() => {
    if (jobCardSummaryDetails?.users) {
      const usersList1 = [] as any;
      usersList1.push({ id: 0, value: "", label: "All" });

      jobCardSummaryDetails?.users?.map((user: any) => {
        const name =
          user?.firstName?.toLowerCase() + " " + user?.lastName?.toLowerCase();
        usersList1.push({ id: user?.id, value: user?.id, label: name });
      });
      setUsersList(usersList1);
    }
  }, [jobCardSummaryDetails]);
  const getAllLeadsCall = () => {
    dispatch(getallleadsApi());
  };
  // console.log("selectedField", selectedField);
  // console.log("selectedValue", selectedValue);
  // console.log("status", status);
  // console.log("status", status);
  // console.log("assigne", assigne);
  const handleClearFilter = () => {
    setStatus("");
    setAssigne("");
    setSeselectedField("");
    setSeselectedValue("");
    setFromDate("");
    setToDate("");
  };
  const handleSubmit = async (pageN: any) => {
    const paramsList = [] as any;
    const page222 = pageN || currentPage;
    paramsList.push(`pageNo=${page222}`);
    if (fromDashboard) {
      paramsList.push(`fromDashboard=${fromDashboard}`);
    }
    if (status) {
      paramsList.push(`status=${status}`);
    }
    if (assigne) {
      paramsList.push(`selectedUserId=${assigne}`);
    }
    if (fromDate) {
      paramsList.push(`fromDate=${fromDate}`);
    }
    if (toDate) {
      paramsList.push(`endDate=${toDate}`);
    }
    if (priorityValue) {
      paramsList.push(`priority=${priorityValue}`);
    }
    for (var key in fieldsData) {
      const Pvalue = fieldsData[key];
      if (Pvalue && Pvalue !== "") {
        paramsList.push(`${key}=${Pvalue}`);
      }
    }
    // if (selectedValue) {
    //   paramsList.push(`${selectedField}=${selectedValue}`);
    // }
    const paramsV = paramsList.join("&");
    // http://173.249.11.237:8080/focusnextjobcard/filteredjobcardsummary?pageNo=1
    // &jobNo=vinod2&serialNo1=vinod4&fromDate=2023-06-08&endDate=2023-06-23
    // &customerName=vinod1&mobileNo=8686864923&status=Closed&selectedUserId=5&refNo=vinod3
    // y?pageNo=1&fromDate=2023-06-08&endDate=2023-06-23&mobileNo=8686864923&status=Closed&selectedUserId=5
    setIsLoading(true);
    await dispatch(bookservicesummaryApi(paramsV));
    // await dispatch(getfilteredleadsummaryApi(paramsV));

    setIsLoading(false);
  };
  const handleChange = (event: any) => {
    setFromDashboard(false);
    setSeselectedValue(event.target.value as string);
  };
  const handleChangeStatus = (e: any) => {
    setCurrentPage(1);
    setStatus(e.value);
  };
  const statusUpdate = (sts: any, keName?: any) => {
    setCurrentPage(1);

    setAssigne("");
    setSeselectedValue("");
    setPriorityValue("");
    setStatus("");
    setFromDate("");
    setToDate("");
    triggerMixpanelEvent("dashboard_item_click", { status: sts });
    if (keName === "Priority") {
      setPriorityValue(sts);
      setFromDashboard(true);
    } else {
      setFromDashboard(false);
      setStatus(sts);
    }
  };
  const handleChangeAssigne = (e: any) => {
    setCurrentPage(1);
    setAssigne(e.value);
  };
  const handleChangeField = (e: any) => {
    setSeselectedField(e.value);
  };
  const onChangeValuePriority = (name: any, value: any, jobId: any) => {
    // const { name, value, jobId } = e.target;
    console.log("jobIdjobId", jobId);
    let jsonvalBody = {
      id: jobId,
      status: value,
    } as any;
    let newText = `Are you sure, do you want to change status to ${value}`;
    if (name == "priority") {
      newText = `Are you sure, do you want to change Priority to ${value}`;
      jsonvalBody = {
        id: jobId,
        priority: value,
      };
    } else {
    }
    setJsonBody(jsonvalBody);
    setIsConfirmModalOpen(true);
    setMessageText(newText);
  };
  const handleUpdateValue = async () => {
    setIsConfirmModalOpen(false);
    console.log("JsonBody", jsonBody);
    await dispatch(updateJobcardItemApi(jsonBody));
    setIsSuccessModalOpen(true);
    // dispatch(getJobcardSummaryApi());
    handleSubmit(1);
  };
  const editJobCardItem = (jobcard: any) => {
    triggerMixpanelEvent("book_service_edit_click", { jobId: jobcard });
    // history.push(``);
    window.location.href = `/editBookingService/${jobcard}`;
  };
  const gotoProfilePage = () => {
    history.push(`/myProfile`);
  };
  const viewJobCardItem = (jobcard: any) => {
    triggerMixpanelEvent("lead_item_view_click", { jobId: jobcard });
    // dispatch(getJobCardDetailsApi(jobcard));
    dispatch(getLeadDetailsById(jobcard));
    setShowJobDetails(true);
  };
  const handleDateChange = (fromD: any, toDate: any) => {
    setFromDashboard(false);
    setFromDate(fromD);
    setToDate(toDate);
  };
  const printJobCardItem = (jID: any) => {
    triggerMixpanelEvent("job_card_print_click", { jobId: jID });
    // window.location.href = ;
    window.open(`/printlead/${jID}`, "_blank");
  };

  const downloadJobCardItem = (jID: any) => {
    console.log("download", jID);
    triggerMixpanelEvent("job_card_download_click", { jobId: jID });
    // window.location.href = `/printJobCard/${jID}`;
  };
  const deleteJobCardItem = (jID: any) => {
    console.log("delete", jID);
    triggerMixpanelEvent("job_card_delete_click", { jobId: jID });
    // window.location.href = `/printJobCard/${jID}`;
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    const newList = fieldsData;
    newList[name] = value;
    setFieldsData(newList);
    setUpdateData(!updateData);
  };
  const cancelPopup = () => {
    // dispatch(getJobcardSummaryApi());
    setIsConfirmModalOpen(false);
    setIsSuccessModalOpen(false);
  };
  const handleSwichChange = (e: any) => {
    setShowClosedLeads(e.target.checked);
  };
  const items = [
    {
      key: "print",
      label: <span className="drop-down-single-item-sec">Print</span>,
    },
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "view",
      label: <span className="drop-down-single-item-sec">View</span>,
    },

    // {
    //   key: "download",
    //   label: <span className="drop-down-single-item-sec">Download</span>,
    // },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];

  const columns = [
    {
      title: "Booking ID",
      width: 80,
      dataIndex: "id",
      key: "0",
      fixed: "left",
      render: (id: any) => {
        return (
          <>
            <a target="_blank" href={`/bookingdetails/${id}`}>
              {id}
            </a>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "8",
      width: 100,
      fixed: "left",
      render: (item2: any) => {
        const name = item2;
        return <>{item2 ? moment(item2).format("DD/MM/YYYY") : null}</>;
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },
    {
      title: "Mobile No",
      dataIndex: "customerInfo",
      key: "2",
      width: 120,
      render: (item: any) => {
        // const name = item?.firstName + " " + item?.lastName;
        return <>{item?.mobileNo}</>;
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerInfo",
      key: "Name",
      width: 150,
      // fixed: "left",
      render: (item: any) => {
        // const name = item?.firstName + " " + item?.lastName;
        return <>{item?.customerName}</>;
      },
    },

    {
      title: "Email",
      dataIndex: "customerInfo",
      key: "8",
      width: 180,
      render: (item2: any) => {
        return <>{item2?.email}</>;
      },
    },
    {
      title: "City/Town",
      dataIndex: "customerInfo",
      key: "8",
      width: 180,
      render: (item2: any) => {
        return <>{item2?.town}</>;
      },
    },

    {
      title: "Product",
      dataIndex: "product",
      key: "64",
      width: 110,
      render: (item: any) => {
        return <>{item?.name}</>;
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "64",
      width: 110,
      render: (item: any) => {
        return <>{item?.name}</>;
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "64",
      width: 110,
      render: (item: any) => {
        return <>{item}</>;
      },
    },
    {
      title: "SerialNo1",
      dataIndex: "serialNo1",
      key: "64",
      width: 110,
      render: (item: any) => {
        return <>{item}</>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "64",
      width: 90,
      render: (item: any) => {
        return <>{item}</>;
      },
    },

    {
      title: "Age",
      dataIndex: "age",
      key: "64",
      width: 70,
      render: (item: any) => {
        return <>{item}</>;
      },
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "8",
      render: (item2: any) => {
        const name = item2?.firstName + " " + item2?.lastName;
        // return <>{name?.toLowerCase()}</>;
      },
      width: 150,
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 70,
      dataIndex: "id",
      render: (itemId: any) => {
        // console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  if (key === "view") {
                    viewJobCardItem(itemId);
                  } else if (key === "edit") {
                    editJobCardItem(itemId);
                  } else if (key === "download") {
                    downloadJobCardItem(itemId);
                  } else if (key === "delete") {
                    deleteJobCardItem(itemId);
                  } else if (key === "print") {
                    printJobCardItem(itemId);
                  }
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;
  const statusList1 = [{ id: 0, value: "", label: "All" }];
  const statusList3 = [
    { id: 5, value: "Closed", label: "Closed" },
    { id: 5, value: "Closing Approval", label: "Closing Approval" },
    { id: 5, value: "Re Open", label: "Re Open" },
  ];
  const initiateWhatsApp = () => {
    // const mobileNumber = 9164236553;
    // const whatsAppMsg = "hello";
    // // Check for perfect 10 digit length
    // if (mobileNumber.length != 10) {
    //   alert('Please insert correct WhatsApp number');
    //   return;
    // }
    // // Using 91 for India
    // // You can change 91 with your country code
    // let url =
    //   'whatsapp://send?text=' +
    //    whatsAppMsg +
    //   '&phone=91' + mobileNumber;
    // Linking.openURL(url)
    //   .then((data) => {
    //     console.log('WhatsApp Opened');
    //   })
    //   .catch(() => {
    //     alert('Make sure Whatsapp installed on your device');
    //   });
  };
  const statusList2 = [...statusList1, ...statusList, ...statusList3];
  console.log("fieldsData", fieldsData);
  console.log("allLeads", allLeads);
  console.log("allBookingsList", allBookingsList);
  return (
    <BookingServiceSummaryStyled>
      <div className="job-card-summary">
        <ConfirmationModal
          className="club-confirmation"
          show={isConfirmModalOpen}
          onHide={cancelPopup}
          description={messageText}
          onConfirmClick={handleUpdateValue}
          confirmLabel="UPDATE"
          cancelLabel="CANCEL"
        ></ConfirmationModal>

        <ConfirmationModal
          className="club-confirmation"
          show={isSuccessModalOpen}
          onHide={cancelPopup}
          description="Successfully updated"
          onConfirmClick={cancelPopup}
          confirmLabel="OKAY"
          // cancelLabel="CANCEL"
        ></ConfirmationModal>

        <Modal
          size="xl"
          show={showJobDetails}
          className="job-card-details-popup"
          onHide={() => setShowJobDetails(false)}
          aria-labelledby="job-card-details-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="job-card-details-lg">
              Lead Item Details{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LeadItemDetails showItems={true} fromPopup={true} />
          </Modal.Body>
        </Modal>
        {isLoading && <Loader />}
        {showWarningMessage && (
          <div className="profile-warning-message-text">
            <p>
              Please complete the profile setup, profile details will display in
              print page.
            </p>
            <button onClick={gotoProfilePage}>Complete Profle</button>
          </div>
        )}
        <h2 className="section-container-header-new" id="headingOne1111">
          <span>Booking Summary Dashboard</span>
        </h2>
        {/* <LeadSummaryDashboard /> */}
        {/* <JobCardDashboard statusUpdate={statusUpdate} /> */}
        <div className="quick-search-section-list">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Quick Search
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse " //open
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className=" row ">
                    <div className="col-sm-6 mt-15 date-select-range-sec">
                      <DatePicker handleDateChange={handleDateChange} />
                    </div>

                    {/* <div className="select-dropdown-filters row col-sm-6 mt-15"> */}
                    <div className="col-sm-3 product-name-drop-down">
                      <Form.Group>
                        <label htmlFor="" className="col-form-label">
                          Select Status <span className="manditary">*</span>
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue="Select"
                          isSearchable={true}
                          options={statusList2 as any}
                          onChange={(e) => {
                            setFromDashboard(false);
                            handleChangeStatus(e);
                          }}
                          required
                          placeholder={status ? status : "Select..."}
                          // value={jobData?.brandName}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 product-name-drop-down">
                      <Form.Group>
                        <label htmlFor="" className="col-form-label">
                          Assigned To <span className="manditary">*</span>
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue="Select"
                          isSearchable={true}
                          options={usersList}
                          onChange={(e) => {
                            setFromDashboard(false);
                            handleChangeAssigne(e);
                          }}
                          required
                        />
                      </Form.Group>
                    </div>
                    {/* </div> */}
                  </div>
                  {/* <div> */}
                  <div className="row mt-15">
                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Customer Name
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          value={fieldsData?.recevierName}
                          type="text"
                          name="customerName"
                          id="customerName"
                          onChange={onChangeValue}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Mobile No
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          value={fieldsData?.mobileNo}
                          type="text"
                          name="mobileNo"
                          id="mobileNo"
                          onChange={onChangeValue}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Booking ID
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          value={fieldsData?.bookServiceId}
                          type="text"
                          name="bookServiceId"
                          id="bookServiceId"
                          onChange={onChangeValue}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-3 ">
                      <Form.Group>
                        <Form.Label className="requiredField">
                          Seril No 1
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          value={fieldsData?.serialNo1}
                          type="text"
                          name="serialNo1"
                          id="serialNo1"
                          onChange={onChangeValue}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className=" row filter-action-sec">
                    <div className="col-sm-3 "></div>
                    <div className="col-sm-3 "></div>
                    <div className="col-sm-3 "></div>
                    {/*  <div className="col-sm-9 mt-15 search-row-sec">
                   <div className="search-row-select">
                        <Form.Group>
                          <label htmlFor="" className="col-form-label">
                            Select Field <span className="manditary">*</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue="Select"
                            isSearchable={true}
                            options={selctfieldsList as any}
                            onChange={(e) => {
                              setFromDashboard(false);
                              handleChangeField(e);
                            }}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="search-row-search">
                        <input
                          value={selectedValue}
                          onChange={handleChange}
                          placeholder="Enter Value"
                        />
                      </div> 
                    </div>*/}
                    <div className="col-sm-3 5 filter-actions-btn">
                      <Button
                        onClick={() => {
                          setCurrentPage(1);
                          handleSubmit(1);
                        }}
                        text="Filter"
                      />
                      <div className="download-icon">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-file-arrow-down"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z" />
                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h2
          onClick={() => {
            setShowSearch(!showSearch);
          }}
          className="section-container-header-new section-container-header-new-search"
        >
          <span> Quick Search </span>{" "}
          <span>
            {" "}
            <img src={showSearch ? arrow_icon_down : arrow_icon} />{" "}
          </span>
          
        </h2> */}
        {/* <span onClick={handleClearFilter} className="clear-filters-sec">
              Clear Filters
            </span> */}
        {/* <div className="page-sec-header"></div> */}
        {showSearch && <></>}
        {/* <div className="jobcard-filters">
          <DateRangePicker
            ranges={range}
            alwaysShowCalendars={true}
            onEvent={handleEvent}
          >
            <button>
              {moment(fromDate).format("LL")} to {moment(toDate).format("LL")}
            </button>
          </DateRangePicker>
        </div> */}
        <h2 className="section-container-header-new d-flex-s-b ">
          <span onClick={initiateWhatsApp}>
            {" "}
            Booking Management Summary ({allBookingsList?.totalCount})
          </span>

          {/* {checkRolePermission("createjobcard") && (
            <>
              <span className="middle-line-space-sec">
                {``}|{``}
              </span>
              <span>
                <a href="/CreateLead">Create Lead</a>
              </span>
            </>
          )} */}
        </h2>

        <div className="jobcard-content">
          {allBookingsList?.bookServices?.length > 0 && (
            <Table
              columns={columns}
              pagination={false}
              dataSource={allBookingsList?.bookServices || []}
              scroll={{ x: 1500, y: 500 }}
              rowClassName={(record, index) =>
                `row_selected_priority_${record?.priority}`
              }
              // expandable={{
              //   // defaultExpandAllRows: false,
              //   expandedRowRender: (record) => <RowItemInfo item={record} />,
              // // }}
              // expandable={{
              //   expandedRowRender: (record) => (
              //     <p style={{ margin: 0 }}>{"record.description"}</p>
              //   ),
              //   // rowExpandable: record => record.name !== "Not Expandable"
              // }}
              // total={50}
              // expandRowByClick
            />
          )}

          {/* <Pagination defaultCurrent={1} total={50} /> */}
          {/* noOfPages */}
          <div className="job-summary-pagination">
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={allLeadsData?.totalCount}
              onChange={(val) => {
                pageonChange(val);
              }}
              pageSize={10}
              showSizeChanger={false}
              // size="small"
            />
          </div>
        </div>
      </div>
      <div></div>
    </BookingServiceSummaryStyled>
  );
};

export default BookingServiceSummary;
