import CommonDynamicComponent from "../ServiceManagement/CommonDynamicComponent/CommonDynamicComponent";
const LeadActiontakenList = () => {
  return (
    <>
      <CommonDynamicComponent
        title="Lead Action taken List"
        section="leadActiontakenList"
        subTitle="Create New Action taken"
      />
    </>
  );
};
export default LeadActiontakenList;
