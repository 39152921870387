import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import AboutUsList from "./../Home/AboutUsList";
import { AboutUsStyled } from "./AboutUs.styles";
import about_us from "../../img/about-us.png";
import location_img from "../../img/icons8-location-26.png";
import phone_img from "../../img/icons8-phone-26.png";
import email_img from "../../img/icons8-email-30.png";

import { triggerMixpanelEvent } from "./../../Scenes/common";
const AboutUs = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { allProducts } = useSelector(
  //   (MainROute: any) => MainROute?.productCart
  // );
  useEffect(() => {
    triggerMixpanelEvent("About_Us");
  }, []);

  return (
    <AboutUsStyled>
      <div className="middle-section">
        <div className="middle-about-us-section">
          <div className="about-us-top-section">
            <h2>
              About <span>RASKO</span>
            </h2>
            <div className="about-us-page-top-sec">
              {/* <div> */}
              <p>
                A complete services and repairs business operations management
                solution. Create Job sheets, manage services and generate
                invoices.A common module to manage both products and spare parts
                inventory. Stock management, consumption history and stock
                transfers.Simple and sophisticated sales billing solution.
                Invoice generation and payment details.
                <br />
                Create unlimited users with just single license. Set up your
                entire network from day one with any of our licenses and upgrade
                only on more usage. A true pay as you go model.
              </p>
              {/* <p>
                  Create unlimited users with just single license. Set up your
                  entire network from day one with any of our licenses and
                  upgrade only on more usage. A true pay as you go model.
                </p>
              </div> */}
              <div>
                <img src={about_us} />
              </div>
            </div>
          </div>
          <div className="about-us-bottom-section">
            <h2>OUR APPROACH</h2>
            <div className="about-us-item">
              <h4>Industry Interaction</h4>
              <div>
                <ul>
                  <li>Drive More Customers Through Digital</li>
                  <li>
                    Dialogue to understand emerging trends, technological
                    advancements, and skill demands.
                  </li>
                  <li>
                    Identify the most sought-after skill sets and competencies
                    in various sectors.
                  </li>
                </ul>
              </div>
            </div>

            <div className="about-us-item">
              <h4>Customized Program Design</h4>
              <div>
                <ul>
                  <li>
                    Forge connections with distinguished academic experts from
                    around the world.
                  </li>
                  <li>Revolutionize your online business today!</li>
                  <li>Unlimited users Access</li>
                  <li>Unlimited Reports</li>
                </ul>
              </div>
            </div>

            {/* <div className="about-us-item">
              <h4>Needs Assessment and Alignment</h4>
              <div>
                <ul>
                  <li>
                    Conduct a comprehensive needs assessment combining industry
                    insights and academic expertise.
                  </li>
                  <li>
                    Analyze the gap between existing education and industry
                    requirements.
                  </li>
                  <li>
                    Align curriculum development with the identified skill gaps
                    and emerging trends.
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className="about-us-item">
              <h4>Customized Program Design</h4>
              <div>
                <ul>
                  <li>
                    Tailor program content to address specific industry demands.
                  </li>
                  <li>
                    Craft modules that incorporate cutting-edge practices,
                    tools, and methodologies.
                  </li>
                  <li>
                    Design a cohesive learning journey that builds holistic
                    skill sets.
                  </li>
                </ul>
              </div>
            </div>
            <div className="about-us-item">
              <h4>High-Tech Pedagogy Integration</h4>
              <div>
                <ul>
                  <li>
                    Integrate advanced technology and pedagogical tools for
                    enhanced learning.
                  </li>
                  <li>
                    Utilize virtual labs, simulations, interactive content, and
                    AI-driven assessments.
                  </li>
                  <li>
                    Create an immersive and dynamic learning environment that
                    resonates with learners.
                  </li>
                </ul>
              </div>
            </div>
            <div className="about-us-item">
              <h4>Engaging Learning Experience</h4>
              <div>
                <ul>
                  <li>
                    Develop interactive and engaging content to captivate
                    learners&#39; interest.
                  </li>
                  <li>
                    Foster active participation, collaboration, and critical
                    thinking.
                  </li>
                  <li>
                    Ensure a seamless blend of theoretical concepts and
                    practical applications.
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="about-us-item">
              <h4>Continuous Review and Improvement</h4>
              <div>
                <ul>
                  <li>
                    Establish mechanisms for ongoing review and feedback from
                    Store partners and learners.
                  </li>
                  <li>
                    Iteratively refine program content based on real-world
                    outcomes and changing demands.
                  </li>
                  <li>
                    Embrace agility to adapt to evolving industry landscapes and
                    technological advancements.
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="about-us-item">
              <h4>Outcome-Centric Focus</h4>
              <div>
                <ul>
                  <li>
                    Prioritize the acquisition of skills that translate directly
                    to industry success.
                  </li>
                  <li>
                    Measure learner progress and achievement against predefined
                    benchmarks.
                  </li>
                  <li>
                    Empower learners to confidently navigate professional
                    challenges and excel in their careers.
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="contact-us-sec">
              <p>Contact US</p>
              <div>
                <div className="sec-row-item-row">
                  <div>
                    {" "}
                    <img src={location_img} />{" "}
                  </div>
                  <div>
                    <p>Address</p>
                    <span>
                      No.2 100 ft JP Nagar 7th Phase, Bangalore, India -560043
                    </span>
                  </div>
                </div>
                <div className="sec-row-item-row">
                  {" "}
                  <div>
                    {" "}
                    <img src={phone_img} />{" "}
                  </div>
                  <div>
                    <p>Phone</p>
                    <span>+91 916 423 6553 / +91 964 034 3337</span>
                  </div>
                </div>
                <div className="sec-row-item-row">
                  <div>
                    {" "}
                    <img src={email_img} />{" "}
                  </div>
                  <div>
                    <p>Email</p>
                    <span>contact@rasko.in</span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutUsStyled>
  );
};
export default AboutUs;
