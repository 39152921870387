import { RightOutlined, LeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Button = (props) => {
  const text = props?.text ?? "submit";
  return (
    <div>
      <div className="button-global-btn">
        <button {...props}>{text}</button>
      </div>
      <style jsx>{`
        .button-global-btn button {
          text-transform: capitalize;
          background: steelblue;
          line-height: 35px;
          border-radius: 5px;
          min-width: 100px;
          text-align: center;
          color: #fff;
          border: none;
          padding: 0px 40px;
          height: 40px;
          font-size: 20px;
          margin-top: -2px;
        }
      `}</style>
    </div>
  );
};

export default Button;
