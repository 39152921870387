import styled from "styled-components";

export const CreateLeadManagementStyled = styled.div`
  .terms-sec-son {
    width: 80%;
    margin: auto;
  }
  .terms-sec-son-btn {
    text-align: center;
    button {
      height: 45px;
      background: #000;
      border: 4px;
      color: #fff;
      padding: 0px 40px;
      border-radius: 4px;
      font-size: 20px;
    }
  }
  .create-lead-action-btns {
    justify-content: end;
    margin-top: 30px;
  }
  .Requirement-Information-sec {
    margin-top: 20px;
  }
  .row_create_lead_req_information {
    height: 60px;
  }
  .input-row-box {
    width: 100%;
    height: 35px;
  }
  .terms-sec-son-btn {
    margin-top: 30px;
  }

  .main-row-actions-items {
    display: flex;
    gap: 10px;
    svg {
      width: 25px;
      cursor: pointer;
      height: 25px;
    }
  }
  .action-taken-items1 {
    width: 100%;
    margin-top: 10px;
    padding: 15px 20px;
    // border: 1px solid #006261;
    border-radius: 4px;
    margin-top: 10px;
    .action-usernames > div {
      line-height: 18px;
    }
  }

  .warrenty-type-section23 {
    margin-bottom: 30px;
  }
  .warrenty-type-section23 .radio-button-values {
    margin: 0;
    .row-radio-box {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .is-dropdown-expanded-values,
  .ant-table-body {
    overflow: initial !important;
  }
  .remarks-sec-to-sec {
    textarea {
      display: block;
      width: 100%;
      margin-top: 10px;
      padding: 10px;
    }

    label {
      font-weight: bold;
      font-size: 22px;
      color: #0b66b2;
      background: #d8e6f3;
      padding-left: 20px;
      width: 100%;
    }
  }
  .create-edit-lead-sec {
  }
`;
