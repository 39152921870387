import React, { useState, useEffect, useRef } from "react";
import CommonReportData from "./CommonReportData";
import { StyledTodayStatusReport } from "./TodayStatusReport.styled";
import DateRangeSelect from "./../../components/DateRangeSelect/DateRangeSelect";
import moment from "moment";
import { getStoreWiseJobCardReportAPi } from "../../redux/slices/Reports/ReportsService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import usersicon from "../../img/usersicon.png";
import priorityicon from "../../img/priorityicon.png";
import jobcardicon from "../../img/jobcardicon.png";

const TodayStatusReport = (props: any) => {
  const [startDate, setStartDate] = useState(moment().format("DD/MM/YYYY"));
  const [endDate, setEndDate] = useState(moment().format("DD/MM/YYYY"));
  const history = useHistory();
  const dispatch = useDispatch();
  const { todayReports } = useSelector((state: any) => state.reports);
  const { fromDashboard } = props;
  const buttonRef = useRef(null as any);

  useEffect(() => {
    console.log("useEffect");
    getAllData();
  }, []);
  useEffect(() => {
    console.log("useEffect");
    getAllData();
    // window.scrollTo(0, 600);
  }, [startDate, endDate]);

  const handleFilterClick = (datesV?: any) => {
    console.log("datesV", datesV);
    setStartDate(moment(datesV[0]?.startDate).format("DD/MM/YYYY"));
    setEndDate(moment(datesV[0]?.endDate).format("DD/MM/YYYY"));
    buttonRef.current.click();
  };
  const getAllData = () => {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(getStoreWiseJobCardReportAPi(body));
  };
  const listing = "null";
  console.log("todayReports", todayReports);
  const dataF = todayReports;
  return (
    <>
      <StyledTodayStatusReport>
        {!fromDashboard && (
          <div className="status-header-report">
            <h4>Today Status Report</h4>
          </div>
        )}

        <div className="accordion" id="accordionExample1">
          <div className="accordion-item">
            <h2
              className="accordion-header"
              id="today_status_report_headingOne1"
            >
              <button
                className="accordion-button"
                type="button"
                ref={buttonRef}
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne1"
                aria-expanded="true"
                aria-controls="collapseOne1"
              >
                Select Custom Date wise
              </button>
            </h2>
            <div
              id="collapseOne1"
              className="accordion-collapse  collapse" //open //collapse
              aria-labelledby="today_status_report_headingOne1"
              data-bs-parent="#accordionExample1"
            >
              <div className="accordion-body">
                <div className="line-reports-status-view-sec">
                  <DateRangeSelect handleFilterClick={handleFilterClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4>{`Showing Reports from ${startDate} To ${endDate}`} </h4>
          <br />
        </div>
        <div className="dashboard-left col-12">
          <div className="dashboard-top d-flex">
            <div className="dashboard-overall col-3">
              <h5>{dataF?.totalJobcards}</h5>
              <p>Total Job cards</p>
            </div>
            <div className="dashboard-new col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-new">
                  <img src={jobcardicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.openJobcards}</h5>
              <p>Open Job cards</p>
            </div>
            <div className="dashboard-under-review col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-ur">
                  <img src={jobcardicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.closeJobcards}</h5>
              <p>Close Job cards</p>
            </div>
            <div className="dashboard-approved col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-app">
                  <img src={jobcardicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.otherJobcards}</h5>
              <p>Other Status Job cards</p>
            </div>
          </div>

          <div className="dashboard-middle d-flex">
            <div className="dashboard-overall col-3">
              <h5>{dataF?.totalCustomers}</h5>
              <p>Total Customers</p>
            </div>
            <div className="dashboard-new col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-new">
                  <img src={usersicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.newCustomers}</h5>
              <p>New Customers</p>
            </div>
            <div className="dashboard-under-review col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-ur">
                  <img src={usersicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.oldCustomers}</h5>
              <p>Old Customers</p>
            </div>
            {/* <div className="dashboard-approved col-3">
                            <div className="list-view-pp-view">
                                <div className="dashboard-list-app">
                                    <img src={listing} alt="" />
                                </div>
                            </div>
                            <h5>80</h5>
                            <p>Approved Content</p>
                        </div> */}
          </div>

          <div className="dashboard-bottom d-flex">
            <div className="dashboard-overall col-3">
              <h5>
                {dataF?.highPriotiryJobcards +
                  dataF?.mediumPriotiryJobcards +
                  dataF?.lowPriotiryJobcards}
              </h5>
              <p>Overall Status</p>
            </div>
            <div className="dashboard-new col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-new">
                  <img src={priorityicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.highPriotiryJobcards}</h5>
              <p>High</p>
            </div>
            <div className="dashboard-under-review col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-ur-order">
                  <img src={priorityicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.mediumPriotiryJobcards}</h5>
              <p>Medium</p>
            </div>
            <div className="dashboard-approved col-3">
              <div className="list-view-pp-view">
                <div className="dashboard-list-app">
                  <img src={priorityicon} alt="" />
                </div>
              </div>
              <h5>{dataF?.lowPriotiryJobcards}</h5>
              <p>Low</p>
            </div>
          </div>

          {/* {!isAuctionContentOnly && (
            <div className="dashboard-graph d-flex">
            <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data1}
            options={options1}
          />
            </div>
            )} */}
        </div>

        {/* <CommonReportData secName="status" /> */}
      </StyledTodayStatusReport>
    </>
  );
};
export default TodayStatusReport;
