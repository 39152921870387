import styled from "styled-components";

export const TermsOfServiceStyled = styled.div`
  .content {
    width: 80%;
    margin: auto;
    margin-top: 60px;
  }
  .bold-p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .normal-p {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 640px) {
    .content {
      width: 95%;
      margin: auto;
      margin-top: 30px;
    }
  }
`;
